import qoalaStorage from '~/core/Storage';

export default {
  updatePolicy(state, value) {
    state.policy = value;
    qoalaStorage.setItem('policy', JSON.stringify(state.policy));
  },
  updateBenefits(state, value) {
    state.benefits = value;
    qoalaStorage.setItem('benefits', JSON.stringify(state.benefits));
  },
  updateCancelToken(state, value) {
    state.cancelTokenAxios = value;
  }
};
