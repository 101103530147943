const tncUS = {
  tncContent: {
    headTitle: 'Qoala Application’s Terms and Condition',
    content: [
      {
        title: 'Terms of Use',
        paragraph: [
          'The terms of use below apply to every Apps from PT Archor Teknologi Digital (“Qoala”) namely Qoala App (“Application”),' +
            ' including the use of contents, services and features on the Application.',
          'As part of the registration process, you are required to provide your name, mobile phone number, email, identity and' +
            ' bank account number through the Application.' +
            ' You agree that all information provided on Registration and contained as' +
            ' part of your account is true, complete and accurate and that you will promptly inform us of any changes to such' +
            ' information by updating the information in your account. All information, data, and documents that you provide. Will be kept confidential and will not be misused.',
          'If required by law or as part of data/identity verification process,' +
            ' you agree for us right to share your information to' +
            ' government institutions or other related third parties. By registering and using the Application means that you' +
            ' understand and agree to be bound with it.' +
            ' If you do not agree with all of the terms that applied in the Application,' +
            ' we advise you to not use this Application.'
        ]
      },
      {
        title: 'Change in Terms of Use',
        paragraph: [
          'Qoala can change, add, or amend this Terms of Use at any time, which will be pushed through our Application update. You' +
            ' agree that with every amendment and' +
            ' therefore we suggest you to regularly check on the Application to see and access' +
            ' every information regarding the Term of Use that bound you.'
        ]
      },
      {
        title: 'Intellectual Property Rights',
        paragraph: [
          'Every logo and/or Application’s  logo and/or the brand of every services, contents, products, and features in the' +
            ' Application are the intellectual properties of Qoala. You are not permitted to use, or implement the logo and/or' +
            ' Application’s logo without a prior written consent from Qoala. All contents throughout the Application was' +
            ' developed and owned by Qoala, and therefore Qoala has the full ownership rights based on Indonesian Copyright law.'
        ]
      },
      {
        title: 'Terms and Conditions of Application Services',
        paragraph: [
          'By clicking on the “REGISTER” button, you agree to access and/or subscribe the services, contents, products, or other' +
            ' feature (will be referred as “Services”) in the application. You also agree to follow the Terms and Conditions for' +
            ' using the Services according with the law that applies in Indonesia. If you don’t agree to the Terms and Conditions' +
            ' of this Application, you’re not allowed to use the Application’s Service.',
          'By using the Service on the Application, you have made an agreement with Qoala and therefore you are obliged to learn the' +
            ' Terms and Conditions of Service every time you access the Services. When you keep using the Services on the' +
            ' Application, you accept the Terms and Conditions of the Services including any future amendments.'
        ]
      },
      {
        title: 'User Obligation',
        paragraph: [
          'The use of this Application must abide to the law and regulations of Republic of Indonesia. You guarantee that you will' +
            ' only use the Application and its Services according to the Terms and Conditions and/or by the rule of law in' +
            ' Indonesia.',
          'You agreed to exempt and/or protect Qoala from any charges and/or lawsuits from other parties on but not limited to your' +
            ' usage of the Application and/or Services within the Application;' +
            ' and/or violation of the Terms of Services and/or the' +
            ' Terms and Conditions of the Application.'
        ]
      },
      {
        title: 'Misuse or Loss',
        paragraph: [
          'If there are missing and/or misuse of Application’s account, you as the user who use the services must inform Qoala right' +
            ' away. As the Application user,' +
            ' you are responsible for every expenses that has become your burden as a User, including' +
            ' but not limited to all expenses that are related to misuse and/or' +
            ' using it to do  scam/illegal activities by the user' +
            ' or another parties.'
        ]
      },
      {
        title: 'Suspension and Service Termination',
        paragraph: [
          'Based on its discretion and for the customer experience of their users, Qoala has the rights to suspend or terminate the' +
            ' services without prior notice or compensation towards you, if anything listed below happen:'
        ],
        list: [
          'Reparation, modification or maintenance of the Services.',
          'User violate the Terms and Conditions.',
          'If you and/or other parties are considered by Qoala causing loss or damage towards the service or Qoala.',
          'Qoala considered you and/or other parties misuse the service for scamming.'
        ],
        paragraphTwo: [
          'Qoala will try to act as fast as possible if the suspension or termination happens because of the things mentioned above.' +
            ' You will be responsible for all the expenses during the Service disturbance, termination or loss.'
        ]
      },
      {
        title: 'Disclaimer',
        paragraph: [
          'All the information in the Application is valid at the time its provided by Qoala. Qoala reserves the rights to amend' +
            ' the information from time to time.',
          'The app may include inaccuracies and typographical errors. Changes and improvements are periodically made to the app' +
            ' and the information therein.' +
            ' We do not warrant or assume any legal liability or responsibility for the completeness,' +
            ' or usefulness of any information.',
          'You also agree to hold Qoala  and each of its officers, directors, employees and agents from and against any and all' +
            ' claims, actions, demands, liabilities, judgments and settlements.',
          'All costs incurred during the claim submission process are fully deferred to the customer.'
        ]
      },
      {
        title: 'Applicable Law',
        paragraph: [
          'This Term of Use including the Terms and Conditions of the Services in the Application abide to the law of the Republic' +
            ' of Indonesia.'
        ]
      },
      {
        title: 'How to report a potential security vulnerability',
        paragraph: [
          'You can email us at security@qoala.id to report a potential security vulnerability. Qoala will respond in a timely manner to confirm receipt of your email. '
        ]
      },
      {
        title: 'Customer Grievance',
        paragraph: [
          'We value your satisfaction and are committed to providing the best possible experience with our products or services. We understand that issues may arise from time to time, and we apologize for any inconvenience caused.',
          'To communicate and resolve any existing or arising grievances you may have experienced while using the Qoala Application, you may contact Qoala Customer Experience via:',
          'Email: cs@qoala.id <br/> Landline: 021-50645035 <br/> WA: +62 822 1033 3220',
          'Rest assured that Qoala Customer Experience will respond to your query in a timely manner within 24 hours during working days to handle any such grievances involving any issues you may be experiencing from the use of the Qoala Application or any of our products.',
        ],
      },
      {
        title: 'Claims Rejection',
        paragraph: [
          'Under specific circumstances where the insurance company has issued a claim rejection, you may submit an appeal towards such claim rejection to the Qoala Claims Department team via email to claimdept@qoala.id. Whilst we will ensure our best efforts to represent your cause in resolving any claim rejections, by use of our service, you agree that the final decision with regard to such claims is subject to the terms and conditions as set out within your insurance policy, and we cannot be held responsible for circumstances outside of our control.',
        ],
        withExternalLink: true,
        link: 'mailto:claimdept@qoala.id',
        linkWords: 'claimdept@qoala.id',
      },
    ]
  },
  tncContentEnMs: {
    headTitle: 'Qoala Application’s Terms and Condition',
    content: [
      {
        title: 'Terms of Use',
        paragraph: [
          'The terms of use below apply to every application from Qoala Technology Sdn Bhd (“Qoala”) particularly Qoala App (“Application”),' +
            ' including the use of contents, services and features on the Application (“Services”).',
          'As part of the registration process, you are required to provide your name, mobile phone number, email, identity and' +
            ' bank account number through the Application.' +
            ' You agree that all information provided during registration and contained as' +
            ' part of your account is true, complete and accurate and that you will promptly inform us of any changes to such' +
            ' information by updating the information in your account. All information, data, and documents that you provide' +
            ' will be kept confidential and will not be misused.' +
            ' If required by law or as part of the data/identity verification process, you agree to grant us the right to' +
            ' share your information with government institutions or other related third parties.',
          '	By using the Application, you agree and consent to these Terms of Use.'
        ]
      },
      {
        title: 'Change in Terms of Use',
        paragraph: [
          'Qoala can change, add, or amend these Terms of Use at any time without prior notice. We' +
            ' advise that you check the Application for these Terms of Use on a regular basis.'
        ]
      },
      {
        title: 'Intellectual Property Rights',
        paragraph: [
          'Every logo and/or Application’s  logo and/or the brand of every service, content, product, and feature in the' +
            ' Application are the intellectual properties of Qoala. You are not permitted to use, or implement the logo and/or' +
            ' Application’s logo without prior written consent from Qoala. All content throughout the Application was' +
            ' developed and owned by Qoala, and therefore Qoala has the full ownership rights based on Malaysian Copyright law.'
        ]
      },
      {
        title: 'User Obligation',
        paragraph: [
          'The use of this Application must abide by the law and regulations of Malaysia. You guarantee that you will' +
            ' only use the Application and its Services according to the Terms and Conditions and/or by the rule of law in' +
            ' Malaysia.',
          'You agreed to exempt and/or protect Qoala from any charges and/or lawsuits from other parties on but not limited to your' +
            ' usage of the Application and/or Services within the Application;' +
            ' and/or violation of the Terms of Services and/or the' +
            ' Terms and Conditions of the Application.'
        ]
      },
      {
        title: 'Scope of Services',
        paragraph: [
          '	Qoala is an approved participant of Bank Negara Malaysia’s Financial Technology Regulatory Sandbox, as' +
            ' an insurance & takaful aggregator. We provide information on insurance and takaful products, and enable' +
            ' you to configure, compare and purchase these products. We do not provide insurance advice.'
        ]
      },
      {
        title: 'Misuse',
        paragraph: [
          'If there is compromise and/or misuse of your account on the Application, you must inform Qoala right' +
            ' away. As the Application user,' +
            ' you are responsible for every expense that has become your burden as a User, including' +
            ' but not limited to all expenses that are related to misuse and/or' +
            ' scams/illegal activities by the user' +
            ' or other parties.'
        ]
      },
      {
        title: 'Suspension and Service Termination',
        paragraph: [
          'Based on its discretion and for the customer experience of its users, Qoala has the rights to suspend or terminate the' +
            ' Services without prior notice or compensation towards you, if anything listed below happen:'
        ],
        list: [
          'Reparation, modification or maintenance of the Services.',
          'You violate the Terms and Conditions.',
          'If you and/or other parties are considered by Qoala to be causing loss or damage towards the service or Qoala.',
          'If you and/or other parties are considered by Qoala to be misusing the service for scams/illegal activities.'
        ],
        paragraphTwo: [
          'Qoala will try to act as fast as possible if the suspension or termination happens because of the reasons mentioned above.' +
            ' You will be responsible for all the expenses during the Service disturbance, termination or loss.'
        ]
      },
      {
        title: 'Disclaimer',
        paragraph: [
          'All the information in the Application is valid at the time it is provided by Qoala. Qoala reserves the rights to amend' +
            ' the information from time to time.',
          'The app may include inaccuracies and typographical errors. Changes and improvements are periodically made to the app' +
            ' and the information therein.' +
            ' We do not warrant or assume any legal liability or responsibility for the completeness,' +
            ' or usefulness of any information.',
          'You also agree to hold Qoala  and each of its officers, directors, employees and agents from and against any and all' +
            ' claims, actions, demands, liabilities, judgments and settlements.'
        ]
      },
      {
        title: 'Applicable Law',
        paragraph: [
          'This Term of Use including the Terms and Conditions of the Services in the Application abide to the law of Malaysia.'
        ]
      },
      {
        title: 'How to report a potential security vulnerability',
        paragraph: [
          'You can email us at security@qoala.id to report a potential security vulnerability. Qoala will respond in a timely manner to confirm receipt of your email. '
        ]
      }
    ]
  }
};

export default tncUS;
