export default {
  async getProductList(context, { payload }) {
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    try {
      const { data } = await this.$axios.post(
        `/products/personalaccident`,
        payload
      );
      const response = data;
      context.commit('setListProduct', response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getRecalculatePremi(context, { payload }) {
    try {
      const { data } = await this.$axios.post(
        `/products/personalaccident/calculate`,
        payload
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },
};
