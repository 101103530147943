const FaqUS = {
  // FAQ
  tabbar_general: 'General',
  tabbar_flight: 'Flight',
  tabbar_train: 'Train',
  tabbar_gadgets: 'Gadget',
  tabbar_bus: 'Bus',
  tabbar_dbd: 'Dengue',
  tabbar_accom: 'Accommodation',

  faq_general_title: 'Do you have any problem?',
  faq_general: [
    {
      id: 1,
      question: 'When is the latest I can submit the claim?',
      answer: 'You can submit your claim through Qoala apps within 2x24 hours from your scheduled departure time.'
    },
    {
      id: 2,
      question: 'How can I register my profile at Qoala?',
      answer: 'Registration can be done in less than 2 minutes, all you have to do is:',
      list: [
        'Go to www.qoala.app',
        'Fill in your ID and Bank information',
        'Submit your claim at "My Policy"',
        'Your compensation will be paid in 1x24 hours after our system approved your claim'
      ]
    },
    {
      id: 3,
      question: 'Why did I fail to upload my ID card?',
      answer:
        'Please make sure you have uploaded your ID card, not blurry, not croped, and taken in a good light condition so Qoala can verify your ID.'
    },
    {
      id: 4,
      question: 'Can I register and submit the claim without my bank information?',
      answer:
        'Unfortunately, the compensation from Qoala can only be transfered through banks that are listed and verified by Qoala. We suggest you to create an account on one of the listed banks to continue the registration and claim process.'
    },
    {
      id: 5,
      question: 'Why do I need to inform my ID and Bank account?',
      answer:
        'We need your ID and Bank information to guarantee that your compensation will be received fast and go to the right person. Qoala will keep it secret and never spread your information with any reason.'
    },
    {
      id: 6,
      question: 'How can I get protected by Qoala?',
      answer:
        'You can get protected when purchasing a travel ticket from our partners: Padiciti, Goden Nusa, Panorama, MNC, etc. For more information please visit our website at www.qoala.id.'
    },
    {
      id: 7,
      question: 'Can I submit the claim without giving my location?',
      answer:
        'To submit the claim, you have to give permission to access your location. You can go to your browser setting, and choose to accept permission. For best result, we recommend you to use Google Chrome when using Qoala app.'
    },
    {
      id: 8,
      question: "Why didn't I receive my OTP?",
      answer:
        'Please make sure that your email is correct and you are using a registered email. You can also click "Resend OTP" to resend the code.'
    },
    {
      id: 9,
      question: 'Why is my username not working?',
      answer: 'Please make sure you enter your registered email on the username box, not your full name.'
    },
    {
      id: 10,
      question: 'Forget password?',
      answer:
        'Please click "Forget Password", a reset confirmation will be sent to your email and you can change your password into the new one.'
    },
    {
      id: 11,
      question: "Can I use someone else's bank account to receive the compensation?",
      answer:
        'Unfortunately, Qoala only transfer the compensation to the registered bank account that have been verified by Qoala.'
    },
    {
      id: 12,
      question: "Why haven't I get my compensation within 1x24 hours?",
      answer:
        "If you haven't received the compensation within 1x24 hours, it may be due to some technical problems during the transfering process from the bank to your account. Please wait and check periodically, for more information you can contact us through live chat. Thank you."
    },
    {
      id: 13,
      question: 'How can I change my bank information?',
      answer:
        'You can contact our support team through email at cs@qoala.id to change your bank information. Our team will help you to change it.'
    },
    {
      id: 14,
      question: 'How can I get the compensation?',
      answer:
        'The compensation will be transfered to your account (or the one that you registered) within 1x24 hours after your claim has been approved.'
    },
    {
      id: 15,
      question: 'Who else have the rights to get the compensation?',
      answer: 'Anyone who is listed on the policy have the rights to get compensated.'
    }
  ],
  faq_flight_title: 'What problem are you facing?',
  faq_flight: [
    {
      id: 1,
      question: 'What is Flight Delay Protection?',
      answer:
        'If your flight got delayed for 120 minutes or more from the official schedule, you can get a compensation up to Rp3.000.000.'
    },
    {
      id: 2,
      question:
        'If I travel with 10 peoples (group booking), can I represent them to submit the claim, or do they have to personally submit the claim?',
      answer:
        'Submitting the claim can be represented by you or anyone as long as you and the other passengers have the same booking code. You just need to upload all of the boarding pass.'
    },
    {
      id: 3,
      question:
        "My flight has been delayed and I've got to change my flight to another airline, can I still submit the claim and got compensated?",
      answer:
        'You can submit the claim and got compensated for your first delayed flight (the one that is registered in Qoala). If your new flight got delayed too, it will not be elligible for the compensation.'
    },
    {
      id: 4,
      question: "Can I get compensated if my flight got delayed due to airline's change of schedule?",
      answer:
        "Unfortunately, Qoala can't give you compensation if your flight got delayed due to airline's change of schedule. Please contact the airline for more information about your compensation."
    },
    {
      id: 5,
      question:
        'I got an accident while on flight or train and got wounded, can I submit a claim and get compensation from Qoala?',
      answer:
        'If the accident causes you to partially or fully lose your body functions, you can submit your claim to get compensated. Please send an email to cs@qoala.id or chat us on www.qoala.app for more information.'
    },
    {
      id: 6,
      question: 'When can I make a claim for a delayed flight?',
      answer: 'You can make the claim if your flight got delayed for more than 120 minutes from the scheduled time.'
    },
    {
      id: 7,
      question: 'How can I submit the claim for my family member who got into an accident while travelling?',
      answer:
        "Register your family member's information, the one who got into an accident, to www.qoala.app and you can help him/her to submit the claim. For more information, you can send an email to cs@qoala.id or contact us via live chat at www.qoala.id."
    },
    {
      id: 8,
      question: 'How long can I submit a claim for flight cancellation?',
      answer:
        'You can submit your claim between H-15 to H+2 from your official schedule, according to the terms and condition that applied.'
    },
    {
      id: 9,
      question: 'What are the required documents for cancellation due to health issues?',
      answer:
        'You will need to submit a medical document from a hospital and the assigned doctor stating that you have to cancel your trip due to medical reasons.'
    }
  ],
  faq_train_title: 'What problem are you facing?',
  faq_train: [
    {
      id: 1,
      question: 'What is Train Delay Protection?',
      answer:
        "If your train's arrival gets delayed for 60 minutes or more from the official schedule, you can get a compensation up to Rp.50.000."
    },
    {
      id: 2,
      question:
        'I got an accident while in flight or train and got wounded, can I submit a claim and get compensation from Qoala?',
      answer:
        'If the accident causes you to partially or fully lose your body functions, you can submit your claim to get compensated. Please send an email to cs@qoala.id or chat us on www.qoala.app for more information.'
    },
    {
      id: 3,
      question: 'How can I submit the claim for my family member who got into an accident while travelling?',
      answer:
        "Register your family member's information, the one who got into an accident, to www.qoala.app and you can help him/her to submit the claim. For more information, you can send an email to cs@qoala.id or contact us via live chat at www.qoala.id."
    },
    {
      id: 4,
      question: 'If I make cancellation for my train ticket, can I still submit the claim and get the compensation?',
      answer: 'You can submit a claim and get compensation if you meet one of these conditions:',
      list: [
        ' Personal cancellation: you have to upload your refund from KAI in 1x24 hours before your train departure time. You will get 25% from your ticket price as a compensation (you will get the rest of 75% from KAI)',
        'Cancellation because of sickness / accident / mourning: you will get 100% compensation (of your ticket price). Claim can be submitted by uploading the letter from doctor or hospital (no need to attach the refund from KAI) and submitted in maximum of 7x24 hours before the scheduled departure time'
      ]
    },
    {
      id: 5,
      question: 'How to submit claim for delayed train?',
      answer:
        'If your arrival time got delayed for 60 minutes or more, you can report it to our support team. Please make sure you take a picture of your train with your ticket attached next to it and upload it to our Qoala app.'
    },
    {
      id: 6,
      question: 'What if I forget to take a picture of my train or the destination station?',
      answer:
        'Unfortunately, to process your claim for delayed train, we need a picture along with the train or the last station that is written on your train ticket.'
    },
    {
      id: 7,
      question: 'What are the required documents for cancellation due to health issues?',
      answer:
        'We will need a medical document from a hospital and the assigned doctor stating that you have to cancel your trip due to medical reasons.'
    }
  ],
  faq_gadgets_title: 'What problem are you facing?',
  faq_gadgets: [
    {
      id: 1,
      question: 'What is the difference between Screen Protection & Full Protection?',
      answer:
        'Screen Protection only covers damage to the phone screen and LCD, while Full Protection covers any damage to the phone, except theft. Coverage limit can be found here.'
    },
    {
      id: 2,
      question: 'What are the benefits of buying Screen Protection from Qoala?',
      answer: 'This is the benefit that you will get from Qoala Screen Protection:',
      list: [
        'Protection from any risk that damage your smartphone screen',
        'Protection limit based on your smartphone value, and valid for one use only',
        'Claim and service process through www.qoala.app',
        'Qoala protection is valid for 1 year after purchasing date'
      ]
    },
    {
      id: 3,
      question: 'What are the benefits of buying Full Protection from Qoala?',
      answer: 'This is the benefit that you will get from Qoala Full Protection:',
      list: [
        'Protection from any risk that damage your smartphone',
        'Protection limit based on your smartphone value, and can be used more than once',
        'Protecting your phone from liquid damage (soaked in rain, and others)',
        'Qoala protection is valid for 1 year after purchasing date'
      ]
    },
    {
      id: 4,
      question: 'How can I purchase Qoala Phone Protection?',
      answer:
        "You can purchase Qoala Phone Protection when buying new phone from our partner's stores. List of our partner can be found here."
    },
    {
      id: 5,
      question: 'How can I submit a claim for my smartphone?',
      answer:
        "To submit a claim, you need to register and complete your personal information on www.qoala.app. Open your policy details and click the '+' button to submit a claim."
    },
    {
      id: 6,
      question: 'If my phone is damaged by liquid, will it be covered?',
      answer: 'Any liquid damage will be covered if you purchased the Full Protection.'
    },
    {
      id: 7,
      question: 'If my phone is stolen, will it be covered?',
      answer: 'Unfortunately, lost phone is not covered by our insurance products.'
    },
    {
      id: 8,
      question:
        "My parents bought Qoala Phone Protection for their phone, but they don't know how to make a claim. Can I submit a claim on behalf of my friends or family?",
      answer: 'Yes, to submit a claim on behalf of someone else, you can login to your account and do the following:',
      list: [
        "Go to 'Add Policy' section",
        "Select 'Smartphone' on the dropdown options",
        "Enter your family member or friend's IMEI number",
        'Submit a claim'
      ]
    },
    {
      id: 9,
      question: "Can I use my phone protection plan to make a claim for someone else's phone?",
      answer: 'No, you can only make a claim for the phone that was registered in the policy.'
    },
    {
      id: 10,
      question: 'Can I have multiple protections for one phone?',
      answer: 'Each phone can only have one phone protection plan.'
    },
    {
      id: 11,
      question: "I just bought my phone few days ago and it got damaged. Why can't I submit a claim?",
      answer: 'There is a waiting period of 14 days for you to make a claim after you purchased the phone protection.'
    },
    {
      id: 12,
      question: 'Can I renew the Qoala Phone Protection after it expires?',
      answer: "Currently you can't renew the smartphone protection after it expires."
    },
    {
      id: 13,
      question: 'Can I top up the phone protection balance?',
      answer: 'No, the phone protection balance cannot be topped up.'
    },
    {
      id: 14,
      question: 'Can I upgrade my phone protection from Screen Protection to Full Protection?',
      answer: 'Currently phone protection plan cannot be upgraded.'
    },
    {
      id: 15,
      question:
        'If the sales input my policy incorrectly (Screen Protection instead of Full Protection), will my phone still be protected?',
      answer: 'No need to worry. If that happened, we can changed your policy so your phone will still get protected.'
    },
    {
      id: 16,
      question: "Will i get a notified if there's a delay on my smartphone reparation?",
      answer:
        'Yes, you will get a notification from Qoala Customer Experiences Team and your apps if your phone repair got delayed.'
    }
  ],
  faq_bus_title: 'What problem are you facing?',
  faq_bus: [
    {
      id: 1,
      question: 'What is Qoala redBus Protection?',
      answer: 'redBus works together with Qoala to protect you from any troubles that might happen during the trip.'
    },
    {
      id: 2,
      question: 'What kind of protection that redBus and Qoala offered?',
      list: [
        'Accidental Death, protect you from the worst thing that could happen (death) whenever an accident occur during the trip, with coverage up to RM 25,000',
        'Partial & Permanent Disability, protect you whenever an accident occur during the trip and causes you to lost a body function, total or partial with coverage up to RM 25,000',
        'Medical Expense Reimbursement, coverage up to RM 2000 for your medical expense, if something happens during the trip and causes you to get medical help',
        'Loss or Damage of Luggage, protect you whenever your luggage was lost during the trip, with coverage up to RM 500 (electronics and Jewelry are not covered)'
      ]
    },
    {
      id: 3,
      question: 'How can i get those protection?',
      answer: 'You can add Qoala protection whenever you bought a ticket from redBus official website at www.redbus.my.'
    },
    {
      id: 4,
      question: 'How can i submit the claim?',
      answer:
        'You must register through www.qoala.app to verify your account. After that, you submit the claim and upload the required documents.'
    },
    {
      id: 5,
      question: 'What is Accidental Death and what the required documents?',
      answer:
        'Passengers can submit a claim if they have an accident that causes passengers to die. Here are the required documents for Accidental Death:',
      list: [
        'Takaful form',
        'Prove of travel (e.g ticket bus)',
        'Copy of NRIC',
        'Copy of Police report',
        'Death certificate',
        'Burial permit',
        'Post mortem report (upon request)',
        'Copy of NRIC/birth certificate/married certificate of next kin'
      ]
    },
    {
      id: 6,
      question: 'What is Partial & Permanent Disability and what the required documents?',
      answer:
        'If the accident causes you to partially or fully lose your body functions, you can submit your claim to get compensated. Here are the required documents for Partial & Permanent Disability:',
      list: [
        'Takaful form',
        'Prove of travel (e.g ticket bus)',
        'Copy of NRIC',
        'Copy of Police report',
        'Copy of medical report/attending Physician statement (APS form provided by us) completed by a Doctor ',
        'Photographs of injured limb/disability-latest photo of injury part and whole body'
      ]
    },
    {
      id: 7,
      question: 'What is Medical Expense Reimbursement and what the required documents?',
      answer:
        'If something happens during the trip and causes you to get medical help, you can submit a claim. Here are the required documents for Medical Expense Reimbursement:',
      list: [
        'Takaful form',
        'Prove of travel (e.g ticket bus)',
        'Copy of NRIC',
        'Copy of Police report',
        'Copy of medical report from attending Physician/Doctor',
        'Original medical bills/receipt'
      ]
    },
    {
      id: 8,
      question: 'What is Loss or Damage of Luggage and what the required documents?',
      answer:
        'If you sustain loss your baggage within the accommodation area, will pay the relevant benefits subject to the terms and conditions of the insurance policy. Here are the required documents for Loss or Damage of Luggage:',
      list: [
        'Takaful form',
        'Prove of travel (e.g ticket bus)',
        'Copy of NRIC',
        'Copy of Police report',
        'Letter of confirmation from the bus operator',
        'Purchase receipts for loss luggage claimed. If not available, provide a description of items and the date, place, and price of purchase'
      ]
    },
    {
      id: 9,
      question: 'Will I get compensation if I lose valuable items from my luggage?',
      answer:
        'Unfortunately we do not cover valuable items like electronics, jewelry, and cash. We suggest you not to leave any valuable items in your luggage.'
    },
    {
      id: 10,
      question: 'Who should I contact if I experiences any troubles?',
      answer:
        'If you’re experiencing any troubles when submitting your claim, register, or anything that is related to Qoala, please contact our Team through live chat feature on www.qoala.app or send an email to cs@qoala.id.'
    },
    {
      id: 11,
      question: 'How long will my claim be approved?',
      list: [
        'For Death, TPD and PPD claims, your compensation will be made within 7 working days',
        'For Medical and Loss of Luggage, your compensation will be made within 3 working days'
      ]
    },
    {
      id: 12,
      question: 'I forgot my my password, can i using another email to submit the claim?',
      answer:
        'If you forgot your password, please click ‘forgot password’ and input your email (please use the registered email to ease your claim process).'
    },
    {
      id: 13,
      question: 'Can i extend this protection for 1 year?',
      answer: 'Unfortunately you can’t. You can only use this protection when you ride with redBus.'
    },
    {
      id: 14,
      question: 'When I bought Qoala redBus Protection, will i automatically get a policy from the insurer?',
      answer:
        'You will automatically registered on www.takaful-ikhlas.com.my and don’t worry, your claim process will be fast and simple with www.qoala.app.'
    },
    {
      id: 15,
      question: 'Do I need to do registration with Takaful Ikhlas?',
      answer:
        'No, you only need to register through www.qoala.app, no need to register again and you can submit the claim.'
    },
    {
      id: 16,
      question: 'Will i still get protected if my trip got rescheduled?',
      answer:
        'Unfortunately, no. Your protection can only be applied once and if your trip got rescheduled, you can buy it again for your new trip.'
    },
    {
      id: 17,
      question: 'Which insurer does Qoala and redBus collaborate with?',
      answer:
        'To protect your trip, we have collaborate with Takaful Ikhlas, you can visit their official website at www.takaful-ikhlas.com.my.'
    },
    {
      id: 18,
      question: 'Will I received my Takaful Certificate?',
      answer:
        'Of course! Everytime you bought a ticket, we will automatically send the Takaful Certificate policy to your email.'
    }
  ]
};

export default FaqUS;
