import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';
import Cookies from 'js-cookie';

export default {
  getAppVersion(state) {
    return state.appVersion;
  },
  getLoadingStatus(state) {
    return state.loader;
  },
  getModalMessage(state) {
    return state.message;
  },
  getModalPasswordConfirmation(state) {
    return state.modalPasswordConfirmation;
  },
  getMessageStatus(state) {
    return state.modalMessage;
  },
  getSearchPolicy(state) {
    return state.searchPolis;
  },
  getListBank: (state) => {
    if (!state.listBank) {
      return qoalaStorage.getItem('banks')
        ? parseIfJsonString(qoalaStorage.getItem('banks'))
        : state.listBank || [];
    }
    return state.listBank;
  },
  getFirstTime: (state) => {
    if (!state.firstTime) {
      return qoalaStorage.getItem('activeFirstTime')
        ? parseIfJsonString(qoalaStorage.getItem('activeFirstTime'))
        : state.firstTime;
    }
    return state.firstTime;
  },
  getPin: (state) => {
    if (!state.pin) {
      return qoalaStorage.getItem('pin')
        ? parseIfJsonString(qoalaStorage.getItem('pin'))
        : state.pin;
    }
    return state.pin;
  },
  getOTPStatus: (state) => {
    if (!state.otpStatus) {
      return qoalaStorage.getItem('otpStatus')
        ? parseIfJsonString(qoalaStorage.getItem('otpStatus'))
        : state.otpStatus;
    }
    return state.otpStatus;
  },
  getProfileView: (state) => state.profileView,
  getCalledOnce: (state) => state.calledOnce,
  getNotifHome: (state) => {
    if (!state.notifHome) {
      return qoalaStorage.getItem('notifHome')
        ? parseIfJsonString(qoalaStorage.getItem('notifHome'))
        : state.notifHome;
    }
    return state.notifHome;
  },
  getPdfLink: (state) => {
    if (!state.pdfLink) {
      return qoalaStorage.getItem('pdfLink')
        ? parseIfJsonString(qoalaStorage.getItem('pdfLink'))
        : state.pdfLink;
    }
    return state.pdfLink;
  },
  getA2hs: (state) => {
    if (!state.a2hs) {
      return qoalaStorage.getItem('A2HS')
        ? parseIfJsonString(qoalaStorage.getItem('A2HS'))
        : state.a2hs;
    }
    return state.a2hs;
  },
  getVolumeUp: (state) => state.volumeUp,
  getVolumeDown: (state) => state.volumeDown,
  getPickUpDates: (state) => state.pickUpDates,
  getAddress: (state) => state.addressData,
  getLoginData: (state) => {
    if (!state.loginData) {
      return qoalaStorage.getItem('loginData')
        ? parseIfJsonString(qoalaStorage.getItem('loginData'))
        : state.loginData;
    }
    return state.loginData;
  },
  getPurchaseHistory: (state) => {
    if (!state.purchaseHistory) {
      return qoalaStorage.getItem('purchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('purchaseHistory'))
        : state.purchaseHistory;
    }
    return state.purchaseHistory;
  },
  getDetailPromo: (state) => state.detailPromo,
  getActiveTransaction: (state) => {
    if (!state.activeTransaction) {
      return qoalaStorage.getItem('activeTransaction')
        ? parseIfJsonString(qoalaStorage.getItem('activeTransaction'))
        : state.activeTransaction;
    }
    return state.activeTransaction;
  },
  getInsurancePartner: (state) => {
    return state.insurancePartner || [];
  },
  getComparedProduct: (state) => {
    if (!state.comparedProduct) {
      return qoalaStorage.getItem('comparedProduct')
        ? parseIfJsonString(qoalaStorage.getItem('comparedProduct'))
        : state.comparedProduct || [];
    }
    return state.comparedProduct;
  },
  getGuest: (state) => {
    if (!state.guest) {
      return Cookies.get('guest')
        ? parseIfJsonString(Cookies.get('guest'))
        : state.guest || {};
    }
    return state.guest;
  },
  getTransactionHistory: (state) => {
    if (!state.transactionHistory) {
      return qoalaStorage.getItem('transactionHistory')
        ? parseIfJsonString(qoalaStorage.getItem('transactionHistory'))
        : state.transactionHistory || [];
    }
    return state.transactionHistory;
  },
  getTransactionHistoryDetail: (state) => {
    if (!state.transactionHistoryDetail) {
      return qoalaStorage.getItem('transactionHistoryDetail')
        ? parseIfJsonString(qoalaStorage.getItem('transactionHistoryDetail'))
        : state.transactionHistoryDetail;
    }
    return state.transactionHistoryDetail;
  },
  getSnapToken: (state) => {
    if (!state.snapToken) {
      return qoalaStorage.getItem('snapToken')
        ? parseIfJsonString(qoalaStorage.getItem('snapToken'))
        : state.snapToken;
    }
    return state.snapToken;
  },
  getLocaleLanguage: (state) => {
    if (!state.localeLanguage) {
      return qoalaStorage.getItem('localeLanguage')
        ? parseIfJsonString(qoalaStorage.getItem('localeLanguage'))
        : state.localeLanguage || [];
    }
    return state.localeLanguage;
  },
  getUtmParams: (state) => {
    if (state.utmParams) return state.utmParams;

    const storage = qoalaStorage.getItem('utmParams');
    if (!storage) return undefined;

    const parsedStorage = parseIfJsonString(storage);
    return parsedStorage;
  },
  getDefaultLanguage: (state) => {
    if (!state.defaultLanguage) {
      return qoalaStorage.getItem('defaultLanguage')
        ? parseIfJsonString(qoalaStorage.getItem('defaultLanguage'))
        : state.defaultLanguage;
    }
    return state.defaultLanguage;
  },
  getReferer: (state) => {
    if (!state.referer) {
      const storage = qoalaStorage.getItem('referer');
      if (!storage) return undefined;

      const parsedStorage = parseIfJsonString(qoalaStorage.getItem('referer'));
      if (parsedStorage.expiresAt < new Date().getTime()) return undefined;
      return parsedStorage.value;
    }
    if (state.referer.expiresAt < new Date().getTime()) return undefined;
    return state.referer.value;
  },
  getGtmUserId: (state) => {
    if (!state.gtmUserId) {
      return qoalaStorage.getItem('gtmUserId')
        ? parseIfJsonString(qoalaStorage.getItem('gtmUserId'))
        : state.gtmUserId;
    }
    return state.gtmUserId;
  },
  getTheme: (state) => {
    if (!state.theme) {
      return qoalaStorage.getItem('theme')
        ? parseIfJsonString(qoalaStorage.getItem('theme'))
        : state.theme;
    }
    return state.theme;
  }
};
