function healthMap(code) {
  let label = 'Rawat Inap & Rawat Jalan';
  switch (code) {
    case 'IP':
      label = 'Rawat Inap';
      break;

    case 'OP':
      label = 'Rawat Jalan';
      break;

    default:
      break;
  }
  return label;
}
export default healthMap;
