export default {
  async getProductList(context, { sort, payload }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    payload.uuid = uuid;
    const url = `/products/life${sort ? '?sort=' + sort : ''}`;
    try {
      const { data } = await this.$axios.post(url, payload);
      const response = data;
      return context.commit('setListProduct', response.data);
    } catch (error) {
      return error;
    }
  },
};
