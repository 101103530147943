import axios from 'axios';
import { devopsImageCompressorAlias } from '~/shared/utils/generalUtils';
const config = {
  headers: {
    'Content-Type': 'video/mp4',
  },
};

export default {
  setLoader(context, payload) {
    context.commit('appLoader', payload);
  },
  setModalMessage(context, payload) {
    context.commit('appModalMessage', payload);
  },
  setMessage(context, payload) {
    context.commit('appMessage', payload);
  },
  setModalPasswordConfirmation(context, payload) {
    context.commit('appModalPasswordConfirmation', payload);
  },
  updateSearchPolis(context, payload) {
    context.commit('updateSearchPolis', payload);
  },
  async getBank(context, country) {
    try {
      let url;
      if (country.type === 'flight')
        url = `travel/v1/banks?country=${country.country}`;
      else url = `/payment/banks?countryCode=${country}&&limit=999`;

      const { data } = await this.$axios.get(url);
      const response = data.data;
      context.commit('updateDataBank', response);
      return response;
    } catch (error) {
      return error;
    }
  },
  async getBase64(context, { urlLink, type }) {
    const urlReplace = urlLink.replace('/api/', '/v1/');
    try {
      const { data } = await this.$axios.get(
        type === 'image' ? urlReplace : urlLink
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async callRegion(context, country) {
    const { data } = await axios.post(`${process.env.AIRTABLE_URL}/region`, {
      ...country,
    });
    context.commit('updateLocaleLanguage', data.data.languages);
  },
  async callPickUpDates(context) {
    try {
      const { data } = await axios.get(
        'https://api-staging.qoala.app/api/business-calendar'
      );
      context.commit('updateInfoDates', data.data);
      const response = data.data;
      return response;
    } catch (error) {
      return error;
    }
  },
  async callAddress(context, params) {
    try {
      let response;
      let url = `${process.env.API_BASE_URL}/list-of-province?`;
      if (params.isComplete) {
        url += 'complete=true&';
      }
      if (params.zipcode) {
        const { data } = await axios.get(`${url}zipcode=${params.zipcode}`);
        response = data.data;
      } else if (params.province) {
        if (params.city) {
          const { data } = await axios.get(
            `${url}province=${params.province}&city=${params.city}`
          );
          context.commit('updateAddressDistrict', data.data);
          response = data.data;
        }
        if (params.district) {
          const { data } = await axios.get(
            `${url}province=${params.province}&city=${params.city}&district=${params.district}`
          );
          context.commit('updateAddressVillage', data.data);
          response = data.data;
        } else {
          const { data } = await axios.get(`${url}province=${params.province}`);
          context.commit('updateAddressCity', data.data);
          response = data.data;
        }
      } else {
        const { data } = await axios.get(url);
        context.commit('updateAddressProvince', data.data);
        response = data.data;
      }
      return response;
    } catch (error) {
      return error;
    }
  },
  getFilesFromSupportDocument(context, document) {
    const files = [];
    for (let index = 0; index < document.length; index++) {
      const element = document[index];
      const extensionArray = element.filename.split('.');
      const extension = extensionArray[extensionArray.length - 1];
      files.push({
        documentable: 'claim',
        type: element.type,
        filename: `${index}.${extension}`,
      });
    }
    return files;
  },
  getDocumentSend(context, { data, files, supportingDocument }) {
    const documentSend = [];
    for (let index = 0; index < supportingDocument.length; index++) {
      const element = supportingDocument[index];
      const elementIndex = files[index].filename;
      const elementData = data.data[elementIndex];
      const urlPresigned = elementData.presigned_url;
      const buffer = Buffer.from(element.content, 'base64');

      elementData.type = element.type;
      documentSend.push(elementData);
      axios.put(urlPresigned, buffer, {
        headers: {
          'Content-Type': '*',
          reportProgress: true,
        },
      });
    }
    return documentSend;
  },
  async uploadPresignedFile(context, { dataSend, policyType, callback }) {
    try {
      const { supportingDocument } = dataSend || [];
      const cloneDataSend = Object.assign({}, dataSend);
      const files = await context.dispatch(
        'getFilesFromSupportDocument',
        supportingDocument
      );

      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/bus/documents/presigned`,
        {
          files,
        }
      );
      const documentSend = await context.dispatch('getDocumentSend', {
        data,
        files,
        supportingDocument,
      });
      cloneDataSend.supportingDocument = documentSend;
      return callback(cloneDataSend, policyType);
    } catch (error) {
      return error;
    }
  },
  async getPresign(context, payload) {
    const dataSend = {
      filename: payload.filename,
      content_type: payload.content_type,
    };
    try {
      const { data } = await this.$axios.put(
        `/smartphone/policies/activations/${payload.number}`,
        dataSend
      );
      const response = data.data;
      return response;
    } catch (err) {
      return err;
    }
  },
  async hitPresignUrl(context, { video, url }) {
    context.commit('appLoader', true);
    try {
      const data = await axios.put(url, video, config);
      const response = data.data;
      return response;
    } catch (err) {
      return err;
    }
  },
  async getInsurancePartner(context) {
    try {
      const data = await this.$axios.get('/users/insurances?status=active');
      const response = data.data.data;
      let partners = [];
      response.forEach((partner) => {
        if (partner.config.logo) {
          partners.push({
            alias: partner.alias,
            name: partner.name,
            logo: devopsImageCompressorAlias(partner.config.logo),
          });
        }
      });
      context.commit('updateInsurancePartner', partners);
    } catch (err) {
      return err;
    }
  },
  async transactionHistory(context, { query }) {
    try {
      const { data } = await this.$axios.get(
        `${process.env.API_BASE_URL}/transactions/histories?${query}`
      );
      const response = data.data;
      context.commit('updateTransactionHistory', response);
      return response;
    } catch (err) {
      context.commit('updateTransactionHistory', []);
      this.$sentry.withScope((scope) => {
        scope.setExtra('response', err.response.data);
        this.$sentry.captureMessage(
          `Failed to get General Transaction History`
        );
      });
      return err;
    }
  },
  async requestProduct(context, payload) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.uuid = uuid;
    await axios.post(`${process.env.API_BASE_URL}/request/product`, payload);
  },
  async requestInquiry(context, { payload, product }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.uuid = uuid;
    await axios.post(`${process.env.API_BASE_URL}/request/${product}`, payload);
  },
  async requestRenewal(context, payload) {
    await axios.post(`${process.env.API_BASE_URL}/request/renewal`, payload);
  },
  async requestEmployeeBenefit(context, payload) {
    await axios.post(
      `${process.env.API_BASE_URL}/request/employeeBenefit`,
      payload
    );
  },
  async getSharedId(context, payload) {
    try {
      const response = axios.post(
        `${process.env.API_BASE_URL}/prequote/share`,
        payload
      );
      return response;
    } catch (err) {
      return err;
    }
  },
  async getSharedProduct(context, payload) {
    try {
      const response = axios.get(
        `${process.env.API_BASE_URL}/prequote/share/${payload}`
      );
      return response;
    } catch (err) {
      return err;
    }
  },
  async getPdfUrl(_, { payload, query }) {
    try {
      const response = await axios.get(
        `${process.env.API_BASE_URL}/products/${payload}/pdf?${query}`
      );
      return response;
    } catch (error) {
      this.$sentry.withScope((scope) => {
        scope.setExtra('payload', JSON.stringify(error));
        this.$sentry.captureMessage('Failed to Get PDF URL');
      });
      return null;
    }
  },
  async getPdfQuotation(context, { id }) {
    const { data } = await axios.get(
      `${process.env.API_BASE_URL}/policies/documents/comparison/${id}`
    );
    return data.data;
  },
  async sendLeads(context, { url, payload }) {
    try {
      const uuid = await context.rootGetters['user/getUuid'];
      const token = await context.rootGetters['user/getUserToken'];
      let config = null;
      if (token) {
        config = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      }

      payload.uuid = uuid;
      payload.grade = 'HOT';
      payload.utm = this.$constructUtmLeadPayload().utmParams;
      payload.referer = this.$constructUtmLeadPayload().referer;
      payload.country = context.rootGetters['core/getCountryCode'];
      await axios.post(
        `${process.env.API_BASE_URL}/products/${url}/integrate`,
        payload,
        config
      );
    } catch (error) {
      this.$sentry.withScope((scope) => {
        scope.setExtra('payload', payload);
        this.$sentry.captureMessage(`FAILED TO SAVE LEADS`);
      });
      throw error;
    }
  },
  async getTransactionHistory(context, payload) {
    try {
      const { data } = await this.$axios.get(
        `/transactions/histories/${payload}`
      );
      return data.data;
    } catch (error) {
      return error;
    }
  },
  async getCertificate(context, { transactionId }) {
    try {
      const { data } = await axios.get(
        `${process.env.API_BASE_URL}/policies/documents/certificate/${transactionId}`
      );
      return data.data;
    } catch (error) {
      throw error.response;
    }
  },
  // Create Session External
  async createSessionExternal(context, payload) {
    try {
      const header = {
        headers: {
          'x-api-key': `${process.env.SMARTPHONE_MOMO_API_KEY}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/sessions/external`,
        payload,
        header
      );
      return data;
    } catch (error) {
      return error.response;
    }
  },
  async getTheme(context, themePath) {
    try {
      const { data } = await axios.get(themePath);
      context.commit('updateTheme', data);
    } catch {
      // will use default theme
      context.commit('updateTheme', null);
    }
  }
};
