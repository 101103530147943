import getPhoneCountryDetail from './getPhoneCountryDetail';

export default function sanitizePhoneCountryCode(phoneNum: string = '') {
  const countryCode = getPhoneCountryDetail(phoneNum)?.phoneCode;

  if (countryCode) {
    return phoneNum.replace(countryCode, '');
  }
  return phoneNum;
}
