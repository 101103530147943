export default ({ store, app }) => {
  if (process.browser) {
    window.onNuxtReady(() => {
      if (
        store.getters['general/getUserData'] &&
        store.getters['user/getUserToken']
      ) {
        window.initChat(store.getters.getUserData);
      }
    });
  } else console.log('something wrong');

  function updateRestore(userData, dataFreshchat) {
    if (userData.email) {
      const datas = {
        email: userData.email,
        fullName: userData.fullName,
        phoneNumber: userData.phoneNumber,
        chattingId: dataFreshchat.restoreId,
        noRefresh: true,
      };
      store.dispatch('user/updateUser', datas);
    }
  }
  const countryCode = app.store.getters['core/getCountryCode'];
  window.initChat = (userData) => {
    const tags = [];
    switch (countryCode) {
      case 'ID':
        tags.push('indonesia');
        break;
      case 'MY':
        tags.push('malaysia');
        break;
      case 'VN':
        tags.push('vietnam');
        break;
      default:
        break;
    }
    if (!window.fcWidget) {
      window.fcWidget = '';
      return false;
    }
    const data = {
      token: process.env.FRESHCAT_TOKEN,
      host: 'https://wchat.freshchat.com',
      externalId: userData.email,
      firstName: userData.fullName,
      phone: userData.phoneNumber,
      email: userData.email,
      open: true,
      tags,
      config: {
        headerProperty: {
          hideChatButton: true,
        },
        content: {
          placeholders: {
            search_field: 'Search',
            reply_field: 'Reply',
            csat_reply: 'Add your comments here',
          },
        },
        hideFAQ: true,
      },
    };
    if (userData.chattingId) data.restoreId = userData.chattingId;
    window.fcWidget.init(data);
    window.fcWidget.user.get().then(
      (result) => {
        const userInfo = result.data;
        updateRestore(userData, userInfo);
        // Do Something
      },
      (error) => {
        if (error.status === 401) {
          window.fcWidget.user.create().then(
            (response) => {
              updateRestore(userData, response.data);
            },
            (errorCreate) => {
              console.log(errorCreate);
            }
          );
        } else {
          console.log(error);
        }
      }
    );
    return true;
  };

  return true;
};
