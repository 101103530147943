import Vue from 'vue';
import { CLEVERTAP_UUID_KEY } from '~/shared/constants';
import generateUuid from '~/utils/common/generateUuid';

export default async ({ store }) => {
  if (!localStorage.getItem(CLEVERTAP_UUID_KEY)) {
    localStorage.setItem(CLEVERTAP_UUID_KEY, generateUuid());
  }

  if (
    process.env.NODE_ENV &&
    process.env.NODE_ENV.toLowerCase() === 'production'
  ) {
    console.log = () => {
      console.warn('Please check on https://apply.workable.com/qoala/');
    };
  }

  if (
    store.getters['general/getUserData'] &&
    store.getters['user/getUserToken']
  ) {
    window.errorHandler.setUser(store.getters.getUserData.email);
  }
  // const resp = await store.dispatch('general/callCountryCode', route);
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    store.commit('general/updateA2hs', e);
  });
  Vue.mixin({
    methods: {
      translateConcat(codeTranslation, data) {
        let translation = '';
        translation = this.$t(codeTranslation);
        // for (let i = 0, len = data.length; i < len; i++) {
        translation = translation.replace(`{0}`, data);
        // }
        return translation;
      },
      async getImage(imageUrl, callback) {
        if (imageUrl.startsWith('http')) {
          const base64 = await store.dispatch('general/getBase64', {
            urlLink: imageUrl,
            type: 'image',
          });
          if (base64) {
            let formatData = '';
            const dotSplit = imageUrl.split('.');
            formatData = dotSplit[dotSplit.length - 1];
            callback(`data:image/${formatData};base64,${base64}`);
          } else {
            callback(null);
          }
        }
      },
      async getPdf(pdfUrl, callback) {
        if (pdfUrl.startsWith('http')) {
          const base64 = await store.dispatch('general/getBase64', {
            urlLink: pdfUrl,
            type: 'pdf',
          });
          if (base64) {
            callback(base64);
          } else {
            callback(null);
          }
        }
      },
      formatCurrency(money) {
        if (typeof money === 'string') {
          const formatNumber = new Intl.NumberFormat(['ban', 'id']);
          return formatNumber.format(money);
        }
        return money;
      },
      formatCurencyToIdr(money) {
        const convertedMoney = this.formatCurrency(money);
        let idr = '';
        const angkarev = convertedMoney.toString().split('').reverse().join('');
        for (let i = 0; i < angkarev.length; i++)
          if (i % 3 === 0) idr += `${angkarev.substr(i, 3)}.`;
        const result = idr
          .split('', idr.length - 1)
          .reverse()
          .join('');
        return `IDR ${result}`;
      },
    },
  });
  // check service worker
  if ('serviceWorker' in navigator) {
    console.log('this is support service worker');
  } else {
    console.log('this is not supporting service worker :( ');
  }
  window.goHome = false;
  window.isAndroid = typeof qoala !== 'undefined';
  window.isIos =
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.qoala;
  window.isApple =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (window.isApple || window.isAndroid) {
    window.setupWebViewJavascriptBridge = (callback) => {
      if (window.WebViewJavascriptBridge) {
        return callback(WebViewJavascriptBridge); // eslint-disable-line no-undef
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      const WVJBIframe = document.createElement('iframe');
      WVJBIframe.style.display = 'none';
      WVJBIframe.src = 'https://__bridge_loaded__';
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
      return true;
    };
  }
  // check ios natively
  if (!window.isAndroid && window.isApple) {
    window.setupWebViewJavascriptBridge((bridge) => {
      bridge.registerHandler('appleIsNative', () => {
        window.isApple = true;
      });
      bridge.callHandler('appleIsNative', {}, () => {
        window.isApple = true;
      });
    });
  }
  store.commit('user/setUuid', generateUuid());
  //Accestrade integration
  window.__atw = [];
  window.__atw.push({
    mcn: process.env.ACCESSTRADE_MCN,
    param: {
      result_id: 13,
      identifier: store.getters['user/getUuid'],
    },
  });
  // Number.prototype.formatNumber = function (c, d, t) {
  //   let n = this;
  //   const c = isNaN((c = Math.abs(c))) ? 2 : c;
  //   const d = d == undefined ? '.' : d;
  //   const t = t == undefined ? ',' : t;
  //   const s = n < 0 ? '-' : '';
  //   const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  //   const j = (j = i.length) > 3 ? j % 3 : 0;
  //   return (
  //     s
  //     + (j ? i.substr(0, j) + t : '')
  //     + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`)
  //     + (c
  //       ? d
  //         + Math.abs(n - i)
  //           .toFixed(c)
  //           .slice(2)
  //       : '')
  //   );
  // };
};
