function bindDragListener(elementId) {
  // The current position of mouse
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const bindedElement = document.getElementById(elementId);

  const mouseMoveHandler = (e) => {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    bindedElement.scrollTop = pos.top - dy;
    bindedElement.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = () => {
    bindedElement.style.cursor = 'grab';
    bindedElement.style.removeProperty('user-select');
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (e) => {
    // Change the cursor and prevent user from selecting the text
    bindedElement.style.cursor = 'grabbing';
    bindedElement.style.userSelect = 'none';

    pos = {
      // The current scroll
      left: bindedElement.scrollLeft,
      top: bindedElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  bindedElement.addEventListener('mousedown', mouseDownHandler);
}

export default bindDragListener;
