import axios from 'axios';

export default {
  async createQuotation(context, payload) {
    const uuid = context.rootGetters['user/getUuid'];
    payload.uuid = uuid;
    if (payload.data) payload.data.uuid = uuid;
    const { data } = await this.$axios.post('quote/quotes', payload);
    return data.data;
  },

  async createQuotationNonApi(context, payload) {
    const uuid = context.rootGetters['user/getUuid'];
    payload.uuid = uuid;
    await this.$axios.post('quote/quotes/nonapi', payload);
  },

  async getQuotation(context, code) {
    try {
      const { data } = await this.$axios.get(`quote/quotes/${code}`);
      return data.data;
    } catch (err) {
      throw err;
    }
  },

  async editQuotation(context, { payload, code }) {
    try {
      const uuid = context.rootGetters['user/getUuid'];
      payload.uuid = uuid;
      const { data } = await this.$axios.put(
        `quote/quotes/update/${code}`,
        payload
      );
      return data.data;
    } catch (err) {
      throw err;
    }
  },

  async getQuotationSummary(context, code) {
    try {
      const { data } = await this.$axios.get(`quote/quotes/summary/${code}`);
      return context.commit('setQuotationSummary', data.data);
    } catch (err) {
      throw err;
    }
  },

  async getQuotationScraping(context, { quotationCode }) {
    const { data } = await axios.get(
      `${process.env.API_BASE_URL}/quote/quotes/scraping/${quotationCode}`
    );
    context.commit('setQuotationScraping', data.data);
  },

  async getPdfFreshSaleQuotation(context, { id }) {
    const { data } = await axios.get(
      `${process.env.API_BASE_URL}/products/vehicle/generatePDFFreshsaleQuote/${id}`
    );
    return data;
  },
};
