import listCountry from '@/static/data/countryList.json';

function getPhoneCountryDetail(phone = '') {
  const country = listCountry.find((country) => {
    return phone.includes(country.phoneCode);
  });
  return country;
}

export default getPhoneCountryDetail;
