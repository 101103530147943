import DataHelper from './helper/googleAnalytic.json';
import { v1 as uuidv1 } from 'uuid';

export default async function ({ app, $gtm, route }) {
  const gtmUserId = app.store.getters['general/getGtmUserId'];
  if (!gtmUserId) {
    app.store.commit('general/setGtmUserId', uuidv1());
  }
  $gtm.push(DataHelper[route.name]);
}
