export default {
  async terminateTransaction(context, payload) {
    try {
      const response = await this.$axios.post('/transactions/cancel', payload);
      return response.data;
    } catch {
      throw new Error();
    }
  },
  async smartphoneTerminateTransaction(context, payload) {
    try {
      const response = await this.$axios.post('/smartphone/purchase/terminate', payload);
      return response.data;
    } catch {
      throw new Error();
    }
  },
};
