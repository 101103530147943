import qoalaStorage from '~/core/Storage';

export default () => ({
  healthPurchaseHistory: JSON.parse(qoalaStorage.getItem('healthPurchaseHistory')),
  type: qoalaStorage.getItem('protectionType'),
  covered: qoalaStorage.getItem('covered'),
  selfData: JSON.parse(qoalaStorage.getItem('selfData')),
  spouseData: JSON.parse(qoalaStorage.getItem('spouseData')),
  sonData: JSON.parse(qoalaStorage.getItem('sonData')),
  daughterData: JSON.parse(qoalaStorage.getItem('daughterData')),
  listProduct: JSON.parse(qoalaStorage.getItem('healthProduct')),
  productDetail: JSON.parse(qoalaStorage.getItem('productDetailHealth')),
  payload: JSON.parse(qoalaStorage.getItem('healthPayload'))
});
