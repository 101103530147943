import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getTropicalDiseasePurchaseData: state => {
    if (!state.tropicalDiseasePurchaseData) {
      return qoalaStorage.getItem('tropicalDiseasePurchaseData')
        ? parseIfJsonString(qoalaStorage.getItem('tropicalDiseasePurchaseData'))
        : state.tropicalDiseasePurchaseData || {};
    }
    return state.tropicalDiseasePurchaseData;
  },
  getProductDetail: state => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('tropicalDiseaseProductDetail')
        ? parseIfJsonString(qoalaStorage.getItem('tropicalDiseaseProductDetail'))
        : state.productDetail || {};
    }
    return state.productDetail;
  },
  getListProduct: state => {
    if (!state.listProduct)
      return qoalaStorage.getItem('tropicalDiseaseProduct')
        ? parseIfJsonString(qoalaStorage.getItem('tropicalDiseaseProduct'))
        : state.listProduct || [];
    return state.listProduct || [];
  },
  getClaims: state => {
    if (!state.claims) {
      return '';
    }
    if (state.claims === 'undefined') {
      return '';
    }
    if (typeof state.claims === 'string') {
      return JSON.parse(state.claims);
    }
    return state.claims;
  }
};
