const LocaleMap = {
  MY: 'my',
  ID: 'id',
  TH: 'th',
  VN: 'vn',
  PH: 'ph',
};

export default async function ({ isHMR, app }) {
  // Ignore if development mode
  if (isHMR) {
    return;
  }
  // Only do server level redirect
  if (!process.server) {
    return;
  }
  const isEnglish = app.context.req.session.isEnglish;
  let sessionLocaleFromCF = app.context.req.session.countryID;
  let sessionLocale = LocaleMap[sessionLocaleFromCF] || LocaleMap['ID'];
  if (isEnglish) {
    sessionLocale = sessionLocale + '-en';
  }
  await app.i18n.setLocale(sessionLocale);
}
