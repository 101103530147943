import qoalaStorage from '~/core/Storage';

export default {
  updateHealthPurchaseHistory(state, value) {
    state.healthPurchaseHistory = value;
    qoalaStorage.setItem('healthPurchaseHistory', JSON.stringify(state.healthPurchaseHistory));
  },
  setType(state, value) {
    state.type = value;
    qoalaStorage.setItem('protectionType', state.type);
  },
  setCovered(state, value) {
    state.covered = value;
    qoalaStorage.setItem('covered', state.covered);
  },
  setSelfData(state, value) {
    state.selfData = value;
    qoalaStorage.setItem('selfData', JSON.stringify(state.selfData));
  },
  setSpouseData(state, value) {
    state.spouseData = value;
    qoalaStorage.setItem('spouseData', JSON.stringify(state.spouseData));
  },
  setSonData(state, value) {
    state.sonData = value;
    qoalaStorage.setItem('sonData', JSON.stringify(state.sonData));
  },
  setDaughterData(state, value) {
    state.daughterData = value;
    qoalaStorage.setItem('daughterData', JSON.stringify(state.daughterData));
  },
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('healthProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('productDetailHealth', JSON.stringify(state.productDetail));
  },
  setPayload(state, value) {
    state.payload = value;
    qoalaStorage.setItem('healthPayload', JSON.stringify(state.payload));
  },
  deleteHealthData(state) {
    state.type = '';
    state.covered = '';
    state.selfData = [];
    state.spouseData = [];
    state.sonData = [];
    state.daughterData = [];
    state.listProduct = [];
    state.productDetail = {};
    state.payload = {};
    state.healthPurchaseHistory = {};
    qoalaStorage.removeItem('protectionType');
    qoalaStorage.removeItem('covered');
    qoalaStorage.removeItem('selfData');
    qoalaStorage.removeItem('spouseData');
    qoalaStorage.removeItem('sonData');
    qoalaStorage.removeItem('daughterData');
    qoalaStorage.removeItem('healthProduct');
    qoalaStorage.removeItem('productDetailHealth');
    qoalaStorage.removeItem('healthPayload');
    qoalaStorage.removeItem('healthPurchaseHistory');
  },
  deleteHealthPurchaseHistory(state) {
    state.healthPurchaseHistory = {};
    qoalaStorage.removeItem('healthPurchaseHistory');
  }
};
