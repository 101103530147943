import axios from 'axios';

export default {
  async getDocumentPresignLink(context, payload) {
    try {
      const response = await this.$axios.post('/electronic/presigned/claims', { documents: payload });
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async documentsResubmitPresign(context, payload) {
    try {
      const response = await this.$axios.post(
        'electronic/presigned/claims',
        { documents: payload },
        {
          params: {
            type: 'resubmit-document'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getPolicyDetail(context, { policyNumber, callback = () => null }) {
    try {
      const { data } = await this.$axios.get(`/electronic/policies/${policyNumber}`);
      const response = data.data;
      context.commit('updatePolicy', response);
      return callback();
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async submitClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(`/electronic/claims`, payload, {
        timeout: Infinity,
        cancelToken: source.token
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  async resubmitClaim(context, { payload, number }) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.put(`/electronic/claims/${number}`, payload, {
        timeout: Infinity,
        cancelToken: source.token
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
};
