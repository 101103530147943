import qoalaStorage from '~/core/Storage';
import Cookies from 'js-cookie';

export default {
  appLoader(state, data) {
    state.loader = data;
  },
  appMessage(state, data) {
    state.message = data;
  },
  appModalMessage(state, data) {
    state.modalMessage = data;
  },
  appModalPasswordConfirmation(state, data) {
    state.modalPasswordConfirmation = data;
  },
  updateDataBank(state, value) {
    state.listBank = value;
    qoalaStorage.setItem('banks', JSON.stringify(state.listBank));
  },
  updatePin(state, value) {
    state.pin = value;
    qoalaStorage.setItem('pin', JSON.stringify(state.pin));
  },
  updateOTPStatus(state, value) {
    state.otpStatus = value;
    qoalaStorage.setItem('otpStatus', JSON.stringify(state.otpStatus));
  },
  updateProfileView(state, value) {
    state.profileView = value;
  },
  updateCalledOnce(state, value) {
    state.calledOnce = value;
  },
  updateFirstTime(state, value) {
    state.firstTime = value;
    qoalaStorage.setItem('activeFirstTime', JSON.stringify(state.firstTime));
  },
  updateNotifHome(state, value) {
    state.notifHome = value;
    qoalaStorage.setItem('notifHome', JSON.stringify(state.notifHome));
  },
  updatePdfLink(state, value) {
    state.pdfLink = value;
    qoalaStorage.setItem('pdfLink', state.pdfLink);
  },
  updateA2hs(state, value) {
    state.a2hs = value;
    qoalaStorage.setItem('A2HS', JSON.stringify(state.a2hs));
  },
  updateVolumeUp(state, value) {
    state.volumeUp = value;
  },
  updateVolumeDown(state, value) {
    state.volumeDown = value;
  },
  updateInfoDates(state, value) {
    state.pickUpDates = value;
  },
  updateAddressProvince(state, value) {
    state.addressData.province = value;
  },
  updateAddressCity(state, value) {
    state.addressData.city = value;
  },
  updateAddressDistrict(state, value) {
    state.addressData.district = value;
  },
  updateAddressVillage(state, value) {
    state.addressData.village = value;
  },
  updateLoginData(state, value) {
    state.loginData = value;
    qoalaStorage.setItem('loginData', JSON.stringify(state.loginData));
  },
  updatePurchaseHistory(state, value) {
    let data = [];
    if (state.purchaseHistory) {
      if (typeof state.purchaseHistory === 'string') {
        data = JSON.parse(state.purchaseHistory);
        data.push(value);
      } else {
        data = state.purchaseHistory;
        data.push(value);
      }
    } else {
      data.push(value);
    }
    qoalaStorage.setItem('purchaseHistory', JSON.stringify(data));
    state.purchaseHistory = data;
  },
  deletePurchaseHistory(state) {
    let data = [];
    if (typeof state.purchaseHistory === 'string') {
      data = JSON.parse(state.purchaseHistory);
    } else {
      data = state.purchaseHistory || [];
    }
    data.pop();
    qoalaStorage.setItem('purchaseHistory', JSON.stringify(data));
  },
  removeHistory(state, value) {
    qoalaStorage.removeItem('purchaseHistory');
    state.purchaseHistory = value;
  },
  saveDetailPromo(state, value) {
    state.detailPromo = value;
  },
  updateSearchPolis(state, payload) {
    const { type } = payload;
    type === 'update'
      ? (state.searchPolis = payload)
      : (state.searchPolis = null);
  },
  updateActiveTransaction(state, value) {
    state.activeTransaction = value;
    qoalaStorage.setItem('activeTransaction', JSON.stringify(value));
  },
  updateInsurancePartner(state, value) {
    state.insurancePartner = value;
  },
  updateComparedProduct(state, value) {
    state.comparedProduct = value;
    qoalaStorage.setItem(
      'comparedProduct',
      JSON.stringify(state.comparedProduct)
    );
  },
  removeComparedProduct(state) {
    qoalaStorage.removeItem('comparedProduct');
    state.comparedProduct = [];
  },
  updateGuest(state, value) {
    state.guest = value;
    Cookies.set('guest', JSON.stringify(state.guest), {
      expires: 7,
      secure: true,
    });
  },
  updateTransactionHistory(state, value) {
    state.transactionHistory = value;
    qoalaStorage.setItem(
      'transactionHistory',
      JSON.stringify(state.transactionHistory)
    );
  },
  updateTransactionHistoryDetail(state, value) {
    state.transactionHistoryDetail = value;
    qoalaStorage.setItem(
      'transactionHistoryDetail',
      JSON.stringify(state.transactionHistoryDetail)
    );
  },
  updateSnapToken(state, value) {
    state.snapToken = value;
    qoalaStorage.setItem('snapToken', JSON.stringify(state.snapToken));
  },
  removeSnapToken(state) {
    state.snapToken = '';
    qoalaStorage.removeItem('snapToken');
  },
  updateLocaleLanguage(state, value) {
    const updatedLanguages = value.map((lang, index) => ({
      id: index,
      country_name: lang.country_name,
      title: lang.language_name,
      language_name: lang.language_name,
      content: lang.language_alias.toUpperCase(),
      iso: lang.iso,
      value: lang.iso,
      localePath: lang.country_code,
    }));
    state.localeLanguage = updatedLanguages;
    qoalaStorage.setItem(
      'localeLanguage',
      JSON.stringify(state.localeLanguage)
    );
  },
  setUtmParams(state, value) {
    state.utmParams = value;
    qoalaStorage.setItem('utmParams', JSON.stringify(state.utmParams));
  },
  deleteUtmParams(state) {
    qoalaStorage.removeItem('utmParams');
    state.utmParams = {};
  },
  setDefaultLanguage(state, value) {
    state.defaultLanguage = value;
    qoalaStorage.setItem(
      'defaultLanguage',
      JSON.stringify(state.defaultLanguage)
    );
  },
  setReferer(state, value) {
    const lifetime = 60 * 60 * 24 * 7;
    const expiresAt = new Date().getTime() + lifetime;

    state.referer = { value, expiresAt };
    qoalaStorage.setItem('referer', JSON.stringify(state.referer));
  },
  setGtmUserId(state, value) {
    state.gtmUserId = value;
    qoalaStorage.setItem('gtmUserId', JSON.stringify(state.gtmUserId));
  },
  updateTheme(state, value) {
    state.theme = value;
    qoalaStorage.setItem(
      'theme',
      JSON.stringify(value)
    );
  }
};
