import { mapResponse } from './transform';
import axios from 'axios';

export default {
  async transactionHistory(context, status) {
    try {
      const params = {
        orderBy: 'createdAt',
        descending: true,
        status,
      };
      const { data } = await this.$axios.get('/smartphone/purchase-histories', {
        params,
      });
      const response = data.data;
      context.commit('updateTransactionHistory', response);
      return response;
    } catch (err) {
      this.$sentry.withScope((scope) => {
        scope.setExtra('response', err.response.data);
        this.$sentry.captureMessage(
          `Failed to get Smartphone Transaction History`
        );
      });
      return err;
    }
  },
  async getPolicyList(context, { page }) {
    // const codeCountry = await context.rootGetters['core/getCountryCode'];
    let APIPath = '/smartphone/policies';
    /**
     * Disable logic for VN as per request for demo
     * TODO : REVISIT IF WANT TO RELEASE THE CHANGES
     */
    // if (codeCountry === 'VN') {
      // New Endpoint not support limit
      // APIPath = ` t?productCategory=smartphone&page=${page}&country=${codeCountry}`;
    // } else {
      APIPath = APIPath + `?limit=100&descending=true&page=${page}`;
    // }
    try {
      const { data } = await this.$axios.get(
        `${process.env.API_BASE_URL}${APIPath}`
      );
      const response = data;
      const policies = context.getters['getPolicyList'];
      context.commit('updatePolicyList', response);

      if (policies) {
        if (response === policies) return true;
        if (response.meta.page > policies.meta.page) {
          // Copy Response data for avoiding error vuex mutate
          const newResponse = JSON.parse(JSON.stringify(response));
          newResponse.data = [...policies.data, ...response.data];
          context.commit('updatePolicyList', newResponse);
        }
      }
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = (errorResponse || {}).data;
      return errorData;
    }
  },
  async getListClaim(context, { page }) {
    try {
      const query = `orderBy=updatedAt&&limit=100&&page=${page}`;
      const { data } = await this.$axios.get(`/smartphone/claims?${query}`);
      const response = data;
      const claims = context.getters['getClaims'];
      if (claims && Object.keys(claims).length > 0) {
        response.data = claims.data.concat(response.data);
        context.commit('updateClaims', response);
      } else {
        context.commit('updateClaims', response);
      }
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = (errorResponse || {}).data;
      return errorData;
    }
  },
  async getSmartphone(context, { url, type, params }) {
    try {
      const { data } = await axios.get(
        `${process.env.API_BASE_URL}/products/smartphone/${url}`,
        { params }
      );
      const response = data.data;
      if (type === 'brand') {
        context.commit('updateSmartphoneBrand', response);
      } else if (type === 'model') {
        context.commit('updateSmartphoneModel', response);
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getProductList(context, { payload }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    payload.uuid = uuid;
    try {
      const { data } = await this.$axios.post(`/products/smartphone`, payload);
      const response = data.data;
      context.commit(
        'setListProduct',
        mapResponse({ type: 'productList', response })
      );
      // context.commit('setListProduct', response);
      // return response;
      return mapResponse({ type: 'productList', response });
      // return response;
    } catch (error) {
      return error;
    }
  },
  // * Update Claim Smartphone MOMO Screen Crack confirm excessFee
  async updateClaimExcessFee(context, { number, status }) {
    try {
      const { data } = await this.$axios.put(
        `${process.env.API_BASE_URL}/smartphone/v1/claims/${number}`,
        { status }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  /** create job from ML endpoint */
  async createJobML(context, data) {
    return axios({
      method: 'post',
      url: `${process.env.SMARTPHONE_BASE_URL}/api/pipeline/smartphone/jobs`,
      data,
    });
  },
  /** get presign url from ML endpoint */
  async getPresignedML(context, data) {
    const { jobId, ...sendData } = data;
    return axios({
      method: 'post',
      url: `${process.env.SMARTPHONE_BASE_URL}/api/pipeline/smartphone/jobs/:jobId/frames/presign`.replace(
        ':jobId',
        jobId
      ),
      data: { ...sendData },
    });
  },
  /** detecting phone from ML endpoint */
  async detectingPhone(context, data) {
    return axios({
      method: 'post',
      url: `${process.env.SMARTPHONE_ML_URL}/smp/frame/frontcam`,
      data,
    });
  },
  async getRegion(context, payload) {
    let response;
    let url = `/smartphone/v1/locations/`;
    try {
      if (payload?.province) {
        if (payload?.city) {
          // Get list of regencies (kecamatan)
          const { data } = await this.$axios.get(
            `${url}regencies?country=ID&province=${payload.province}&district=${payload.city}`
          );
          response = data.data;
        } else if (payload?.subdistrict) {
          // Get list of zipcode
          const { data } = await this.$axios.get(
            `${url}zipcodes?country=ID&province=${payload.province}&regency=${payload.subdistrict}`
          );
          response = data.data;
        } else {
          // Get list of city
          const { data } = await this.$axios.get(
            `${url}cities?country=ID&province=${payload.province}`
          );

          response = data.data;
        }
      } else {
        // Get list of province when address form created
        const { data } = await this.$axios.get(`${url}provinces?country=ID`);
        response = data.data;
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
