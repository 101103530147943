export default ({ app, store }) => {
  function checkNavigator() {
    const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
    if (navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          return true;
        }
      }
    }
    return false;
  }
  // for ios redirecting
  if (!window.fcWidget) {
    window.fcWidget = '';
    return false;
  }
  // for freshchat close
  app.router.beforeEach((to, from, next) => {
    // Do something
    if (window.fcWidget.isOpen() && from.name.indexOf('contact') >= 0) {
      window.fcWidget.close();
      next(app.localePath('contacts'));
    } else {
      next();
    }
  });

  window.directingNotif = (id, type, number) => {
    store.dispatch('user/readNotification', {
      notifId: id,
      callback: () => {},
      callbackFail: error => console.log(error)
    });
    if (type === 'profile' || type === 'general') {
      app.router.push(app.localePath('accounts'));
    } else if (type === 'claim') {
      const dataClaim = {
        number
      };
      store.commit('policy/updateClaim', dataClaim);
      app.router.push(app.localePath('claim-detailClaim'));
    } else if (type === 'policy') {
      const dataPolicy = {
        number
      };
      store.commit('policy/updatePolicy', dataPolicy);
      app.router.push(app.localePath('claim-detailPolicy'));
    } else {
      app.router.push(app.localePath('index'));
    }
  };

  // for ios
  if (checkNavigator() || window.isApple) {
    window.setupWebViewJavascriptBridge(bridge => {
      bridge.registerHandler('appleDirectingNotif', (data, responseCallback) => {
        console.log('ObjC called testJavascriptHandler with', data);
        console.log(responseCallback);
      });
      bridge.callHandler('appleDirectingNotif', {}, response => {
        const notifData = response.split(',');
        window.directingNotif(notifData[0], notifData[1]);
      });
    });
  }
  return true;
};
