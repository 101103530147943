function getPolicyStatus({ content, status }) {
  if (!content) {
    if (status === 'WAITING_FOR_PAYMENT') return 'Menunggu Pembayaran';
    else if (status === 'WAITING_FOR_UPDATE') return 'Menunggu Update';
    else if (status === 'PAID') return 'Dalam Proses';
    else if (status === 'ACTIVE') return 'Aktif';
    else if (status === 'REJECTED') return 'Ditolak';
    else if (status === 'CANCELLED') return 'Dibatalkan';
    else if (status === 'EXPIRED') return 'Tidak Berlaku Lagi';
  } else {
    return content[status];
  }
  return status;
}

export default getPolicyStatus;
