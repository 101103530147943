import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6c472da0 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _615f6cbf = () => interopDefault(import('../pages/landing/car/index.vue' /* webpackChunkName: "pages/landing/car/index" */))
const _7c124b60 = () => interopDefault(import('../pages/landing/car-my/index.vue' /* webpackChunkName: "pages/landing/car-my/index" */))
const _1a9f9ddc = () => interopDefault(import('../pages/downloadCertificate/index.vue' /* webpackChunkName: "pages/downloadCertificate/index" */))
const _1492e9cb = () => interopDefault(import('../pages/employee-benefit/index.vue' /* webpackChunkName: "pages/employee-benefit/index" */))
const _35a3cb79 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _20ef1b00 = () => interopDefault(import('../pages/landing/hcp/index.vue' /* webpackChunkName: "pages/landing/hcp/index" */))
const _d32494b2 = () => interopDefault(import('../pages/landing/health/index.vue' /* webpackChunkName: "pages/landing/health/index" */))
const _2965ef30 = () => interopDefault(import('../pages/landing/health-my/index.vue' /* webpackChunkName: "pages/landing/health-my/index" */))
const _8a63b372 = () => interopDefault(import('../pages/landing/life/index.vue' /* webpackChunkName: "pages/landing/life/index" */))
const _41307f8b = () => interopDefault(import('../pages/landing/mcis/index.vue' /* webpackChunkName: "pages/landing/mcis/index" */))
const _136c1c20 = () => interopDefault(import('../pages/landing/motor/index.vue' /* webpackChunkName: "pages/landing/motor/index" */))
const _49afdf53 = () => interopDefault(import('../pages/landing/motorcycle-my/index.vue' /* webpackChunkName: "pages/landing/motorcycle-my/index" */))
const _bfccab04 = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _506579e7 = () => interopDefault(import('../pages/partnership/index.vue' /* webpackChunkName: "pages/partnership/index" */))
const _0b52e269 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _55e129d0 = () => interopDefault(import('../pages/privacy-notice/index.vue' /* webpackChunkName: "pages/privacy-notice/index" */))
const _a7576892 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _058f9432 = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _667b7c40 = () => interopDefault(import('../pages/landing/property/index.vue' /* webpackChunkName: "pages/landing/property/index" */))
const _cd0d689c = () => interopDefault(import('../pages/request/index.vue' /* webpackChunkName: "pages/request/index" */))
const _10a2a950 = () => interopDefault(import('../pages/landing/smartphone/index.vue' /* webpackChunkName: "pages/landing/smartphone/index" */))
const _eff929aa = () => interopDefault(import('../pages/toc/index.vue' /* webpackChunkName: "pages/toc/index" */))
const _b5eaa330 = () => interopDefault(import('../pages/transactions/index.vue' /* webpackChunkName: "pages/transactions/index" */))
const _7c696fbc = () => interopDefault(import('../pages/landing/zurich/index.vue' /* webpackChunkName: "pages/landing/zurich/index" */))
const _2e3f2c44 = () => interopDefault(import('../pages/edu/asuransi/index.vue' /* webpackChunkName: "pages/edu/asuransi/index" */))
const _7e1eae73 = () => interopDefault(import('../pages/about/about-us/index.vue' /* webpackChunkName: "pages/about/about-us/index" */))
const _efe1da20 = () => interopDefault(import('../pages/about/disclaimer-chapter/index.vue' /* webpackChunkName: "pages/about/disclaimer-chapter/index" */))
const _dfeba428 = () => interopDefault(import('../pages/about/privacy-policy/index.vue' /* webpackChunkName: "pages/about/privacy-policy/index" */))
const _0de72ace = () => interopDefault(import('../pages/about/term-and-condition/index.vue' /* webpackChunkName: "pages/about/term-and-condition/index" */))
const _ae807792 = () => interopDefault(import('../pages/account/assessment/index.vue' /* webpackChunkName: "pages/account/assessment/index" */))
const _314a31bb = () => interopDefault(import('../pages/certificate/dana/index.vue' /* webpackChunkName: "pages/certificate/dana/index" */))
const _4b7264b1 = () => interopDefault(import('../pages/claim/add/index.vue' /* webpackChunkName: "pages/claim/add/index" */))
const _1801cbd7 = () => interopDefault(import('../pages/claim/detail/index.vue' /* webpackChunkName: "pages/claim/detail/index" */))
const _9a7ac7c4 = () => interopDefault(import('../pages/edu/car/index.vue' /* webpackChunkName: "pages/edu/car/index" */))
const _40eba1df = () => interopDefault(import('../pages/edu/hcp/index.vue' /* webpackChunkName: "pages/edu/hcp/index" */))
const _88995430 = () => interopDefault(import('../pages/edu/health/index.vue' /* webpackChunkName: "pages/edu/health/index" */))
const _d45e3a70 = () => interopDefault(import('../pages/edu/life/index.vue' /* webpackChunkName: "pages/edu/life/index" */))
const _e463ed6a = () => interopDefault(import('../pages/employee-benefit/request/index.vue' /* webpackChunkName: "pages/employee-benefit/request/index" */))
const _d1cdd482 = () => interopDefault(import('../pages/employee-benefit/success/index.vue' /* webpackChunkName: "pages/employee-benefit/success/index" */))
const _13ace5dd = () => interopDefault(import('../pages/payment/method/index.vue' /* webpackChunkName: "pages/payment/method/index" */))
const _2c9d905d = () => interopDefault(import('../pages/payment/success/index.vue' /* webpackChunkName: "pages/payment/success/index" */))
const _5b7520a6 = () => interopDefault(import('../pages/policy/active/index.vue' /* webpackChunkName: "pages/policy/active/index" */))
const _55004697 = () => interopDefault(import('../pages/policy/add/index.vue' /* webpackChunkName: "pages/policy/add/index" */))
const _23f64f9e = () => interopDefault(import('../pages/policy/detail/index.vue' /* webpackChunkName: "pages/policy/detail/index" */))
const _772f73fa = () => interopDefault(import('../pages/profile/edit/index.vue' /* webpackChunkName: "pages/profile/edit/index" */))
const _87c245ba = () => interopDefault(import('../pages/profile/summary/index.vue' /* webpackChunkName: "pages/profile/summary/index" */))
const _b9ede938 = () => interopDefault(import('../pages/promo/detail/index.vue' /* webpackChunkName: "pages/promo/detail/index" */))
const _4bdb8599 = () => interopDefault(import('../pages/purchase/car/index.vue' /* webpackChunkName: "pages/purchase/car/index" */))
const _4bf6e94c = () => interopDefault(import('../pages/purchase/hcp/index.vue' /* webpackChunkName: "pages/purchase/hcp/index" */))
const _1fd528ad = () => interopDefault(import('../pages/purchase/life/index.vue' /* webpackChunkName: "pages/purchase/life/index" */))
const _75e65c82 = () => interopDefault(import('../pages/purchase/motorcycle/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/index" */))
const _349bc736 = () => interopDefault(import('../pages/purchase/smartphone/index.vue' /* webpackChunkName: "pages/purchase/smartphone/index" */))
const _20af7e78 = () => interopDefault(import('../pages/purchase/tropical-disease/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/index" */))
const _e462beb4 = () => interopDefault(import('../pages/request/success/index.vue' /* webpackChunkName: "pages/request/success/index" */))
const _52ecbfb5 = () => interopDefault(import('../pages/sessions/forgot.vue' /* webpackChunkName: "pages/sessions/forgot" */))
const _7c8854b5 = () => interopDefault(import('../pages/sessions/register.vue' /* webpackChunkName: "pages/sessions/register" */))
const _2b8d2604 = () => interopDefault(import('../pages/smartphone/service-center-address.vue' /* webpackChunkName: "pages/smartphone/service-center-address" */))
const _fef05df8 = () => interopDefault(import('../pages/smartphone/service-center-list.vue' /* webpackChunkName: "pages/smartphone/service-center-list" */))
const _58db7961 = () => interopDefault(import('../pages/landing/special-promo/1212/index.vue' /* webpackChunkName: "pages/landing/special-promo/1212/index" */))
const _7b3c286e = () => interopDefault(import('../pages/transactions/detail/index.vue' /* webpackChunkName: "pages/transactions/detail/index" */))
const _a5345456 = () => interopDefault(import('../pages/claim/travel/flight/index.vue' /* webpackChunkName: "pages/claim/travel/flight/index" */))
const _3be86865 = () => interopDefault(import('../pages/payment/confirmation/directDebit/index.vue' /* webpackChunkName: "pages/payment/confirmation/directDebit/index" */))
const _116411ff = () => interopDefault(import('../pages/payment/confirmation/recurring/index.vue' /* webpackChunkName: "pages/payment/confirmation/recurring/index" */))
const _c17c3f0a = () => interopDefault(import('../pages/policy/add/flight.vue' /* webpackChunkName: "pages/policy/add/flight" */))
const _573c8867 = () => interopDefault(import('../pages/policy/add/health.vue' /* webpackChunkName: "pages/policy/add/health" */))
const _2e60d550 = () => interopDefault(import('../pages/policy/add/smartphone.vue' /* webpackChunkName: "pages/policy/add/smartphone" */))
const _49c57625 = () => interopDefault(import('../pages/policy/add/travel.vue' /* webpackChunkName: "pages/policy/add/travel" */))
const _d443197c = () => interopDefault(import('../pages/purchase/car-my/addons/index.vue' /* webpackChunkName: "pages/purchase/car-my/addons/index" */))
const _db1ad10a = () => interopDefault(import('../pages/purchase/car-my/holder/index.vue' /* webpackChunkName: "pages/purchase/car-my/holder/index" */))
const _0954c216 = () => interopDefault(import('../pages/purchase/car-my/product/index.vue' /* webpackChunkName: "pages/purchase/car-my/product/index" */))
const _cffc23be = () => interopDefault(import('../pages/purchase/car-my/protections/index.vue' /* webpackChunkName: "pages/purchase/car-my/protections/index" */))
const _d27e37e6 = () => interopDefault(import('../pages/purchase/car-my/summary/index.vue' /* webpackChunkName: "pages/purchase/car-my/summary/index" */))
const _9eaa3902 = () => interopDefault(import('../pages/purchase/car/addons/index.vue' /* webpackChunkName: "pages/purchase/car/addons/index" */))
const _ed95efc6 = () => interopDefault(import('../pages/purchase/car/detail/index.vue' /* webpackChunkName: "pages/purchase/car/detail/index" */))
const _a581f090 = () => interopDefault(import('../pages/purchase/car/holder/index.vue' /* webpackChunkName: "pages/purchase/car/holder/index" */))
const _de0b6176 = () => interopDefault(import('../pages/purchase/car/prequote/index.vue' /* webpackChunkName: "pages/purchase/car/prequote/index" */))
const _48165979 = () => interopDefault(import('../pages/purchase/car/product/index.vue' /* webpackChunkName: "pages/purchase/car/product/index" */))
const _0a417216 = () => interopDefault(import('../pages/purchase/car/quotation/index.vue' /* webpackChunkName: "pages/purchase/car/quotation/index" */))
const _894611ce = () => interopDefault(import('../pages/purchase/car/request/index.vue' /* webpackChunkName: "pages/purchase/car/request/index" */))
const _54fb0920 = () => interopDefault(import('../pages/purchase/car/summary/index.vue' /* webpackChunkName: "pages/purchase/car/summary/index" */))
const _459936d7 = () => interopDefault(import('../pages/purchase/hcp/holder/index.vue' /* webpackChunkName: "pages/purchase/hcp/holder/index" */))
const _89fbe38c = () => interopDefault(import('../pages/purchase/hcp/product/index.vue' /* webpackChunkName: "pages/purchase/hcp/product/index" */))
const _486e3031 = () => interopDefault(import('../pages/purchase/hcp/summary/index.vue' /* webpackChunkName: "pages/purchase/hcp/summary/index" */))
const _7553276f = () => interopDefault(import('../pages/purchase/health-my/holder/index.vue' /* webpackChunkName: "pages/purchase/health-my/holder/index" */))
const _faf39ebc = () => interopDefault(import('../pages/purchase/health-my/product/index.vue' /* webpackChunkName: "pages/purchase/health-my/product/index" */))
const _0ff25299 = () => interopDefault(import('../pages/purchase/health-my/summary/index.vue' /* webpackChunkName: "pages/purchase/health-my/summary/index" */))
const _18287890 = () => interopDefault(import('../pages/purchase/health/declaration/index.vue' /* webpackChunkName: "pages/purchase/health/declaration/index" */))
const _018b63fc = () => interopDefault(import('../pages/purchase/health/family/index.vue' /* webpackChunkName: "pages/purchase/health/family/index" */))
const _47b4c778 = () => interopDefault(import('../pages/purchase/health/holder/index.vue' /* webpackChunkName: "pages/purchase/health/holder/index" */))
const _d06636b0 = () => interopDefault(import('../pages/purchase/health/insured/index.vue' /* webpackChunkName: "pages/purchase/health/insured/index" */))
const _13fb5326 = () => interopDefault(import('../pages/purchase/health/product/index.vue' /* webpackChunkName: "pages/purchase/health/product/index" */))
const _f9230f38 = () => interopDefault(import('../pages/purchase/health/summary/index.vue' /* webpackChunkName: "pages/purchase/health/summary/index" */))
const _066df450 = () => interopDefault(import('../pages/purchase/life/declaration/index.vue' /* webpackChunkName: "pages/purchase/life/declaration/index" */))
const _c760f3b8 = () => interopDefault(import('../pages/purchase/life/holder/index.vue' /* webpackChunkName: "pages/purchase/life/holder/index" */))
const _17dcf5d2 = () => interopDefault(import('../pages/purchase/life/newPrequote/index.vue' /* webpackChunkName: "pages/purchase/life/newPrequote/index" */))
const _3b15a88d = () => interopDefault(import('../pages/purchase/life/product/index.vue' /* webpackChunkName: "pages/purchase/life/product/index" */))
const _6efc6af8 = () => interopDefault(import('../pages/purchase/life/summary/index.vue' /* webpackChunkName: "pages/purchase/life/summary/index" */))
const _26070a2a = () => interopDefault(import('../pages/purchase/motorcycle-my/addons/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/addons/index" */))
const _2cdec1b8 = () => interopDefault(import('../pages/purchase/motorcycle-my/holder/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/holder/index" */))
const _d410a0e6 = () => interopDefault(import('../pages/purchase/motorcycle-my/product/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/product/index" */))
const _15f34818 = () => interopDefault(import('../pages/purchase/motorcycle-my/protections/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/protections/index" */))
const _2363d184 = () => interopDefault(import('../pages/purchase/motorcycle-my/summary/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/summary/index" */))
const _b1416dd8 = () => interopDefault(import('../pages/purchase/motorcycle/detail/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/detail/index" */))
const _692d6ea2 = () => interopDefault(import('../pages/purchase/motorcycle/holder/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/holder/index" */))
const _2197913c = () => interopDefault(import('../pages/purchase/motorcycle/product/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/product/index" */))
const _7ca05959 = () => interopDefault(import('../pages/purchase/motorcycle/summary/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/summary/index" */))
const _2d3bdfd2 = () => interopDefault(import('../pages/purchase/personal-accident/beneficiary/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/beneficiary/index" */))
const _146d6710 = () => interopDefault(import('../pages/purchase/personal-accident/holder/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/holder/index" */))
const _729835be = () => interopDefault(import('../pages/purchase/personal-accident/product/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/product/index" */))
const _54200718 = () => interopDefault(import('../pages/purchase/personal-accident/summary/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/summary/index" */))
const _2217bb32 = () => interopDefault(import('../pages/purchase/property/addons/index.vue' /* webpackChunkName: "pages/purchase/property/addons/index" */))
const _27373e1e = () => interopDefault(import('../pages/purchase/property/declaration/index.vue' /* webpackChunkName: "pages/purchase/property/declaration/index" */))
const _0abc4060 = () => interopDefault(import('../pages/purchase/property/detail/index.vue' /* webpackChunkName: "pages/purchase/property/detail/index" */))
const _1eabdf6b = () => interopDefault(import('../pages/purchase/property/holder/index.vue' /* webpackChunkName: "pages/purchase/property/holder/index" */))
const _04447826 = () => interopDefault(import('../pages/purchase/property/product/index.vue' /* webpackChunkName: "pages/purchase/property/product/index" */))
const _dc9ecbc6 = () => interopDefault(import('../pages/purchase/property/summary/index.vue' /* webpackChunkName: "pages/purchase/property/summary/index" */))
const _4dfc2b7b = () => interopDefault(import('../pages/purchase/smartphone/holder/index.vue' /* webpackChunkName: "pages/purchase/smartphone/holder/index" */))
const _8204a3d4 = () => interopDefault(import('../pages/purchase/smartphone/product/index.vue' /* webpackChunkName: "pages/purchase/smartphone/product/index" */))
const _32444bb6 = () => interopDefault(import('../pages/purchase/smartphone/request/index.vue' /* webpackChunkName: "pages/purchase/smartphone/request/index" */))
const _4c69d00d = () => interopDefault(import('../pages/purchase/smartphone/summary/index.vue' /* webpackChunkName: "pages/purchase/smartphone/summary/index" */))
const _5a8c762d = () => interopDefault(import('../pages/purchase/tropical-disease/holder/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/holder/index" */))
const _4476b9a4 = () => interopDefault(import('../pages/purchase/tropical-disease/product/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/product/index" */))
const _5c3a48ca = () => interopDefault(import('../pages/purchase/tropical-disease/summary/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/summary/index" */))
const _e2e08474 = () => interopDefault(import('../pages/claim/travel/flight/success.vue' /* webpackChunkName: "pages/claim/travel/flight/success" */))
const _0e9cc8ce = () => interopDefault(import('../pages/payment/confirmation/directDebit/linked/index.vue' /* webpackChunkName: "pages/payment/confirmation/directDebit/linked/index" */))
const _291c3d6c = () => interopDefault(import('../pages/purchase/car-my/product/compare/index.vue' /* webpackChunkName: "pages/purchase/car-my/product/compare/index" */))
const _3dd99600 = () => interopDefault(import('../pages/purchase/car-my/product/detail/index.vue' /* webpackChunkName: "pages/purchase/car-my/product/detail/index" */))
const _6a647c62 = () => interopDefault(import('../pages/purchase/car/product/compare/index.vue' /* webpackChunkName: "pages/purchase/car/product/compare/index" */))
const _9683cf86 = () => interopDefault(import('../pages/purchase/car/product/detail/index.vue' /* webpackChunkName: "pages/purchase/car/product/detail/index" */))
const _6c67b9ec = () => interopDefault(import('../pages/purchase/car/quotation/success/index.vue' /* webpackChunkName: "pages/purchase/car/quotation/success/index" */))
const _7a5ff50d = () => interopDefault(import('../pages/purchase/car/request/success/index.vue' /* webpackChunkName: "pages/purchase/car/request/success/index" */))
const _085d2590 = () => interopDefault(import('../pages/purchase/hcp/product/compare/index.vue' /* webpackChunkName: "pages/purchase/hcp/product/compare/index" */))
const _507a8f48 = () => interopDefault(import('../pages/purchase/hcp/product/detail/index.vue' /* webpackChunkName: "pages/purchase/hcp/product/detail/index" */))
const _6f2e90f4 = () => interopDefault(import('../pages/purchase/health-my/product/detail/index.vue' /* webpackChunkName: "pages/purchase/health-my/product/detail/index" */))
const _0c378ac3 = () => interopDefault(import('../pages/purchase/health/product/compare/index.vue' /* webpackChunkName: "pages/purchase/health/product/compare/index" */))
const _433d60c9 = () => interopDefault(import('../pages/purchase/health/product/detail/index.vue' /* webpackChunkName: "pages/purchase/health/product/detail/index" */))
const _0266bce3 = () => interopDefault(import('../pages/purchase/life/product/compare/index.vue' /* webpackChunkName: "pages/purchase/life/product/compare/index" */))
const _61612aae = () => interopDefault(import('../pages/purchase/life/product/detail/index.vue' /* webpackChunkName: "pages/purchase/life/product/detail/index" */))
const _3a51c3e3 = () => interopDefault(import('../pages/purchase/motorcycle-my/product/compare/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/product/compare/index" */))
const _0b30f8ae = () => interopDefault(import('../pages/purchase/motorcycle-my/product/detail/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/product/detail/index" */))
const _32af7234 = () => interopDefault(import('../pages/purchase/motorcycle/product/detail/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/product/detail/index" */))
const _6aa39f95 = () => interopDefault(import('../pages/purchase/personal-accident/product/detail/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/product/detail/index" */))
const _9f863908 = () => interopDefault(import('../pages/purchase/property/product/compare/index.vue' /* webpackChunkName: "pages/purchase/property/product/compare/index" */))
const _1f3d8cf0 = () => interopDefault(import('../pages/purchase/property/product/detail/index.vue' /* webpackChunkName: "pages/purchase/property/product/detail/index" */))
const _d86e6e00 = () => interopDefault(import('../pages/purchase/smartphone/product/detail/index.vue' /* webpackChunkName: "pages/purchase/smartphone/product/detail/index" */))
const _7cab5caa = () => interopDefault(import('../pages/purchase/smartphone/request/success/index.vue' /* webpackChunkName: "pages/purchase/smartphone/request/success/index" */))
const _1ff77c9c = () => interopDefault(import('../pages/purchase/tropical-disease/product/detail/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/product/detail/index" */))
const _028743bc = () => interopDefault(import('../pages/purchase/property/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/property/summary/_quotationCode/index" */))
const _55d16722 = () => interopDefault(import('../pages/purchase/car/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/car/summary/_quotationCode/index" */))
const _5f6d9c60 = () => interopDefault(import('../pages/purchase/car/detail/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/car/detail/_transactionNumber/index" */))
const _aab28134 = () => interopDefault(import('../pages/purchase/motorcycle/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/summary/_quotationCode/index" */))
const _109cc779 = () => interopDefault(import('../pages/purchase/motorcycle/detail/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/detail/_transactionNumber/index" */))
const _48b0f632 = () => interopDefault(import('../pages/purchase/car-my/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/car-my/summary/_quotationCode/index" */))
const _edf6e84a = () => interopDefault(import('../pages/purchase/motorcycle-my/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/summary/_quotationCode/index" */))
const _37e80872 = () => interopDefault(import('../pages/purchase/personal-accident/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/personal-accident/summary/_quotationCode/index" */))
const _697933fe = () => interopDefault(import('../pages/purchase/motorcycle/holder/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/holder/_transactionNumber/index" */))
const _3d37702d = () => interopDefault(import('../pages/purchase/car/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/car/product/detail/_sharedId/index" */))
const _2693fd38 = () => interopDefault(import('../pages/purchase/motorcycle/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/motorcycle/product/detail/_sharedId/index" */))
const _310db530 = () => interopDefault(import('../pages/purchase/car-my/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/car-my/product/detail/_sharedId/index" */))
const _62a5b58c = () => interopDefault(import('../pages/purchase/hcp/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/hcp/product/detail/_sharedId/index" */))
const _8f630c8e = () => interopDefault(import('../pages/purchase/health/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/health/product/detail/_sharedId/index" */))
const _2845ab99 = () => interopDefault(import('../pages/purchase/life/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/life/product/detail/_sharedId/index" */))
const _aa5af6ce = () => interopDefault(import('../pages/purchase/motorcycle-my/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/motorcycle-my/product/detail/_sharedId/index" */))
const _064b6da0 = () => interopDefault(import('../pages/purchase/smartphone/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/smartphone/product/detail/_sharedId/index" */))
const _46240b62 = () => interopDefault(import('../pages/purchase/tropical-disease/product/detail/_sharedId/index.vue' /* webpackChunkName: "pages/purchase/tropical-disease/product/detail/_sharedId/index" */))
const _29259e55 = () => interopDefault(import('../pages/purchase/car/holder/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/car/holder/_transactionNumber/index" */))
const _ad3e98de = () => interopDefault(import('../pages/purchase/health/family/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/health/family/_transactionNumber/index" */))
const _10efc649 = () => interopDefault(import('../pages/purchase/health/holder/_transactionNumber/index.vue' /* webpackChunkName: "pages/purchase/health/holder/_transactionNumber/index" */))
const _6d896e0a = () => interopDefault(import('../pages/purchase/health/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/health/summary/_quotationCode/index" */))
const _471572db = () => interopDefault(import('../pages/purchase/life/summary/_quotationCode/index.vue' /* webpackChunkName: "pages/purchase/life/summary/_quotationCode/index" */))
const _74699173 = () => interopDefault(import('../pages/purchase/life/summary/_quotationCode/product-information-summary/index.vue' /* webpackChunkName: "pages/purchase/life/summary/_quotationCode/product-information-summary/index" */))
const _b240f924 = () => interopDefault(import('../pages/claim/add/_token/index.vue' /* webpackChunkName: "pages/claim/add/_token/index" */))
const _ed296c20 = () => interopDefault(import('../pages/claim/detail/_number/index.vue' /* webpackChunkName: "pages/claim/detail/_number/index" */))
const _918f24cc = () => interopDefault(import('../pages/payment/success/_policyId/index.vue' /* webpackChunkName: "pages/payment/success/_policyId/index" */))
const _766f91d7 = () => interopDefault(import('../pages/policy/add/_partner/index.vue' /* webpackChunkName: "pages/policy/add/_partner/index" */))
const _4f7803be = () => interopDefault(import('../pages/promo/detail/_promoCode/index.vue' /* webpackChunkName: "pages/promo/detail/_promoCode/index" */))
const _3a997936 = () => interopDefault(import('../pages/purchase/smartphone/_partnerCode/index.vue' /* webpackChunkName: "pages/purchase/smartphone/_partnerCode/index" */))
const _7a16edde = () => interopDefault(import('../pages/quotation/comparison/_quoteId/index.vue' /* webpackChunkName: "pages/quotation/comparison/_quoteId/index" */))
const _5ead2afd = () => interopDefault(import('../pages/quotation/pdf/_id/index.vue' /* webpackChunkName: "pages/quotation/pdf/_id/index" */))
const _7f44fdb6 = () => interopDefault(import('../pages/claim/_product/index.vue' /* webpackChunkName: "pages/claim/_product/index" */))
const _01aa442b = () => interopDefault(import('../pages/downloadCertificate/_number/index.vue' /* webpackChunkName: "pages/downloadCertificate/_number/index" */))
const _536fa256 = () => interopDefault(import('../pages/payment/_result/index.vue' /* webpackChunkName: "pages/payment/_result/index" */))
const _7b23604c = () => interopDefault(import('../pages/quotation/_id/index.vue' /* webpackChunkName: "pages/quotation/_id/index" */))
const _677172fa = () => interopDefault(import('../pages/request/_product/index.vue' /* webpackChunkName: "pages/request/_product/index" */))
const _7e04aac9 = () => interopDefault(import('../pages/claim/_product/_token/index.vue' /* webpackChunkName: "pages/claim/_product/_token/index" */))
const _0af2b07b = () => interopDefault(import('../pages/products-whitelabel/_partner/_product.vue' /* webpackChunkName: "pages/products-whitelabel/_partner/_product" */))
const _290aee9c = () => interopDefault(import('../pages/benefits/_.vue' /* webpackChunkName: "pages/benefits/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/id",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___id"
  }, {
    path: "/id-en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___id-en"
  }, {
    path: "/my",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___my"
  }, {
    path: "/my-en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___my-en"
  }, {
    path: "/ph",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___ph"
  }, {
    path: "/ph-en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___ph-en"
  }, {
    path: "/th",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___th"
  }, {
    path: "/th-en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___th-en"
  }, {
    path: "/vn",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___vn"
  }, {
    path: "/vn-en",
    component: _e542f99c,
    pathToRegexpOptions: {"strict":true},
    name: "index___vn-en"
  }, {
    path: "/id-en/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___id-en"
  }, {
    path: "/id-en/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___id-en"
  }, {
    path: "/id-en/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___id-en"
  }, {
    path: "/id-en/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___id-en"
  }, {
    path: "/id-en/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___id-en"
  }, {
    path: "/id-en/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___id-en"
  }, {
    path: "/id-en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___id-en"
  }, {
    path: "/id-en/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___id-en"
  }, {
    path: "/id-en/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___id-en"
  }, {
    path: "/id-en/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___id-en"
  }, {
    path: "/id-en/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___id-en"
  }, {
    path: "/id-en/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___id-en"
  }, {
    path: "/id-en/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___id-en"
  }, {
    path: "/id-en/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___id-en"
  }, {
    path: "/id-en/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___id-en"
  }, {
    path: "/id-en/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___id-en"
  }, {
    path: "/id-en/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___id-en"
  }, {
    path: "/id-en/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___id-en"
  }, {
    path: "/id-en/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___id-en"
  }, {
    path: "/id-en/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___id-en"
  }, {
    path: "/id-en/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___id-en"
  }, {
    path: "/id-en/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___id-en"
  }, {
    path: "/id-en/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___id-en"
  }, {
    path: "/id-en/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___id-en"
  }, {
    path: "/id-en/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___id-en"
  }, {
    path: "/id-en/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___id-en"
  }, {
    path: "/id/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___id"
  }, {
    path: "/id/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___id"
  }, {
    path: "/id/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___id"
  }, {
    path: "/id/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___id"
  }, {
    path: "/id/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___id"
  }, {
    path: "/id/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___id"
  }, {
    path: "/id/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___id"
  }, {
    path: "/id/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___id"
  }, {
    path: "/id/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___id"
  }, {
    path: "/id/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___id"
  }, {
    path: "/id/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___id"
  }, {
    path: "/id/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___id"
  }, {
    path: "/id/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___id"
  }, {
    path: "/id/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___id"
  }, {
    path: "/id/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___id"
  }, {
    path: "/id/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___id"
  }, {
    path: "/id/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___id"
  }, {
    path: "/id/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___id"
  }, {
    path: "/id/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___id"
  }, {
    path: "/id/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___id"
  }, {
    path: "/id/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___id"
  }, {
    path: "/my-en/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___my-en"
  }, {
    path: "/my-en/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___my-en"
  }, {
    path: "/my-en/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___my-en"
  }, {
    path: "/my-en/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___my-en"
  }, {
    path: "/my-en/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___my-en"
  }, {
    path: "/my-en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___my-en"
  }, {
    path: "/my-en/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___my-en"
  }, {
    path: "/my-en/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___my-en"
  }, {
    path: "/my-en/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___my-en"
  }, {
    path: "/my-en/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___my-en"
  }, {
    path: "/my-en/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___my-en"
  }, {
    path: "/my-en/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___my-en"
  }, {
    path: "/my-en/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___my-en"
  }, {
    path: "/my-en/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___my-en"
  }, {
    path: "/my-en/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___my-en"
  }, {
    path: "/my-en/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___my-en"
  }, {
    path: "/my-en/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___my-en"
  }, {
    path: "/my-en/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___my-en"
  }, {
    path: "/my-en/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___my-en"
  }, {
    path: "/my-en/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___my-en"
  }, {
    path: "/my-en/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___my-en"
  }, {
    path: "/my-en/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___my-en"
  }, {
    path: "/my-en/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___my-en"
  }, {
    path: "/my/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___my"
  }, {
    path: "/my/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___my"
  }, {
    path: "/my/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___my"
  }, {
    path: "/my/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___my"
  }, {
    path: "/my/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___my"
  }, {
    path: "/my/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___my"
  }, {
    path: "/my/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___my"
  }, {
    path: "/my/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___my"
  }, {
    path: "/my/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___my"
  }, {
    path: "/my/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___my"
  }, {
    path: "/my/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___my"
  }, {
    path: "/my/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___my"
  }, {
    path: "/my/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___my"
  }, {
    path: "/my/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___my"
  }, {
    path: "/my/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___my"
  }, {
    path: "/my/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___my"
  }, {
    path: "/my/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___my"
  }, {
    path: "/my/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___my"
  }, {
    path: "/my/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___my"
  }, {
    path: "/my/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___my"
  }, {
    path: "/my/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___my"
  }, {
    path: "/my/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___my"
  }, {
    path: "/my/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___my"
  }, {
    path: "/ph-en/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___ph-en"
  }, {
    path: "/ph-en/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___ph-en"
  }, {
    path: "/ph-en/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___ph-en"
  }, {
    path: "/ph-en/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___ph-en"
  }, {
    path: "/ph-en/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___ph-en"
  }, {
    path: "/ph-en/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___ph-en"
  }, {
    path: "/ph-en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___ph-en"
  }, {
    path: "/ph-en/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___ph-en"
  }, {
    path: "/ph-en/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___ph-en"
  }, {
    path: "/ph-en/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___ph-en"
  }, {
    path: "/ph-en/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___ph-en"
  }, {
    path: "/ph-en/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___ph-en"
  }, {
    path: "/ph-en/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___ph-en"
  }, {
    path: "/ph-en/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___ph-en"
  }, {
    path: "/ph-en/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___ph-en"
  }, {
    path: "/ph-en/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___ph-en"
  }, {
    path: "/ph-en/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___ph-en"
  }, {
    path: "/ph-en/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___ph-en"
  }, {
    path: "/ph-en/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___ph-en"
  }, {
    path: "/ph-en/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___ph-en"
  }, {
    path: "/ph-en/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___ph-en"
  }, {
    path: "/ph-en/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___ph-en"
  }, {
    path: "/ph-en/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___ph-en"
  }, {
    path: "/ph-en/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___ph-en"
  }, {
    path: "/ph-en/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___ph-en"
  }, {
    path: "/ph-en/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___ph-en"
  }, {
    path: "/ph/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___ph"
  }, {
    path: "/ph/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___ph"
  }, {
    path: "/ph/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___ph"
  }, {
    path: "/ph/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___ph"
  }, {
    path: "/ph/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___ph"
  }, {
    path: "/ph/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___ph"
  }, {
    path: "/ph/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___ph"
  }, {
    path: "/ph/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___ph"
  }, {
    path: "/ph/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___ph"
  }, {
    path: "/ph/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___ph"
  }, {
    path: "/ph/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___ph"
  }, {
    path: "/ph/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___ph"
  }, {
    path: "/ph/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___ph"
  }, {
    path: "/ph/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___ph"
  }, {
    path: "/ph/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___ph"
  }, {
    path: "/ph/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___ph"
  }, {
    path: "/ph/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___ph"
  }, {
    path: "/ph/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___ph"
  }, {
    path: "/ph/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___ph"
  }, {
    path: "/ph/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___ph"
  }, {
    path: "/ph/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___ph"
  }, {
    path: "/ph/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___ph"
  }, {
    path: "/ph/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___ph"
  }, {
    path: "/ph/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___ph"
  }, {
    path: "/ph/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___ph"
  }, {
    path: "/ph/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___ph"
  }, {
    path: "/th-en/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___th-en"
  }, {
    path: "/th-en/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___th-en"
  }, {
    path: "/th-en/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___th-en"
  }, {
    path: "/th-en/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___th-en"
  }, {
    path: "/th-en/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___th-en"
  }, {
    path: "/th-en/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___th-en"
  }, {
    path: "/th-en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___th-en"
  }, {
    path: "/th-en/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___th-en"
  }, {
    path: "/th-en/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___th-en"
  }, {
    path: "/th-en/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___th-en"
  }, {
    path: "/th-en/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___th-en"
  }, {
    path: "/th-en/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___th-en"
  }, {
    path: "/th-en/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___th-en"
  }, {
    path: "/th-en/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___th-en"
  }, {
    path: "/th-en/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___th-en"
  }, {
    path: "/th-en/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___th-en"
  }, {
    path: "/th-en/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___th-en"
  }, {
    path: "/th-en/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___th-en"
  }, {
    path: "/th-en/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___th-en"
  }, {
    path: "/th-en/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___th-en"
  }, {
    path: "/th-en/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___th-en"
  }, {
    path: "/th-en/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___th-en"
  }, {
    path: "/th-en/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___th-en"
  }, {
    path: "/th-en/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___th-en"
  }, {
    path: "/th-en/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___th-en"
  }, {
    path: "/th-en/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___th-en"
  }, {
    path: "/th/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___th"
  }, {
    path: "/th/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___th"
  }, {
    path: "/th/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___th"
  }, {
    path: "/th/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___th"
  }, {
    path: "/th/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___th"
  }, {
    path: "/th/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___th"
  }, {
    path: "/th/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___th"
  }, {
    path: "/th/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___th"
  }, {
    path: "/th/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___th"
  }, {
    path: "/th/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___th"
  }, {
    path: "/th/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___th"
  }, {
    path: "/th/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___th"
  }, {
    path: "/th/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___th"
  }, {
    path: "/th/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___th"
  }, {
    path: "/th/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___th"
  }, {
    path: "/th/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___th"
  }, {
    path: "/th/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___th"
  }, {
    path: "/th/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___th"
  }, {
    path: "/th/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___th"
  }, {
    path: "/th/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___th"
  }, {
    path: "/th/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___th"
  }, {
    path: "/th/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___th"
  }, {
    path: "/th/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___th"
  }, {
    path: "/th/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___th"
  }, {
    path: "/th/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___th"
  }, {
    path: "/th/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___th"
  }, {
    path: "/vn-en/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___vn-en"
  }, {
    path: "/vn-en/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___vn-en"
  }, {
    path: "/vn-en/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___vn-en"
  }, {
    path: "/vn-en/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___vn-en"
  }, {
    path: "/vn-en/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___vn-en"
  }, {
    path: "/vn-en/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___vn-en"
  }, {
    path: "/vn-en/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___vn-en"
  }, {
    path: "/vn-en/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___vn-en"
  }, {
    path: "/vn-en/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___vn-en"
  }, {
    path: "/vn-en/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___vn-en"
  }, {
    path: "/vn-en/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___vn-en"
  }, {
    path: "/vn-en/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___vn-en"
  }, {
    path: "/vn-en/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___vn-en"
  }, {
    path: "/vn-en/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___vn-en"
  }, {
    path: "/vn-en/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___vn-en"
  }, {
    path: "/vn-en/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___vn-en"
  }, {
    path: "/vn-en/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___vn-en"
  }, {
    path: "/vn-en/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___vn-en"
  }, {
    path: "/vn-en/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___vn-en"
  }, {
    path: "/vn-en/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___vn-en"
  }, {
    path: "/vn-en/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___vn-en"
  }, {
    path: "/vn-en/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___vn-en"
  }, {
    path: "/vn-en/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___vn-en"
  }, {
    path: "/vn-en/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___vn-en"
  }, {
    path: "/vn-en/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___vn-en"
  }, {
    path: "/vn-en/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___vn-en"
  }, {
    path: "/vn/about",
    component: _6c472da0,
    pathToRegexpOptions: {"strict":true},
    name: "about___vn"
  }, {
    path: "/vn/account",
    component: _079375d0,
    pathToRegexpOptions: {"strict":true},
    name: "account___vn"
  }, {
    path: "/vn/car",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___vn"
  }, {
    path: "/vn/car-my",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___vn"
  }, {
    path: "/vn/downloadCertificate",
    component: _1a9f9ddc,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate___vn"
  }, {
    path: "/vn/employee-benefit",
    component: _1492e9cb,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit___vn"
  }, {
    path: "/vn/faq",
    component: _35a3cb79,
    pathToRegexpOptions: {"strict":true},
    name: "faq___vn"
  }, {
    path: "/vn/hcp",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___vn"
  }, {
    path: "/vn/health",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___vn"
  }, {
    path: "/vn/health-my",
    component: _2965ef30,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health-my___vn"
  }, {
    path: "/vn/life",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___vn"
  }, {
    path: "/vn/mcis",
    component: _41307f8b,
    pathToRegexpOptions: {"strict":true},
    name: "landing-mcis___vn"
  }, {
    path: "/vn/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___vn"
  }, {
    path: "/vn/motorcycle-my",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___vn"
  }, {
    path: "/vn/notification",
    component: _bfccab04,
    pathToRegexpOptions: {"strict":true},
    name: "notification___vn"
  }, {
    path: "/vn/partnership",
    component: _506579e7,
    pathToRegexpOptions: {"strict":true},
    name: "partnership___vn"
  }, {
    path: "/vn/payment",
    component: _0b52e269,
    pathToRegexpOptions: {"strict":true},
    name: "payment___vn"
  }, {
    path: "/vn/privacy-notice",
    component: _55e129d0,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-notice___vn"
  }, {
    path: "/vn/products",
    component: _a7576892,
    pathToRegexpOptions: {"strict":true},
    name: "products___vn"
  }, {
    path: "/vn/promo",
    component: _058f9432,
    pathToRegexpOptions: {"strict":true},
    name: "promo___vn"
  }, {
    path: "/vn/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___vn"
  }, {
    path: "/vn/request",
    component: _cd0d689c,
    pathToRegexpOptions: {"strict":true},
    name: "request___vn"
  }, {
    path: "/vn/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___vn"
  }, {
    path: "/vn/toc",
    component: _eff929aa,
    pathToRegexpOptions: {"strict":true},
    name: "toc___vn"
  }, {
    path: "/vn/transactions",
    component: _b5eaa330,
    pathToRegexpOptions: {"strict":true},
    name: "transactions___vn"
  }, {
    path: "/vn/zurich",
    component: _7c696fbc,
    pathToRegexpOptions: {"strict":true},
    name: "landing-zurich___vn"
  }, {
    path: "/id-en/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___id-en"
  }, {
    path: "/id-en/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___id-en"
  }, {
    path: "/id-en/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___id-en"
  }, {
    path: "/id-en/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___id-en"
  }, {
    path: "/id-en/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___id-en"
  }, {
    path: "/id-en/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___id-en"
  }, {
    path: "/id-en/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___id-en"
  }, {
    path: "/id-en/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___id-en"
  }, {
    path: "/id-en/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___id-en"
  }, {
    path: "/id-en/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___id-en"
  }, {
    path: "/id-en/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___id-en"
  }, {
    path: "/id-en/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___id-en"
  }, {
    path: "/id-en/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___id-en"
  }, {
    path: "/id-en/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___id-en"
  }, {
    path: "/id-en/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___id-en"
  }, {
    path: "/id-en/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___id-en"
  }, {
    path: "/id-en/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___id-en"
  }, {
    path: "/id-en/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___id-en"
  }, {
    path: "/id-en/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___id-en"
  }, {
    path: "/id-en/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___id-en"
  }, {
    path: "/id-en/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___id-en"
  }, {
    path: "/id-en/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___id-en"
  }, {
    path: "/id-en/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___id-en"
  }, {
    path: "/id-en/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___id-en"
  }, {
    path: "/id-en/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___id-en"
  }, {
    path: "/id-en/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___id-en"
  }, {
    path: "/id-en/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___id-en"
  }, {
    path: "/id-en/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___id-en"
  }, {
    path: "/id-en/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___id-en"
  }, {
    path: "/id-en/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___id-en"
  }, {
    path: "/id-en/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___id-en"
  }, {
    path: "/id-en/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___id-en"
  }, {
    path: "/id-en/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___id-en"
  }, {
    path: "/id-en/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___id-en"
  }, {
    path: "/id-en/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___id-en"
  }, {
    path: "/id/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___id"
  }, {
    path: "/id/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___id"
  }, {
    path: "/id/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___id"
  }, {
    path: "/id/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___id"
  }, {
    path: "/id/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___id"
  }, {
    path: "/id/asuransi/jiwa",
    component: _8a63b372,
    pathToRegexpOptions: {"strict":true},
    name: "landing-life___id"
  }, {
    path: "/id/asuransi/kesehatan",
    component: _d32494b2,
    pathToRegexpOptions: {"strict":true},
    name: "landing-health___id"
  }, {
    path: "/id/asuransi/mobil",
    component: _615f6cbf,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car___id"
  }, {
    path: "/id/asuransi/motor",
    component: _136c1c20,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motor___id"
  }, {
    path: "/id/asuransi/penyakit-tropis",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___id"
  }, {
    path: "/id/asuransi/santunan-tunai",
    component: _20ef1b00,
    pathToRegexpOptions: {"strict":true},
    name: "landing-hcp___id"
  }, {
    path: "/id/asuransi/smartphone",
    component: _10a2a950,
    pathToRegexpOptions: {"strict":true},
    name: "landing-smartphone___id"
  }, {
    path: "/id/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___id"
  }, {
    path: "/id/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___id"
  }, {
    path: "/id/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___id"
  }, {
    path: "/id/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___id"
  }, {
    path: "/id/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___id"
  }, {
    path: "/id/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___id"
  }, {
    path: "/id/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___id"
  }, {
    path: "/id/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___id"
  }, {
    path: "/id/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___id"
  }, {
    path: "/id/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___id"
  }, {
    path: "/id/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___id"
  }, {
    path: "/id/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___id"
  }, {
    path: "/id/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___id"
  }, {
    path: "/id/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___id"
  }, {
    path: "/id/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___id"
  }, {
    path: "/id/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___id"
  }, {
    path: "/id/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___id"
  }, {
    path: "/id/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___id"
  }, {
    path: "/id/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___id"
  }, {
    path: "/id/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___id"
  }, {
    path: "/id/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___id"
  }, {
    path: "/id/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___id"
  }, {
    path: "/id/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___id"
  }, {
    path: "/id/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___id"
  }, {
    path: "/my-en/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___my-en"
  }, {
    path: "/my-en/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___my-en"
  }, {
    path: "/my-en/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___my-en"
  }, {
    path: "/my-en/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___my-en"
  }, {
    path: "/my-en/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___my-en"
  }, {
    path: "/my-en/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___my-en"
  }, {
    path: "/my-en/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___my-en"
  }, {
    path: "/my-en/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___my-en"
  }, {
    path: "/my-en/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___my-en"
  }, {
    path: "/my-en/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___my-en"
  }, {
    path: "/my-en/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___my-en"
  }, {
    path: "/my-en/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___my-en"
  }, {
    path: "/my-en/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___my-en"
  }, {
    path: "/my-en/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___my-en"
  }, {
    path: "/my-en/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___my-en"
  }, {
    path: "/my-en/insurance/car",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___my-en"
  }, {
    path: "/my-en/insurance/motorcycle",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___my-en"
  }, {
    path: "/my-en/insurance/property",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___my-en"
  }, {
    path: "/my-en/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___my-en"
  }, {
    path: "/my-en/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___my-en"
  }, {
    path: "/my-en/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___my-en"
  }, {
    path: "/my-en/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___my-en"
  }, {
    path: "/my-en/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___my-en"
  }, {
    path: "/my-en/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___my-en"
  }, {
    path: "/my-en/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___my-en"
  }, {
    path: "/my-en/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___my-en"
  }, {
    path: "/my-en/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___my-en"
  }, {
    path: "/my-en/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___my-en"
  }, {
    path: "/my-en/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___my-en"
  }, {
    path: "/my-en/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___my-en"
  }, {
    path: "/my-en/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___my-en"
  }, {
    path: "/my-en/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___my-en"
  }, {
    path: "/my-en/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___my-en"
  }, {
    path: "/my-en/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___my-en"
  }, {
    path: "/my-en/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___my-en"
  }, {
    path: "/my-en/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___my-en"
  }, {
    path: "/my-en/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___my-en"
  }, {
    path: "/my-en/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___my-en"
  }, {
    path: "/my/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___my"
  }, {
    path: "/my/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___my"
  }, {
    path: "/my/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___my"
  }, {
    path: "/my/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___my"
  }, {
    path: "/my/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___my"
  }, {
    path: "/my/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___my"
  }, {
    path: "/my/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___my"
  }, {
    path: "/my/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___my"
  }, {
    path: "/my/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___my"
  }, {
    path: "/my/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___my"
  }, {
    path: "/my/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___my"
  }, {
    path: "/my/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___my"
  }, {
    path: "/my/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___my"
  }, {
    path: "/my/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___my"
  }, {
    path: "/my/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___my"
  }, {
    path: "/my/insurans/kereta",
    component: _7c124b60,
    pathToRegexpOptions: {"strict":true},
    name: "landing-car-my___my"
  }, {
    path: "/my/insurans/motorsikal",
    component: _49afdf53,
    pathToRegexpOptions: {"strict":true},
    name: "landing-motorcycle-my___my"
  }, {
    path: "/my/insurans/rumah",
    component: _667b7c40,
    pathToRegexpOptions: {"strict":true},
    name: "landing-property___my"
  }, {
    path: "/my/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___my"
  }, {
    path: "/my/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___my"
  }, {
    path: "/my/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___my"
  }, {
    path: "/my/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___my"
  }, {
    path: "/my/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___my"
  }, {
    path: "/my/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___my"
  }, {
    path: "/my/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___my"
  }, {
    path: "/my/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___my"
  }, {
    path: "/my/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___my"
  }, {
    path: "/my/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___my"
  }, {
    path: "/my/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___my"
  }, {
    path: "/my/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___my"
  }, {
    path: "/my/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___my"
  }, {
    path: "/my/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___my"
  }, {
    path: "/my/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___my"
  }, {
    path: "/my/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___my"
  }, {
    path: "/my/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___my"
  }, {
    path: "/my/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___my"
  }, {
    path: "/my/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___my"
  }, {
    path: "/my/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___my"
  }, {
    path: "/my/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___my"
  }, {
    path: "/ph-en/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___ph-en"
  }, {
    path: "/ph-en/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___ph-en"
  }, {
    path: "/ph-en/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___ph-en"
  }, {
    path: "/ph-en/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___ph-en"
  }, {
    path: "/ph-en/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___ph-en"
  }, {
    path: "/ph-en/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___ph-en"
  }, {
    path: "/ph-en/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___ph-en"
  }, {
    path: "/ph-en/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___ph-en"
  }, {
    path: "/ph-en/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___ph-en"
  }, {
    path: "/ph-en/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___ph-en"
  }, {
    path: "/ph-en/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___ph-en"
  }, {
    path: "/ph-en/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___ph-en"
  }, {
    path: "/ph-en/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___ph-en"
  }, {
    path: "/ph-en/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___ph-en"
  }, {
    path: "/ph-en/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___ph-en"
  }, {
    path: "/ph-en/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___ph-en"
  }, {
    path: "/ph-en/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___ph-en"
  }, {
    path: "/ph-en/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___ph-en"
  }, {
    path: "/ph-en/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___ph-en"
  }, {
    path: "/ph-en/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___ph-en"
  }, {
    path: "/ph-en/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___ph-en"
  }, {
    path: "/ph-en/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___ph-en"
  }, {
    path: "/ph-en/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___ph-en"
  }, {
    path: "/ph-en/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___ph-en"
  }, {
    path: "/ph-en/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___ph-en"
  }, {
    path: "/ph-en/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___ph-en"
  }, {
    path: "/ph-en/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___ph-en"
  }, {
    path: "/ph-en/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___ph-en"
  }, {
    path: "/ph-en/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___ph-en"
  }, {
    path: "/ph-en/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___ph-en"
  }, {
    path: "/ph-en/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___ph-en"
  }, {
    path: "/ph-en/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___ph-en"
  }, {
    path: "/ph-en/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___ph-en"
  }, {
    path: "/ph/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___ph"
  }, {
    path: "/ph/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___ph"
  }, {
    path: "/ph/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___ph"
  }, {
    path: "/ph/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___ph"
  }, {
    path: "/ph/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___ph"
  }, {
    path: "/ph/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___ph"
  }, {
    path: "/ph/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___ph"
  }, {
    path: "/ph/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___ph"
  }, {
    path: "/ph/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___ph"
  }, {
    path: "/ph/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___ph"
  }, {
    path: "/ph/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___ph"
  }, {
    path: "/ph/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___ph"
  }, {
    path: "/ph/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___ph"
  }, {
    path: "/ph/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___ph"
  }, {
    path: "/ph/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___ph"
  }, {
    path: "/ph/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___ph"
  }, {
    path: "/ph/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___ph"
  }, {
    path: "/ph/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___ph"
  }, {
    path: "/ph/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___ph"
  }, {
    path: "/ph/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___ph"
  }, {
    path: "/ph/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___ph"
  }, {
    path: "/ph/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___ph"
  }, {
    path: "/ph/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___ph"
  }, {
    path: "/ph/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___ph"
  }, {
    path: "/ph/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___ph"
  }, {
    path: "/ph/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___ph"
  }, {
    path: "/ph/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___ph"
  }, {
    path: "/ph/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___ph"
  }, {
    path: "/ph/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___ph"
  }, {
    path: "/ph/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___ph"
  }, {
    path: "/ph/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___ph"
  }, {
    path: "/ph/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___ph"
  }, {
    path: "/ph/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___ph"
  }, {
    path: "/ph/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___ph"
  }, {
    path: "/ph/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___ph"
  }, {
    path: "/ph/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___ph"
  }, {
    path: "/th-en/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___th-en"
  }, {
    path: "/th-en/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___th-en"
  }, {
    path: "/th-en/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___th-en"
  }, {
    path: "/th-en/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___th-en"
  }, {
    path: "/th-en/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___th-en"
  }, {
    path: "/th-en/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___th-en"
  }, {
    path: "/th-en/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___th-en"
  }, {
    path: "/th-en/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___th-en"
  }, {
    path: "/th-en/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___th-en"
  }, {
    path: "/th-en/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___th-en"
  }, {
    path: "/th-en/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___th-en"
  }, {
    path: "/th-en/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___th-en"
  }, {
    path: "/th-en/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___th-en"
  }, {
    path: "/th-en/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___th-en"
  }, {
    path: "/th-en/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___th-en"
  }, {
    path: "/th-en/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___th-en"
  }, {
    path: "/th-en/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___th-en"
  }, {
    path: "/th-en/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___th-en"
  }, {
    path: "/th-en/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___th-en"
  }, {
    path: "/th-en/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___th-en"
  }, {
    path: "/th-en/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___th-en"
  }, {
    path: "/th-en/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___th-en"
  }, {
    path: "/th-en/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___th-en"
  }, {
    path: "/th-en/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___th-en"
  }, {
    path: "/th-en/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___th-en"
  }, {
    path: "/th-en/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___th-en"
  }, {
    path: "/th-en/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___th-en"
  }, {
    path: "/th-en/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___th-en"
  }, {
    path: "/th-en/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___th-en"
  }, {
    path: "/th-en/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___th-en"
  }, {
    path: "/th-en/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___th-en"
  }, {
    path: "/th-en/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___th-en"
  }, {
    path: "/th-en/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___th-en"
  }, {
    path: "/th-en/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___th-en"
  }, {
    path: "/th-en/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___th-en"
  }, {
    path: "/th/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___th"
  }, {
    path: "/th/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___th"
  }, {
    path: "/th/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___th"
  }, {
    path: "/th/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___th"
  }, {
    path: "/th/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___th"
  }, {
    path: "/th/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___th"
  }, {
    path: "/th/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___th"
  }, {
    path: "/th/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___th"
  }, {
    path: "/th/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___th"
  }, {
    path: "/th/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___th"
  }, {
    path: "/th/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___th"
  }, {
    path: "/th/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___th"
  }, {
    path: "/th/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___th"
  }, {
    path: "/th/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___th"
  }, {
    path: "/th/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___th"
  }, {
    path: "/th/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___th"
  }, {
    path: "/th/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___th"
  }, {
    path: "/th/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___th"
  }, {
    path: "/th/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___th"
  }, {
    path: "/th/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___th"
  }, {
    path: "/th/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___th"
  }, {
    path: "/th/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___th"
  }, {
    path: "/th/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___th"
  }, {
    path: "/th/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___th"
  }, {
    path: "/th/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___th"
  }, {
    path: "/th/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___th"
  }, {
    path: "/th/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___th"
  }, {
    path: "/th/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___th"
  }, {
    path: "/th/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___th"
  }, {
    path: "/th/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___th"
  }, {
    path: "/th/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___th"
  }, {
    path: "/th/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___th"
  }, {
    path: "/th/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___th"
  }, {
    path: "/th/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___th"
  }, {
    path: "/th/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___th"
  }, {
    path: "/th/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___th"
  }, {
    path: "/vn-en/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___vn-en"
  }, {
    path: "/vn-en/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___vn-en"
  }, {
    path: "/vn-en/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___vn-en"
  }, {
    path: "/vn-en/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___vn-en"
  }, {
    path: "/vn-en/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___vn-en"
  }, {
    path: "/vn-en/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___vn-en"
  }, {
    path: "/vn-en/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___vn-en"
  }, {
    path: "/vn-en/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___vn-en"
  }, {
    path: "/vn-en/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___vn-en"
  }, {
    path: "/vn-en/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___vn-en"
  }, {
    path: "/vn-en/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___vn-en"
  }, {
    path: "/vn-en/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___vn-en"
  }, {
    path: "/vn-en/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___vn-en"
  }, {
    path: "/vn-en/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___vn-en"
  }, {
    path: "/vn-en/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___vn-en"
  }, {
    path: "/vn-en/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___vn-en"
  }, {
    path: "/vn-en/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___vn-en"
  }, {
    path: "/vn-en/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___vn-en"
  }, {
    path: "/vn-en/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___vn-en"
  }, {
    path: "/vn-en/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___vn-en"
  }, {
    path: "/vn-en/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___vn-en"
  }, {
    path: "/vn-en/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___vn-en"
  }, {
    path: "/vn-en/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___vn-en"
  }, {
    path: "/vn-en/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___vn-en"
  }, {
    path: "/vn-en/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___vn-en"
  }, {
    path: "/vn-en/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___vn-en"
  }, {
    path: "/vn-en/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___vn-en"
  }, {
    path: "/vn-en/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___vn-en"
  }, {
    path: "/vn-en/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___vn-en"
  }, {
    path: "/vn-en/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___vn-en"
  }, {
    path: "/vn-en/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___vn-en"
  }, {
    path: "/vn-en/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___vn-en"
  }, {
    path: "/vn-en/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___vn-en"
  }, {
    path: "/vn/about/about-us",
    component: _7e1eae73,
    pathToRegexpOptions: {"strict":true},
    name: "about-about-us___vn"
  }, {
    path: "/vn/about/disclaimer-chapter",
    component: _efe1da20,
    pathToRegexpOptions: {"strict":true},
    name: "about-disclaimer-chapter___vn"
  }, {
    path: "/vn/about/privacy-policy",
    component: _dfeba428,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___vn"
  }, {
    path: "/vn/about/term-and-condition",
    component: _0de72ace,
    pathToRegexpOptions: {"strict":true},
    name: "about-term-and-condition___vn"
  }, {
    path: "/vn/account/assessment",
    component: _ae807792,
    pathToRegexpOptions: {"strict":true},
    name: "account-assessment___vn"
  }, {
    path: "/vn/certificate/dana",
    component: _314a31bb,
    pathToRegexpOptions: {"strict":true},
    name: "certificate-dana___vn"
  }, {
    path: "/vn/claim/add",
    component: _4b7264b1,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add___vn"
  }, {
    path: "/vn/claim/detail",
    component: _1801cbd7,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail___vn"
  }, {
    path: "/vn/edu/asuransi",
    component: _2e3f2c44,
    pathToRegexpOptions: {"strict":true},
    name: "edu-asuransi___vn"
  }, {
    path: "/vn/edu/car",
    component: _9a7ac7c4,
    pathToRegexpOptions: {"strict":true},
    name: "edu-car___vn"
  }, {
    path: "/vn/edu/hcp",
    component: _40eba1df,
    pathToRegexpOptions: {"strict":true},
    name: "edu-hcp___vn"
  }, {
    path: "/vn/edu/health",
    component: _88995430,
    pathToRegexpOptions: {"strict":true},
    name: "edu-health___vn"
  }, {
    path: "/vn/edu/life",
    component: _d45e3a70,
    pathToRegexpOptions: {"strict":true},
    name: "edu-life___vn"
  }, {
    path: "/vn/employee-benefit/request",
    component: _e463ed6a,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-request___vn"
  }, {
    path: "/vn/employee-benefit/success",
    component: _d1cdd482,
    pathToRegexpOptions: {"strict":true},
    name: "employee-benefit-success___vn"
  }, {
    path: "/vn/payment/method",
    component: _13ace5dd,
    pathToRegexpOptions: {"strict":true},
    name: "payment-method___vn"
  }, {
    path: "/vn/payment/success",
    component: _2c9d905d,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success___vn"
  }, {
    path: "/vn/policy/active",
    component: _5b7520a6,
    pathToRegexpOptions: {"strict":true},
    name: "policy-active___vn"
  }, {
    path: "/vn/policy/add",
    component: _55004697,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add___vn"
  }, {
    path: "/vn/policy/detail",
    component: _23f64f9e,
    pathToRegexpOptions: {"strict":true},
    name: "policy-detail___vn"
  }, {
    path: "/vn/profile/edit",
    component: _772f73fa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___vn"
  }, {
    path: "/vn/profile/summary",
    component: _87c245ba,
    pathToRegexpOptions: {"strict":true},
    name: "profile-summary___vn"
  }, {
    path: "/vn/promo/detail",
    component: _b9ede938,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail___vn"
  }, {
    path: "/vn/purchase/car",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___vn"
  }, {
    path: "/vn/purchase/hcp",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___vn"
  }, {
    path: "/vn/purchase/life",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___vn"
  }, {
    path: "/vn/purchase/motorcycle",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___vn"
  }, {
    path: "/vn/purchase/smartphone",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___vn"
  }, {
    path: "/vn/purchase/tropical-disease",
    component: _20af7e78,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease___vn"
  }, {
    path: "/vn/request/success",
    component: _e462beb4,
    pathToRegexpOptions: {"strict":true},
    name: "request-success___vn"
  }, {
    path: "/vn/sessions/forgot",
    component: _52ecbfb5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-forgot___vn"
  }, {
    path: "/vn/sessions/register",
    component: _7c8854b5,
    pathToRegexpOptions: {"strict":true},
    name: "sessions-register___vn"
  }, {
    path: "/vn/smartphone/service-center-address",
    component: _2b8d2604,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-address___vn"
  }, {
    path: "/vn/smartphone/service-center-list",
    component: _fef05df8,
    pathToRegexpOptions: {"strict":true},
    name: "smartphone-service-center-list___vn"
  }, {
    path: "/vn/special-promo/1212",
    component: _58db7961,
    pathToRegexpOptions: {"strict":true},
    name: "landing-special-promo-1212___vn"
  }, {
    path: "/vn/transactions/detail",
    component: _7b3c286e,
    pathToRegexpOptions: {"strict":true},
    name: "transactions-detail___vn"
  }, {
    path: "/id-en/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___id-en"
  }, {
    path: "/id-en/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___id-en"
  }, {
    path: "/id-en/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___id-en"
  }, {
    path: "/id-en/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___id-en"
  }, {
    path: "/id-en/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___id-en"
  }, {
    path: "/id-en/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___id-en"
  }, {
    path: "/id-en/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___id-en"
  }, {
    path: "/id-en/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___id-en"
  }, {
    path: "/id-en/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___id-en"
  }, {
    path: "/id-en/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___id-en"
  }, {
    path: "/id-en/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___id-en"
  }, {
    path: "/id-en/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___id-en"
  }, {
    path: "/id-en/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___id-en"
  }, {
    path: "/id-en/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___id-en"
  }, {
    path: "/id-en/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___id-en"
  }, {
    path: "/id-en/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___id-en"
  }, {
    path: "/id-en/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___id-en"
  }, {
    path: "/id-en/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___id-en"
  }, {
    path: "/id-en/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___id-en"
  }, {
    path: "/id-en/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___id-en"
  }, {
    path: "/id-en/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___id-en"
  }, {
    path: "/id-en/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___id-en"
  }, {
    path: "/id-en/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___id-en"
  }, {
    path: "/id-en/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___id-en"
  }, {
    path: "/id-en/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___id-en"
  }, {
    path: "/id-en/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___id-en"
  }, {
    path: "/id-en/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___id-en"
  }, {
    path: "/id-en/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___id-en"
  }, {
    path: "/id-en/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___id-en"
  }, {
    path: "/id-en/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___id-en"
  }, {
    path: "/id-en/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___id-en"
  }, {
    path: "/id-en/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___id-en"
  }, {
    path: "/id-en/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___id-en"
  }, {
    path: "/id-en/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___id-en"
  }, {
    path: "/id-en/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___id-en"
  }, {
    path: "/id-en/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___id-en"
  }, {
    path: "/id-en/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___id-en"
  }, {
    path: "/id-en/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___id-en"
  }, {
    path: "/id-en/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___id-en"
  }, {
    path: "/id-en/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___id-en"
  }, {
    path: "/id-en/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___id-en"
  }, {
    path: "/id-en/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___id-en"
  }, {
    path: "/id-en/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___id-en"
  }, {
    path: "/id-en/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___id-en"
  }, {
    path: "/id-en/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___id-en"
  }, {
    path: "/id-en/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___id-en"
  }, {
    path: "/id-en/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___id-en"
  }, {
    path: "/id/asuransi/jiwa/info",
    component: _1fd528ad,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life___id"
  }, {
    path: "/id/asuransi/kesehatan/anggota",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___id"
  }, {
    path: "/id/asuransi/mobil/tipe",
    component: _4bdb8599,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car___id"
  }, {
    path: "/id/asuransi/motor/tipe",
    component: _75e65c82,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle___id"
  }, {
    path: "/id/asuransi/penyakit-tropis/produk",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___id"
  }, {
    path: "/id/asuransi/santunan-tunai/info",
    component: _4bf6e94c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp___id"
  }, {
    path: "/id/asuransi/smartphone/info",
    component: _349bc736,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone___id"
  }, {
    path: "/id/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___id"
  }, {
    path: "/id/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___id"
  }, {
    path: "/id/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___id"
  }, {
    path: "/id/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___id"
  }, {
    path: "/id/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___id"
  }, {
    path: "/id/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___id"
  }, {
    path: "/id/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___id"
  }, {
    path: "/id/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___id"
  }, {
    path: "/id/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___id"
  }, {
    path: "/id/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___id"
  }, {
    path: "/id/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___id"
  }, {
    path: "/id/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___id"
  }, {
    path: "/id/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___id"
  }, {
    path: "/id/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___id"
  }, {
    path: "/id/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___id"
  }, {
    path: "/id/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___id"
  }, {
    path: "/id/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___id"
  }, {
    path: "/id/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___id"
  }, {
    path: "/id/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___id"
  }, {
    path: "/id/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___id"
  }, {
    path: "/id/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___id"
  }, {
    path: "/id/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___id"
  }, {
    path: "/id/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___id"
  }, {
    path: "/id/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___id"
  }, {
    path: "/id/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___id"
  }, {
    path: "/id/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___id"
  }, {
    path: "/id/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___id"
  }, {
    path: "/id/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___id"
  }, {
    path: "/id/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___id"
  }, {
    path: "/id/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___id"
  }, {
    path: "/id/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___id"
  }, {
    path: "/id/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___id"
  }, {
    path: "/id/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___id"
  }, {
    path: "/id/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___id"
  }, {
    path: "/id/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___id"
  }, {
    path: "/id/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___id"
  }, {
    path: "/id/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___id"
  }, {
    path: "/my-en/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___my-en"
  }, {
    path: "/my-en/insurance/car/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___my-en"
  }, {
    path: "/my-en/insurance/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___my-en"
  }, {
    path: "/my-en/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___my-en"
  }, {
    path: "/my-en/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___my-en"
  }, {
    path: "/my-en/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___my-en"
  }, {
    path: "/my-en/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___my-en"
  }, {
    path: "/my-en/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___my-en"
  }, {
    path: "/my-en/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___my-en"
  }, {
    path: "/my-en/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___my-en"
  }, {
    path: "/my-en/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___my-en"
  }, {
    path: "/my-en/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___my-en"
  }, {
    path: "/my-en/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___my-en"
  }, {
    path: "/my-en/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___my-en"
  }, {
    path: "/my-en/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___my-en"
  }, {
    path: "/my-en/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___my-en"
  }, {
    path: "/my-en/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___my-en"
  }, {
    path: "/my-en/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___my-en"
  }, {
    path: "/my-en/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___my-en"
  }, {
    path: "/my-en/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___my-en"
  }, {
    path: "/my-en/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___my-en"
  }, {
    path: "/my-en/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___my-en"
  }, {
    path: "/my-en/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___my-en"
  }, {
    path: "/my-en/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___my-en"
  }, {
    path: "/my-en/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___my-en"
  }, {
    path: "/my-en/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___my-en"
  }, {
    path: "/my-en/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___my-en"
  }, {
    path: "/my-en/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___my-en"
  }, {
    path: "/my-en/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___my-en"
  }, {
    path: "/my-en/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___my-en"
  }, {
    path: "/my-en/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___my-en"
  }, {
    path: "/my-en/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___my-en"
  }, {
    path: "/my-en/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___my-en"
  }, {
    path: "/my-en/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___my-en"
  }, {
    path: "/my-en/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___my-en"
  }, {
    path: "/my-en/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___my-en"
  }, {
    path: "/my-en/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___my-en"
  }, {
    path: "/my-en/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___my-en"
  }, {
    path: "/my-en/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___my-en"
  }, {
    path: "/my-en/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___my-en"
  }, {
    path: "/my/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___my"
  }, {
    path: "/my/insurans/kemalangan-diri/produk",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___my"
  }, {
    path: "/my/insurans/kereta/produk",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___my"
  }, {
    path: "/my/insurans/motorsikal/produk",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___my"
  }, {
    path: "/my/insurans/rumah/produk",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___my"
  }, {
    path: "/my/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___my"
  }, {
    path: "/my/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___my"
  }, {
    path: "/my/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___my"
  }, {
    path: "/my/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___my"
  }, {
    path: "/my/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___my"
  }, {
    path: "/my/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___my"
  }, {
    path: "/my/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___my"
  }, {
    path: "/my/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___my"
  }, {
    path: "/my/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___my"
  }, {
    path: "/my/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___my"
  }, {
    path: "/my/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___my"
  }, {
    path: "/my/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___my"
  }, {
    path: "/my/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___my"
  }, {
    path: "/my/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___my"
  }, {
    path: "/my/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___my"
  }, {
    path: "/my/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___my"
  }, {
    path: "/my/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___my"
  }, {
    path: "/my/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___my"
  }, {
    path: "/my/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___my"
  }, {
    path: "/my/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___my"
  }, {
    path: "/my/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___my"
  }, {
    path: "/my/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___my"
  }, {
    path: "/my/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___my"
  }, {
    path: "/my/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___my"
  }, {
    path: "/my/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___my"
  }, {
    path: "/my/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___my"
  }, {
    path: "/my/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___my"
  }, {
    path: "/my/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___my"
  }, {
    path: "/my/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___my"
  }, {
    path: "/my/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___my"
  }, {
    path: "/my/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___my"
  }, {
    path: "/my/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___my"
  }, {
    path: "/my/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___my"
  }, {
    path: "/my/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___my"
  }, {
    path: "/my/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___my"
  }, {
    path: "/my/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___my"
  }, {
    path: "/my/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___my"
  }, {
    path: "/my/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___my"
  }, {
    path: "/my/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___my"
  }, {
    path: "/my/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___my"
  }, {
    path: "/my/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___my"
  }, {
    path: "/my/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___my"
  }, {
    path: "/my/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___my"
  }, {
    path: "/my/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___my"
  }, {
    path: "/ph-en/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___ph-en"
  }, {
    path: "/ph-en/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___ph-en"
  }, {
    path: "/ph-en/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___ph-en"
  }, {
    path: "/ph-en/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___ph-en"
  }, {
    path: "/ph-en/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___ph-en"
  }, {
    path: "/ph-en/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___ph-en"
  }, {
    path: "/ph-en/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___ph-en"
  }, {
    path: "/ph-en/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___ph-en"
  }, {
    path: "/ph-en/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___ph-en"
  }, {
    path: "/ph-en/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___ph-en"
  }, {
    path: "/ph-en/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___ph-en"
  }, {
    path: "/ph-en/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___ph-en"
  }, {
    path: "/ph-en/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___ph-en"
  }, {
    path: "/ph-en/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___ph-en"
  }, {
    path: "/ph-en/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___ph-en"
  }, {
    path: "/ph-en/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___ph-en"
  }, {
    path: "/ph-en/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___ph-en"
  }, {
    path: "/ph-en/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___ph-en"
  }, {
    path: "/ph-en/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___ph-en"
  }, {
    path: "/ph-en/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___ph-en"
  }, {
    path: "/ph-en/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___ph-en"
  }, {
    path: "/ph-en/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___ph-en"
  }, {
    path: "/ph-en/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___ph-en"
  }, {
    path: "/ph-en/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___ph-en"
  }, {
    path: "/ph-en/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___ph-en"
  }, {
    path: "/ph-en/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___ph-en"
  }, {
    path: "/ph-en/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___ph-en"
  }, {
    path: "/ph-en/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___ph-en"
  }, {
    path: "/ph-en/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___ph-en"
  }, {
    path: "/ph-en/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___ph-en"
  }, {
    path: "/ph-en/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___ph-en"
  }, {
    path: "/ph-en/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___ph-en"
  }, {
    path: "/ph-en/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___ph-en"
  }, {
    path: "/ph-en/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___ph-en"
  }, {
    path: "/ph-en/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___ph-en"
  }, {
    path: "/ph/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___ph"
  }, {
    path: "/ph/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___ph"
  }, {
    path: "/ph/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___ph"
  }, {
    path: "/ph/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___ph"
  }, {
    path: "/ph/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___ph"
  }, {
    path: "/ph/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___ph"
  }, {
    path: "/ph/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___ph"
  }, {
    path: "/ph/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___ph"
  }, {
    path: "/ph/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___ph"
  }, {
    path: "/ph/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___ph"
  }, {
    path: "/ph/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___ph"
  }, {
    path: "/ph/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___ph"
  }, {
    path: "/ph/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___ph"
  }, {
    path: "/ph/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___ph"
  }, {
    path: "/ph/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___ph"
  }, {
    path: "/ph/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___ph"
  }, {
    path: "/ph/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___ph"
  }, {
    path: "/ph/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___ph"
  }, {
    path: "/ph/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___ph"
  }, {
    path: "/ph/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___ph"
  }, {
    path: "/ph/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___ph"
  }, {
    path: "/ph/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___ph"
  }, {
    path: "/ph/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___ph"
  }, {
    path: "/ph/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___ph"
  }, {
    path: "/ph/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___ph"
  }, {
    path: "/ph/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___ph"
  }, {
    path: "/ph/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___ph"
  }, {
    path: "/ph/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___ph"
  }, {
    path: "/ph/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___ph"
  }, {
    path: "/ph/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___ph"
  }, {
    path: "/ph/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___ph"
  }, {
    path: "/ph/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___ph"
  }, {
    path: "/ph/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___ph"
  }, {
    path: "/ph/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___ph"
  }, {
    path: "/ph/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___ph"
  }, {
    path: "/ph/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___ph"
  }, {
    path: "/ph/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___ph"
  }, {
    path: "/ph/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___ph"
  }, {
    path: "/ph/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___ph"
  }, {
    path: "/ph/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___ph"
  }, {
    path: "/ph/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___ph"
  }, {
    path: "/ph/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___ph"
  }, {
    path: "/ph/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___ph"
  }, {
    path: "/ph/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___ph"
  }, {
    path: "/ph/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___ph"
  }, {
    path: "/ph/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___ph"
  }, {
    path: "/ph/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___ph"
  }, {
    path: "/ph/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___ph"
  }, {
    path: "/ph/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___ph"
  }, {
    path: "/ph/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___ph"
  }, {
    path: "/ph/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___ph"
  }, {
    path: "/ph/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___ph"
  }, {
    path: "/ph/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___ph"
  }, {
    path: "/ph/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___ph"
  }, {
    path: "/ph/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___ph"
  }, {
    path: "/ph/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___ph"
  }, {
    path: "/ph/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___ph"
  }, {
    path: "/ph/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___ph"
  }, {
    path: "/th-en/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___th-en"
  }, {
    path: "/th-en/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___th-en"
  }, {
    path: "/th-en/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___th-en"
  }, {
    path: "/th-en/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___th-en"
  }, {
    path: "/th-en/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___th-en"
  }, {
    path: "/th-en/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___th-en"
  }, {
    path: "/th-en/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___th-en"
  }, {
    path: "/th-en/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___th-en"
  }, {
    path: "/th-en/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___th-en"
  }, {
    path: "/th-en/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___th-en"
  }, {
    path: "/th-en/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___th-en"
  }, {
    path: "/th-en/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___th-en"
  }, {
    path: "/th-en/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___th-en"
  }, {
    path: "/th-en/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___th-en"
  }, {
    path: "/th-en/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___th-en"
  }, {
    path: "/th-en/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___th-en"
  }, {
    path: "/th-en/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___th-en"
  }, {
    path: "/th-en/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___th-en"
  }, {
    path: "/th-en/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___th-en"
  }, {
    path: "/th-en/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___th-en"
  }, {
    path: "/th-en/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___th-en"
  }, {
    path: "/th-en/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___th-en"
  }, {
    path: "/th-en/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___th-en"
  }, {
    path: "/th-en/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___th-en"
  }, {
    path: "/th-en/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___th-en"
  }, {
    path: "/th-en/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___th-en"
  }, {
    path: "/th-en/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___th-en"
  }, {
    path: "/th-en/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___th-en"
  }, {
    path: "/th-en/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___th-en"
  }, {
    path: "/th-en/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___th-en"
  }, {
    path: "/th-en/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___th-en"
  }, {
    path: "/th-en/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___th-en"
  }, {
    path: "/th-en/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___th-en"
  }, {
    path: "/th-en/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___th-en"
  }, {
    path: "/th-en/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___th-en"
  }, {
    path: "/th-en/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___th-en"
  }, {
    path: "/th-en/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___th-en"
  }, {
    path: "/th-en/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___th-en"
  }, {
    path: "/th-en/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___th-en"
  }, {
    path: "/th-en/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___th-en"
  }, {
    path: "/th-en/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___th-en"
  }, {
    path: "/th-en/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___th-en"
  }, {
    path: "/th-en/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___th-en"
  }, {
    path: "/th-en/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___th-en"
  }, {
    path: "/th-en/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___th-en"
  }, {
    path: "/th-en/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___th-en"
  }, {
    path: "/th-en/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___th-en"
  }, {
    path: "/th/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___th"
  }, {
    path: "/th/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___th"
  }, {
    path: "/th/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___th"
  }, {
    path: "/th/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___th"
  }, {
    path: "/th/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___th"
  }, {
    path: "/th/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___th"
  }, {
    path: "/th/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___th"
  }, {
    path: "/th/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___th"
  }, {
    path: "/th/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___th"
  }, {
    path: "/th/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___th"
  }, {
    path: "/th/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___th"
  }, {
    path: "/th/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___th"
  }, {
    path: "/th/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___th"
  }, {
    path: "/th/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___th"
  }, {
    path: "/th/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___th"
  }, {
    path: "/th/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___th"
  }, {
    path: "/th/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___th"
  }, {
    path: "/th/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___th"
  }, {
    path: "/th/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___th"
  }, {
    path: "/th/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___th"
  }, {
    path: "/th/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___th"
  }, {
    path: "/th/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___th"
  }, {
    path: "/th/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___th"
  }, {
    path: "/th/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___th"
  }, {
    path: "/th/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___th"
  }, {
    path: "/th/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___th"
  }, {
    path: "/th/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___th"
  }, {
    path: "/th/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___th"
  }, {
    path: "/th/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___th"
  }, {
    path: "/th/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___th"
  }, {
    path: "/th/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___th"
  }, {
    path: "/th/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___th"
  }, {
    path: "/th/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___th"
  }, {
    path: "/th/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___th"
  }, {
    path: "/th/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___th"
  }, {
    path: "/th/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___th"
  }, {
    path: "/th/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___th"
  }, {
    path: "/th/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___th"
  }, {
    path: "/th/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___th"
  }, {
    path: "/th/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___th"
  }, {
    path: "/th/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___th"
  }, {
    path: "/th/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___th"
  }, {
    path: "/th/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___th"
  }, {
    path: "/th/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___th"
  }, {
    path: "/th/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___th"
  }, {
    path: "/th/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___th"
  }, {
    path: "/th/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___th"
  }, {
    path: "/th/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___th"
  }, {
    path: "/th/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___th"
  }, {
    path: "/th/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___th"
  }, {
    path: "/th/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___th"
  }, {
    path: "/th/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___th"
  }, {
    path: "/th/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___th"
  }, {
    path: "/th/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___th"
  }, {
    path: "/th/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___th"
  }, {
    path: "/th/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___th"
  }, {
    path: "/th/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___th"
  }, {
    path: "/th/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___th"
  }, {
    path: "/th/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___th"
  }, {
    path: "/th/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___th"
  }, {
    path: "/th/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___th"
  }, {
    path: "/th/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___th"
  }, {
    path: "/th/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___th"
  }, {
    path: "/vn-en/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___vn-en"
  }, {
    path: "/vn-en/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___vn-en"
  }, {
    path: "/vn-en/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___vn-en"
  }, {
    path: "/vn-en/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___vn-en"
  }, {
    path: "/vn-en/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___vn-en"
  }, {
    path: "/vn-en/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___vn-en"
  }, {
    path: "/vn-en/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___vn-en"
  }, {
    path: "/vn-en/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___vn-en"
  }, {
    path: "/vn-en/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___vn-en"
  }, {
    path: "/vn-en/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___vn-en"
  }, {
    path: "/vn-en/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___vn-en"
  }, {
    path: "/vn-en/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___vn-en"
  }, {
    path: "/vn-en/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___vn-en"
  }, {
    path: "/vn-en/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___vn-en"
  }, {
    path: "/vn-en/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___vn-en"
  }, {
    path: "/vn-en/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___vn-en"
  }, {
    path: "/vn-en/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___vn-en"
  }, {
    path: "/vn-en/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___vn-en"
  }, {
    path: "/vn-en/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___vn-en"
  }, {
    path: "/vn-en/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___vn-en"
  }, {
    path: "/vn-en/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___vn-en"
  }, {
    path: "/vn-en/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___vn-en"
  }, {
    path: "/vn-en/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___vn-en"
  }, {
    path: "/vn-en/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___vn-en"
  }, {
    path: "/vn-en/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___vn-en"
  }, {
    path: "/vn-en/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___vn-en"
  }, {
    path: "/vn-en/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___vn-en"
  }, {
    path: "/vn-en/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___vn-en"
  }, {
    path: "/vn-en/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___vn-en"
  }, {
    path: "/vn-en/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___vn-en"
  }, {
    path: "/vn-en/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___vn-en"
  }, {
    path: "/vn-en/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___vn-en"
  }, {
    path: "/vn-en/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___vn-en"
  }, {
    path: "/vn-en/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___vn-en"
  }, {
    path: "/vn-en/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___vn-en"
  }, {
    path: "/vn/claim/travel/flight",
    component: _a5345456,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight___vn"
  }, {
    path: "/vn/payment/confirmation/directDebit",
    component: _3be86865,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit___vn"
  }, {
    path: "/vn/payment/confirmation/recurring",
    component: _116411ff,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-recurring___vn"
  }, {
    path: "/vn/policy/add/flight",
    component: _c17c3f0a,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-flight___vn"
  }, {
    path: "/vn/policy/add/health",
    component: _573c8867,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-health___vn"
  }, {
    path: "/vn/policy/add/smartphone",
    component: _2e60d550,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-smartphone___vn"
  }, {
    path: "/vn/policy/add/travel",
    component: _49c57625,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-travel___vn"
  }, {
    path: "/vn/purchase/car-my/addons",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___vn"
  }, {
    path: "/vn/purchase/car-my/holder",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___vn"
  }, {
    path: "/vn/purchase/car-my/product",
    component: _0954c216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product___vn"
  }, {
    path: "/vn/purchase/car-my/protections",
    component: _cffc23be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-protections___vn"
  }, {
    path: "/vn/purchase/car-my/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___vn"
  }, {
    path: "/vn/purchase/car/addons",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___vn"
  }, {
    path: "/vn/purchase/car/detail",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___vn"
  }, {
    path: "/vn/purchase/car/holder",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___vn"
  }, {
    path: "/vn/purchase/car/prequote",
    component: _de0b6176,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-prequote___vn"
  }, {
    path: "/vn/purchase/car/product",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___vn"
  }, {
    path: "/vn/purchase/car/quotation",
    component: _0a417216,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation___vn"
  }, {
    path: "/vn/purchase/car/request",
    component: _894611ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request___vn"
  }, {
    path: "/vn/purchase/car/summary",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___vn"
  }, {
    path: "/vn/purchase/hcp/holder",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___vn"
  }, {
    path: "/vn/purchase/hcp/product",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___vn"
  }, {
    path: "/vn/purchase/hcp/summary",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___vn"
  }, {
    path: "/vn/purchase/health-my/holder",
    component: _7553276f,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-holder___vn"
  }, {
    path: "/vn/purchase/health-my/product",
    component: _faf39ebc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product___vn"
  }, {
    path: "/vn/purchase/health-my/summary",
    component: _0ff25299,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-summary___vn"
  }, {
    path: "/vn/purchase/health/declaration",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___vn"
  }, {
    path: "/vn/purchase/health/family",
    component: _018b63fc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family___vn"
  }, {
    path: "/vn/purchase/health/holder",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___vn"
  }, {
    path: "/vn/purchase/health/insured",
    component: _d06636b0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-insured___vn"
  }, {
    path: "/vn/purchase/health/product",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___vn"
  }, {
    path: "/vn/purchase/health/summary",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___vn"
  }, {
    path: "/vn/purchase/life/declaration",
    component: _066df450,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-declaration___vn"
  }, {
    path: "/vn/purchase/life/holder",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___vn"
  }, {
    path: "/vn/purchase/life/newPrequote",
    component: _17dcf5d2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-newPrequote___vn"
  }, {
    path: "/vn/purchase/life/product",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___vn"
  }, {
    path: "/vn/purchase/life/summary",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/addons",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/holder",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/product",
    component: _d410a0e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/protections",
    component: _15f34818,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-protections___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___vn"
  }, {
    path: "/vn/purchase/motorcycle/detail",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___vn"
  }, {
    path: "/vn/purchase/motorcycle/holder",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___vn"
  }, {
    path: "/vn/purchase/motorcycle/product",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___vn"
  }, {
    path: "/vn/purchase/motorcycle/summary",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___vn"
  }, {
    path: "/vn/purchase/personal-accident/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___vn"
  }, {
    path: "/vn/purchase/personal-accident/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___vn"
  }, {
    path: "/vn/purchase/personal-accident/product",
    component: _729835be,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product___vn"
  }, {
    path: "/vn/purchase/personal-accident/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___vn"
  }, {
    path: "/vn/purchase/property/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___vn"
  }, {
    path: "/vn/purchase/property/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___vn"
  }, {
    path: "/vn/purchase/property/detail",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___vn"
  }, {
    path: "/vn/purchase/property/holder",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___vn"
  }, {
    path: "/vn/purchase/property/product",
    component: _04447826,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product___vn"
  }, {
    path: "/vn/purchase/property/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___vn"
  }, {
    path: "/vn/purchase/smartphone/holder",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___vn"
  }, {
    path: "/vn/purchase/smartphone/product",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___vn"
  }, {
    path: "/vn/purchase/smartphone/request",
    component: _32444bb6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request___vn"
  }, {
    path: "/vn/purchase/smartphone/summary",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___vn"
  }, {
    path: "/vn/purchase/tropical-disease/holder",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___vn"
  }, {
    path: "/vn/purchase/tropical-disease/product",
    component: _4476b9a4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product___vn"
  }, {
    path: "/vn/purchase/tropical-disease/summary",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___vn"
  }, {
    path: "/id-en/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___id-en"
  }, {
    path: "/id-en/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___id-en"
  }, {
    path: "/id-en/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___id-en"
  }, {
    path: "/id-en/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___id-en"
  }, {
    path: "/id-en/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___id-en"
  }, {
    path: "/id-en/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___id-en"
  }, {
    path: "/id-en/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___id-en"
  }, {
    path: "/id-en/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___id-en"
  }, {
    path: "/id-en/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___id-en"
  }, {
    path: "/id-en/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___id-en"
  }, {
    path: "/id-en/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___id-en"
  }, {
    path: "/id-en/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___id-en"
  }, {
    path: "/id-en/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___id-en"
  }, {
    path: "/id-en/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___id-en"
  }, {
    path: "/id-en/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___id-en"
  }, {
    path: "/id-en/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___id-en"
  }, {
    path: "/id-en/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___id-en"
  }, {
    path: "/id-en/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___id-en"
  }, {
    path: "/id-en/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___id-en"
  }, {
    path: "/id/asuransi/jiwa/info/produk",
    component: _3b15a88d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product___id"
  }, {
    path: "/id/asuransi/kesehatan/anggota/produk",
    component: _13fb5326,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk",
    component: _48165979,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk",
    component: _2197913c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product___id"
  }, {
    path: "/id/asuransi/penyakit-tropis/produk/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___id"
  }, {
    path: "/id/asuransi/santunan-tunai/info/produk",
    component: _89fbe38c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product___id"
  }, {
    path: "/id/asuransi/smartphone/info/produk",
    component: _8204a3d4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product___id"
  }, {
    path: "/id/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___id"
  }, {
    path: "/id/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___id"
  }, {
    path: "/id/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___id"
  }, {
    path: "/id/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___id"
  }, {
    path: "/id/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___id"
  }, {
    path: "/id/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___id"
  }, {
    path: "/id/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___id"
  }, {
    path: "/id/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___id"
  }, {
    path: "/id/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___id"
  }, {
    path: "/id/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___id"
  }, {
    path: "/id/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___id"
  }, {
    path: "/id/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___id"
  }, {
    path: "/id/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___id"
  }, {
    path: "/id/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___id"
  }, {
    path: "/id/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___id"
  }, {
    path: "/id/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___id"
  }, {
    path: "/id/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___id"
  }, {
    path: "/my-en/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___my-en"
  }, {
    path: "/my-en/insurance/car/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___my-en"
  }, {
    path: "/my-en/insurance/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___my-en"
  }, {
    path: "/my-en/insurance/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___my-en"
  }, {
    path: "/my-en/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___my-en"
  }, {
    path: "/my-en/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___my-en"
  }, {
    path: "/my-en/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___my-en"
  }, {
    path: "/my-en/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___my-en"
  }, {
    path: "/my-en/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___my-en"
  }, {
    path: "/my-en/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___my-en"
  }, {
    path: "/my-en/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___my-en"
  }, {
    path: "/my-en/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___my-en"
  }, {
    path: "/my-en/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___my-en"
  }, {
    path: "/my-en/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___my-en"
  }, {
    path: "/my-en/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___my-en"
  }, {
    path: "/my-en/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___my-en"
  }, {
    path: "/my-en/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___my-en"
  }, {
    path: "/my-en/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___my-en"
  }, {
    path: "/my-en/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___my-en"
  }, {
    path: "/my-en/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___my-en"
  }, {
    path: "/my/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___my"
  }, {
    path: "/my/insurans/kemalangan-diri/produk/perincian",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___my"
  }, {
    path: "/my/insurans/kereta/produk/perincian",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___my"
  }, {
    path: "/my/insurans/rumah/produk/bandingkan",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___my"
  }, {
    path: "/my/insurans/rumah/produk/perincian",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___my"
  }, {
    path: "/my/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___my"
  }, {
    path: "/my/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___my"
  }, {
    path: "/my/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___my"
  }, {
    path: "/my/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___my"
  }, {
    path: "/my/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___my"
  }, {
    path: "/my/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___my"
  }, {
    path: "/my/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___my"
  }, {
    path: "/my/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___my"
  }, {
    path: "/my/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___my"
  }, {
    path: "/my/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___my"
  }, {
    path: "/my/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___my"
  }, {
    path: "/my/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___my"
  }, {
    path: "/my/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___my"
  }, {
    path: "/my/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___my"
  }, {
    path: "/my/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___my"
  }, {
    path: "/my/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___my"
  }, {
    path: "/my/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___my"
  }, {
    path: "/my/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___my"
  }, {
    path: "/ph-en/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___ph-en"
  }, {
    path: "/ph-en/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___ph-en"
  }, {
    path: "/ph-en/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___ph-en"
  }, {
    path: "/ph-en/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___ph-en"
  }, {
    path: "/ph/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___ph"
  }, {
    path: "/ph/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___ph"
  }, {
    path: "/ph/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___ph"
  }, {
    path: "/ph/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___ph"
  }, {
    path: "/ph/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___ph"
  }, {
    path: "/ph/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___ph"
  }, {
    path: "/ph/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___ph"
  }, {
    path: "/ph/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___ph"
  }, {
    path: "/ph/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___ph"
  }, {
    path: "/ph/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___ph"
  }, {
    path: "/ph/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___ph"
  }, {
    path: "/ph/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___ph"
  }, {
    path: "/ph/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___ph"
  }, {
    path: "/ph/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___ph"
  }, {
    path: "/ph/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___ph"
  }, {
    path: "/ph/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___ph"
  }, {
    path: "/ph/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___ph"
  }, {
    path: "/ph/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___ph"
  }, {
    path: "/ph/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___ph"
  }, {
    path: "/ph/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___ph"
  }, {
    path: "/ph/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___ph"
  }, {
    path: "/ph/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___ph"
  }, {
    path: "/th-en/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___th-en"
  }, {
    path: "/th-en/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___th-en"
  }, {
    path: "/th-en/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___th-en"
  }, {
    path: "/th-en/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___th-en"
  }, {
    path: "/th-en/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___th-en"
  }, {
    path: "/th-en/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___th-en"
  }, {
    path: "/th-en/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___th-en"
  }, {
    path: "/th-en/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___th-en"
  }, {
    path: "/th-en/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___th-en"
  }, {
    path: "/th-en/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___th-en"
  }, {
    path: "/th-en/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___th-en"
  }, {
    path: "/th-en/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___th-en"
  }, {
    path: "/th-en/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___th-en"
  }, {
    path: "/th-en/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___th-en"
  }, {
    path: "/th-en/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___th-en"
  }, {
    path: "/th-en/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___th-en"
  }, {
    path: "/th-en/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___th-en"
  }, {
    path: "/th-en/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___th-en"
  }, {
    path: "/th-en/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___th-en"
  }, {
    path: "/th/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___th"
  }, {
    path: "/th/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___th"
  }, {
    path: "/th/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___th"
  }, {
    path: "/th/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___th"
  }, {
    path: "/th/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___th"
  }, {
    path: "/th/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___th"
  }, {
    path: "/th/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___th"
  }, {
    path: "/th/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___th"
  }, {
    path: "/th/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___th"
  }, {
    path: "/th/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___th"
  }, {
    path: "/th/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___th"
  }, {
    path: "/th/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___th"
  }, {
    path: "/th/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___th"
  }, {
    path: "/th/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___th"
  }, {
    path: "/th/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___th"
  }, {
    path: "/th/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___th"
  }, {
    path: "/th/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___th"
  }, {
    path: "/th/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___th"
  }, {
    path: "/th/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___th"
  }, {
    path: "/th/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___th"
  }, {
    path: "/th/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___th"
  }, {
    path: "/th/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___th"
  }, {
    path: "/th/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___th"
  }, {
    path: "/th/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___th"
  }, {
    path: "/vn-en/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___vn-en"
  }, {
    path: "/vn-en/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___vn-en"
  }, {
    path: "/vn-en/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___vn-en"
  }, {
    path: "/vn-en/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___vn-en"
  }, {
    path: "/vn/claim/travel/flight/success",
    component: _e2e08474,
    pathToRegexpOptions: {"strict":true},
    name: "claim-travel-flight-success___vn"
  }, {
    path: "/vn/payment/confirmation/directDebit/linked",
    component: _0e9cc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "payment-confirmation-directDebit-linked___vn"
  }, {
    path: "/vn/purchase/car-my/product/compare",
    component: _291c3d6c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-compare___vn"
  }, {
    path: "/vn/purchase/car-my/product/detail",
    component: _3dd99600,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail___vn"
  }, {
    path: "/vn/purchase/car/product/compare",
    component: _6a647c62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-compare___vn"
  }, {
    path: "/vn/purchase/car/product/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___vn"
  }, {
    path: "/vn/purchase/car/quotation/success",
    component: _6c67b9ec,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-quotation-success___vn"
  }, {
    path: "/vn/purchase/car/request/success",
    component: _7a5ff50d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-request-success___vn"
  }, {
    path: "/vn/purchase/hcp/product/compare",
    component: _085d2590,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-compare___vn"
  }, {
    path: "/vn/purchase/hcp/product/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___vn"
  }, {
    path: "/vn/purchase/health-my/product/detail",
    component: _6f2e90f4,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-my-product-detail___vn"
  }, {
    path: "/vn/purchase/health/product/compare",
    component: _0c378ac3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-compare___vn"
  }, {
    path: "/vn/purchase/health/product/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___vn"
  }, {
    path: "/vn/purchase/life/product/compare",
    component: _0266bce3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-compare___vn"
  }, {
    path: "/vn/purchase/life/product/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/product/compare",
    component: _3a51c3e3,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-compare___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/product/detail",
    component: _0b30f8ae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail___vn"
  }, {
    path: "/vn/purchase/motorcycle/product/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___vn"
  }, {
    path: "/vn/purchase/personal-accident/product/detail",
    component: _6aa39f95,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-product-detail___vn"
  }, {
    path: "/vn/purchase/property/product/compare",
    component: _9f863908,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-compare___vn"
  }, {
    path: "/vn/purchase/property/product/detail",
    component: _1f3d8cf0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-product-detail___vn"
  }, {
    path: "/vn/purchase/smartphone/product/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___vn"
  }, {
    path: "/vn/purchase/smartphone/request/success",
    component: _7cab5caa,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-request-success___vn"
  }, {
    path: "/vn/purchase/tropical-disease/product/detail",
    component: _1ff77c9c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail___vn"
  }, {
    path: "/id/asuransi/jiwa/info/produk/detail",
    component: _61612aae,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail___id"
  }, {
    path: "/id/asuransi/kesehatan/anggota/produk/detail",
    component: _433d60c9,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail",
    component: _9683cf86,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail",
    component: _32af7234,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail___id"
  }, {
    path: "/id/asuransi/penyakit-tropis/produk/detail/data-diri",
    component: _5a8c762d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-holder___id"
  }, {
    path: "/id/asuransi/santunan-tunai/info/produk/detail",
    component: _507a8f48,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail___id"
  }, {
    path: "/id/asuransi/smartphone/info/produk/detail",
    component: _d86e6e00,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail___id"
  }, {
    path: "/my-en/insurance/car/product/detail/ciri",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail/ciri",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product/detail/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___my-en"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___my-en"
  }, {
    path: "/my/insurans/kemalangan-diri/produk/perincian/holder",
    component: _146d6710,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-holder___my"
  }, {
    path: "/my/insurans/kereta/produk/perincian/ciri",
    component: _d443197c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-addons___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian/ciri",
    component: _26070a2a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-addons___my"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi",
    component: _27373e1e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-declaration___my"
  }, {
    path: "/id/asuransi/jiwa/info/produk/detail/data-diri",
    component: _c760f3b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-holder___id"
  }, {
    path: "/id/asuransi/kesehatan/anggota/produk/detail/data-diri",
    component: _47b4c778,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/fitur",
    component: _9eaa3902,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-addons___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri",
    component: _692d6ea2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder___id"
  }, {
    path: "/id/asuransi/penyakit-tropis/produk/detail/data-diri/ringkasan",
    component: _5c3a48ca,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-summary___id"
  }, {
    path: "/id/asuransi/santunan-tunai/info/produk/detail/data-diri",
    component: _459936d7,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-holder___id"
  }, {
    path: "/id/asuransi/smartphone/info/produk/detail/data-diri",
    component: _4dfc2b7b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-holder___id"
  }, {
    path: "/my-en/insurance/car/product/detail/ciri/personal-data",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail/ciri/personal-data",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product/detail/holder/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___my-en"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___my-en"
  }, {
    path: "/my/insurans/kemalangan-diri/produk/perincian/holder/beneficiary",
    component: _2d3bdfd2,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-beneficiary___my"
  }, {
    path: "/my/insurans/kereta/produk/perincian/ciri/maklumat-peribadi",
    component: _db1ad10a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-holder___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian/ciri/maklumat-peribadi",
    component: _2cdec1b8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-holder___my"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi/addons",
    component: _2217bb32,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-addons___my"
  }, {
    path: "/id/asuransi/jiwa/info/produk/detail/data-diri/ringkasan",
    component: _6efc6af8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary___id"
  }, {
    path: "/id/asuransi/kesehatan/anggota/produk/detail/data-diri/deklarasi",
    component: _18287890,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-declaration___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/fitur/data-diri",
    component: _a581f090,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri/data-motor",
    component: _b1416dd8,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail___id"
  }, {
    path: "/id/asuransi/santunan-tunai/info/produk/detail/data-diri/ringkasan",
    component: _486e3031,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-summary___id"
  }, {
    path: "/id/asuransi/smartphone/info/produk/detail/data-diri/ringkasan",
    component: _4c69d00d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-summary___id"
  }, {
    path: "/my-en/insurance/car/product/detail/ciri/personal-data/summary",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail/ciri/personal-data/summary",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product/detail/holder/beneficiary/summary",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___my-en"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration/addons/personal-data",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___my-en"
  }, {
    path: "/my/insurans/kemalangan-diri/produk/perincian/holder/beneficiary/ringkasan",
    component: _54200718,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary___my"
  }, {
    path: "/my/insurans/kereta/produk/perincian/ciri/maklumat-peribadi/ringkasan",
    component: _d27e37e6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian/ciri/maklumat-peribadi/ringkasan",
    component: _2363d184,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary___my"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi/addons/maklumat-peribadi",
    component: _1eabdf6b,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-holder___my"
  }, {
    path: "/id/asuransi/kesehatan/anggota/produk/detail/data-diri/deklarasi/ringkasan",
    component: _f9230f38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/fitur/data-diri/data-mobil",
    component: _ed95efc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri/data-motor/ringkasan",
    component: _7ca05959,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary___id"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration/addons/personal-data/policy-details",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___my-en"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi/addons/maklumat-peribadi/butiran-polisi",
    component: _0abc4060,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-detail___my"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/fitur/data-diri/data-mobil/ringkasan",
    component: _54fb0920,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary___id"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration/addons/personal-data/policy-details/summary",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___my-en"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi/addons/maklumat-peribadi/butiran-polisi/ringkasan",
    component: _dc9ecbc6,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary___my"
  }, {
    path: "/my-en/insurance/property/product/detail/declaration/addons/personal-data/policy-details/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___my-en"
  }, {
    path: "/my/insurans/rumah/produk/perincian/deklarasi/addons/maklumat-peribadi/butiran-polisi/ringkasan/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___my"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/data-diri/data-mobil/ringkasan/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/fitur/data-diri/data-mobil/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri/data-motor/ringkasan/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri/data-motor/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___id"
  }, {
    path: "/my-en/insurance/car/product/detail/ciri/personal-data/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail/ciri/personal-data/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___my-en"
  }, {
    path: "/my-en/insurance/personal-accident/product/detail/holder/beneficiary/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___my-en"
  }, {
    path: "/my/insurans/kemalangan-diri/produk/perincian/holder/beneficiary/ringkasan/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___my"
  }, {
    path: "/my/insurans/kereta/produk/perincian/ciri/maklumat-peribadi/ringkasan/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian/ciri/maklumat-peribadi/ringkasan/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___my"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/data-diri/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___id"
  }, {
    path: "/id/asuransi/mobil/tipe/produk/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___id"
  }, {
    path: "/id/asuransi/motor/tipe/produk/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___id"
  }, {
    path: "/id-en/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___id-en"
  }, {
    path: "/id-en/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___id-en"
  }, {
    path: "/id/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___id"
  }, {
    path: "/id/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___id"
  }, {
    path: "/my-en/insurance/car/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/insurance/motorcycle/product/detail/:_sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___my-en"
  }, {
    path: "/my-en/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___my-en"
  }, {
    path: "/my/insurans/kereta/produk/perincian/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___my"
  }, {
    path: "/my/insurans/motorsikal/produk/perincian/:_sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___my"
  }, {
    path: "/my/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___my"
  }, {
    path: "/ph-en/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___ph-en"
  }, {
    path: "/ph-en/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___ph-en"
  }, {
    path: "/ph/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___ph"
  }, {
    path: "/ph/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___ph"
  }, {
    path: "/th-en/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___th-en"
  }, {
    path: "/th-en/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___th-en"
  }, {
    path: "/th/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___th"
  }, {
    path: "/th/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___th"
  }, {
    path: "/vn-en/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___vn-en"
  }, {
    path: "/vn-en/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___vn-en"
  }, {
    path: "/vn/purchase/car-my/product/detail/:sharedId",
    component: _310db530,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/car/product/detail/:sharedId",
    component: _3d37702d,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/hcp/product/detail/:sharedId",
    component: _62a5b58c,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-hcp-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/health/product/detail/:sharedId",
    component: _8f630c8e,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/life/product/detail/:sharedId",
    component: _2845ab99,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/product/detail/:sharedId",
    component: _aa5af6ce,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/motorcycle/product/detail/:sharedId",
    component: _2693fd38,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/smartphone/product/detail/:sharedId",
    component: _064b6da0,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-product-detail-sharedId___vn"
  }, {
    path: "/vn/purchase/tropical-disease/product/detail/:sharedId",
    component: _46240b62,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-tropical-disease-product-detail-sharedId___vn"
  }, {
    path: "/id-en/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___id-en"
  }, {
    path: "/id-en/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___id-en"
  }, {
    path: "/id-en/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___id-en"
  }, {
    path: "/id/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___id"
  }, {
    path: "/id/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___id"
  }, {
    path: "/id/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___id"
  }, {
    path: "/id/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___id"
  }, {
    path: "/id/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___id"
  }, {
    path: "/id/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___id"
  }, {
    path: "/id/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___id"
  }, {
    path: "/id/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___id"
  }, {
    path: "/id/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___id"
  }, {
    path: "/my-en/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___my-en"
  }, {
    path: "/my-en/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___my-en"
  }, {
    path: "/my-en/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___my-en"
  }, {
    path: "/my-en/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___my-en"
  }, {
    path: "/my/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___my"
  }, {
    path: "/my/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___my"
  }, {
    path: "/my/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___my"
  }, {
    path: "/my/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___my"
  }, {
    path: "/my/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___my"
  }, {
    path: "/my/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___my"
  }, {
    path: "/my/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___my"
  }, {
    path: "/my/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___my"
  }, {
    path: "/my/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___my"
  }, {
    path: "/my/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___my"
  }, {
    path: "/ph-en/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___ph-en"
  }, {
    path: "/ph-en/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___ph-en"
  }, {
    path: "/ph-en/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___ph-en"
  }, {
    path: "/ph/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___ph"
  }, {
    path: "/ph/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___ph"
  }, {
    path: "/ph/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___ph"
  }, {
    path: "/ph/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___ph"
  }, {
    path: "/ph/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___ph"
  }, {
    path: "/ph/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___ph"
  }, {
    path: "/ph/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___ph"
  }, {
    path: "/ph/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___ph"
  }, {
    path: "/th-en/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___th-en"
  }, {
    path: "/th-en/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___th-en"
  }, {
    path: "/th-en/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___th-en"
  }, {
    path: "/th/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___th"
  }, {
    path: "/th/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___th"
  }, {
    path: "/th/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___th"
  }, {
    path: "/th/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___th"
  }, {
    path: "/th/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___th"
  }, {
    path: "/th/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___th"
  }, {
    path: "/th/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___th"
  }, {
    path: "/th/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___th"
  }, {
    path: "/th/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___th"
  }, {
    path: "/th/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___th"
  }, {
    path: "/th/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___th"
  }, {
    path: "/th/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___th"
  }, {
    path: "/th/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___th"
  }, {
    path: "/th/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___th"
  }, {
    path: "/vn-en/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___vn-en"
  }, {
    path: "/vn-en/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___vn-en"
  }, {
    path: "/vn-en/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___vn-en"
  }, {
    path: "/vn/purchase/car-my/summary/:quotationCode",
    component: _48b0f632,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-my-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/car/detail/:transactionNumber",
    component: _5f6d9c60,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-detail-transactionNumber___vn"
  }, {
    path: "/vn/purchase/car/holder/:transactionNumber",
    component: _29259e55,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-holder-transactionNumber___vn"
  }, {
    path: "/vn/purchase/car/summary/:quotationCode",
    component: _55d16722,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-car-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/health/family/:transactionNumber",
    component: _ad3e98de,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-family-transactionNumber___vn"
  }, {
    path: "/vn/purchase/health/holder/:transactionNumber",
    component: _10efc649,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-holder-transactionNumber___vn"
  }, {
    path: "/vn/purchase/health/summary/:quotationCode",
    component: _6d896e0a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-health-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/life/summary/:quotationCode",
    component: _471572db,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/motorcycle-my/summary/:quotationCode",
    component: _edf6e84a,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-my-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/motorcycle/detail/:transactionNumber",
    component: _109cc779,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-detail-transactionNumber___vn"
  }, {
    path: "/vn/purchase/motorcycle/holder/:transactionNumber",
    component: _697933fe,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-holder-transactionNumber___vn"
  }, {
    path: "/vn/purchase/motorcycle/summary/:quotationCode",
    component: _aab28134,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-motorcycle-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/personal-accident/summary/:quotationCode",
    component: _37e80872,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-personal-accident-summary-quotationCode___vn"
  }, {
    path: "/vn/purchase/property/summary/:quotationCode",
    component: _028743bc,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-property-summary-quotationCode___vn"
  }, {
    path: "/id-en/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___id-en"
  }, {
    path: "/id/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___id"
  }, {
    path: "/my-en/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___my-en"
  }, {
    path: "/my/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___my"
  }, {
    path: "/ph-en/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___ph-en"
  }, {
    path: "/ph/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___ph"
  }, {
    path: "/th-en/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___th-en"
  }, {
    path: "/th/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___th"
  }, {
    path: "/vn-en/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___vn-en"
  }, {
    path: "/vn/purchase/life/summary/:quotationCode/product-information-summary",
    component: _74699173,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-life-summary-quotationCode-product-information-summary___vn"
  }, {
    path: "/id-en/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___id-en"
  }, {
    path: "/id-en/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___id-en"
  }, {
    path: "/id-en/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___id-en"
  }, {
    path: "/id-en/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___id-en"
  }, {
    path: "/id-en/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___id-en"
  }, {
    path: "/id-en/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___id-en"
  }, {
    path: "/id-en/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___id-en"
  }, {
    path: "/id-en/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___id-en"
  }, {
    path: "/id/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___id"
  }, {
    path: "/id/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___id"
  }, {
    path: "/id/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___id"
  }, {
    path: "/id/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___id"
  }, {
    path: "/id/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___id"
  }, {
    path: "/id/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___id"
  }, {
    path: "/id/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___id"
  }, {
    path: "/id/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___id"
  }, {
    path: "/my-en/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___my-en"
  }, {
    path: "/my-en/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___my-en"
  }, {
    path: "/my-en/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___my-en"
  }, {
    path: "/my-en/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___my-en"
  }, {
    path: "/my-en/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___my-en"
  }, {
    path: "/my-en/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___my-en"
  }, {
    path: "/my-en/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___my-en"
  }, {
    path: "/my-en/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___my-en"
  }, {
    path: "/my/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___my"
  }, {
    path: "/my/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___my"
  }, {
    path: "/my/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___my"
  }, {
    path: "/my/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___my"
  }, {
    path: "/my/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___my"
  }, {
    path: "/my/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___my"
  }, {
    path: "/my/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___my"
  }, {
    path: "/my/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___my"
  }, {
    path: "/ph-en/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___ph-en"
  }, {
    path: "/ph-en/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___ph-en"
  }, {
    path: "/ph-en/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___ph-en"
  }, {
    path: "/ph-en/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___ph-en"
  }, {
    path: "/ph-en/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___ph-en"
  }, {
    path: "/ph-en/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___ph-en"
  }, {
    path: "/ph-en/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___ph-en"
  }, {
    path: "/ph-en/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___ph-en"
  }, {
    path: "/ph/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___ph"
  }, {
    path: "/ph/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___ph"
  }, {
    path: "/ph/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___ph"
  }, {
    path: "/ph/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___ph"
  }, {
    path: "/ph/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___ph"
  }, {
    path: "/ph/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___ph"
  }, {
    path: "/ph/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___ph"
  }, {
    path: "/ph/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___ph"
  }, {
    path: "/th-en/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___th-en"
  }, {
    path: "/th-en/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___th-en"
  }, {
    path: "/th-en/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___th-en"
  }, {
    path: "/th-en/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___th-en"
  }, {
    path: "/th-en/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___th-en"
  }, {
    path: "/th-en/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___th-en"
  }, {
    path: "/th-en/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___th-en"
  }, {
    path: "/th-en/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___th-en"
  }, {
    path: "/th/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___th"
  }, {
    path: "/th/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___th"
  }, {
    path: "/th/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___th"
  }, {
    path: "/th/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___th"
  }, {
    path: "/th/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___th"
  }, {
    path: "/th/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___th"
  }, {
    path: "/th/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___th"
  }, {
    path: "/th/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___th"
  }, {
    path: "/vn-en/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___vn-en"
  }, {
    path: "/vn-en/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___vn-en"
  }, {
    path: "/vn-en/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___vn-en"
  }, {
    path: "/vn-en/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___vn-en"
  }, {
    path: "/vn-en/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___vn-en"
  }, {
    path: "/vn-en/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___vn-en"
  }, {
    path: "/vn-en/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___vn-en"
  }, {
    path: "/vn-en/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___vn-en"
  }, {
    path: "/vn/claim/add/:token",
    component: _b240f924,
    pathToRegexpOptions: {"strict":true},
    name: "claim-add-token___vn"
  }, {
    path: "/vn/claim/detail/:number",
    component: _ed296c20,
    pathToRegexpOptions: {"strict":true},
    name: "claim-detail-number___vn"
  }, {
    path: "/vn/payment/success/:policyId",
    component: _918f24cc,
    pathToRegexpOptions: {"strict":true},
    name: "payment-success-policyId___vn"
  }, {
    path: "/vn/policy/add/:partner",
    component: _766f91d7,
    pathToRegexpOptions: {"strict":true},
    name: "policy-add-partner___vn"
  }, {
    path: "/vn/promo/detail/:promoCode",
    component: _4f7803be,
    pathToRegexpOptions: {"strict":true},
    name: "promo-detail-promoCode___vn"
  }, {
    path: "/vn/purchase/smartphone/:partnerCode",
    component: _3a997936,
    pathToRegexpOptions: {"strict":true},
    name: "purchase-smartphone-partnerCode___vn"
  }, {
    path: "/vn/quotation/comparison/:quoteId",
    component: _7a16edde,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-comparison-quoteId___vn"
  }, {
    path: "/vn/quotation/pdf/:id",
    component: _5ead2afd,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-pdf-id___vn"
  }, {
    path: "/id-en/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___id-en"
  }, {
    path: "/id-en/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___id-en"
  }, {
    path: "/id-en/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___id-en"
  }, {
    path: "/id-en/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___id-en"
  }, {
    path: "/id-en/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___id-en"
  }, {
    path: "/id/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___id"
  }, {
    path: "/id/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___id"
  }, {
    path: "/id/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___id"
  }, {
    path: "/id/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___id"
  }, {
    path: "/id/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___id"
  }, {
    path: "/my-en/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___my-en"
  }, {
    path: "/my-en/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___my-en"
  }, {
    path: "/my-en/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___my-en"
  }, {
    path: "/my-en/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___my-en"
  }, {
    path: "/my-en/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___my-en"
  }, {
    path: "/my/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___my"
  }, {
    path: "/my/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___my"
  }, {
    path: "/my/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___my"
  }, {
    path: "/my/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___my"
  }, {
    path: "/my/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___my"
  }, {
    path: "/ph-en/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___ph-en"
  }, {
    path: "/ph-en/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___ph-en"
  }, {
    path: "/ph-en/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___ph-en"
  }, {
    path: "/ph-en/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___ph-en"
  }, {
    path: "/ph-en/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___ph-en"
  }, {
    path: "/ph/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___ph"
  }, {
    path: "/ph/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___ph"
  }, {
    path: "/ph/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___ph"
  }, {
    path: "/ph/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___ph"
  }, {
    path: "/ph/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___ph"
  }, {
    path: "/th-en/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___th-en"
  }, {
    path: "/th-en/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___th-en"
  }, {
    path: "/th-en/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___th-en"
  }, {
    path: "/th-en/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___th-en"
  }, {
    path: "/th-en/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___th-en"
  }, {
    path: "/th/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___th"
  }, {
    path: "/th/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___th"
  }, {
    path: "/th/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___th"
  }, {
    path: "/th/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___th"
  }, {
    path: "/th/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___th"
  }, {
    path: "/vn-en/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___vn-en"
  }, {
    path: "/vn-en/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___vn-en"
  }, {
    path: "/vn-en/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___vn-en"
  }, {
    path: "/vn-en/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___vn-en"
  }, {
    path: "/vn-en/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___vn-en"
  }, {
    path: "/vn/claim/:product",
    component: _7f44fdb6,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product___vn"
  }, {
    path: "/vn/downloadCertificate/:number",
    component: _01aa442b,
    pathToRegexpOptions: {"strict":true},
    name: "downloadCertificate-number___vn"
  }, {
    path: "/vn/payment/:result",
    component: _536fa256,
    pathToRegexpOptions: {"strict":true},
    name: "payment-result___vn"
  }, {
    path: "/vn/quotation/:id",
    component: _7b23604c,
    pathToRegexpOptions: {"strict":true},
    name: "quotation-id___vn"
  }, {
    path: "/vn/request/:product",
    component: _677172fa,
    pathToRegexpOptions: {"strict":true},
    name: "request-product___vn"
  }, {
    path: "/id-en/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___id-en"
  }, {
    path: "/id-en/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___id-en"
  }, {
    path: "/id/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___id"
  }, {
    path: "/id/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___id"
  }, {
    path: "/my-en/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___my-en"
  }, {
    path: "/my-en/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___my-en"
  }, {
    path: "/my/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___my"
  }, {
    path: "/my/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___my"
  }, {
    path: "/ph-en/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___ph-en"
  }, {
    path: "/ph-en/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___ph-en"
  }, {
    path: "/ph/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___ph"
  }, {
    path: "/ph/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___ph"
  }, {
    path: "/th-en/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___th-en"
  }, {
    path: "/th-en/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___th-en"
  }, {
    path: "/th/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___th"
  }, {
    path: "/th/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___th"
  }, {
    path: "/vn-en/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___vn-en"
  }, {
    path: "/vn-en/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___vn-en"
  }, {
    path: "/vn/claim/:product?/:token",
    component: _7e04aac9,
    pathToRegexpOptions: {"strict":true},
    name: "claim-product-token___vn"
  }, {
    path: "/vn/products-whitelabel/:partner/:product?",
    component: _0af2b07b,
    pathToRegexpOptions: {"strict":true},
    name: "products-whitelabel-partner-product___vn"
  }, {
    path: "/ph-en/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___ph-en"
  }, {
    path: "/ph/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___ph"
  }, {
    path: "/th-en/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___th-en"
  }, {
    path: "/th/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___th"
  }, {
    path: "/vn-en/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___vn-en"
  }, {
    path: "/vn/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___vn"
  }, {
    path: "/my-en/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___my-en"
  }, {
    path: "/my/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___my"
  }, {
    path: "/id/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___id"
  }, {
    path: "/id-en/benefits/*",
    component: _290aee9c,
    pathToRegexpOptions: {"strict":true},
    name: "benefits-all___id-en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
