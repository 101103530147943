import { Context } from '@nuxt/types';
import { TGender } from '~/utils/common/types';
import {
  TGetMalayHealthProductListReq,
  TGetMalayHealthProductListRes,
  TMalayHealthProductList,
  TMalayHealthPrequoteData,
  TCreateQuotationPayload,
  TCreateQuotationAPIRes,
  TCreatePolicyReqData,
  TCreatePolicyAPIReq,
  TQuotationData,
} from './types';
import axios from 'axios';

export type TMalayHealthFetchProductListPayload = {
  params?: { sort?: string; filter?: string };
  data?: TMalayHealthPrequoteData;
};

// TODO: move Lead's API call and typings somewhere else for a better domain separation
export type TLeadProductCategory = 'HEALTH';
export type TMalayLeadPayload = {
  productCategory: TLeadProductCategory;
  dob: string; // Format: "YYYY-MM-DD"
  gender: TGender;
  phoneNumber: string;
  identityNumber: string;
  utm?: { key: string; value: string }[];
  referer?: string;
};

export type TMalayHealthActions = {
  fetchProductList: (
    context: Context,
    payload: TMalayHealthFetchProductListPayload
  ) => Promise<void>;
  sendLeads: (context: Context, payload: TMalayLeadPayload) => Promise<void>;
  createQuotation: (
    context: Context,
    payload: TCreateQuotationPayload
  ) => Promise<TQuotationData>;
  createPolicy: (
    context: Context,
    payload: TCreatePolicyReqData
  ) => Promise<TCreatePolicyReqData>;
};

const malayHealthActions: TMalayHealthActions = {
  async fetchProductList(context, payload) {
    let purchaseInformation = payload?.data;
    if (!purchaseInformation) {
      purchaseInformation = await (context as any).rootGetters[
        'health-my/getInsuredInformation'
      ];
      if (!purchaseInformation) {
        (this as any).$router.replace(
          (this as any).localePath({
            name: 'landing-health-my',
          })
        );
        return;
      }
    }
    const { dob, gender } = purchaseInformation;
    const reqPayload: TGetMalayHealthProductListReq = {
      category: 'INDIVIDUAL',
      covereds: [
        {
          kind: 'SELF',
          datas: [
            {
              gender,
              birthdate: new Date(dob).toISOString().split('T')[0],
            },
          ],
        },
      ],
    };

    let queryParam = '';
    payload.params?.sort && (queryParam = '?sort=' + payload.params.sort);
    payload.params?.filter && (queryParam = '?filter=' + payload.params.filter);

    const {
      data,
    }: {
      data: TGetMalayHealthProductListRes;
    } = await (this as any).$axios.post(
      `/products/my/health` + queryParam,
      reqPayload
    );
    const response: TMalayHealthProductList | undefined = data.data;
    if (!response) return;
    (context as any).commit('setProductList', response);
  },
  async sendLeads(_, leadPayload) {
    // NOTES: This is a generic Leads collection API.
    // First used for Malaysian Health category
    // In the future this could be used for other categories as well
    try {
      const {
        productCategory,
        gender,
        phoneNumber,
        dob,
        identityNumber,
      } = leadPayload;
      const payload = {
        productCategory,
        dob,
        gender,
        phoneNumber,
        identityNumber,
        utm: (this as any).$constructUtmLeadPayload().utmParams,
      };
      await axios.post(`${process.env.API_BASE_URL}/leads/my`, payload);
    } catch (e) {
      // No error handling yet
    }
  },
  async createQuotation(context, payload) {
    const res: { data: TCreateQuotationAPIRes } = await axios.post(
      `${process.env.API_BASE_URL}/quotes`,
      payload
    );
    const response = res.data.data;
    if (!response) {
      throw new Error('Failed to create quotation' + res.data.message);
    }
    return response;
  },
  async createPolicy(_, payload) {
    const res: { data: TCreatePolicyAPIReq } = await axios.post(
      `${process.env.API_BASE_URL}/policies`,
      payload
    );
    const response = res.data.data;
    if (!response) {
      throw new Error('Failed to create policy' + res.data.message);
    }
    return response;
  },
};

export default malayHealthActions;
