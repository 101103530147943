import qoalaStorage from '~/core/Storage';

export default () => ({
  policyList: [],
  policyListMeta: {},
  vehiclePlate: qoalaStorage.getItem('vehiclePlate'),
  vehicleBrand: qoalaStorage.getItem('vehicleBrand'),
  vehicleModel: qoalaStorage.getItem('vehicleModel'),
  vehicleSeries: qoalaStorage.getItem('vehicleSeries'),
  carPurchaseHistory: qoalaStorage.getItem('carPurchaseHistory'),
  motorPurchaseHistory: qoalaStorage.getItem('motorPurchaseHistory'),
  productDetailCar: qoalaStorage.getItem('productDetailCar'),
  productDetailMotor: qoalaStorage.getItem('productDetailMotor'),
  documentsType: qoalaStorage.getItem('documentsType')
});
