<template>
  <q-modal
    id="modalMessage"
    :active="modalShowMessage"
    :show-close-button="false"
    @close="modalAction('CLOSE')"
  >
    <q-column justify="center" center>
      <q-spacer bottom="md">
        <q-column justify="center" center>
          <q-spacer bottom="xs">
            <q-text
              variant="ui-large"
              bold="bold"
              ink="label"
              data-cy="messagemodalTitle"
              center="center"
            >
              {{ title }}
            </q-text>
          </q-spacer>
          <q-text
            variant="ui-baseline"
            tag="p"
            data-cy="messagemodalDescription"
            :center="alignCenterContent"
          >
            {{ msg }}
          </q-text>
        </q-column>
      </q-spacer>
      <q-button
        id="modal-ok"
        variant="primary"
        type="button"
        data-cy="messagemodalOKbtn"
        @click="modalAction('OK')"
      >
        {{ okText }}
      </q-button>
    </q-column>
    <div v-if="cancelText" class="row">
      <div id="modal-cancle" class="col" @click="modalAction('CANCEL')">
        <span
          class="volo-small-label qoala-primary-text"
          data-cy="messagemodalCancelbtn"
          style="display: block; margin: 10px 0 10px; text-align: center"
          @click="modalShowMessage = false"
        >
          {{ cancelText }}
        </span>
      </div>
    </div>
  </q-modal>
</template>

<script>
import {
  Text as QText,
  Button as QButton,
  Column as QColumn,
  Modal as QModal,
  Spacer as QSpacer,
} from '@qoala/ui';

export default {
  components: {
    QButton,
    QText,
    QModal,
    QSpacer,
    QColumn,
  },
  props: {
    alignCenterContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: '',
      msg: '',
      okText: 'OK',
      modalShowMessage: false,
      cancelText: '',
    };
  },
  methods: {
    showModal(titleShow, msgShow, textOk, cancelText) {
      this.title = titleShow;
      this.msg = msgShow;
      if (textOk) this.okText = textOk;
      this.cancelText = cancelText;
      this.modalShowMessage = true;
    },
    modalAction(type) {
      switch (type) {
        case 'OK':
          this.$emit('message-ok');
          break;
        case 'CANCEL':
          this.$emit('message-cancel');
          break;
        case 'CLOSE':
        default:
          this.$emit('on-close');
          break;
      }
      this.modalShowMessage = false;
    },
    getStatusModal() {
      return this.modalShowMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.message__desc {
  white-space: pre-wrap;
}
</style>
