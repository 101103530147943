/**
 * Mock localStorage on server side level
 * TODO: Have server side storage for session management
 */
class QoalaStorage {
  private get isBrowser() {
    return typeof window !== 'undefined';
  }

  setItem(key: string, value: string) {
    if (this.isBrowser) {
      return localStorage.setItem(key, value);
    }
    return null;
  }

  removeItem(key: string) {
    if (this.isBrowser) {
      return localStorage.removeItem(key);
    }
    return null;
  }

  getItem(key: string): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    }
    return null;
  }

  clear(): void {
    if (this.isBrowser) {
      return localStorage.clear();
    }
  }
}

export default new QoalaStorage();
