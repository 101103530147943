function isAvailablePromo(promoCode) {
  const hiddenPromos = [
    'CCINSTALLMENT',
    'REFERRALCAMPAIGN',
    'QOALABOOKING',
    'LOWESTPRICE',
  ];
  const isHiddenPromo = hiddenPromos.some((hiddenPromo) =>
    promoCode.includes(hiddenPromo)
  );
  return !isHiddenPromo;
}
export default isAvailablePromo;
