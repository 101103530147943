import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getPolicy: state => {
    if (!state.policy) {
      return qoalaStorage.getItem('policy') ? parseIfJsonString(qoalaStorage.getItem('policy')) : state.policy;
    }
    return state.policy;
  },
  getBenefits: state => {
    if (!state.benefits) {
      return qoalaStorage.getItem('benefits') ? parseIfJsonString(qoalaStorage.getItem('benefits')) : state.benefits;
    }
    return state.benefits;
  }
};
