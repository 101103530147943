import { Context } from '@nuxt/types';
import { NextFunction } from 'express';

export default async function (ctx: Context): Promise<void> {
  ctx.app.router?.beforeEach(async (_: any, __: any, next: NextFunction) => {
    try {
      const clientVer = ctx.store.getters['general/getAppVersion'];
      await fetch(
        window.location.origin + '/check-app-ver?client_ver=' + clientVer
      );
    } catch {
      // NOTES: No need to do anything.
      // Cache reset is performed through 'Clear-Site-Data: cache' header
    }
    next();
  });
}
