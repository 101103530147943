const privacyNotice = {
  privacyNoticeContentID: {
    headTitle: 'Kebijakan Privasi',
    content: [
      {
        title:
          'Dengan menggunakan Qoala, berarti Anda menerima dan menyetujui ketentuan yang dijelaskan dalam Privacy Policy atau Kebijakan Privasi Kami.',
        list: [
          'Kebijakan privasi ini menginformasikan Anda terkait kebijakan kami mengenai pengumpulan, penggunaan, dan pengungkapan data pribadi saat Anda menggunakan Platform Qoala (sebagaimana didefinisikan di bawah) dan pilihan Anda terkait dengan data tersebut.',
          'Dengan mengunjungi, menggunakan, dan/atau mendaftar pada Platform Qoala, Anda telah membaca, menyetujui, dan menerima Kebijakan Privasi ini beserta praktik yang dijelaskan dalam Kebijakan Privasi ini.',
          'Harap baca dengan cermat untuk memastikan Anda memahami praktik kebijakan privasi kami.',
        ],
      },
      {
        title: 'A. DEFINISI',
        list: [
          '1. “Anda” berarti setiap pengunjung, pengguna, nasabah, pelanggan dan/atau masyarakat yang dari Platform Qoala;',
          '2. “Informasi Pribadi” berarti informasi yang dapat diidentifikasi secara pribadi tentang Anda yang dapat dikumpulkan melalui Platform Qoala, antara lain: nama lengkap, alamat, gender, nomor rekening bank, nomor polis, premi, nomor kartu kredit, nomor telepon/ponsel, alamat email dan informasi lain yang dapat dikaitkan dengan orang yang dapat diidentifikasi (misalnya informasi bisnis, atau informasi tentang aktivitas Anda ketika informasi tersebut ditautkan ke informasi yang dapat diidentifikasi secara pribadi, atau informasi yang mungkin mengaktifkan identifikasi individu tertentu yang menggunakan Platform Qoala);',
          '3. “Kami” dan “Perusahaan” berarti Qoala dan PT Archor Teknologi Digital (termasuk grup perusahaan, perusahaan induk, dan anak perusahaannya (jika ada));',
          '4. “Layanan” berarti setiap transaksi atau layanan yang dilakukan pada atau oleh Platform Qoala, sebagaimana diatur di dalam Syarat dan Ketentuan, termasuk namun tidak terbatas pada layanan daring untuk melihat, membandingkan dan membeli produk asuransi dari Mitra;',
          '5. “Mitra” berarti setiap vendor, konsultan, penyedia layanan atau pihak ketiga lainnya (entitas dan/atau individu), termasuk namun tidak terbatas pada perusahaan asuransi, perusahaan broker asuransi (mencakup PT Mitra Jasa Pratama), perusahaan underwriting atau pihak relevan lainnya; dan',
          '6. “Platform Qoala” berarti situs web Qoala (www.Qoala.app), setiap subdomainnya, dan platform Qoala lainnya dimana Qoala menyediakan layanan melaluinya (secara kolektif, “Situs”), aplikasi perangkat seluler, tablet, dan perangkat pintar lainnya, dan program aplikasi antarmuka (secara kolektif, “Aplikasi”) dan semua layanan terkait (secara kolektif, “Layanan Qoala”). Situs, Aplikasi dan Layanan Qoala bersama-sama selanjutnya secara bersama-sama disebut sebagai “Platform Qoala”; dan',
          '7. “Syarat dan Ketentuan” berarti syarat dan ketentuan Layanan (Terms of Service) sebagaimana tersedia di dalam Platform Qoala, sebagaimana dapat diubah dari waktu ke waktu.',
        ],
      },
      {
        title: 'B. PENGUMPULAN INFORMASI PRIBADI',
        list: [
          'Informasi Pribadi dapat dikumpulkan secara otomatis dari Anda dengan berbagai cara saat Anda mengunjungi, mendaftar di Platform Qoala, atau menggunakan Layanan dan aktivitas yang tersedia di Platform Qoala. Informasi Pribadi diperlukan untuk kinerja yang memadai atas kesepakatan antara Anda dengan kami dan untuk memungkinkan kami mematuhi kewajiban hukum kami, seperti memverifikasi informasi akun, profil dan informasi daftar, melawan penipuan dan menciptakan lingkungan daring yang lebih aman. Tanpanya, kami mungkin tidak dapat memberikan Anda semua Layanan yang diminta.',
          'Jika sewaktu-waktu Anda memberikan Informasi Pribadi orang lain ke Platform Qoala, Anda harus memastikan bahwa orang tersebut telah membaca dan memahami Kebijakan Privasi ini dan menyetujui bahwa Informasi Pribadi tersebut digunakan dan diungkapkan oleh kami sesuai dengan Kebijakan Privasi ini.',
          'Pada Platform Qoala, kami dapat mengumpulkan informasi tertentu yang Anda berikan secara sukarela kepada kami yang mungkin berisi Informasi Pribadi (misalnya jika Anda mendaftar di Platform Qoala, mengirimkan kami email, menghubungi atau merekomendasikan seorang profesional, atau mengirimkan peringkat klien, kami dapat mengumpulkan nama, alamat, nomor telepon, alamat email, dan informasi pribadi, profesional, dan bisnis lainnya yang Anda berikan).',
          'Jika Anda adalah pengguna Platform Qoala dan membuat atau memperbarui profil Anda, kami juga dapat mengumpulkan foto Anda, serta informasi profesional dan bisnis Anda tentang Anda. Dari waktu ke waktu kami juga dapat mengumpulkan kontak atau Informasi Pribadi lainnya yang Anda berikan sehubungan dengan partisipasi Anda dalam survei, penawaran promosi, forum, dan area interaktif lainnya dari Platform Qoala.',
          'Saat Anda mengunjungi Platform Qoala, beberapa informasi juga dikumpulkan secara otomatis melalui penggunaan log files, seperti jenis perangkat seluler yang Anda gunakan, nomor identifikasi unik perangkat seluler Anda, alamat Internet Protocol (IP) komputer Anda, sistem operasi komputer Anda, jenis browser, alamat situs web pengarah dan aktivitas Anda di Platform Qoala. Kami menggunakan informasi ini untuk tujuan seperti menganalisis tren, mengelola Platform Qoala, meningkatkan layanan pelanggan, mendiagnosis masalah dengan server kami, dan mengumpulkan informasi demografis yang luas untuk penggunaan agregat.',
          'Kami juga dapat mengumpulkan dan menggunakan log files dan “cookie” untuk membantu Anda mempersonalisasi pengalaman daring Anda (misalnya jika Anda mendaftar di Platform Qoala, cookie membantu kami mengingat informasi spesifik Anda pada kunjungan berikutnya). Ini menyederhanakan proses pencatatan Informasi Pribadi Anda, seperti alamat penagihan, informasi login, dan sebagainya. Saat Anda kembali ke halaman web yang sama, informasi yang Anda berikan sebelumnya dapat diambil kembali, sehingga Anda dapat dengan mudah menggunakan fitur Platform Qoala yang telah Anda sesuaikan. Sebagian besar browser web secara otomatis menerima cookie, tetapi Anda dapat mengubah pengaturan browser Anda untuk menolak cookie jika Anda mau. Jika Anda memilih untuk menolak cookie, Anda mungkin tidak dapat sepenuhnya merasakan fitur interaktif dari Platform Qoala.',
          'Dalam kasus tertentu, browser mungkin meminta lokasi geografis Anda untuk mengizinkan kami memberikan Anda pengalaman yang lebih baik. Dengan data teknis ini kami dapat mengelola Platform Qoala, misalnya dengan menyelesaikan kesulitan teknis atau meningkatkan aksesibilitas bagian tertentu dari Platform Qoala.',
        ],
      },
      {
        title: 'C. PENGGUNAAN INFORMASI PRIBADI',
        paragraph: [
          'Kami mengumpulkan Informasi Pribadi melalui Platform Qoala untuk memfasilitasi penggunaan Anda atas Platform Qoala, untuk memproses permintaan atau transaksi Anda, untuk memberi Anda informasi, produk dan layanan yang Anda minta, untuk mengelola dan membantu kami dengan pengoperasian Platform Qoala dan bisnis kami, dan untuk memenuhi tujuan penyediaan informasi. Kami dapat menggunakan Informasi Pribadi untuk tujuan bisnis kami, termasuk namun tidak terbatas pada:',
        ],
        list: [
          '1. untuk memverifikasi atau mengotentikasi informasi atau identifikasi yang Anda berikan;',
          '2. untuk memberikan analisis atau informasi berharga kepada Anda, terkait dengan Layanan yang digunakan;',
          '3. untuk mengembangkan dan meningkatkan usaha pemasaran kami;',
          '4. untuk menawarkan kepada Anda pesan promosi, pemasaran, iklan, dan informasi lain yang mungkin menarik bagi Anda berdasarkan preferensi dan iklan media sosial Anda melalui platform media sosial;',
          '5. untuk mengirimkan berita, pembaruan umum pada Platform Qoala, penawaran atau promosi khusus kepada Anda;',
          '6. untuk mendeteksi dan mencegah penipuan, spam, penyalahgunaan, insiden keamanan, dan aktivitas berbahaya lainnya; dan',
          '7. untuk meminta umpan balik dan untuk memungkinkan kami menganalisis, mengembangkan, menyesuaikan dan meningkatkan Platform Qoala serta informasi dan Layanan kami.',
          'Kami dapat menggunakan Informasi Pribadi Anda, seperti nama, nomor telepon/ponsel, email dan informasi lainnya untuk memverifikasi kepemilikan Anda atas akun Anda di Platform Qoala. Kami juga dapat menggunakan informasi tersebut untuk mengirimi Anda berita, pembaruan umum pada Platform Qoala, penawaran atau promosi khusus. Kami juga akan mengirimkan email yang meminta Anda untuk berlangganan pada daftar email kami. Anda dapat setiap saat memilih untuk tidak menerima komunikasi pemasaran dari kami dengan mengikuti instruksi berhenti berlangganan yang disertakan dalam email kami kepada Anda atau Platform Qoala.',
          'Kami juga dapat menggunakan Informasi Pribadi Anda dalam bentuk anonim dan agregat untuk memantau dengan cermat fitur Layanan mana yang paling banyak digunakan, untuk menganalisis pola penggunaan dan untuk menentukan di mana kami harus menawarkan atau memfokuskan Layanan kami. Dengan ini Anda setuju bahwa data Anda akan digunakan untuk pemrosesan data internal kami guna memberikan Layanan yang lebih baik kepada Anda.',
          'Kami dapat membagikan Informasi Pribadi Anda dengan Mitra jika Anda telah menunjukkan kepada kami bahwa Anda ingin menerima informasi dari pihak tersebut atau jika Anda memberikan persetujuan Anda kepada kami untuk melakukannya, termasuk dalam hal kami memberi tahu Anda di Platform Qoala bahwa informasi yang Anda berikan akan dibagikan dengan cara tertentu dan Anda memberikan informasi tersebut. Jika kami terlibat atau bermitra dengan Mitra, kami dapat membagikan Informasi Pribadi dengan Mitra yang membutuhkan akses ke informasi tersebut.',
          'Selain itu, kami mungkin menawarkan berbagai fitur melalui Mitra di masa mendatang (misalnya, layanan pemetaan yang disediakan oleh Google Maps). Saat Anda menggunakan layanan ini, Anda memberikan informasi langsung ke Mitra, dan penyediaan informasi tersebut dapat tunduk pada kebijakan privasi pihak tersebut sendiri. Kami tidak bertanggung jawab atas tindakan Mitra atau pihak ketiga lainnya atau atas informasi yang Anda berikan kepada pihak-pihak ini secara langsung, dan kami mendorong Anda untuk memahami praktik mereka sebelum mengungkapkan informasi secara langsung kepada pihak ketiga yang Anda hubungi.',
          'Kami akan mengungkapkan Informasi Pribadi Anda, dengan atau tanpa pemberitahuan, hanya jika diminta untuk dilakukan oleh hukum atau dengan itikad baik bahwa tindakan tersebut diperlukan: (a) untuk mematuhi persyaratan undang-undang, peraturan dan pemerintah dalam keadaan apa pun, termasuk, tetapi tidak terbatas pada, dalam perselisihan atau proses hukum apa pun antara Anda dan kami, atau antara Anda dan pengguna lain sehubungan dengan, atau terkait dengan Platform Qoala atau Layanan, atau dalam keadaan darurat yang berkaitan dengan Anda. kesehatan dan/atau keselamatan; (b) untuk melindungi dan membela hak, kekayaan, atau kekayaan intelektual Perusahaan; dan (c) untuk bertindak dalam keadaan darurat untuk melindungi keamanan pribadi pengguna Perusahaan, atau publik.',
        ],
      },
      {
        title: 'D. TAUTAN KE SITUS LAIN',
        list: [
          'Platform Qoala dapat berisi tautan ke situs lain yang tidak dioperasikan oleh kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau syarat penggunaan dan kebijakan privasi setiap situs yang Anda kunjungi dan memastikan langkah-langkah keamanan dan manajemen yang aman di perangkat Anda sebelum Anda mengaksesnya.',
          'Kami tidak memiliki kendali dan tidak bertanggung jawab atas konten, ketentuan penggunaan, kebijakan privasi, atau praktik apa pun dari situs atau layanan pihak ketiga tersebut.',
        ],
      },
      {
        title: 'E. PERUBAHAN ATAS KEBIJAKAN PRIVASI INI',
        paragraph: [
          'Kami dapat sesekali mengubah atau memperbarui Kebijakan Privasi ini untuk mencerminkan umpan balik pelanggan atau perubahan dalam praktik kami. Kami mendorong Anda untuk secara berkala meninjau halaman ini untuk informasi terbaru tentang praktik privasi kami. Setiap perubahan, amandemen atau modifikasi pada Kebijakan Privasi ini segera berlaku efektif ketika dipasang atau ditampilkan di Platform Qoala.',
        ],
      },
      {
        title: 'F. PERSETUJUAN ANDA',
        list: [
          'Dengan masuk ke dalam atau menggunakan Platform Qoala, Anda setuju, menerima dan mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini, dan memberi kami persetujuan penuh untuk mengumpulkan, menggunakan, memanfaatkan, memelihara, memproses, dan memindahkan Informasi Pribadi apa pun seperti yang dijelaskan dalam Kebijakan Privasi ini.',
          'Anda setuju dan mengakui bahwa kami memiliki hak untuk membagikan setiap atau semua informasi yang telah Anda berikan kepada kami, dan untuk memberi kami hak untuk menggunakan dan membagikan informasi tersebut dengan Mitra. Anda juga setuju dan mengakui bahwa Informasi Pribadi Anda dapat ditransfer ke, disimpan, digunakan dan diproses di yurisdiksi mana pun, termasuk Indonesia dan di luar Indonesia di mana server kami berada.',
          'Anda sepakat dan setuju untuk memberi kami wewenang untuk memindahkan Informasi Pribadi Anda ke Mitra sebagai bagian dari penyediaan Layanan. Meskipun Mitra hanya memiliki akses ke Informasi Pribadi Anda untuk melakukan tugas-tugas ini atas nama kami dan terikat kontrak untuk tidak mengungkapkan atau menggunakannya untuk tujuan lain apa pun, Anda mengakui dan memahami bahwa ada kemungkinan Mitra dapat menyimpan data Anda di perangkat mereka dengan cara apa pun. Kami tidak bertanggung jawab atas penyimpanan data semacam ini dan Anda setuju untuk membela, mengganti kerugian, dan membebaskan kami dan tidak bertanggung jawab atas penyalahgunaan Informasi Pribadi Anda oleh Mitra setelah menyelesaikan Layanan yang disediakan.',
        ],
      },
      {
        title: 'G. HAK ANDA',
        list: [
          'Anda dapat mengakses dan memperbarui beberapa informasi Anda melalui pengaturan akun Anda. Jika Anda telah memilih untuk menghubungkan akun Anda ke aplikasi pihak ketiga, seperti Facebook atau Google, Anda dapat mengubah pengaturan Anda dan menghapus izin untuk aplikasi dengan mengubah pengaturan akun Anda. Anda bertanggung jawab untuk selalu memperbarui informasi pribadi Anda.',
          'Anda berhak meminta kami untuk mengoreksi informasi pribadi yang tidak akurat atau tidak lengkap tentang Anda, dalam hal Anda tidak dapat memperbaruinya sendiri pada Platform Qoala.',
          'Kami pada umumnya menyimpan informasi pribadi Anda selama diperlukan untuk pelaksanaan perjanjian antara Anda dan kami dan untuk mematuhi kewajiban hukum kami. Jika Anda tidak lagi ingin kami menggunakan informasi Anda untuk menyediakan Platform Qoala kepada Anda, Anda dapat meminta kami untuk menghapus informasi pribadi Anda dan menutup akun Anda. Harap diperhatikan bahwa jika Anda meminta penghapusan informasi pribadi Anda:',
          'a. Kami dapat menyimpan beberapa Informasi Pribadi Anda sebagaimana diperlukan untuk kepentingan bisnis kami yang sah, seperti mendeteksi dan pencegahan penipuan serta meningkatkan keamanan. Misalnya, jika kami menangguhkan akun karena penipuan atau alasan keamanan, kami dapat menyimpan informasi tertentu dari akun tersebut untuk mencegah anggota tersebut membuka akun baru di Platform Qoala di masa mendatang.',
          'b. Kami dapat menyimpan dan menggunakan Informasi Pribadi Anda sejauh yang diperlukan untuk mematuhi kewajiban hukum kami. Misalnya, kami mungkin menyimpan beberapa informasi Anda untuk pajak, pelaporan hukum, dan kewajiban audit.',
          'c. Karena kami memelihara Platform Qoala untuk melindungi dari kehilangan dan kerusakan yang tidak disengaja atau berbahaya, sisa salinan Informasi Pribadi Anda tidak dapat dihapus dari sistem cadangan kami untuk jangka waktu terbatas.',
        ],
      },
      {
        title: 'H. PEMBATASAN TANGGUNG JAWAB',
        list: [
          'Anda bertanggung jawab untuk menjaga dan mencegah akses tidak sah ke informasi pengguna dan kata sandi yang Anda gunakan untuk mengakses Platform Qoala. Anda setuju untuk tidak mengungkapkan kata sandi Anda kepada pihak ketiga mana pun dan Anda bertanggung jawab atas aktivitas apa pun yang menggunakan akun Anda, baik Anda mengizinkan aktivitas itu atau tidak. Anda harus segera memberi tahu kami tentang penggunaan akun Anda yang tidak sah. Kami berusaha untuk mengamankan Informasi Pribadi Anda dari akses, penggunaan, atau pengungkapan yang tidak sah dengan menerapkan prosedur fisik, elektronik dan manajerial untuk melindungi informasi yang kami kumpulkan melalui Platform Qoala.',
          'Kami tidak menjamin keamanan database kami dan kami juga tidak dapat menjamin bahwa informasi yang Anda berikan tidak akan disadap saat dikirimkan kepada kami. Setiap transmisi informasi oleh Anda kepada kami adalah risiko Anda sendiri. Seefektif apa pun suatu teknologi, Anda memahami bahwa tidak ada sistem keamanan yang tidak dapat ditembus atau diamankan dari bug, virus, akses ilegal atau tidak sah.',
          'Anda setuju dan mengakui bahwa kami tidak akan bertanggung jawab atas kerusakan langsung, tidak langsung, insidental, khusus, hukuman atau konsekuensial, atau kerusakan karena kehilangan keuntungan, pendapatan, penggunaan data atau data yang timbul dari atau sehubungan dengan kondisi di atas. Anda setuju untuk membebaskan kami dari klaim, permintaan, gugatan, dan kewajiban dan kewajiban lainnya terhadap kondisi tersebut.',
        ],
      },
      {
        title: 'I. HUKUM YANG BERLAKU DAN BAHASA',
        list: [
          'Kebijakan privasi ini diatur oleh dan ditafsirkan berdasarkan hukum Republik Indonesia.',
          'Setiap perselisihan, baik kontraktual atau tidak, yang timbul dari atau sehubungan dengan Kebijakan Privasi ini (termasuk pertanyaan tentang keberadaan, validitas atau penghentian) akan diselesaikan bersama secara damai dalam waktu 30 hari kalender sejak adanya pemberitahuan tentang perselisihan tersebut.',
          'Jika penyelesaian di atas tidak dapat dicapai dalam waktu 30 hari kalender sejak pemberitahuan perselisihan diterima oleh pihak manapun, perselisihan tersebut harus dirujuk dan akhirnya diselesaikan di Badan Arbitrase Nasional Indonesia (“Arbitrase”) di Wahana Graha, Lantai 1 dan 2, Jl. Mampang Prapatan No. 2, Jakarta, Indonesia sesuai dengan Peraturan Arbitrase Badan Arbitrase Nasional Indonesia (“Peraturan BANI”) untuk sementara waktu yang berlaku, dimana peraturan tersebut dianggap tergabung dengan acuan di pasal ini.',
          'Arbitrase akan dilakukan sebagai berikut:',
          '(1) Arbitrase akan dilakukan dengan Peraturan BANI dalam bahasa Inggris;',
          '(2) Majelis arbitrase terdiri dari tiga arbiter, yang mana satu akan dipilih oleh penggugat, satu dipilih oleh tergugat dan seorang ketua arbiter akan dipilih dan diangkat oleh Ketua BANI;',
          '(3) putusan majelis arbitrase bersifat final dan mengikat terhadap mereka;',
          '(4) Konvensi New York 1958 tentang Pengakuan dan Penegakan Putusan Arbitrase Asing berlaku untuk semua putusan arbitrase yang dibuat berdasarkan Kebijakan Privasi ini;',
          '(5) Putusan arbitrase harus menyertakan perintah pembayaran biaya;',
          '(6) Majelis arbitrase akan memutuskan masalah secepat mungkin, namun tidak ada batasan waktu yang akan diberlakukan;',
          '(7) Pasal 56 UU No. 30 tahun 1999 akan berlaku, dan oleh karena itu arbiter hanya dapat mencapai keputusannya dengan menerapkan aturan hukum yang tegas terhadap fakta dan tidak boleh bermaksud untuk menyelesaikan sengketa secara ex aequo et bono; dan',
          '(8) Sembari menunggu pengajuan arbitrase dan sampai majelis arbitrase mengeluarkan keputusannya, masing-masing pihak harus, kecuali jika terjadi kedaluwarsa, penghentian atau kegagalan pihak lain untuk mematuhi atau melaksanakan perintah atau keputusan tertentu dari majelis arbitrase, terus melakukan semua kewajibannya berdasarkan Perjanjian ini tanpa mengurangi penyesuaian akhir sesuai dengan penghargaan tersebut.',
        ],
      },
      {
        title: 'J. BAHASA YANG BERLAKU',
        paragraph: [
          'Kebijakan Privasi ini tersedia untuk Anda dalam bahasa Inggris dan bahasa Indonesia, yang akan mengikat Anda dan kami. Jika terdapat ketidaksesuaian antara versi bahasa Indonesia dan versi bahasa Inggris, yang akan berlaku adalah versi bahasa Inggris dan versi bahasa Indonesia tersebut akan dianggap diubah dan ditafsirkan agar sesuai dengan dan konsisten dengan versi bahasa Inggris yang relevan dari Kebijakan Privasi ini.',
        ],
      },
      {
        title: 'K. BERLANGGANAN EMAIL',
        paragraph: [
          'Untuk langganan email, kami mengikuti kebijakan memilih masuk dan keluar. Jika Anda ingin berhenti menerima email dari kami, silakan klik berhenti berlangganan yang disertakan di setiap email.',
        ],
      },
      {
        title: 'L. HUBUNGI KAMI',
        list: [
          'Qoala tunduk dan patuh pada aturan di bawah Google yang tertuang dalam Peraturan Privasi Google. Untuk membaca aturan Google secara lengkap, silakan kunjungi https://www.google.com/policies/privacy/.',
          'Dengan menggunakan situs ini berarti Anda telah menerima dan menyetujui praktik dan ketentuan serta persyaratan yang dijelaskan dalam privasi dan kebijakan di atas. Jika Anda memerlukan informasi lebih lanjut atau memiliki pertanyaan tentang Privacy Policy kami, jangan ragu untuk menghubungi kami melalui email di cs@qoala.id atau dengan melihat footer pada bagian bawah halaman situs.',
        ],
      },
    ],
  },
};

export default privacyNotice;
