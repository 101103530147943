import qoalaStorage from '~/core/Storage';

export default {
  updateActiveList(state, value) {
    state.activeList = value;
    qoalaStorage.setItem('activeList', state.activeList);
  },
  updatePolicies(state, value) {
    if (value) {
      state.policies = value;
    } else {
      state.policies = {
        data: [],
        meta: { page: 0, pages: 0, limit: 0 },
      };
    }
    qoalaStorage.setItem('policies', JSON.stringify(state.policies));
  },
  updatePolicy(state, value) {
    state.policy = value;
    qoalaStorage.setItem('policy', JSON.stringify(state.policy));
  },
  updatePolicyList(state, value) {
    state.policyList = value;
  },
  updateClaimForm(state, value) {
    state.claimForm = value;
  },
  updateClaims(state, value) {
    state.claims = value;
    qoalaStorage.setItem('claims', JSON.stringify(state.claims));
  },
  updateClaim(state, value) {
    state.claim = value;
    qoalaStorage.setItem('claim', JSON.stringify(state.claim));
  },
  updateHistoriesClaim(state, value) {
    state.historiesClaim = value;
  },
  updateBenefits(state, value) {
    state.benefits = value;
    qoalaStorage.setItem('benefits', JSON.stringify(state.benefits));
  },
  updateUpcoming(state, value) {
    state.upcoming = value;
    qoalaStorage.setItem('upcoming', JSON.stringify(state.benefit));
  },
  updateCancelToken(state, value) {
    state.cancelTokenAxios = value;
  },
  temporaryData(state, data) {
    state.tempData = data;
    qoalaStorage.setItem('tempDat', JSON.stringify(state.tempData));
  },
  temporaryDataBus(state, data) {
    state.tempDataBus = data;
  },
  updateSmartPhoneProduct(state, data) {
    state.smartPhoneProduct = data;
    qoalaStorage.setItem(
      'smartPhoneProduct',
      JSON.stringify(state.smartPhoneProduct)
    );
  },
  updatePurchaseConfirmation(state, data) {
    state.purchaseConfirmation = data;
    qoalaStorage.setItem(
      'smartPhonePurchaseConfirmation',
      JSON.stringify(state.purchaseConfirmation)
    );
  },
  updatePurchasePayment(state, data) {
    state.purchasePayment = data;
    qoalaStorage.setItem(
      'smartPhonePurchasePayment',
      JSON.stringify(state.purchasePayment)
    );
  },
  updateClaimSmartphone(state, data) {
    state.smartphoneClaim = data;
  },
  updateStoreList(state, data) {
    state.storeList = data;
    qoalaStorage.setItem('storeList', JSON.stringify(state.storeList));
  },
  updateDataPolicyCreation(state, data) {
    state.dataPolicyCreation = data;
    qoalaStorage.setItem(
      'dataPolicyCreation',
      JSON.stringify(state.dataPolicyCreation)
    );
  },
  deleteDataPolicyCreation(state) {
    qoalaStorage.removeItem('dataPolicyCreation');
    state.dataPolicyCreation = {};
  },
  updateInsuredListPaPlus(state, data) {
    state.insuredPaPlusList = data;
    qoalaStorage.setItem(
      'insuredPaPlusList',
      JSON.stringify(state.insuredPaPlusList)
    );
  },
  setQoalaInitiatedBankList(state, data) {
    state.qoalaInitiatedBankListData = data;
  },
};
