// eslint-disable-next-line
export default async function({ $axios, app, route, store, redirect }) {
  // const restrictPath = path => {
  //   // const listPath=[]
  //   if (path.indexOf('/') >= 0) return true;
  //   if (path.indexOf('/accounts') >= 0) return true;
  //   if (path.indexOf('/products') >= 0) return true;
  //   if (path.indexOf('/notification') >= 0) return true;
  //   if (path.indexOf('/policy/add') >= 0) return true;
  //   if (path.indexOf('/transactions') >= 0) return true;
  //   return false;
  // };
  const localeLang = app.i18n.locale;
  const localeMoment = app.$moment.locale();

  if (localeLang !== localeMoment) app.$moment.locale(localeLang);
  const token = store.getters['user/getUserToken'];
  // let lang = '/en';
  // if (!token && restrictPath(route.path)) {
  //   if (store.getters['general/getFirstTime']) {
  //     // ????? log language?
  //     // console.log(lang);
  //   }
  // }
  return token ? ($axios.defaults.headers.common.Authorization = `Bearer ${token}`) : null;
}
