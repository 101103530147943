import formatMoney from './formatMoney';
import formatMoneyWithCent from './formatMoneyWithCent';

function getFormatMoneyWithCurrencyCode(
  currencyCode,
  price = '',
  centDecimal = 2
) {
  switch (currencyCode) {
    case 'IDR':
      return `Rp ${formatMoney(price)}`;
    case 'MYR':
    case 'RM':
      return `RM ${formatMoneyWithCent(price, ',', '.', centDecimal)}`;
    case 'VND':
      return `VND ${formatMoney(price)}`;
    default:
      return `Rp ${formatMoney(price)}`;
  }
}

export default getFormatMoneyWithCurrencyCode;
