/*
 * Feel free give feedback and Improvement
 * This func coverage for claim inside
 * accident-partnership
 * bike
 * bus
 * dbd
 * electornic
 * hcp-partnership
 * pa+-partnership
 * smartphone
 */

function isShowDocumentClaim(historyStatus, pointer, history) {
  switch (historyStatus) {
    case 'PRE_APPROVED':
    case 'INSURANCE_APPROVED':
    case 'INSURANCE_REJECTED':
    case 'APPROVED':
    case 'PAID':
    case 'REJECTED':
    case 'SOFT_REJECT':
    case 'ESTIMATE_COST':
    case 'ON_PROGRESS':
    case 'WAITING_PICKUP':
    case 'RECEIVED_SC':
    case 'DONE':
    case 'REPLACED':
    case 'DONE_REPLACED':
    case 'DONE_REJECTED':
    case 'RESUBMIT_DOCUMENT' && pointer !== 0:
    case 'ACKNOWLEDGE_QOALA':
    case 'PAYMENT_NOT_COMPLETE':
    case 'WAITING_COURIER_START':
    case 'WAITING_COURIER_FINISH':
    case 'WAITING_WALKIN_FINISH':
    case 'WAITING_WALKIN_START':
    case 'COURIER_PICKED_UP':
    case 'INITIATED' && !history[pointer].documents:
      return false;
    default:
      return true;
  }
}

export default isShowDocumentClaim;
