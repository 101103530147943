<template>
  <full-page-modal
    id="filter"
    :active="isShow"
    need-header
    background-color="#ffffff"
    close-button-color="#1253a4"
    title-ink="label"
    :title="title"
    :close-icon-right="true"
    class="filter-modal"
    @close="closeModal"
  >
    <q-spacer horizontal="xs" class="filter-modal__premi">
      <q-padder vertical="md" horizontal="xxs">
        <q-spacer bottom="xs">
          <q-text variant="ui-small" ink="label" bold>
            {{ content.QoalaOnlineHealthDetail.premiRangeLabel }}
          </q-text>
        </q-spacer>
        <q-input
          id="input-currency"
          v-model="currencyPremi"
          variant="currency"
          :border="true"
          :addition-field="true"
          :formal-padding="true"
          :addition-field-value="$helper.getFormatMoneyWithCurrencyCode('IDR')"
          font-variant="ui-small"
        />
        <q-spacer top="xs">
          <q-range-slider
            id="filter-range-slider"
            :set-value="premi"
            :max-value="maxPremi"
            @input="getValue"
          />
          <q-spacer top="xs">
            <q-grid :column="2">
              <q-text variant="ui-small" bold>{{
                $helper.getFormatMoneyWithCurrencyCode('IDR', premi)
              }}</q-text>
              <q-text variant="ui-small" bold right>{{
                $helper.getFormatMoneyWithCurrencyCode('IDR', maxPremi)
              }}</q-text>
            </q-grid>
          </q-spacer>
        </q-spacer>
      </q-padder>
    </q-spacer>
    <!-- Dynamic Checkbox Filter -->
    <q-spacer
      v-for="(checkboxFilter, idx) in checkboxFilters"
      :key="idx"
      vertical="md"
      horizontal="sm"
      class="filter-modal__checkboxes top-border"
    >
      <q-padder top="sm" horizontal="xxs">
        <q-row>
          <q-text style="flex-grow: 1" variant="ui-small" ink="label" bold>
            {{ checkboxFilter.title }}
          </q-text>
          <div
            v-if="checkboxFilter.selectAll"
            style="
              align-self: flex-end;
              cursor: pointer;
              color: #ff6400;
              font-weight: 700;
              font-size: 12px;
            "
            @click="handleSelectAll(idx)"
          >
            {{ checkboxFilter.selectAll.label }}
          </div>
        </q-row>
        <q-spacer
          v-for="(opt, index) in checkboxFilter.options"
          :key="index"
          top="xxs"
        >
          <q-checkbox
            :id="`filter-${index}`"
            :value="opt.value"
            @change.native="opt.value = !opt.value"
          >
            {{ opt.label }}
          </q-checkbox>
        </q-spacer>
      </q-padder>
    </q-spacer>
    <!-- Filter by Feature -->
    <q-padder
      v-if="features"
      vertical="md"
      horizontal="sm"
      class="filter-modal__features"
    >
      <q-text variant="ui-small" ink="label" bold>{{
        content.QoalaOnlineHealthDetail.featureLabel
      }}</q-text>
      <q-spacer v-for="(feature, index) in features" :key="index" top="xxs">
        <q-checkbox
          :id="`filter-${index}`"
          :value="feature.value"
          @change.native="feature.value = !feature.value"
        >
          {{ feature.label }}
        </q-checkbox>
      </q-spacer>
    </q-padder>
    <q-button id="btn-filter" class="filter-modal__button" @click="applyFilter">
      {{ content.QoalaOnlineHealthDetail.applyButtonLabel }}
    </q-button>
  </full-page-modal>
</template>

<script>
import {
  Padder as QPadder,
  Spacer as QSpacer,
  Text as QText,
  Grid as QGrid,
  Checkbox as QCheckbox,
  Button as QButton,
  Input as QInput,
  FullPageModal,
  Row as QRow,
} from '@qoala/ui';
import { RangeSlider as QRangeSlider } from '@qoala/ui/v2';

export default {
  components: {
    QPadder,
    QSpacer,
    QText,
    QGrid,
    QCheckbox,
    QButton,
    QInput,
    QRangeSlider,
    FullPageModal,
    QRow,
  },
  props: {
    handleSelectAll: {
      type: Function,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
    },
    features: {
      type: Array,
    },
    maxPremi: {
      type: Number,
      default: 0,
    },
    defaultPremi: {
      type: Number,
      default: 0,
    },
    checkboxFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
      premi: this.defaultPremi,
      currencyPremi: this.defaultPremi.toString(),
    };
  },
  watch: {
    premi(e) {
      this.currencyPremi = this.$helper.formatMoney(e);
    },
    currencyPremi(e) {
      this.premi = Number(this.$helper.currenciesToNumber(e));
    },
    defaultPremi(e) {
      this.premi = e;
      this.currencyPremi = e.toString();
    },
  },
  methods: {
    showModal() {
      this.isShow = true;
    },
    closeModal() {
      this.isShow = false;
      this.$emit('closeModal', '');
    },
    applyFilter() {
      this.$emit('handleFilter', this.premi);
      this.closeModal();
    },
    getValue(premi) {
      this.premi = premi;
    },
  },
};
</script>

<style lang="scss">
.top-border {
  border-top: 1px solid rgba(148, 201, 222, 0.2);
}

.filter-modal {
  &__premi {
    .currency {
      height: 35px;
      .addition-field {
        border-right: 0;
        width: 40px;
        padding-right: 0;
        .row-section {
          font-weight: bold;
          display: inline-block;
        }
      }
      input {
        color: #ff8742;
        font-weight: bold;
        padding-left: 4px;
      }
    }
    .eu-rangeslider-container {
      padding-right: 0;
    }
  }

  &__features,
  &__checkboxes {
    .checkbox-container {
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
      cursor: pointer;
      .checkbox {
        border: 0;
        background: #fffef2;
        border: 1px solid #ffa000;
        &.checked:before {
          background: linear-gradient(to bottom, #ff9738, #ff8743);
        }
      }
    }
  }
  &__button {
    border-radius: 8px !important;
    background-image: linear-gradient(to bottom, #ff9738, #ff8743);
    width: calc(100% - 24px) !important;
    margin-bottom: 20px;
    left: 12px;
    right: 12px;
    @media screen and (max-width: 360px) {
      position: static !important;
      margin: 0 12px 24px 12px;
    }
  }
}
</style>
