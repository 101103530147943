const errorUS = {
  response_userNotFound: ' User Not Found',
  'VB-GEN-001': 'Verification Failed',
  'VB-GEN-002': 'Email and PIN doesn’t match, please try again',
  'VB-REG-001': 'This email has been registered',
  'VB-REG-002': 'This email has been registered but not active',
  'VB-REG-003': 'This phone number has been registered',
  'VB-REG-004': 'Email and PIN doesn’t match, please try again',
  response_userUnregis: 'User Not Registered',
  'VB-REG-005': 'Wrong OTP',
  'VB-REG-006': 'OTP is expired',
  'VB-REG-007': 'ID has been registered',
  'VB-REG-008': 'Email already reach maximum request OTP',
  'VB-REG-009': 'Phone number already reach maximum request OTP',
  'VB-USR-001': ' Not Found',
  'VB-USR-002': 'Failed to Upload ID',
  'VB-USR-003': 'Failed to Upload Photo',
  // 'VB-USR-004': 'Failed to Update the Information',
  'VB-FL-001': 'Flight not found',
  'VB-AUTH-002': 'Inactive User',
  'VB-AUTH-003': 'Email/Phone Number and PIN doesn’t match, please try again',
  'VB-AUTH-009': 'Email is not registered',
  'VB-AUTH-010': 'Phone Number is not registered',
  'VB-AUTH-011': 'Email not verified',
  'VB-AUTH-012': 'Phone Number not verified',
  'VB-VERIF-001': 'Verification failed',
  'VB-VERIF-002': 'Sorry, {0} has been registered to other account.',
  'VB-VERIF-003': 'Wrong OTP',
  response_sessionExpired: 'Session has expired',
  'VB-PLCY-002': 'The policy is already registered',
  'SPS-POLICY-005': 'The policy is already registered',
  'BUS-POLICY-005': 'The policy is already registered',
  'VB-TRVL-CLM-001': 'You are not included in this policy',
  'VB-TRVL-CLM-003': 'This user is not a part of this policy',
  'VB-TRVL-CLM-004': 'Can’t find the benefit',
  'VB-TRVL-CLM-005': 'Claim has been submitted by another user',
  'VB-TRVL-CLM-006': 'Benefit is not the part of policy Benefit bukan bagian dari polis',
  'VB-TRVL-CLM-007': 'User is not covered by the policy',
  'VB-TRVL-CLM-008': 'Claim can be submitted 2 hours before the departure time',
  'VB-TRVL-CLM-009': 'Can’t find the flight',
  'VB-TRVL-CLM-010': 'The policy number doesn’t covered flight delay',
  'VB-TRVL-CLM-011': 'Claim has been automatically submitted',
  'VB-TRVL-CLM-012': 'Claim with this benefit is not available for the flight',
  'VB-TRVL-CLM-013': 'Claim is verified',
  response_userNotLead: 'Please contact your relative who previously submit this claim',
  'VB-TRVL-CLM-015': 'Can’t re-submit the claim because it’s in process',
  'VB-TRVL-CLM-016': 'Claim can be submitted 2 hours before departure time',
  'VB-TRVL-CLM-017': 'Claim can be submitted no later than 30 days after the departure time',
  'VB-TRVL-CLM-018': 'Claim can be submitted no later than 30 days after the departure time',
  'VB-TRVL-CLM-019': 'Claim can be submitted no later than 30 days after the departure time',
  'VB-TRVL-CLM-020': 'Can’t submit the claim before the departure time',
  'VB-TRVL-CLM-021': 'Claim can be submitted at least 15 days before the departure time',
  'VB-TRVL-CLM-022': 'Claim can be submitted no later than 30 days after the departure time',
  'VB-TRVL-CLM-023': 'Claim can only be submitted after the arrival time!',
  'VB-TRVL-CLM-024': 'Claim can only be submitted at least 14 days after the departure time',
  response_wentWrong: 'Ooops! Something went wrong',
  // error code
  'VB-USR-004': 'Please re-upload',
  'VB-USR-005': 'Please re-upload your ID',
  'VB-USR-006': 'Your ID number has been registered',
  'VB-USR-007': 'Your name didn’t matched with the ID',
  'VB-USR-008': 'Please re-upload your ID',
  'VB-OCR-001': 'Please upload your ID correctly',
  'VB-OCR-002': 'Please upload your selfie along with your ID correctly',
  'VB-OCR-003': 'Please re-upload photo with a bigger dimension',
  'VB-TRVL-CLM-025': 'Submitting claim for delayed train can’t be done before the departure time',
  'VB-TRVL-CLM-026': 'Submitting claim for delayed train can’t be done 24 hours after the arrival time',
  'VB-TRVL-CLM-027': 'Submitting claim for personal accident can’t be done before the departure time',
  'VB-TRVL-CLM-028': 'Submitting claim for personal accident can’t be done in 7 days after  the arrival time',
  'VB-TRVL-CLM-029': 'Submitting claim for train cancellation can’t be done in 7 days before the departure time',
  'VB-TRVL-CLM-030': 'Submitting claim for train cancellation can’t be done in 2 days after the arrival time',
  'VB-TRVL-CLM-031': 'Submitting claim for train cancellation can’t be done 24 hours before the departure time',
  'VB-TRVL-CLM-032': 'Submitting claim for train cancellation can’t be done on scheduled departure time.',
  'SPS-CLAIM-003': 'Claim status still active!',
  'SPS-CLAIM-005': 'Claim status still active!',
  'SPS-CLAIM-007': 'Policy exceeded premi is zero!',
  'SPS-POLICY-004': 'The Policy is Already Registered',
  'BUS-CLAIM-002': 'This policy already has active claim!',
  'HEALTH-CLAIM-002': 'This policy already has active claim!',
  'HIS-ADD-POLICY-001': 'This policy already has active claim!',
  'HEALTH-CLAIM-004': 'Please add this policy before claim!',
  413: 'File is too large',
  404: 'Not Found',
  503: 'Service Unavailable',
  response_submitClaim: 'Submit Claim',
  response_havetoagree: 'You have to agree on the terms and condition',
  response_selectvideo: 'Please select a video of your screen cracked!',
  response_havetobevideo: 'Please upload a video format!',
  response_videobig: 'Your video size is too big',
  response_whatproblem: 'Please select what seems to be the problem!',
  sistem_error: 'Sorry, our system is temporarily unavailable. Please try again in a few minutes',
  'USER-USER-005': 'Wrong PIN entered',
  'USER-SESSION-006': 'Wrong OTP entered',
  'Q-GEN-001': 'Ooops! Something went wrong',
  policy_not_found: 'Policy is not found, please search via partner list',
  response_information: 'Information',
  response_benefitfull: 'This benefit is still on claim process for all insured. Please choose another benefit.'
};

export default errorUS;
