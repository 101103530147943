import qoalaStorage from '~/core/Storage';

export default () => ({
  promoData: [],
  detailPromo: JSON.parse(qoalaStorage.getItem('detailPromo')),
  searchCodePromo: [],
  useCodePromo: {},
  promoProduct: JSON.parse(qoalaStorage.getItem('promoProduct')),
  promoCalculation: JSON.parse(qoalaStorage.getItem('promoCalculation')),
  listPaymentMethod: JSON.parse(qoalaStorage.getItem('listPaymentMethod'))
});
