<template lang="html">
  <modal
    id="selectOption"
    :active="selectOption"
    :title="textTitle"
    :hide-header="hideHeader"
    class="text-center option-modal"
    centered="centered"
    no-close-on-esc="no-close-on-esc"
    no-close-on-backdrop="no-close-on-backdrop"
    hide-header-close="hide-header-close"
    body-class="modal-body-custom"
    header-class="modal-header-custom"
    footer-class="modal-footer-custom"
  >
    <div v-for="(item, index) in itemOption" v-if="!multipleType && !countryType" :key="index" class="row mt-2">
      <div class="col-12">
        <div
          :class="{ active: index === selectedOption }"
          :data-cy="dataCY(item)"
          class="text-left volo-button-secondary pl-3"
          @click="
            chooseOption(index);
            submitSelect(item);
          "
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in itemOption"
      v-if="multipleType && !countryType"
      :key="index"
      class="row mt-2"
      @click="chooseMultiple(index)"
    >
      <div class="col-12">
        <div
          :class="{ active: clickedItem(item) }"
          :data-cy="dataCY(item)"
          class="text-left volo-button-secondary pl-3"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in itemOption"
      v-if="countryType && !multipleType"
      :key="index"
      class="row mt-2"
      @click="
        chooseOption(index);
        submitSelect(item);
      "
    >
      <div class="col-12">
        <div
          :class="{ active: index === selectedOption }"
          :data-cy="dataCY(item)"
          class="country-pick volo-button-secondary pl-3"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <br />
    <div slot="modal-footer" class="w-100">
      <div v-if="multipleType" class="row confirm-button">
        <div class="col">
          <div class="btn volo-button" @click="submitSelectMultiple">{{ textSubmit }}</div>
        </div>
      </div>
      <div class="row cancel-button">
        <div class="col text-center">
          <div
            class="btn-cancel volo-small-label qoala-primary-text"
            data-cy="selectoptionCancelDropDown"
            @click="cancelSelect"
          >
            {{ textCancel }}
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { Modal } from '@qoala/ui';
export default {
  components: {
    Modal
  },
  props: {
    textTitle: {
      type: String,
      default: 'c'
    },
    itemOption: {
      type: Array,
      default: null
    },
    textSubmit: {
      type: String,
      default() {
        return this.$t('choose_text');
      }
    },
    textCancel: {
      type: String,
      default() {
        return this.$t('otp_cancel');
      }
    },
    hideHeader: {
      type: Boolean,
      default: true
    },
    multipleType: {
      type: Boolean,
      default: false
    },
    countryType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectOption: false,
      selectedOption: null,
      otherSelect: false,
      valueSelected: '',
      multipleClicked: []
    };
  },
  methods: {
    dataCY(value) {
      if (value === 'Orang Tua' || value === 'Parent') {
        return 'selectoptionRelationParentDropdown';
      }
      if (value === 'Saudara' || value === 'Sibling') {
        return 'selectoptionRelationSiblingDropdown';
      }
      if (value === 'Pasangan' || value === 'Partner') {
        return 'selectoptionRelationPartnerDropdown';
      }
      if (value === 'Kerabat' || value === 'Relative') {
        return 'selectoptionRelationRelativeDropdown';
      }
      if (value === 'Kereta' || value === 'Train') {
        return 'selectoptionTrainDropdown';
      }
      if (value === 'Penerbangan' || value === 'Flight') {
        return 'selectoptionFlightDropdown';
      }
      if (value === 'Lain' || value === 'Etc') {
        return 'selectoptionRelationEtcDropdown';
      }
      if (value === 'KTP') {
        return 'selectoptionKTP';
      }
      if (value === 'PASSPORT (WNA)') {
        return 'selectoptionPassport';
      }
      return 'selectoptionBankList';
    },
    chooseOption(val) {
      const item = this.itemOption[val];
      this.selectedOption = val;
      if (item === 'Lain') {
        this.otherSelect = true;
        this.valueSelected = '';
      } else {
        this.otherSelect = false;
        this.valueSelected = item;
      }
    },
    cancelSelect() {
      this.selectOption = false;
      this.otherSelect = false;
      this.selectedOption = '';
      this.multipleClicked = [];
    },
    chooseMultiple(index) {
      const item = this.itemOption[index];
      const test = this.multipleClicked.find(testing => testing === item);

      if (!test) {
        this.multipleClicked.push(item);
        this.$forceUpdate();
      }
      for (let i = 0, len = this.multipleClicked.length; i < len; i++) {
        if (this.multipleClicked[i] === item) {
          this.multipleClicked.splice(i, 1);
          break;
        }
      }
    },
    submitSelect(item) {
      this.selectOption = false;
      this.$emit('select-option', item);
    },
    submitSelectMultiple() {
      const sendMultiple = this.multipleClicked;
      this.selectOption = false;
      this.$emit('select-option', sendMultiple);
    },
    hideShow() {
      this.selectOption = !this.selectOption;
    },
    clickedItem(item) {
      for (let i = 0, len = this.multipleClicked.length; i < len; i++) {
        if (this.multipleClicked[i] === item) return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~/assets/css/var.scss';
.modal-body {
  padding: 16px 16px 0;
}
.btn-cancel {
  display: inline-block;
}
.volo-button-secondary {
  &.active {
    border: solid 1px #f57e20;
  }
}

.country-pick {
  img {
    width: 25px;
    margin-right: 5px;
  }
}
</style>
