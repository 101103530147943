import qoalaStorage from '~/core/Storage';

export default {
  setTropicalDiseasePurchaseData(state, value) {
    state.tropicalDiseasePurchaseData = value;
    qoalaStorage.setItem('tropicalDiseasePurchaseData', JSON.stringify(value));
  },
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('tropicalDiseaseProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('tropicalDiseaseProductDetail', JSON.stringify(state.productDetail));
  },
  updateClaims(state, value) {
    state.claims = value;
  },
  deleteData(state) {
    state.tropicalDiseasePurchaseData = {};
    state.productDetail = {};
    state.listProduct = [];
    qoalaStorage.removeItem('tropicalDiseasePurchaseData');
    qoalaStorage.removeItem('tropicalDiseaseProduct');
    qoalaStorage.removeItem('tropicalDiseaseProductDetail');
  }
};
