import qoalaStorage from '~/core/Storage';

export default {
  setPropertyData(state, value) {
    state.propertyData = value;
    qoalaStorage.setItem('propertyData', JSON.stringify(value));
  },
  deletePropertyData(state) {
    state.propertyData = {};
    state.listProduct = [];
    qoalaStorage.removeItem('propertyData');
  },
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('propertyProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('productDetailProperty', JSON.stringify(state.productDetail));
  }
};
