function strip(number) {
  return parseFloat(number).toPrecision(12);
}

function formatMoneyWithCent(
  amount = '',
  thousands = '.',
  decimal = '.',
  decimalCount = 0
) {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  const negativeSign = amount < 0 ? '-' : '';

  const amountWithoutCent = parseInt(
    (amount = Math.abs(Number(amount) || 0))
  ).toString();
  const multipleOfThreeDigitAmount =
    amountWithoutCent.length > 3 ? amountWithoutCent.length % 3 : 0;

  return (
    negativeSign +
    (multipleOfThreeDigitAmount
      ? amountWithoutCent.substr(0, multipleOfThreeDigitAmount) + thousands
      : '') +
    amountWithoutCent
      .substr(multipleOfThreeDigitAmount)
      .replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(strip(amount - amountWithoutCent))
          .toFixed(decimalCount)
          .slice(2)
      : '')
  );
}

export default formatMoneyWithCent;
