import axios from 'axios';

export type TUploadTPDFieldsPayload = {
  filename: string;
  type: string;
  key: string;
  content: any;
}[];

type TClaimTPDPayload = {
  benefitID: number;
  policyNumber: string;
  coveredUserID: number;
  supportingDocuments: any[];
  bankAccount: {
    accountHolderName: string;
    accountNumber: string;
    bankName: string;
  };
};

type TCreateClaimReq = {
  benefit_id: number;
  policy_number: string;
  covered_user_id: number;
  supporting_documents: {
    filename: string;
    key: string;
    type: string;
  }[];
  additional_data: {
    bank_account: {
      account_holder_name: string;
      account_number: string;
      bank_name: string;
    };
  };
};

// TODO: Fix `(this as any)`
export default {
  async getProductList(context, { sort, payload }) {
    payload.utm = (this as any).$constructUtmLeadPayload().utmParams;
    payload.referer = (this as any).$constructUtmLeadPayload().referer;
    const uuid = await context.rootGetters['user/getUuid'];
    payload.uuid = uuid;
    try {
      const { data } = await (this as any).$axios.post(
        `/products/health/tpd${sort ? `?sort=${sort}` : ''}`,
        payload
      );
      const response = data;
      context.commit('setListProduct', response.data);
    } catch (error) {
      return error;
    }
  },
  async getListClaim(context) {
    try {
      const query = `orderBy=updatedAt&&limit=100`;
      const { data } = await (this as any).$axios.get(`/health/claim?${query}`);
      context.commit('updateClaims', data.data);
    } catch (error) {
      throw error;
    }
  },
  async uploadTPDFiles(
    context,
    payload: TUploadTPDFieldsPayload
  ): Promise<any[]> {
    try {
      // Step 1: Generate presigned URL (API doesn't support upload)
      const createPresignedURLReq = {
        documents: payload.map((document) => {
          return {
            filename: document.filename,
            type: document.key,
          };
        }),
      };

      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('policy/updateCancelToken', source, { root: true });
      const res = await (this as any).$axios.post(
        '/health/hcp/claims/presigned',
        createPresignedURLReq,
        { cancelToken: source.token }
      );

      if (res instanceof Error) {
        throw res;
      }
      const createPresignedURLRes = res?.data?.data;
      if (!createPresignedURLRes) {
        throw new Error(
          'Create Presigned URL Response: ' + createPresignedURLRes
        );
      }

      // Step 2: Upload file directly to presigned URL
      const presignArr: {
        data: string; // base64 string
        url: string;
      }[] = [];
      for (const presignedDoc of createPresignedURLRes) {
        const originalPayload = payload.find(
          (document) => document.key === presignedDoc.type
        );
        if (originalPayload && presignedDoc.presigned_url) {
          presignArr.push({
            data: originalPayload.content,
            url: presignedDoc.presigned_url,
          });
        }
      }
      await Promise.all(
        presignArr.map((val) => {
          // AWS cannot receive bearer token when uploading with presigned URL
          // Deleting Auth header using transformRequest doesn't work
          // probably need to modify our axios plugin for it to work
          // so we use vanilla axios here
          return axios.put(val.url, Buffer.from(val.data, 'base64'), {
            cancelToken: source.token,
          });
        })
      );
      return createPresignedURLRes.map((doc) => {
        return {
          filename: doc.filename,
          key: doc.key,
          type: doc.type,
        };
      });
    } catch (error) {
      if ((error as any).message !== 'Operation canceled by the user.') {
        (this as any).$sentry.withScope((scope) => {
          scope.setExtra(
            'payload',
            payload.map((doc) => delete doc.content)
          );
          scope.setExtra('error', error);
          (this as any).$sentry.captureMessage(
            `Failed to upload TPD claim docs`
          );
        });
      }
      throw error;
    }
  },
  async claim(context, params: TClaimTPDPayload): Promise<any> {
    const req: TCreateClaimReq = {
      benefit_id: params.benefitID,
      policy_number: params.policyNumber,
      covered_user_id: params.coveredUserID,
      supporting_documents: params.supportingDocuments.map((doc) => ({
        filename: doc.filename,
        key: doc.key,
        type: doc.type,
      })),
      additional_data: {
        bank_account: {
          account_holder_name: params.bankAccount.accountHolderName,
          account_number: params.bankAccount.accountNumber,
          bank_name: params.bankAccount.bankName,
        },
      },
    };

    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('policy/updateCancelToken', source, { root: true });
      const res = await (this as any).$axios.post(
        `/health/v1.0/claims/tropical-disease`,
        req,
        { cancelToken: source.token }
      );
      if (res instanceof Error) {
        throw res;
      }
    } catch (err) {
      if ((err as any).message !== 'Operation canceled by the user.') {
        (this as any).$sentry.withScope((scope) => {
          scope.setExtra('payload', req);
          scope.setExtra('error', err);
          (this as any).$sentry.captureMessage(`Failed to create TPD claim`);
        });
      }
      throw err;
    }
  },
};
