import { CLEVERTAP_UUID_KEY } from '~/shared/constants';
import sanitizePhoneCountryCode from '~/utils/common/sanitizePhoneCountryCode';

const CLEVERTAP_CACHED_EVENTS = 'clevertap_cached_ev';

const analytics = (appNuxt) => ({
  pushEvent(eventData) {
    let userType = 'Guest';
    const token = appNuxt.store.getters['user/getUserToken'];
    const user = appNuxt.store.getters['user/getUserData'];
    const guest = appNuxt.store.getters['general/getGuest'];

    const isTelesales =
      user?.groups?.some((group) => group?.name?.includes('telesale')) ||
      user?.groups?.some((group) => group?.includes('telesale'));

    if (token) {
      userType = isTelesales ? 'Telesales' : 'Registered';
    }
    if (guest && Object.keys(guest).length > 0) {
      userType = `Leads-${guest.guest.email}`;
    }
    appNuxt.$gtm.push({ event: 'setUserType', usertype: userType });
    appNuxt.$gtm.push(eventData);
  },
  pushEventClevertap(eventName, props) {
    let userType = 'Guest';
    let email = '';
    let phoneNumber = '';
    let name = '';
    const token = appNuxt.store.getters['user/getUserToken'];
    const guest = appNuxt.store.getters['general/getGuest'];
    const user = appNuxt.store.getters['user/getUserData'];

    let utmParam = appNuxt.store.getters['general/getUtmParams'];
    if (!utmParam) {
      const {
        context: { route },
      } = appNuxt;
      utmParam = {
        'UTM Source': route.query.utm_source || 'organic',
        'UTM Medium': route.query.utm_medium || 'organic',
        'UTM Campaign': route.query.utm_campaign || '',
      };
    }
    const isTelesales =
      user?.groups?.some((group) => group?.name?.includes('telesale')) ||
      user?.groups?.some((group) => group?.includes('telesale'));
    if (token) {
      userType = isTelesales ? 'Telesales' : 'Registered';
    }
    this.pushUserProfileClevertap(utmParam);
    const carPurchaseHistory =
      appNuxt.store.getters['vehicle/getCarPurchaseHistory'];

    if (carPurchaseHistory?.prequoteData?.phoneNumber) {
      userType = 'Leads';
      phoneNumber = carPurchaseHistory.prequoteData?.phoneNumber?.phoneNo ?? '';
    } else if (user && Object.keys(user).length > 0) {
      email = user.email;
      phoneNumber = user.account?.phoneNumber;
      name = user.fullName;
    } else if (guest && Object.keys(guest).length > 0) {
      userType = `Leads-${guest.guest.email}`;
      phoneNumber = guest.guest.phone;
      name = guest.guest.fullName;
      email = guest.guest.email;
    }
    Object.assign(props, utmParam);
    if (eventName !== 'Login' || eventName !== 'Sign Up') {
      Object.assign(props, { 'User Type': userType });
    }

    if (!props['User Name']) props['User Name'] = name;
    if (!props['User Email']) props['User Email'] = email;
    if (!props['User Phone Number']) {
      props['User Phone Number'] = sanitizePhoneCountryCode(
        typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber
      );
    }

    const clevertapID = localStorage.getItem(CLEVERTAP_UUID_KEY);
    props['Identity'] = clevertapID;

    if (window.androidCleverTap) {
      window.androidCleverTap.pushEvent(eventName, JSON.stringify(props));
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.iosCleverTap
    ) {
      const message = { action: eventName, properties: props };
      window.webkit.messageHandlers.iosCleverTap.postMessage(message);
    } else {
      // NOTES: we want to make sure an event is sent right after
      // Clevertap has been loaded so we implement this retry mechanism
      if (!window.clevertap) {
        const retrySendingClevertap = setInterval(() => {
          if (window.clevertap) {
            window.clevertap.event.push(eventName, props);
            clearInterval(retrySendingClevertap);
            return;
          }
        }, 100);
        return;
      }
      window.clevertap.event.push(eventName, props);
    }
  },
  async pushUserProfileClevertap(utmParam) {
    await appNuxt.store.dispatch('user/getProfile', {
      callback: () => {},
    });
    let userProperties = { Site: {} };
    const userData = appNuxt.store.getters['user/getUserData'];
    if (!appNuxt.$helper.isEmpty(userData)) {
      userProperties = {
        Site: {
          Identity: userData.uuid,
          Name: userData.fullName,
          Phone:
            '+' + userData.account
              ? userData.account.phoneNumber
              : userData.phoneNumber,
          AcquistionType: utmParam ? utmParam['UTM Medium'] : 'organic',
          Country: userData.nationality,
          RegistrationStartDate: userData.createdAt,
          Email: userData.email,
          'MSG-email': true,
          'MSG-push': true,
          'MSG-sms': true,
          'Preferred language': userData.lang,
        },
      };
      const profileImage = userData.documents.find(
        (item) => item.type === 'profile-image'
      );
      if (profileImage) {
        userProperties.Site.ProfileImage = profileImage.publicUrl;
      }
      if (!appNuxt.$helper.isEmpty(userData.gender)) {
        userProperties.Site.Gender =
          userData.gender && userData.gender === 'MALE' ? 'M' : 'F';
      }
      if (!appNuxt.$helper.isEmpty(userData.birthPlace)) {
        const birthData = userData.birthPlace.split(',');
        let birthDate = birthData[1];
        if (birthDate) {
          birthDate = birthDate.split('-');
          birthDate = new Date(
            `${birthDate[2]}-${birthDate[1]}-${birthDate[0]}`
          );
          userProperties.Site.DOB = birthDate;
        }
      }
    } else {
      userProperties = {
        Site: {
          ...userProperties.Site,
          AcquistionType: utmParam ? utmParam['UTM Medium'] : 'organic',
          'MSG-email': true,
          'MSG-push': true,
          'MSG-sms': true,
          'Preferred language': appNuxt.i18n.defaultLocale,
        },
      };
    }
    if (window && window.clevertap && window.clevertap.profile) {
      window.clevertap.profile.push(userProperties);
    }
  },
});

export default ({ app, store }, inject) => {
  const user = store.getters['user/getUserData'];
  const isLogin = store.getters['user/getUserToken'];
  const countryCode = store.getters['core/getCountryCode'];
  let id = '';
  if (isLogin && user) {
    id = user.id;
  } else {
    id = app.store.getters['general/getGtmUserId'];
  }

  inject('analytics', analytics(app));
  window.onNuxtReady((app) => {
    app.$gtm.init(process.env.GTM_ID);
    if (countryCode === 'MY') {
      initClevertap(process.env['CLEVERTAP_MY']);
    } else {
      initClevertap(process.env['CLEVERTAP_ID']);
    }
    app.$gtm.push({ event: 'setUserId', userId: id });
  });
};

function initClevertap(idClevertap) {
  // use window because the source only can be read by browser param level
  window.clevertap = {
    event: [],
    profile: [],
    region: 'sg1',
    account: [],
    onUserLogin: [],
    notifications: [],
    privacy: [],
  };
  // remove CleverTap web call if android or ios
  if (window.isAndroid || window.isIos) return null;
  // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
  window.clevertap.account.push({ id: idClevertap });
  window.clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
  window.clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
  (function () {
    const wzrk = document.createElement('script');
    wzrk.type = 'text/javascript';
    wzrk.rel = 'preconnect';
    wzrk.defer = true;
    wzrk.src =
      ('https:' === document.location.protocol
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/a.js?v=0';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wzrk, s);
  })();
}
