const privacyNotice = {
  privacyNoticeContent: {
    headTitle: 'Qoala Privacy Notice',
    content: [
      {
        title: 'Introduction',
        paragraph: [
          'In compliance with the Personal Data Protection Act 2010 (“the Act”) and its regulations, this notice is issued to all our valued clients and/or prospective clients. This Notice will inform you or your rights with regards to your personal data that has been and/or will be collected and processed by us.',
        ],
      },
      {
        title: 'Collection of personal data',
        paragraph: [
          'We have collected and will collect your personal data to be processed by us and/or on your behalf in the course of your present or future dealings with Qoala Technology  Sdn Bhd (“Qoala”), its subsidiary(ies), its associated company(ies) (“we”, “our”, “us” or “Company”). Your personal data enables us to achieve the “Purpose” herein that is in connection with our business.',
        ],
      },
      {
        title: 'Description of personal data',
        list: [
          'Name;',
          'Identity Card no.;',
          'Gender;',
          'Email address;',
          'Mobile phone no.;',
          'Passport no.;',
          'IP address;',
          'Images (still/recording);',
          'Sensitive personal data; and any',
          'Personally Identifiable Information (PII) defined by international privacy laws or standards.',
        ],
      },
      {
        title: 'Purposes for collection of personal data',
        paragraph: [
          'Qoala will use clients’ personal data for the following purposes including but not limited to:',
        ],
        list: [
          'Issuance of insurance policies and associated services;',
          'Customer service; and',
          'Personalization of sales and marketing activities.',
        ],
      },
      {
        title: 'Sources of personal data',
        paragraph: [
          'Qoala may obtain clients’ personal data from the following sources including but not limited to:',
        ],
        list: [
          'Submissions by the client to Qoala;',
          'Other data holders who have been authorised by clients to share data with Qoala.',
        ],
      },
      {
        title: 'Disclosure of personal data',
        paragraph: [
          'We may disclose client’s personal data to other subsidiaries within our group. These subsidiaries will treat client’s personal data as confidential, in accordance with this Privacy Policy and all applicable Data Protection Legislation and will process such personal data only for the Purposes and within the terms set out herein. We are responsible for the personal data under our control, including personal data disclosed by us to a Vendor (often referred to as the “data processor”). We take every measure to provide a comparable level of protection for personal data should the information be processed by a Vendor. Qoala is committed to complying with the Personal Data Protection Act 2010, in particular, its policies as well as corresponding guidelines and orders.',
        ],
      },
      {
        title: 'Security of personal data',
        paragraph: [
          'Qoala uses strict procedures and security features to prevent unauthorized access wherever possible. Personal data provided to Qoala via our website or via any Applications, online credit card transactions are protected during transit using encryption such as Transport Layer Security (TLS). When personal data is stored by Qoala, we use computer systems with limited access housed in facilities using physical security measures. Data stored in cloud services is encrypted form including when we utilize third-party storage. The level of security of personal data which is kept in a non-electronic environment is also treated with strict procedures and means.',
        ],
      },
      {
        title: 'Use of Cookies',
        paragraph: [
          'The cookies used by the Website are solely associated with anonymous users and their computers and do not provide personal data on users. Some cookies are used by third parties to provide Qoala with data on the effectiveness of its engagements and promotions. The cookies used by the Website do not in any way collect personal data that could be used to identify a specific user. In addition, cookies are of a temporary nature, used solely to improve the efficiency of the last transmission. Users may configure their browser to notify them of the reception of cookies and to prevent their installation on their computer.',
        ],
      },
      {
        title: 'Transfer of Personal Data',
        paragraph: [
          'We are very serious about providing a comparable level of protection for personal data should the information be processed or used outside Malaysia by our vendors or experts.',
        ],
      },
      {
        title: 'Marketing and promotional purposes',
        paragraph: [
          'Upon subscribing to our services, you will be receiving marketing and/or promotion emails or calls from us, which includes monthly promotions and information on future events. If you do not wish to receive any promotional news or calls please click the “unsubscribe” link at the bottom of the email and you will be excluded from our newsletters and contacts.',
        ],
      },
      {
        title: 'Data subject’s obligations',
        paragraph: [
          'It is necessary for Qoala to collect and to retain clients’ personal data. Therefore, it is also obligatory for clients to supply personal data to us in the most accurate manner. If clients do not provide us with personal data, Qoala is unable to process personal data on clients’ behalf, for the Purpose stated in clause 4 herein, or effectively render our services to clients, and all relationships created or to be created between us shall then be terminated and ceased to be in effect immediately.',
        ],
      },
      {
        title: 'The rights of data subject',
        list: [
          'To request access to personal data. Clients have the right to request for access to personal data processed by us with reasonable notice. Access requests can be made online by sending an email to cs@qoala.my.',
          'To request correction of personal data. Clients have the right to request for correction and/or update of your personal data that is inaccurate, incomplete or outdated. Correction requests can be online by sending an email to cs@qoala.my.',
          'To limit processing of your personal data. Clients are entitled to limit our processing of personal data by expressly withdrawing the consent given previously, in each case, including for direct marketing purposes subject to any applicable legal restrictions, contractual conditions and within a reasonable time period.',
        ],
      },
      {
        title: 'Changes to Privacy Policy',
        paragraph: [
          'We reserve the right to amend this Privacy Policy from time to time without prior notice. We advise that you check our website for this Privacy Policy on a regular basis. Kindly view our privacy notice on the website, www.qoala.app.',
        ],
      },
      {
        title: 'Acknowledgement and Consent',
        paragraph: [
          'By communicating, engaging with Qoala, or using the Company’s products and services, you acknowledge that you have read and understood this Notice and agree and consent to the use, processing, disclosure and transfer of your personal data by Qoala as described in this Notice. Any email Qoala sends to you will contain an automated unsubscribe link so that you can opt-out of the mailing list. You can also withdraw your consent by written notice.',
        ],
      },
      {
        title: 'Inquiries and complaints',
        vhtml:
          '<div>Should you require further information about the personal data we hold or the way in which your information is being collected or used which are not answered by this Privacy Notice, please or write to us at:<br/><br/> Contact Number: +60 11-5423 2787 (Whatsapp only)<br/> Email: cs@qoala.my<br/> Address: Level 8, Vertical Corporate Tower B, Avenue 10, The Vertical, 8 Jalan Kerinchi, Kg. Kerinchi, 59200 Kuala Lumpur, Malaysia</div>',
      },
      {
        title: 'Takaful Privacy Notice',
        takaful:
          '<li><a href="https://www.axa.com.my/personal-data-policy" target="_blank" rel=”noopener noreferrer”>AXA</a></li><li><a href="https://www.takaful-malaysia.com.my/lang/bm/footer/Pages/privacypolicy.aspx" target="_blank" rel=”noopener noreferrer”>Syarikat Takaful Malaysia</a></li><li><a href="https://www.takaful-ikhlas.com.my/ms/polisi-privasi" target="_blank" rel=”noopener noreferrer”>Takaful IKHLAS</a></li><li><a href="https://www.zurich.com.my/en/customer-hub/show-me-more-info/personal-data-protection-notice" target="_blank" rel=”noopener noreferrer”>Zurich</a></li>',
      },
    ],
  },
};

export default privacyNotice;
