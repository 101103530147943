import qoalaStorage from '~/core/Storage';

export default {
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('lifeInsuranceProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('productDetailLife', JSON.stringify(state.productDetail));
  },
  updateLifePurchaseHistory(state, value) {
    state.lifePurchaseHistory = value;
    qoalaStorage.setItem('lifePurchaseHistory', JSON.stringify(state.lifePurchaseHistory));
  },
  clearData(state) {
    state.lifeInsuranceData = {};
    state.listProduct = [];
    state.productDetail = {};
    state.lifePurchaseHistory = {};
    qoalaStorage.removeItem('lifeInsuranceData');
    qoalaStorage.removeItem('lifeInsuranceProduct');
    qoalaStorage.removeItem('productDetailLife');
    qoalaStorage.removeItem('lifePurchaseHistory');
  }
};
