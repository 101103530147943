import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getPersonalAccidentData: state => {
    if (!state.personalAccidentData) {
      return qoalaStorage.getItem('personalAccidentData')
        ? parseIfJsonString(qoalaStorage.getItem('personalAccidentData'))
        : state.personalAccidentData || {};
    }
    return state.personalAccidentData;
  },
  getListProduct: state => {
    if (!state.listProduct) {
      return qoalaStorage.getItem('personalAccidentProduct')
        ? parseIfJsonString(qoalaStorage.getItem('personalAccidentProduct'))
        : state.listProduct || [];
    }
    return state.listProduct || [];
  },
  getProductDetail: state => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('productDetailPersonalAccident')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailPersonalAccident'))
        : state.productDetail || {};
    }
    return state.productDetail;
  }
};
