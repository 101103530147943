import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getPropertyData: state => {
    if (!state.propertyData) {
      return qoalaStorage.getItem('propertyData')
        ? parseIfJsonString(qoalaStorage.getItem('propertyData'))
        : state.propertyData || {};
    }
    return state.propertyData;
  },
  getListProduct: state => {
    if (!state.listProduct) {
      return qoalaStorage.getItem('propertyProduct')
        ? parseIfJsonString(qoalaStorage.getItem('propertyProduct'))
        : state.listProduct || [];
    }
    return state.listProduct || [];
  },
  getProductDetail: state => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('productDetailProperty')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailProperty'))
        : state.productDetail || {};
    }
    return state.productDetail;
  }
};
