import qoalaStorage from '~/core/Storage';

export default {
  //car
  updateCarPurchaseHistory(state, value) {
    state.carPurchaseHistory = value;
    qoalaStorage.setItem(
      'carPurchaseHistory',
      JSON.stringify(state.carPurchaseHistory)
    );
  },
  deleteCarPurchaseHistory(state) {
    qoalaStorage.removeItem('carPurchaseHistory');
    state.carPurchaseHistory = {};
  },
  updateProductDetailCar(state, value) {
    state.productDetailCar = value;
    qoalaStorage.setItem(
      'productDetailCar',
      JSON.stringify(state.productDetailCar)
    );
  },
  deleteProductDetailCar(state) {
    state.productDetailCar = {};
    qoalaStorage.removeItem('productDetailCar');
  },
  //motor
  updateMotorPurchaseHistory(state, value) {
    state.motorPurchaseHistory = value;
    qoalaStorage.setItem(
      'motorPurchaseHistory',
      JSON.stringify(state.motorPurchaseHistory)
    );
  },
  deleteMotorPurchaseHistory(state) {
    qoalaStorage.removeItem('motorPurchaseHistory');
    state.motorPurchaseHistory = {};
  },
  updateProductDetailMotor(state, value) {
    state.productDetailMotor = value;
    qoalaStorage.setItem(
      'productDetailMotor',
      JSON.stringify(state.productDetailMotor)
    );
  },
  deleteProductDetailMotor(state) {
    state.productDetailMotor = {};
    qoalaStorage.removeItem('productDetailMotor');
  },
  //vehicle
  updatePolicyList(state, { hasFilters, value }) {
    const updatedList = value;
    if (state.policyList.length && hasFilters) {
      updatedList.push(
        ...state.policyList.filter(
          ({ product_code }) => product_code !== value[0].product_code
        )
      );
    }
    state.policyList = updatedList;
  },
  updateIndoPolicyList(state, payload) {
    state.policyList = payload;
  },
  updatePolicyListMeta(state, value) {
    state.policyListMeta = value;
  },
  deleteVehicleProductListMeta(state) {
    state.policyListMeta = { total_processed: 0, total_products: -1 };
  },
  updateVehiclePlate(state, value) {
    state.vehiclePlate = value;
    qoalaStorage.setItem('vehiclePlate', JSON.stringify(state.vehiclePlate));
  },
  deleteVehiclePlate(state) {
    state.vehiclePlate = [];
    qoalaStorage.removeItem('vehiclePlate');
  },
  updateVehicleBrand(state, value) {
    state.vehicleBrand = value;
    qoalaStorage.setItem('vehicleBrand', JSON.stringify(state.vehicleBrand));
  },
  deleteVehicleBrand(state) {
    state.vehicleBrand = [];
    qoalaStorage.removeItem('vehicleBrand');
  },
  updateVehicleModel(state, value) {
    state.vehicleModel = value;
    qoalaStorage.setItem('vehicleModel', JSON.stringify(state.vehicleModel));
  },
  deleteVehicleModel(state) {
    state.vehicleModel = [];
    qoalaStorage.removeItem('vehicleModel');
  },
  updateVehicleSeries(state, value) {
    state.vehicleSeries = value;
    qoalaStorage.setItem('vehicleSeries', JSON.stringify(state.vehicleSeries));
  },
  deleteVehicleSeries(state) {
    state.vehicleSeries = [];
    qoalaStorage.removeItem('vehicleSeries');
  },
  // updateListSearchResults(state, value) {
  //   state.searchResults = value;
  //   qoalaStorage.setItem('searchResults', JSON.stringify(state.searchResults));
  // },
  // deleteListSearchResults(state) {
  //   state.searchResults = [];
  //   qoalaStorage.removeItem('searchResults');
  // },
  updateClosing(state, value) {
    state.closing = value;
    qoalaStorage.setItem('closing', JSON.stringify(state.closing));
  },
  updateDocumentsType(state, value) {
    qoalaStorage.setItem('documentsType', JSON.stringify(value));
    state.documentsType = value;
  },
  deleteDocumentsType(state) {
    qoalaStorage.removeItem('documentsType');
    state.documentsType = [];
  },
  deletePolicyList(state) {
    state.policyList = [];
  },
  deleteData(state) {
    state.policyList = [];
    state.motorPurchaseHistory = {};
    state.vehiclePlate = [];
    state.vehicleBrand = [];
    state.vehicleModel = [];
    state.vehicleSeries = [];
    state.productDetailCar = {};
    state.productDetailMotor = {};
    state.documentsType = [];
    qoalaStorage.removeItem('motorPurchaseHistory');
    qoalaStorage.removeItem('vehiclePlate');
    qoalaStorage.removeItem('vehicleBrand');
    qoalaStorage.removeItem('vehicleModel');
    qoalaStorage.removeItem('vehicleSeries');
    qoalaStorage.removeItem('productDetailCar');
    qoalaStorage.removeItem('productDetailMotor');
    qoalaStorage.removeItem('documentsType');
  },
};
