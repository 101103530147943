import qoalaStorage from '~/core/Storage';

export default () => ({
  activeList: qoalaStorage.getItem('activeList'),
  policies: qoalaStorage.getItem('policies'),
  policy: qoalaStorage.getItem('policy'),
  policyList: [],
  claimForm: null,
  claims: qoalaStorage.getItem('claims'),
  claim: qoalaStorage.getItem('claim'),
  historiesClaim: [],
  benefits: qoalaStorage.getItem('benefits'),
  upcoming: qoalaStorage.getItem('upcoming'),
  cancelTokenAxios: {},
  tempData: qoalaStorage.getItem('tempDat'),
  tempDataBus: {},
  smartPhoneProduct: qoalaStorage.getItem('smartPhoneProduct'),
  purchaseConfirmation: qoalaStorage.getItem('smartPhonePurchaseConfirmation'),
  purchasePayment: qoalaStorage.getItem('smartPhonePurchasePayment'),
  smartphoneClaim: {},
  storeList: qoalaStorage.getItem('storeList'),
  insuredPaPlusList: qoalaStorage.getItem('insuredPaPlusList'),
  dataPolicyCreation: qoalaStorage.getItem('dataPolicyCreation'),
  qoalaInititatedPolicyData: {},
  qoalaInitiatedBankListData: {},
});
