import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getHcpData: state => {
    if (!state.hcpData) {
      return qoalaStorage.getItem('hcpData') ? parseIfJsonString(qoalaStorage.getItem('hcpData')) : state.hcpData || {};
    }
    return state.hcpData;
  },
  getListProduct: state => {
    if (!state.listProduct) {
      return qoalaStorage.getItem('hcpProduct')
        ? parseIfJsonString(qoalaStorage.getItem('hcpProduct'))
        : state.listProduct || [];
    }
    return state.listProduct || [];
  },
  getProductDetail: state => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('productDetailHcp')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailHcp'))
        : state.productDetail || {};
    }
    return state.productDetail;
  }
};
