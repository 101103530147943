const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['gtm'] = require('../middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['localization'] = require('../middleware/localization.js')
middleware['localization'] = middleware['localization'].default || middleware['localization']

middleware['lonpac'] = require('../middleware/lonpac.ts')
middleware['lonpac'] = middleware['lonpac'].default || middleware['lonpac']

middleware['utmCapturer'] = require('../middleware/utmCapturer.js')
middleware['utmCapturer'] = middleware['utmCapturer'].default || middleware['utmCapturer']

export default middleware
