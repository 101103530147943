import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getActiveList: (state) => {
    if (!state.activeList)
      return qoalaStorage.getItem('activeList')
        ? qoalaStorage.getItem('activeList')
        : state.activeList || 'policy';
    return state.activeList;
  },
  getStoreList: (state) => {
    if (!state.storeList)
      return qoalaStorage.getItem('storeList')
        ? parseIfJsonString(qoalaStorage.getItem('storeList'))
        : state.storeList;
    return state.storeList;
  },
  getPolicies: (state) => {
    if (!state.policies)
      return qoalaStorage.getItem('policies')
        ? parseIfJsonString(qoalaStorage.getItem('policies'))
        : [];
    return state.policies;
  },
  getPolicy: (state) => {
    if (!state.policy)
      return qoalaStorage.getItem('policy')
        ? parseIfJsonString(qoalaStorage.getItem('policy'))
        : state.policy;
    return state.policy;
  },
  getPolicyList: (state) => state.policyList || [],
  getClaimForm: (state) => state.claimForm,
  getClaims: (state) => {
    if (!state.claims)
      return qoalaStorage.getItem('claims')
        ? parseIfJsonString(qoalaStorage.getItem('claims'))
        : [];
    return state.claims;
  },
  getClaim: (state) => {
    if (!state.claim)
      return qoalaStorage.getItem('claim')
        ? parseIfJsonString(qoalaStorage.getItem('claim'))
        : state.claim;
    return state.claim;
  },
  getHistoriesClaim: (state) => {
    return state.historiesClaim;
  },
  getBenefits: (state) => {
    if (!state.benefits)
      return qoalaStorage.getItem('benefits')
        ? parseIfJsonString(qoalaStorage.getItem('benefits'))
        : state.benefits;
    return state.benefits;
  },
  getUpcoming: (state) => {
    if (!state.upcoming)
      return qoalaStorage.getItem('upcoming')
        ? parseIfJsonString(qoalaStorage.getItem('upcoming'))
        : state.upcoming;
    return state.upcoming;
  },
  getCancelToken: (state) => state.cancelTokenAxios,
  getTempData: (state) => {
    if (!state.tempData)
      return qoalaStorage.getItem('tempDat')
        ? parseIfJsonString(qoalaStorage.getItem('tempDat'))
        : state.tempData;
    return state.tempData;
  },
  getTempDataBus: (state) => state.tempDataBus,
  getSmartPhoneProduct: (state) => {
    if (!state.smartPhoneProduct)
      return qoalaStorage.getItem('smartPhoneProduct')
        ? parseIfJsonString(qoalaStorage.getItem('smartPhoneProduct'))
        : state.smartPhoneProduct;
    return state.smartPhoneProduct;
  },
  getPurchaseConfirmation: (state) => {
    if (!state.purchaseConfirmation)
      return qoalaStorage.getItem('smartPhonePurchaseConfirmation')
        ? parseIfJsonString(
            qoalaStorage.getItem('smartPhonePurchaseConfirmation')
          )
        : state.purchaseConfirmation;
    return state.purchaseConfirmation;
  },
  getPurchasePayment: (state) => {
    if (!state.purchasePayment)
      return qoalaStorage.getItem('smartPhonePurchasePayment')
        ? parseIfJsonString(qoalaStorage.getItem('smartPhonePurchasePayment'))
        : state.purchasePayment;
    return state.purchasePayment;
  },
  getSmartphoneClaim: (state) => {
    if (!state.smartphoneClaim) {
      return '';
    }
    return state.smartphoneClaim;
  },
  getDataPolicyCreation: (state) => {
    if (!state.dataPolicyCreation) {
      return qoalaStorage.getItem('dataPolicyCreation')
        ? parseIfJsonString(qoalaStorage.getItem('dataPolicyCreation'))
        : state.dataPolicyCreation || {};
    }
    return state.dataPolicyCreation;
  },
  getInsuredListPaPlus: (state) => {
    const insuredList = qoalaStorage.getItem('insuredPaPlusList');
    if (!state.insuredPaPlusList)
      return insuredList
        ? parseIfJsonString(insuredList)
        : state.insuredPaPlusList;
    return state.insuredPaPlusList;
  },
  getQoalaInitiatedPolicyDetail: (state) => {
    return state.qoalaInititatedPolicyData;
  },
  getQoalaInitiatedBankList: (state) => {
    return state.qoalaInitiatedBankListData;
  },
};
