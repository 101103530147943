import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
const loading =
  'https://img.qoala.app/qapp-assets/images/placeholder_all_size.png?tr=bl-10,q-10';

Vue.use(VueLazyload, {
  preLoad: 1,
  attempt: 1,
  loading,
  error: loading
});
