export default {
  async debitAccountLinking(context, payload) {
    try {
      const data = await this.$axios.post('/payment/debit/account_linking', payload);
      const response = data.data.data;
      return response;
    } catch (error) {
      const { data } = error.response;
      if (data)
        this.$sentry.withScope(scope => {
          scope.setExtra('payload', payload);
          scope.setExtra('response', data);
          this.$sentry.captureMessage(`ERROR PAYMENT DIRECT DEBIT  ${JSON.stringify(data)}`);
        });
      throw error;
    }
  },
  async validateAccountLinking(context, payload) {
    try {
      const data = await this.$axios.post('/payment/debit/validate-account-linking', payload);
      const response = data.data.data;
      return response;
    } catch (error) {
      const { data } = error.response;
      if (data)
        this.$sentry.withScope(scope => {
          scope.setExtra('payload', payload);
          scope.setExtra('response', data);
          this.$sentry.captureMessage(`ERROR PAYMENT DIRECT DEBIT  ${JSON.stringify(data)}`);
        });
      throw error;
    }
  },
  async validateOtpPaymentDirectDebit(context, payload) {
    try {
      let data;
      data = await this.$axios.post('/payment/debit/validate-otp', payload);
      const response = data.data.data;
      return response;
    } catch (error) {
      const { data } = error.response;
      if (data)
        this.$sentry.withScope(scope => {
          scope.setExtra('payload', payload);
          scope.setExtra('response', data);
          this.$sentry.captureMessage(`ERROR PAYMENT DIRECT DEBIT  ${JSON.stringify(data)}`);
        });
      throw error;
    }
  },
};
