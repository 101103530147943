function constructUtmLeadPayload({ app }) {
  const utms = app.store.getters['general/getUtmParams'];
  const referer = app.store.getters['general/getReferer'];
  let utmParams = [];
  if (utms) {
    utmParams = Object.keys(utms).map((utmKey) => {
      return {
        key: utmKey,
        value: JSON.stringify(utms[utmKey]),
      };
    });
  }
  return {
    referer: referer ?? undefined,
    utmParams,
  };
}

export default (context, inject) => {
  inject('constructUtmLeadPayload', () => constructUtmLeadPayload(context));
};
