// This function is a plugin that automatically updates the theme colors of
export default ({ app }, inject) => {
  app.router.afterEach((to, from) => {
    // Retrieve theme color tokens from the Vuex store.
    const colorTokens = app.store.getters['general/getTheme'];
    // Apply each color token as a CSS variable on the body element.
    colorTokens && Object.keys(colorTokens).forEach((key) => {
      document.body.style.setProperty(key, colorTokens[key]);
    });
  });
};
