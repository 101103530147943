/* Partner */
export const CONST_MOMO_VIET = 'Momo Viet';
export const CONST_TRAVELOKA = 'Traveloka';
// motor vehicle
export const CONST_MOTORVEHICLE_MY = 'MOTORVEHICLE-MY';
/* Smartphone */
export const CONST_SMARTPHONE = 'smartphone';
export const CONST_SCREENCRACK = 'SCREENCRACK';
export const CONST_SMP_01 = 'SMP-01';
export const CONST_SMP_02 = 'SMP-02';
export const CONST_SMP_17 = 'SMP-17';
export const CONST_SMP_18 = 'SMP-18';
export const CONST_SP = 'SP';
export const CONST_SHOWCRAKEDFORM = 'showCrackedForm';
export const CONST_SHOWCRACKEDRECORDER = 'showCrackedRecorder';
export const CONST_SHOWSTORE = 'showStore';
export const CONST_DEFAULT = 'default';
export const CONST_PICKUPFORM = 'showPickUpForm';
export const CONST_CONFIRMATIONFORM = 'showConfirmationForm';
export const CONST_SHOWPAYBANK = 'showPayBank';
export const CONST_VIETNAM = 'Vietnam';
export const CONST_WALKIN = 'WALKIN';
export const CONST_PICKUP = 'PICKUP';
export const CONST_WAITING_WALKIN_START = 'WAITING_WALKIN_START';
export const CONST_WAITING_EXCESS_FEE_CONFIRM = 'WAITING_EXCESS_FEE_CONFIRM';
export const CONST_CUST_REJECT_FEE = 'CUST_REJECT_FEE';
export const CONST_CUST_APPROVE_FEE = 'CUST_APPROVE_FEE';
export const CONST_WAITING_WALKIN_FINISH = 'WAITING_WALKIN_FINISH';
export const CONST_SUBMITTED = 'SUBMITTED';
export const CONST_QOALA_CLAIM_APPROVED = 'QOALA_CLAIM_APPROVED';
export const CONST_WAITING_PAYMENT = 'WAITING_PAYMENT';
export const CONST_INITIATED = 'INITIATED';
export const CONST_WAITING_COURIER_START = 'WAITING_COURIER_START';
export const CONST_RECEIVED_SC = 'RECEIVED_SC';
export const CONST_ESTIMATE_COST = 'ESTIMATE_COST';
export const CONST_INSURANCE_ASK_DETAIL = 'INSURANCE_ASK_DETAIL';
export const CONST_CX_UPLOAD_DOC = 'CX_UPLOAD_DOC';
export const CONST_APPROVED = 'APPROVED';
export const CONST_REPLACED = 'REPLACED';
export const CONST_RESUBMIT = 'RESUBMIT';
export const CONST_INSURANCE_APPROVED = 'INSURANCE_APPROVED';
export const CONST_INSURANCE_APPROVED_REPLACED = 'INSURANCE_APPROVED_REPLACED';
export const CONST_ON_PROGRESS = 'ON_PROGRESS';
export const CONST_INSURANCE_REJECTED = 'INSURANCE_REJECTED';
export const CONST_WAITING_COURIER_FINISH = 'WAITING_COURIER_FINISH';
export const CONST_COURIER_PICKED_UP = 'COURIER_PICKED_UP';
export const CONST_OOS_SUBMIT_CLAIM = 'OOS_SUBMIT_CLAIM';
export const CONST_REJECTED_SC = 'REJECTED_SC';
export const CONST_DONE_REPAIR = 'DONE_REPAIR';
export const CONST_DONE = 'DONE';
export const CONST_DONE_REJECTED = 'DONE_REJECTED';
export const CONST_REJECTED = 'REJECTED';
export const CONST_DONE_REPLACED = 'DONE_REPLACED';
export const CONST_REJECT_REPAIR = 'REJECT_REPAIR';
export const CONST_CLAIM_ADDED_SC = 'CLAIM_ADDED_SC';
export const CONST_INSURANCE_ASK_RESUBMIT_COST = 'INSURANCE_ASK_RESUBMIT_COST';
export const CONST_DONE_OOS = 'DONE_OOS';
export const CONST_DONE_CANCEL = 'DONE_CANCEL';
export const CONST_DONE_EXPIRED = 'DONE_EXPIRED';
export const CONST_QOALA_REQUEST_SALVAGE = 'QOALA_REQUEST_SALVAGE';
export const CONST_SC_SENT_SALVAGE = 'SC_SENT_SALVAGE';
export const CONST_QOALA_RECEIVE_SALVAGE = 'QOALA_RECEIVE_SALVAGE';
export const CONST_QOALA_SENT_SALVAGE = 'QOALA_SENT_SALVAGE';
export const CONST_DONE_SALVAGE = 'DONE_SALVAGE';
export const CONST_VN = 'VN';
export const CONST_APPLE = 'APPLE';
export const CONST_XIAOMI = 'XIAOMI';
export const CONST_ICLOUD_URL =
  'https://support.apple.com/guide/icloud/remove-a-device-mmfc0eeddd/icloud';
// life
export const CONST_CIPUTRA = 'CIPUTRA';
export const CONST_ADIRASYARIAH = 'ADIRASYARIAH';
//property
export const CONST_LANDED = 'LANDED';
export const CONST_NON_LANDED = 'NON_LANDED';
export const CONST_BRICK = 'BRICK';
export const CONST_PARTIAL_BRICK = 'PARTIAL_BRICK';
export const CONST_HOUSEOWNERS = 'HOUSEOWNERS';
export const CONST_HOUSEHOLDER = 'HOUSEHOLDER';
export const CONST_HOUSEOWNERS_HOUSEHOLDER = 'HOUSEOWNERS_HOUSEHOLDER';
export const CONST_FIRE = 'FIRE';
export const CONST_PLATINUM = 'PLATINUM';
export const CONST_GOLD = 'GOLD';
export const CONST_SILVER = 'SILVER';
export const CONST_JEWELRY = 'JEWELRY';
export const CONST_FURS = 'FURS';
export const CONST_OTHER = 'OTHER';
// purchase
export const CONST_COVERAGES = 'COVERAGES';
export const CONST_TNC = 'TNC';
export const CONST_CLAIM = 'CLAIM';
export const CONST_COMPARE = 'COMPARE';
export const CONST_SORT = 'SORT';
export const CONST_FILTER = 'FILTER';
export const CONST_LOWEST_PRICE = 'LOWEST';
export const CONST_HIGHEST_PRICE = 'HIGHEST';
export const CONST_RECOMMEND = 'RECOMMEND';
export const CONST_A_Z = 'A-Z';
export const CONST_Z_A = 'Z-A';
export const CONST_QM = 'QM';
export const CONST_TOP_SALES = 'TOP_SALES';
export const CONST_MONTHLY = 'MONTHLY';
export const CONST_YEARLY = 'YEARLY';

// Meta
export const ROBOTS_NOINDEX_FOLLOW = {
  name: 'robots',
  content: 'noindex, follow',
};
export const ROBOTS_INDEX_FOLLOW = { name: 'robots', content: 'index, follow' };

//gender
export const CONST_MALE = 'MALE';
export const CONST_FEMALE = 'FEMALE';
//religion
export const CONST_ISLAM = 'ISLAM';
export const CONST_BUDDHIST = 'BUDDHIST';
export const CONST_CHRISTIAN = 'CHRISTIAN';
export const CONST_HINDU = 'HINDU';
export const CONST_OTHERS = 'OTHERS';
//race
export const CONST_MALAY = 'MALAY';
export const CONST_CHINESE = 'CHINESE';
export const CONST_INDIAN = 'INDIAN';
// Query Status Summary
export const SUMMARY_PURCHASE_HISTORY_GENERAL =
  'WAITING_FOR_PAYMENT,WAITING_FOR_UPDATE,PAID,ACTIVE,EXPIRED';
export const SUMMARY_PURCHASE_HISTORY_SMARTPHONE =
  'COMPLETE,PAID,NOT_ACTIVE,EXPIRED';
export const CONST_ID = 'ID';
// country
export const CONST_CURRENCY = {
  MY: 'MYR',
  ID: 'IDR',
};

export const CONST_LOCALE_MAP = {
  MY: 'my',
  ID: 'id',
  TH: 'th',
  VN: 'vn',
  PH: 'ph',
};
export const CONST_OFFICE_ADDRESS = {
  MY: {
    streetAddress: 'officeStreetLabelMy',
    addressRegion: 'officeRegionLabelMy',
    postalCode: 'officePostalCodeLabelMy',
    addressLocality: 'officeAddressLocalityMy',
  },
  ID: {
    streetAddress: 'officeStreetLabel',
    addressRegion: 'officeRegionLabel',
    postalCode: 'officePostalCodeLabel',
    addressLocality: 'officeAddressLocality',
  },
};

// Insurance related constants
export const ETIQA_INSURANCE_CODE = 'ETIQA-MALAYSIA';

export const MALAYSIA_CAR_PRODUCT_CATEGORY = 'Car';
export const MALAYSIA_MOTORCYCLE_PRODUCT_CATEGORY = 'Motorcycle';

export const CLEVERTAP_UUID_KEY = 'clvt_uuid';

export const CUST_SVC_WHATSAPP_MALAY = '601154232787';
export const CUST_SVC_WHATSAPP_INDO = '6285773809313';
