export default {
  async getProductList(context, { sort, payload }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    payload.uuid = uuid;
    try {
      const { data } = await this.$axios.post(
        `/products/health${sort ? `?sort=${sort}` : ''}`,
        payload
      );
      const response = data.data;
      context.commit('setListProduct', response);
    } catch (error) {
      throw error;
    }
  },
};
