import getNestedValue from '~/utils/common/getNestedValue';
import moment from 'moment';

function claimExtendedConfig(claimExtendedObject, policyEndDate) {
  const duration = getNestedValue(claimExtendedObject, 'duration', 'days');
  const term = getNestedValue(claimExtendedObject, 'term', 0);
  const today = moment();
  const maxSubmittedClaim = moment(policyEndDate)
    .add(term, duration)
    .endOf('day');
  if (today <= maxSubmittedClaim) {
    return 'ACTIVE';
  } else {
    return 'EXPIRED';
  }
}

export default claimExtendedConfig;
