import { CoreState } from './state';

export interface CoreGetters {
  getCountryCode: (state: CoreState) => string | null;
}

const coreGetters: CoreGetters = {
  getCountryCode(state) {
    return state.countryCode;
  }
};

export default coreGetters;
