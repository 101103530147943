import moment from 'moment';
import getPolicyStatus from '@/utils/policy/getPolicyStatus';
import { helper } from '~/plugins/helper';

class ProductCard {
  constructor({ contentStatus, data, contentInformation }) {
    this.contentStatus = contentStatus;
    this.data = data;
    this.contentInformation = contentInformation;
  }

  get policyList() {
    const data = {
      headDate: moment(this.data.createdAt).format('DD MMM YYYY'),
      headNumber: this.data.insurance.number
        ? this.contentInformation.policyNumberLabel + ' ' + this.data.insurance.number
        : '',
      productLogo: this.data.insurance.logo,
      generalInformation: this.getGeneralInformation(this.data, this.contentStatus, this.contentInformation),
      productName: this.getProductName(),
      type: 'car',
      status: this.data.status,
    };
    return data;
  }

  get policyFound() {
    const array = [];
    this.data.map(item => {
      const data = {
        transaction: item.transaction,
        status: item.status,
        number: item.transaction.number,
        customer: item.customer,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        product: item.product,
        insurance: item.insurance
      };
      if (item.policyHolder) {
        data.policyHolder = item.policyHolder;
      }
      const type = item.product.type;
      if (type === 'CAR' || type === 'MOTOR') {
        const dataWithType = Object.assign({ type: item.vehicle.type, vehicle: item.vehicle }, data);
        array.push(dataWithType);
      } else {
        const dataWithType = Object.assign({ type: item.product.category, health: item.health }, data);
        array.push(dataWithType);
      }
    });
    return array;
  }

  getProductName() {
    let productName = this.data.product.code;
    if (this.data.insurance.code === 'AXAAFFIN') {
      return productName.replace('SmartDrive Safe', 'Smart<i>Drive Safe</i>');
    }
    return productName;
  }

  getGeneralInformation(card, contentStatus, contentInformation) {
    const information = [
      { key: 'Status', value: getPolicyStatus({ content: contentStatus, status: card.status }) },
      { key: contentInformation.transactionCodeLabel, value: card.transaction.number },
      this.showTotalSumInsured({
        type: card.product.type,
        key: contentInformation.totalPremiumPriceLabel,
        value: helper().getFormatMoneyWithCurrencyCode(card.product.currency, card.product.totalPrice)
      }),
      {
        key: contentInformation.activePeriodLabel,
        value:
          moment(card.product.startedDate).format('DD MMM YYYY') +
          ' - ' +
          moment(card.product.expiredDate).format('DD MMM YYYY')
      }
    ];
    return information;
  }

  showTotalSumInsured({ type, key, value }) {
    if (type === 'CAR' || type === 'MOTOR') {
      return { key, value };
    }
    return {};
  }
}

export const drawProductCard = ({ product, type, contentStatus, contentInformation }) => {
  const drawCarProductCard = new ProductCard({ contentStatus, data: product, contentInformation });
  return drawCarProductCard[type];
};
