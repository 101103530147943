import { Context } from '@nuxt/types';

// NOTES: This middleware exists only to fulfill the requests of Lonpac
// other than that this middleware serves no other product or engineering purposes (that we know of)
export default async function (ctx: Context) {
  try {
    if (ctx.req.headers['cache-control'] === 'max-age=0') {
      ctx.res.setHeader('cache-control', 'no-cache; must-revalidate');
    }
  } catch {}
}
