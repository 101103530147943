import Cookies from 'js-cookie';
import qoalaStorage from '~/core/Storage';

export default () => ({
  callRefreshToken: null,
  userData: {},
  userToken: null,
  userTokenRefresh: Cookies.get('r') || null,
  userNotif: qoalaStorage.getItem('n'),
  userNotifs: qoalaStorage.getItem('l'),
  userPosition: qoalaStorage.getItem('position'),
  userUnread: true,
  tempRegister: qoalaStorage.getItem('tempReg'),
  uuid: qoalaStorage.getItem('uuid'),
  tempSign: {},
  profile: qoalaStorage.getItem('profile'),
});
