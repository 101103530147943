import qoalaStorage from '~/core/Storage';

export default () => ({
  transactionHistory: [],
  policyList: [],
  claims: {},
  smartphonePurchaseHistory: qoalaStorage.getItem('smartphonePurchaseHistory'),
  smartphoneBrand: qoalaStorage.getItem('smartphoneBrand'),
  smartphoneModel: qoalaStorage.getItem('smartphoneModel'),
  listProduct: JSON.parse(qoalaStorage.getItem('smartphoneProduct')),
  productDetail: JSON.parse(qoalaStorage.getItem('smartphoneProductDetail'))
});
