import message from './en-US';
import FaqUS from './faq-US';
import tncUS from './tnc-US';
import privacyNotice from './privacyNotice-US';
import privacyNoticeID from './privacyNotice-ID';
import errorUS from './error-US';
import baseLanguage from './airtable.json';

const english = {
  ...baseLanguage,
  ...message,
  ...FaqUS,
  ...tncUS,
  ...privacyNotice,
  ...privacyNoticeID,
  ...errorUS
};

export default english;
