import Vue from 'vue';
import MessageModal from '@/components/modal/Message.vue';
import ConfirmModal from '@/components//modal/confirmModal.vue';
import OptionModal from '@/components/modal/SelectOption.vue';
import SortModal from '@/components/modal/ProductListSort.vue';
import GuestModal from '@/components/modal/Guest.vue';
import GuestModalMalaysia from '@/components/modal/GuestMalaysia.vue';
import FilterModal from '@/components/modal/ProductListFilter.vue';

Vue.component('MessageModal', MessageModal);
Vue.component('ConfirmModal', ConfirmModal);
Vue.component('OptionModal', OptionModal);
Vue.component('SortModal', SortModal);
Vue.component('GuestModal', GuestModal);
Vue.component('GuestModalMalaysia', GuestModalMalaysia);
Vue.component('FilterModal', FilterModal);
