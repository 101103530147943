import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getHealthPurchaseHistory: (state) => {
    if (!state.healthPurchaseHistory) {
      return qoalaStorage.getItem('healthPurchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('healthPurchaseHistory'))
        : state.healthPurchaseHistory || {};
    }
    return state.healthPurchaseHistory;
  },
  getType: (state, ctx) => {
    if (!state.type) {
      const healthPayload = ctx.getPayload;
      return healthPayload?.products[0];
    }
    return state.type;
  },
  getCovered: (state) => {
    if (!state.covered) {
      return qoalaStorage.getItem('covered')
        ? parseIfJsonString(qoalaStorage.getItem('covered'))
        : state.covered || [];
    }
    return state.covered;
  },
  getSelfData: (state, ctx) => {
    if (!state.selfData) {
      const healthPayload = ctx.getPayload;
      return (
        healthPayload?.covereds?.find((user) => user.kind === 'SELF')?.datas ||
        []
      );
    }
    return state.selfData;
  },
  getSpouseData: (state, ctx) => {
    if (!state.spouseData) {
      const healthPayload = ctx.getPayload;
      return (
        healthPayload?.covereds?.find((user) => user.kind === 'SPOUSE')
          ?.datas || []
      );
    }
    return state.spouseData;
  },
  getSonData: (state, ctx) => {
    if (!state.sonData) {
      const healthPayload = ctx.getPayload;
      const childData =
        healthPayload?.covereds?.find((user) => user.kind === 'CHILD')?.datas ||
        [];
      return childData.filter((user) => user.gender === 'MALE');
    }
    return state.sonData;
  },
  getDaughterData: (state, ctx) => {
    if (!state.daughterData) {
      const healthPayload = ctx.getPayload;
      const childData =
        healthPayload?.covereds?.find((user) => user.kind === 'CHILD')?.datas ||
        [];
      return childData.filter((user) => user.gender === 'FEMALE');
    }
    return state.daughterData;
  },
  getListProduct: (state) => {
    if (!state.listProduct) {
      return qoalaStorage.getItem('healthProduct')
        ? parseIfJsonString(qoalaStorage.getItem('healthProduct'))
        : state.listProduct || [];
    }
    return state.listProduct || [];
  },
  getProductDetail: (state) => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('productDetailHealth')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailHealth'))
        : state.productDetail || {};
    }
    return state.productDetail;
  },
  getPayload: (state) => {
    if (!state.payload) {
      return qoalaStorage.getItem('healthPayload')
        ? parseIfJsonString(qoalaStorage.getItem('healthPayload'))
        : state.payload;
    }
    return state.payload;
  },
};
