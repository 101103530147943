// import { helper } from '@/plugins/helper.client';
class Transform {
  constructor({ response, data, typeVehicle }) {
    this.response = response;
    this.data = data;
    this.typeVehicle = typeVehicle;
  }

  get vehicleInfo() {
    const array = [];
    this.response.map(item => {
      const data = {
        label: item.label,
        value: item.value
      };
      array.push(data);
    });
    return array;
  }

  get vehiclePriceRange() {
    const item = this.response;
    let object = {
      brand: '',
      currency_code: '',
      highest_price: 0,
      lowest_price: 0,
      manufacturer: '',
      recommended_price: 0,
      series: '',
      year: 0
    };
    if (item) {
      object = {
        brand: item.brand,
        currency_code: item.currency_code,
        highest_price: item.highest_price,
        lowest_price: item.lowest_price,
        manufacturer: item.manufacturer,
        recommended_price: item.recommended_price,
        series: item.series,
        year: item.year
      };
      return object;
    }
    return object;
  }

  get productList() {
    const array = [];
    this.response.map(item => {
      const data = {
        addons: item.addons,
        admin_fee: item.admin_fee,
        category: item.category,
        currency_code: item.currency_code,
        detail: item.detail,
        hardcopy_admin_fee: item.hardcopy_admin_fee,
        how_to_claim: item.how_to_claim,
        insurance: item.insurance,
        insurance_code: item.insurance_code,
        insurance_detail: item.insurance_detail,
        insurance_product_code: item.insurance_product_code,
        insurer_name: item.insurer_name,
        is_highlighted: item.is_highlighted,
        is_support_commercial: item.is_support_commercial,
        is_non_api: item.is_non_api,
        logo: item.logo,
        name: item.name,
        premium: item.premium,
        product_code: item.product_code,
        protection_detail: item.protection_detail,
        summary: item.summary,
        qqid: item.qqid,
        vehicle_class: item.vehicle_class,
        discount_precentage: item.discount_precentage,
        discounted_premium: item.discounted_premium,
        vehicleType: item.vehicle_type,
        premium_discounted: item.premium_discounted,
        product_promo: item.product_promo,
        referal_cases: item.referal_cases,
        sum_insured: item.sum_insured,
        source_sum_insured: item.source_sum_insured,
        sum_insured_selection: item.sum_insured_selection,
        extra_info: item.extra_info,
        promo_code: item.promo_code,
      };
      array.push(data);
    });
    return array;
  }

  get documentsType() {
    const array = [];
    this.response.map(item => {
      const data = {
        description: item.description,
        extensions: item.extensions,
        name: item.name
      };
      array.push(data);
    });
    return array;
  }

  get documentPresignLink() {
    const item = this.response;
    let object = {
      data: {},
      message: '',
      status: ''
    };
    if (item) {
      object = {
        data: item.data,
        message: item.message,
        status: item.status
      };
      return object;
    }
    return object;
  }

  get policyList() {
    const array = [];
    this.response.map(item => {
      const data = {
        coveredUsers: item.coveredUsers,
        createdAt: item.createdAt,
        customer: item.customer,
        insurance: item.insurance,
        invoice: item.invoice,
        number: item.number,
        policyHolder: item.policyHolder,
        product: item.product,
        status: item.status,
        transaction: item.transaction,
        updatedAt: item.updatedAt,
        vehicle: item.vehicle
      };
      array.push(data);
    });
    return array;
  }
}

export const mapResponse = ({ type, response }) => {
  const transformResponse = new Transform({ response });
  return transformResponse[type];
};
