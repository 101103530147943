import { Context } from '@nuxt/types';
import { NextFunction } from 'express';

export default async function (ctx: Context): Promise<void> {
  ctx.app.router?.beforeEach(async (to: any, from: any, next: NextFunction) => {
    try {
      const { query } = to;
      const utmQueries = {};
      Object.keys(query).forEach((queryKey) => {
        if (queryKey.includes('utm_')) {
          utmQueries[queryKey] = query[queryKey];
        }
      });
      if (Object.keys(utmQueries).length) {
        window.localStorage.setItem('utmParams', JSON.stringify(utmQueries));
        ctx.store.commit('general/setUtmParams', utmQueries);
      }
    } catch {
      // NOTES: No need to do anything.
    }
    next();
  });
}
