<template>
  <div>
    <app-bar-navigation
      id="appbar"
      hide-navigation
      is-white
      title="Not Found"
      color="#2560ab"
    ></app-bar-navigation>
    <div class="full-page text-center">
      <div class="content-error">
        <img
          :src="errorImage(error.statusCode)"
          :alt="'image-error-' + error.statusCode"
          :title="'image error' + error.statusCode"
        />
        <q-spacer top="lg" />
        <q-text ink="midnight" bold variant="ui-baseline">
          Oh no!<br />
        </q-text>
        <q-text variant="ui-small">
          {{
            error.messageErr
              ? error.messageErr
              : `We couldn't find the page you are looking for`
          }}
        </q-text>
        <div class="footer">
          <q-spacer top="lg">
            <q-button round :color="`#fff`" @click="refresh">
              {{ error.statusCode === 404 ? 'Back To Homepage' : 'Reload' }}
            </q-button>
          </q-spacer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Spacer as QSpacer,
  Button as QButton,
  Text as QText,
  AppBarNavigation,
} from '@qoala/ui';
const connImage = 'https://assets.qoala.app/images/general/connection.png';
const errorImage = 'https://assets.qoala.app/images/general/error-image.png';

export default {
  components: {
    QSpacer,
    QButton,
    QText,
    AppBarNavigation,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  methods: {
    refresh() {
      if (navigator.onLine && this.error.statusCode !== 404) {
        window.location.reload(true);
      }
      if (this.error.statusCode === 404) {
        this.$router.push(this.localePath('index'));
      }
    },
    errorImage(errorCode) {
      if (errorCode === 503 || errorCode === 444) {
        return connImage;
      }
      return errorImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-error {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  padding: 0 15px;
  img {
    width: 30%;
  }
}

.volo-button.error {
  width: 60% !important;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 15px;
}
</style>
