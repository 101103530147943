import {
  TMalayHealthPrequoteData,
  TMalayHealthState,
  TMalayHealthProductList,
  TMalayHealthProduct,
  TMalayHealthPolicyHolderInfo,
  TQuotationData,
} from './types';
import qoalaStorage from '~/core/Storage';
import {
  MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY,
  MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY,
  MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY,
  MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY,
  MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY,
  MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY,
} from './constants';
import { TKeyValue } from '~/utils/common/types';

export default {
  setPurchaseInformation(
    state: TMalayHealthState,
    purchaseInfoPayload: TMalayHealthPrequoteData
  ) {
    state.prequoteData = {
      ...state.prequoteData,
      ...purchaseInfoPayload,
    };
    qoalaStorage.setItem(
      MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY,
      JSON.stringify({
        ...state.prequoteData,
        ...purchaseInfoPayload,
      })
    );
  },

  setProductList(
    state: TMalayHealthState,
    productList: TMalayHealthProductList
  ) {
    state.productList = productList;
    qoalaStorage.setItem(
      MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY,
      JSON.stringify(productList)
    );
  },

  setSelectedProduct(
    state: TMalayHealthState,
    selectedProduct: TMalayHealthProduct
  ) {
    state.selectedProduct = selectedProduct;
    qoalaStorage.setItem(
      MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY,
      JSON.stringify(selectedProduct)
    );
  },

  setPolicyHolderInformation(
    state: TMalayHealthState,
    policyHolderInfoPayload: TMalayHealthPolicyHolderInfo
  ) {
    state.policyHolderInformation = {
      ...state.policyHolderInformation,
      ...policyHolderInfoPayload,
    };
    qoalaStorage.setItem(
      MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY,
      JSON.stringify({
        ...state.policyHolderInformation,
        ...policyHolderInfoPayload,
      })
    );
  },

  setQuotationData(state: TMalayHealthState, quotationPayload: TQuotationData) {
    state.quotationData = quotationPayload;
    qoalaStorage.setItem(
      MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY,
      JSON.stringify(quotationPayload)
    );
  },

  setPriceBreakdown(
    state: TMalayHealthState,
    priceBreakdownPayload: TKeyValue<number>[]
  ) {
    state.priceBreakdown = priceBreakdownPayload;
    qoalaStorage.setItem(
      MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY,
      JSON.stringify(priceBreakdownPayload)
    );
  },

  clearAllData(state: TMalayHealthState) {
    qoalaStorage.removeItem(MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY);
    qoalaStorage.removeItem(MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY);
    qoalaStorage.removeItem(MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY);
    qoalaStorage.removeItem(MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY);
    qoalaStorage.removeItem(MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY);
    qoalaStorage.removeItem(MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY);

    state.prequoteData = undefined;
    state.productList = undefined;
    state.selectedProduct = undefined;
    state.policyHolderInformation = undefined;
    state.quotationData = undefined;
    state.priceBreakdown = undefined;
  },
};
