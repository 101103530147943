import capitalize from './capitalize';

function toSnakeCase(str) {
  if (typeof str !== 'string') return '';
  const afterSplit = str.toLowerCase().split(' ');
  let newStr = '';
  for (const [idx, val] of afterSplit.entries()) {
    if (idx > 0) {
      newStr += capitalize(val);
    } else {
      newStr += val;
    }
  }
  return newStr;
}
export default toSnakeCase;
