const messages = {
  welcome: 'Welcome',
  // general
  yes: 'Yes',
  no: 'No',
  thanks: 'Thanks!',
  sorry: `We're Sorry`,
  oops_sorry: 'Oops! Sorry',
  choose: 'Select',
  village: 'Subdistrict',
  phone_number: 'Phone Number',
  country_selection: 'Country Selection',
  country_select_label: 'Select your country',
  country_notfound: 'Country not found',
  country: 'Country',
  placeholder_name: 'e.g.: John Doe',
  placeholder_address: `e.g.: Scbd, Jl. Jend. Sudirman No.52-53, Senayan, Kby.Baru, Jakarta, Daerah Khusus Ibukota Jakarta`,
  placeholder_zip: 'e.g.: 12190',
  // Message
  message_addToHomeScreenTitle: 'Better Experience',
  message_addToHomeScreenDesc: 'For better experience, you can add our application to your homescreen',

  add_policy_option1: 'Train',
  add_policy_option2: 'Flight',
  add_policy_option3: 'Smartphone',
  add_policy_option4: 'Bus',
  add_policy_option5: 'Health',
  add_policy_option6: 'Electronic',
  add_policy_option7: 'Accommodation',
  add_policy_car: 'CAR',
  add_policy_motor: 'MOTOR',
  add_policy_health: 'HEALTH',
  add_policy_hcp: 'HCP',
  add_policy_life: 'LIFE',
  add_policy_tdp: 'TDP',
  add_policy_jdid_hcp: 'JDIDHealth',
  add_policy_ovo_hcp: 'OVOHealth',
  add_policy_momo_smartphone: 'MOMO',
  add_policy_bkk_hcp: 'BKKHealth',
  add_policy_traveloka_pa: 'TravelokaPA',
  add_policy_jdid_pa: 'JDIDHealthPA',
  add_policy_bike: 'Bike',
  add_policy_triasse_hcp: 'TriasseHCP',
  add_policy_xl_health: 'XLHealth',
  add_policy_viet_smartphone: 'VIET SMARTPHONE',
  add_policy_viettelpay_smartphone: 'ViettelPay',
  add_policy_traveloka_flight: 'TravelokaFlight',
  add_policy_motorvehicle: 'MotorVehicle',
  add_policy_property: 'Property',
  add_policy_personal_accident: 'personal-accident',

  //QoalaOnlineError
  invalidEmailFormat: `Email format must contain '@' (e.g: john@gmail.com).`,
  invalidYearCar: '{year} {brand} car is not found.',
  invalidYearMotor: '{year} {brand} motorcycle is not found.',
  invalidPlateNumber: 'The plate number is invalid, please try again and make sure you enter the right number.',
  image_invalid_size: 'The minimum photo resolution is 256x256 pixels. Please re-upload your photo.',
  ocr_no_result: 'Please only upload the picture of your KTP.',

  // Side Bar
  sidebar_profile: 'Profile',
  sidebar_about: 'About Us',
  sidebar_faq: 'FAQ',
  sidebar_tnc: 'Terms & Condition',
  sidebar_language: 'Language',
  sidebar_contactUs: 'Contact Us',
  sidebar_rateUs: 'Rate Us',
  // login
  login_register_q: 'New Account',
  login_register: 'Register',
  login_formtitle: 'Email',
  login_emailplaceholder: 'Registered Email',
  login_forgetPIN: 'Forgot PIN',
  login_PINtitle: 'PIN',
  login_PINplaceholder: 'Your PIN (6 digit numbers)',
  login_loginbutton: 'Login',
  cannot_login: 'Have problem with login?',
  login_validation: 'Please submit your {0}',
  login_hello: 'Hello!',
  login_phone: 'Login with Phone Number',
  login_email: 'Login with Email address',
  login_pin: 'Enter your PIN code',
  login_password: 'Enter your Password',
  login_switch: 'Login with ',
  phone_switch: 'Phone Number',
  login_forgot: 'Forgot your PIN?',
  login_forgot_password: 'Forgot your Password?',
  pick_country: 'Pick Country',
  search_country: 'Search',
  password_minimum: 'Password needs to be a minimum of 8 characters',
  password_lowercase: 'Password must contain lowercase letter',
  password_uppercase: 'Password must contain uppercase letter',
  password_number: 'Password must contain number',
  password_char: 'Password must contain special character',

  // forgot
  forgot_title: 'No Worries!',
  forget_phone: 'Enter your Phone Number to reset Password',
  forget_email: 'Enter your Email address to reset Password',
  forgot_change_title: 'New PIN Code',
  forgot_change_title2: 'Almost done!',
  forgot_change_desc: 'Enter your new 6 digit PIN code',
  forgot_change_desc2: 'Please confirm your new PIN code',
  resend_text: 'Resend OTP',
  otp_send: 'We have sent an OTP code to your Email / Phone Number, please enter the code below',

  // signup
  signup_newacc: 'Create Profile',
  signup_newAccount_title: 'Email',
  signup_newAccount_security: 'PIN Code',
  signup_newAccount_securityConfirm: 'Confirm PIN Code',
  signup_emailplaceholder: 'Email Address',
  signup_PINplaceholder: 'PIN (6 Digit Numbers)',
  signup_PINconfirmplaceholder: 'Retype Your PIN',
  signup_personalinfo: 'Personal Information',
  signup_fullnameplaceholder: 'Full Name',
  signup_fullnameplaceholder2: '(Based on your ID card)',
  signup_instantpayment: 'Easy claim compensation',
  signup_paymentdatatitle: 'Profile Details',
  signup_paymentdatadesc: 'We need your profile for register the insurance automatically.',
  signup_idnumberplaceholder: 'ID/Passport Number',
  signup_bankdatadesc: 'We need bank account to transfer the compensation automatically.',
  signup_bankplaceholder: 'Bank List',
  signup_accnumber: 'Account Number',
  signup_btnCreate: 'Create My Account',
  signup_step_bank_title: 'One more thing!',
  signup_step_bank_desc: 'Help us to speed up your claim payment by completing your bank information.',
  signup_step_bank_search: 'Click the search button and confirm your bank account.',
  signup_step_bank_forgot: 'I forget my bank account number. Later.',
  step_ktp_title: 'Yay! It’s Done!',
  step_ktp_content:
    'Thank you for your registration. You can make claim now. To speed up the process,' +
    'we ask you to complete your other personal information.',
  step_ktp_content2: 'Don’t forget to fill in your ID in the profile page, so we can verify your account.',
  step_ktp_button: 'OK',
  step_ktp_skip: 'Later',
  step_bank_title: 'Just Information',
  step_bank_content: 'With Qoala, you’ll get paid in 1 day. Don’t forget to fill in the detail of your bank account.',
  step_bank_button: 'OK',
  ktp_identity: 'ID Card',
  ktp_identity2: 'Personal ID',
  ktp_text: 'Follow this steps to upload your ID, make sure all of the ID is visible',
  passport_text: 'Follow this steps to upload your Passport',
  myKad_text: 'Follow this steps to upload your MyKad, make sure all of the ID is visible.',
  myKad_back_text: 'Back side of your MyKad',
  ktpselfie_text: 'Hold the ID below your chin',
  myKadselfie_text: 'Hold MyKad below your chin',
  ktp_photo_text: 'Upload',
  ktp_selfie_text: 'Upload Selfie',
  ktp_photo_retake: 'Reupload',
  ktp_selfie_retake: 'Reshoot Selfie',
  ktp_country: 'Choose Country',
  submit: 'Submit',
  okay: 'Okay!',
  next: 'Next',
  ktp_later: 'Skip',
  pin_not_same: 'PIN not same',
  signup_success: 'Congratulations! Successfully registered account',
  identity_verifying: 'We still verifying your identity',
  identity_info: `Please ensure your name and all information same as in the identity card.
    This is very important for processing your claim.`,
  signup_error_email: 'Please enter a valid email address',
  signup_error_pin: 'Please enter correct 6 digit PIN',
  signup_error_repin: 'Please enter same PIN in PIN confirmation',
  signup_error_name: 'Please enter full name at least 3 character',
  signup_error_phone: 'Please enter phone number at least 7 digit',
  pin_updated_text: 'PIN successfully updated',
  success_text: 'Success!',
  fail_text: 'Sorry',

  // Card Item
  cardItem_booking_number: 'Booking Code',
  cardItem_policy_number: 'Policy No.',
  cardItem_certif_number: 'Certificate No.',
  cardItem_policy_number_train: 'Transaction No.',
  cardItem_travel_agent: 'Travel Agent',
  cardItem_travel_insurance: 'Insurance',
  cardItem_airline: 'Airline',
  cardItem_covered_passenger: 'Covered Passengers',
  cardItem_claim_number: 'Claim Number',
  cardItem_claim_title: 'Claim Detail',
  cardItem_claim_upload: 'Upload Document',
  cardItem_claim_update: 'Submit Document',
  cardItem_claim_view: 'View Document',
  cardItem_claim_status_submission: 'Submit',
  cardItem_claim_status_reviewing: 'Reviewing',
  cardItem_claim_status_approved: 'Approved',
  cardItem_claim_status_paid: 'Paid',
  cardItem_claim_status_analyze: 'Analyzing',
  cardItem_claim_status_repair: 'Repair',
  cardItem_claim_status_replaced: 'Replaced',
  cardItem_claim_status_delivered: 'Delivery',
  cardItem_claim_status_pickup: 'Pick Up',
  cardItem_claim_rated: 'Thank you for using our services',
  cardItem_claim_appeal: 'We will resolve your problem immediately',
  cardItem_claim_appealed: 'You already submit appeal before',
  // List Product
  listProduct_addclaimbutton: 'Add Claim',
  listProduct_addclaimbutton_desc: 'Choose your claim',
  INITIATED: 'Successfully Uploaded',
  RESUBMIT_DOCUMENT: 'Re-upload Document',
  APPROVED: 'Your document is processed!',
  PRE_APPROVED: 'Waiting for Verification',
  PAID: 'Payment has been made',
  REJECTED: 'Invalid document, please re-upload',
  SOFT_REJECT: 'Invalid document, please re-upload',
  // Add Claim
  addClaim_select_passenger: 'Passenger',
  addClaim_please_select_passenger: 'Select Passenger',
  choose_text: 'Choose',
  addClaim_please_select_passenger_cancel: 'Cancel',
  cancellation_reason_title: 'Cancellation Reason',
  remove_title: 'Cancel',
  addClaim_photo: 'Reshoot Photo',
  addClaim_policySubmit: 'Send',
  addClaim_ktpTitle: 'ID Card',
  addClaim_ktpLabel: 'Kami membutuhkan data pribadimu untuk mendaftarkan asuransi secara otomatis.',
  addClaim_photoUpload: 'Upload Photo',
  addClaim_bankAccount: 'Bank Account',
  addClaim_bankAccountLabel: 'We require your bank detail so we can pay your claim.',
  addClaim_bankNameHolder: 'Bank Name',
  addClaim_bankSelectHolder: 'Choose Bank',
  addClaim_bankAccountHolder: 'Account Number',
  addClaim_locationAllow: 'Allow',
  photo_text_code: 'Photo',
  upload_doc_text: 'Upload Document',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  submit_claim_error_1: 'You can only claim if arrival delay more than 1 hour',
  submit_claim_error_2: 'Delay claim can only be submit if the flight delay more than 120 minutes',
  person_interest: 'Person of interest',
  person_baggage: 'Have Baggage?',
  policy_details: 'Policy Details',
  boarding_pass: 'Boarding Pass',
  boarding_pass_train: 'Upload Train Boarding Pass',
  boarding_pass_station: 'Upload Train station name with Boarding Pass',
  delay_report: 'Delay Report',
  baggage_text: 'Baggage',
  new_boarding_pass: 'New Boarding Pass',
  invoice_new_ticket: 'Invoice of New Boarding Pass',
  letter_ticket: 'Letter from Doctor / Hospital',
  family_register: 'Family Registry',
  death_cert: 'Death Certificate',
  letter_cancel: 'Letter of cancellation from KAI',
  claim_submit: 'Claim Submit',
  claim_submit_success: 'Claim submit success',
  claim_process_done_title: 'Claim Process is Done!',
  claim_process_done_how: "How's our services?",
  claim_success: 'Claim successful',
  claim_notRequired: 'Sorry, your claim has not met our requirement',
  claim_notPaid: 'Sorry, your own risk fee is overdue and your claim has been closed.',
  claim_appeal: 'For more info contact our Customer Service',
  identity_photo_error: 'Please add your identity card photo',
  selfie_photo_error: 'Please upload photo of your selfie with the ID card',
  choose_bank: 'Choose Bank',
  choose_bank_2: 'Please choose your bank',

  placeholder_bankinput: 'Fill in bank name',
  placeholder_bankname: 'Fill in account holder name',
  placeholder_bankMY: 'Fill in your account number',
  placeholder_bank: 'Fill in your account number and click search',

  bank_error: 'Please add your bank account',
  bank_error_2: 'Please ensure your bank account is right',
  affected_person_error: 'Please choose affected person',

  // claim smartphone
  title_repair: 'Select Repair Option',
  title_confirmation: 'Confirmation',
  chronology_placeholder: 'Tell us your chronology here...',
  chronology_placeholder_ex: 'Example: The cellphone slides and falls to the floor, causing to damage the screen.',
  full_protection: 'Full Protection',
  screen_protection: 'Screen Protection',
  // Detail Claim
  cardItem_claim_history: 'Claim History',
  cardItem_document_passenger: 'Dokumen untuk penumpang',
  cardItem_update_document: 'Submit Document',
  cardItem_pay_excess_fee: 'Pay Own Risk Fee',
  update_claim_text: 'Update Claim',
  update_claim_success: 'Claim has been updated',

  // Add Policy
  booking_code: 'Booking Code',
  imei: 'IMEI',
  policy_text: 'Policy',
  policy_error_1: 'Policy with Booking Code {0}\n Not found',
  policy_gadget_error_1: 'Policy with Booking Code {0}\n Not found',
  policy_gadget_success_1: 'Policy with Booking Code {0}\n is added to your account',
  policy_success_1: 'Policy with Booking Code {0}\n is added to your account',

  // tutorial
  start_qoala_exp: 'Start your experience with Qoala now',
  welcome_tutorial: 'Welcome!',
  start_tutorial: 'Start Journey Now!',
  s1_p1_tutorial: "Qoala's advance system will notify you whenever claim can be made.",
  s1_p2_tutorial: 'Hello!',
  s1_p3_tutorial: 'We know insurance can be complicated, we are here to simplify it!',
  s2_p1_tutorial: 'Simple & fast claim system!',
  s2_p2_tutorial: 'Easy Breezy',
  s2_p3_tutorial: 'Making a claim? Tell us what happened and we will do the rest for you!',
  s3_p1_tutorial: 'Instant payment!',
  s3_p2_tutorial: 'Instant Payment!',
  s3_p3_tutorial: 'Once your claim is approved, you will be paid within minutes!',
  // insert link within the text below?
  signup_tnccheckbox: 'I have read and agree ',
  signup_tnccheckbox2: 'By continuing, I agree with ',
  signup_tnccheckbox3: ' of Qoala',
  signup_tncpopuptitle: 'Terms & Condition',
  // tnc still not done
  signup_tncdesc: 'Pengguna harus....',
  signup_tncbutton: 'I have read and agree',
  signup_tnccancel: 'Saya tidak menyetujui',
  // forgetPIN
  forgetPIN_title: 'Forgot PIN',
  forgetPIN_helptext: 'Please enter your registered email',
  forgetPIN_emailfield: 'Email',
  forgetPIN_send: 'Send',
  forgetPIN_noaccount: 'Not registered?',
  forgetPIN_signup: 'Register',
  forgetPIN_confirmationtitle: 'Confirm',
  forgetPIN_confirmationmsg: 'We will send an OTP to change your PIN',
  forgetPIN_sendOTPbutton: 'Send OTP',
  forgetPIN_cancelbutton: 'Cancel',
  // resetPIN
  resetPIN_title: 'Lupa PIN Qoala',
  resetPIN_formtitle: 'Change PIN',
  resetPIN_newPIN: 'New PIN',
  resetPIN_confirmnewPIN: 'Confirm new PIN',
  resetPIN_PINplaceholder: 'PIN 6 digit',
  resetPIN_PINconfirmplaceholder: 'PIN 6 digit',
  resetPIN: 'Kami akan mengirimkan One Time Password (OTP) untuk menggantikan PIN Qoala kamu',
  resetPIN_sendNewPIN: 'Confirm',
  resetPIN_sendOTPbutton: 'Kirimkan OTP',
  resetPIN_cancelbutton: 'Cancel',
  // navbar
  navbar_home: 'Home',
  navbar_mypolicy: 'My Policy',
  navbar_addpolicy: 'Add Policy',
  navbar_chat: 'Contact Us',
  // sidebar
  /* sidebar_profile: 'Akun Saya',
    sidebar_about: 'Tentang Qoala',
    sidebar_faq: 'Bantuan',
    sidebar_tnc: 'Syarat dan Ketentuan', */
  // profile
  profile_title: 'My Profile',
  profile_edit: 'Edit Profile',
  profile_phonenum: 'Handphone',
  profile_email: 'Email',
  profile_PIN: 'PIN',
  profile_changecode: 'Change PIN',
  profile_ktp: 'KTP',
  profile_bankacc: 'Bank Account',
  profile_notverified: 'Not Verified',
  profile_processing: 'Processing',
  profile_verified: 'Verified',
  verif_title: 'Account Verification',
  verif_desc: 'We have sent an OTP code to your {0}, please put the code below.',
  // KTP
  ktp_fieldname: 'ID Number',
  ktp_tnctitle: 'Syarat & Ketentuan KTP',
  ktp_tncdesc: 'Setelah KTP',
  ktp_savebutton:
    'Jika ID disimpan, kamu tidak dapat mengubahnya kembali melalui aplikasi. Kontak kami di (021)-5509-524 untuk mengubah' +
    'informasi kamu.',
  // Emergency Contact
  emergency_title: 'Emergency Contact',
  emergency_relationList: ['Parent', 'Sibling', 'Partner', 'Relative', 'Etc'],
  placeholder_emergency_name: 'Name same as identity card',
  emergency_formTitle: 'Emergency Contact Information',
  emergency_detailTitle: 'Why is this important?',
  emergency_detailDesc:
    'People who registered in emergency contact can do the claim on behalf your name' +
    ' if the condition makes it impossible for you to make claim.',
  emergency_formRelation: 'Relationship',
  emergency_formName: 'Name',
  emergency_formHp: 'Phone Number',
  emergency_formEmail: 'Email',
  emergency_formFamilyCard: 'Family Register',
  emergency_button: 'Change Emergency Contact',
  // bankacc
  bankacc_title: 'Bank Account',
  bankacc_formtitle: 'My Account',
  bankacc_bankname: 'Bank Name',
  bankacc_placeholder: 'Choose Bank...',
  bankacc_accnumber: 'Account Number',
  bankacc_tnctitle: 'Terms & Condition',
  bankacc_tncdesc: `Bank account will be verified in 2 weeks.
    After it’s verified, we will start the process of money transfer.`,
  bankacc_savebutton: 'Save',
  // home
  home_upcoming: 'Trip',
  home_recent: 'Recent Purchased Policy',
  home_noflight: 'You don’t have any trip',
  home_addpolicybutton: 'Add Trip',
  home_myclaimstatus: 'Claim Status',
  home_noclaim: 'No claim has been made.',
  home_noclaimcont: 'View your policy. ',
  home_addclaimbutton: 'Add Claim',
  SO: 'On Time',
  SD: 'Delay',
  EO: 'On Time',
  ED: 'Delay',
  LO: 'On Time',
  LD: 'Delay',
  CL: 'Cancellation',
  DV: 'Diverted',
  UK: 'Unknown',
  // mypolicy
  mypolicy_activepolicy: ' My Policy',
  mypolicy_claims: 'Claims',
  mypolicy_addclaimbutton: 'Add Claim',
  // OTP Modal
  otp_desc: 'We will send an OTP to your email. Put the code below here. ',
  otp_resend: 'Resend OTP',
  otp_cancel: 'Cancel',
  // note: should we add a page before adding claim, saying TnC-like stuff?
  mypolicy_addclaimpopup: 'Add Claim',
  mypolicy_addclaimdesc:
    'Choose the claim based on your flight. If is more than one claim, you can add it on the next claim.',
  mypolicy_flightdelay: 'My flight got delayed',
  mypolicy_baggagedelay: 'My baggage got delayed',
  mypolicy_missedflight: 'I missed the next flight',
  mypolicy_flightcancellation: 'Tidak jadi pergi karena saya/kerabat sakit',
  mypolicy_personalaccident: 'Cacat/meninggal karena kecelakaan pesawat',
  mypolicy_cancelbutton: 'Cancel',
  mypolicy_nopolicypopuptitle: 'My Policy',
  mypolicy_nopolicypopupdesc: 'There is no policy, please add it first.',
  mypolicy_nopolicypopupbutton: 'OK',
  add_homescreen_text: 'Add to homescreen for easy access',
  add_text: 'ADD',
  homescreen_notif_text: 'Add to homescreen for easy access and get the latest info through the notification',
  upload_ktp_text: 'Upload your ID photo for faster claim process',
  bank_add_text: 'Add your bank account for faster payment process',
  beneficiary_text: 'Have you register your emergency contact?',
  product_text: 'Our Products',
  // policydetail
  policydetail_listpassenger: 'Passenger List',
  policydetail: 'Policy Detail',
  // listProduct_addclaimbutton: 'Pengajuan Klaim',
  // listProduct_addclaimbutton_desc: 'Silahkan pilih klaim yang ingin anda ajukan',
  // benefit name
  'Flight Delay': 'Flight Delay',
  'Train Delay': 'Train Arrival Delay',
  'Baggage Delay': 'Baggage Delay',
  'Missed Flight': 'Missed Flight Connection',
  'Flight Cancellation': 'Flight Cancellation',
  'Train Cancellation': 'Train Cancellation',
  'Personal Accident': 'Personal Accident',
  'Flight Delay_detail': 'You can make a claim for delayed flight start from 2 hours! ',
  'Train Delay_detail':
    'You can submit your claim when your train got arrival delayed more than 1 hour to its destination',
  'Baggage Delay_detail': 'You can make a claim if your baggage got delayed for 1,5 hours from the schedule!',
  'Missed Flight_detail': 'You can make a claim if you missed your next flight because your first flight got delayed!',
  'Flight Cancellation_detail':
    'Protect you from cancellation because of sick or when your family member is passed away.',
  'Train Cancellation_detail': `Protect you when you canceled your train trip because of sick or mourning.
    (Cancellation because change of plan will makes you get 25% compensation from Qoala - you will get the 75% from KAI)`,
  'Personal Accident_detail':
    'If you’ve got an unfortunate event that leads to the loss of life or make you handicapped,' +
    ' you can get the full benefit of this protection.',
  'Screen Protection - 6 Months_detail': 'Covers any types of damage to the gadget screen and LCD',
  'Screen Protection - 12 Months_detail': 'Covers any types of damage to the gadget screen and LCD',
  'Full Protection - 6 Months_detail': 'Covers any accidental or liquid of damage to the gadget (excluding theft)',
  'Full Protection - 12 Months_detail': 'Covers any accidental or liquid of damage to the gadget (excluding theft)',
  'Accidental Death_detail':
    'Protect you from the worst thing that could happen (death) whenever an accident occur during the trip, with coverage up to RM 25,000',
  'Partial and Permanent Disability_detail':
    'Protect you whenever an accident occur during the trip and causes you to lost a ' +
    'body function, total or partial with coverage up to RM 25,000',
  'Medical Expense Reimbursement_detail':
    'Coverage up to RM 2,000 for your medical expense, if something happens during the trip and causes you to get medical help',
  'Loss or Damage of Luggage_detail':
    'Protect you whenever your luggage was lost during the trip, with coverage up to RM 500 (electronics and jewelry are not covered)',
  undefined_detail: '',
  short_benefit: {
    'Flight Delay': 'Delayed',
    'Train Delay': 'Delayed',
    'Baggage Delay': 'Baggage',
    'Missed Flight': 'Missed',
    'Flight Cancellation': 'Cancellation',
    'Train Cancellation': 'Cancellation',
    'Personal Accident': 'Accident'
  },
  // claimdetail
  claimdetail: 'Claim Detail',
  adult_title: 'Adult',
  // Policy Page
  tabbar_purchase: 'My Policy',
  tabbar_claimstatus: 'Claim Status',
  // Policy Detail
  coverage_title: 'Total Coverage in IDR',
  coverage_title_redbus: 'Total Coverage in RM',
  coverage_title_train: 'Refund up to',
  coverage_title_train_ext: 'from your ticket price',
  delay_time: 'Delay Time (in hours)',
  pdf_title: 'See Document',
  makeclaim_title: 'Make Claim',
  // Search Policy
  addPolicy_title: 'Add Policy',
  addPolicy_pnr: 'Booking Code',
  duplicate_bookingCode: 'The policy with the booking code is already registered',
  descAddPolicyTrain_title: 'Where can i find it?',
  descAddPolicyTrain: 'Booking code is alphanumeric character in your boarding pass.',
  descAddPolicy_title: 'Where can i find it?',
  descAddPolicy:
    'Booking Code is alphanumeric character that referred as  Booking Code or Booking Reference in your boarding pass.',
  descAddPolicyHealth: 'Policy number is a number that writen on your health insurance policy.',
  descAddPolicy_imei: 'IMEI is an unique code that registered on your smartphone.',
  strongDescAddPolicy: 'alphanumeric character that referred as  Booking Code or Booking Reference.',
  charDescAddPolicy: 'Starting from B, C, D, E, F, G, H, I, J, K or L.',
  // claims
  claims_flightdelay: 'Your flight got delayed',
  // claims_underreview: 'Our support team is reviewing your claim',
  claims_userdatapending: 'Please complete your ID and Bank information',
  claims_claimsubmitted: 'Claim has been made, please wait 1x24 hour for the transfer process',
  claims_insufficientdocs: 'Please upload your document',
  claims_underreview: 'Our support team is reviewing your document',
  addClaim_delayTime: 'Delay Time',
  addMorePassanger: 'Add More Passenger',
  claims_listFlightCancelation: [
    {
      value: 'ACCIDENT',
      text: 'Accident'
    },
    {
      value: 'CRITICAL_ILLNESS',
      text: 'Critically Illness'
    },
    {
      value: 'DEATH_OF_FAMILY',
      text: 'Death of a Family Member'
    }
  ],
  claims_listTrainCancelation: [
    {
      value: 'ACCIDENT',
      text: 'Accident'
    },
    {
      value: 'CRITICAL_ILLNESS',
      text: 'Critically Illness'
    },
    {
      value: 'DEATH_OF_FAMILY',
      text: 'Death of a Family Member'
    },
    {
      value: 'CHANGE_OF_MIND',
      text: 'Change of Mind'
    }
  ],
  affected_relationList: ['Parent', 'Sibling', 'Partner', 'Relative'],
  // Accounts
  account_otp_head: 'Input OTP',
  account_topbar_profile: 'My Profile',
  account_pin_change: 'Change PIN',
  account_verify: 'Verify',
  account_edit_upload: 'Upload Photo',
  account_edit_title: 'Edit',
  account_phone_number: 'Phone Number',
  account_email: 'Email',
  account_verified: 'Verified',
  account_checking: 'Checking',
  account_logout: 'Log Out',
  account_ktp_number: 'ID Number',
  account_ktp_upload: 'Upload ID Card',
  account_ktp_tnc: 'ID Terms & Condition',
  account_select_bank: 'Select Bank',
  account_bank_name: 'Bank name',
  account_bank_number: 'Bank Number',
  account_bank_change: 'Change Bank',
  account_bank_tnc: 'Bank Account Terms & Condition',
  account_emergency_contact: 'Emergency Contact',
  account_button_save: 'Save',
  account_button_cancel: 'Cancel',
  account_button_logout: 'Log Out',
  account_confimation_message: 'We will send an OTP to your email for changing your PIN.',
  account_confimation_message2: 'We will send an OTP to ',
  account_confimation: 'Confirmation',
  account_confimation_ok: 'Ok',
  account_confimation_cancel: 'Cancel',
  account_reset_pass: 'Reaet PIN',
  account_reset_pass_enter: 'Enter New PIN',
  account_reset_pass_reenter: 'New PIN Confirmation',
  account_status_verified: 'Verified',
  account_status_processing: 'On Process',
  account_status_notVerified: 'Not Verified',
  account_update: 'Profile Update',
  account_update_success: 'Update Success',
  account_bank_explain: {
    tittle: 'Why is this Important',
    content: [
      '- To automatically transfer your claim compensation, we need your bank account information.',
      '- You can’t change your bank account information once we verified it.'
    ],
    footer:
      'But you can reach out Qoala’s Customer Service through live chat menu or send an email to cs@qoala.id' +
      'and ask them to change it.'
  },
  account_identity_explain: {
    tittle: 'Why is this Important',
    content: [
      '- To speed up the claim process, we need your ID information.',
      '- You can’t change your ID information once we verified it..'
    ],
    footer:
      'But you can reach out Qoala’s Customer Service through live chat menu or send an email to cs@qoala.id' +
      'and ask them to change it'
  },
  // Confirm Modal
  confirm_title_header: 'OTP Confirmation',
  confirmation_confirm: 'Confirm',
  confirmation_cancel: 'Cancel',
  // flight -- reusable for home, mypolicy, and claims
  flight_policynumber: 'Policy No.',
  flight_claimnumber: 'Claim No.',
  flight_flightnumber: 'Flight Number',
  flight_bookingnumber: 'Booking Code',
  // chat
  chat_title: 'Contact Us',
  chat_desc: 'Halo, how can we help you ?',
  // Contact US Page
  contact_us_support: 'How can we help you?',
  liveChat_title: 'Chat',
  // liveChat_subTitle: "Our team ready to help you from Monday - Friday (09:00 - 18:00 WIB)",
  // liveChat_subTitle: "Our team is ready to help you within the operational hours",
  liveChat_subTitle: '',

  telephone_title: 'Telepon',
  telephone_subTitle: 'Live Chat',

  email_title: 'Email',
  email_subTitle: 'cs@qoala.id',

  faster_response: 'Fast Respond',

  workingHour_title: 'Operational Hour ',
  workingHour_title_week: 'From 07:00 - 23:00 WIB on weekday',
  workingHour: '07:00 - 23:00',
  workingHour_title_sat: 'From 06:00 - 12:00 WIB on Saturday',
  workingHour_title_sun: 'From 18:00 - 23:00 WIB on Sunday',
  workingHour_warning_week:
    'We are currently off right now. ' +
    'Please contact us again during operational hour ' +
    'Monday - Friday from 07:00 - 23:00 WIB (Except National Holiday), ' +
    'or send an email to  : cs@qoala.co.id\nThank you',
  workingHour_warning_sat:
    'We are currently off right now. ' +
    'Please contact us again during operational hour ' +
    'On Saturday from 06.00 - 12.00 WIB (Except National Holiday), ' +
    'or send an email to  : cs@qoala.co.id\nThank you',
  workingHour_warning_sun:
    'We are currently off right now. ' +
    'Please contact us again during operational hour ' +
    'On Sunday from 18.00 - 23.00 WIB (Except National Holiday), ' +
    'or send an email to  : cs@qoala.co.id\nThank you',

  // TOC
  toc_titlePage: 'Terms and Condition',

  // RATING MODAL
  rating_title: 'What do you think about Qoala?',
  rating_subtitle: 'Rate and give us your feedback to improve Qoala.',
  rating_placeholder: 'Write your feedback here…',
  rating_choose: 'Choose Category',
  rating_item: ['Function', 'Aesthetic', 'Information', 'Flow', 'Language', 'Other'],

  // ERROR DEFAULT
  error_nopolicy: 'No Policy',
  error_nopolicydesc: 'Please register your policy first by clicking “Add Policy” button',
  error_noclaim: 'No Claim',
  error_noclaimdesc: 'To submit claim, please register your policy first by clicking “Add Policy” button',
  error_nolocation: 'Oops, something wrong when getting your location',
  error_geolocation: 'User denied the request for Geolocation.',

  resubmit_document: 'Re-upload the documents',
  rejected: 'Claim Rejected',
  approved: 'Claim Approved!',
  qoala_approved: 'Document Verified!',
  pre_approved: 'Waiting for verification from CS',
  paid: 'Claim has been Paid!',
  initiated: 'The Data Has Been Successfully Upload',
  soft_reject: 'Has been Claimed',
  //  LOCATION ACCESS MESSAGE
  location_title: 'Location Permission',
  location_error: 'Error Get Location',
  location_needed: 'To submit the claim, we need your location, please allow your device to share your location now.',
  location_error_tool: 'Sorry, your device not supporting for location.',
  location_error_restart: 'Sorry, we cannot get your location. Please try again.',
  //  MODAL MESSAGE
  modal_message_error: 'Error!',
  modal_message_pnrprob: 'Booking Code must be 6 code of alpha-numeric',
  modal_needAllowLocation: 'Sorry, for claim {0}, your location permission is needed.',
  getting_location: 'Getting your location',
  please_wait: 'to resend OTP',
  default_selectModal: 'Please Choose',
  dont_know: 'I dont know how',
  account_notFound: 'Account not found',
  enter_bankNumber: 'Please enter your bank account number',
  enter_bank: 'Please enter choose your bank',
  an_unknown: 'An unknown error occurred.',

  error_browser: 'This is not supported by your browser',
  submit_document_error: 'Please upload all document',
  // notification
  notif_title: 'Notification',
  'notif-register-policy-message-':
    'Your Policy {0} successfully registered, Please visit www.qoala.app to see the policy.',
  'notif-register-new-policy-': 'Policy {0} successfully added',
  'notif-registration-otp-': 'Your OTP Code: {0}',
  'email-registration-otp-': 'Your OTP Code: {0}',
  'notif-registration-otp-message-':
    'Your OTP Code: {0}.' + ' Enter this code to completing the registration. Please use the OTP code before {1}',
  'notif-reset-pin-': 'Your OTP Code: {0}',
  'email-reset-pin-': 'Your OTP Code: {0}',
  'notif-reset-pin-message-':
    'Your OTP Code: {0}.' + ' Enter this code to completing the registration. Please use the OTP code before {1}',
  'email-register-new-policy-': 'Policy {0} successfully added',
  'email-register-new-flight-policy-': 'Your flight with Booking code {0} has been protected by Qoala',
  'email-resend-policy-title-': 'Your flight with Booking code {0} has been protected by Qoala',
  'email-register-policy-': 'Policy {0} successfully added',
  'email-register-flight-policy-': 'Your flight with Booking code {0} has been protected by Qoala',
  'notif-register-policy': 'Policy successfully added!',
  'email-claim-history-pre-approved-description': 'ID and Bank Account is being verified',
  'notif-claim-history-soft-reject-title': 'Claim have been processed',
  'email-claim-history-soft-reject-title-': 'Claim {0} have been processed',
  'notif-claim-history-soft-reject-description-':
    'Claim successfully processed by your peer passanger, {0}.' + ' Please contact {0} for further information',
  'notif-claim-history-initiated-title': 'Claim is being checked',
  'notif-claim-history-initiated-message-': 'Your Claim {0} is being checked. Thank you for being patient!',
  'email-claim-history-initiated-title-': 'Claim {0} is being checked',
  'notif-claim-history-resubmit-document-title': 'Oops! You forget this document!',
  'notif-claim-history-resubmit-document-message-': 'Get claim {0} with uploading this claim document.',
  'email-claim-history-resubmit-document-title-': 'Seems like you forget upoad document for claim {0}',
  'notif-claim-history-pre-approved-title': 'ID and Bank Account is being verified',
  'notif-claim-history-pre-approved-message': 'ID and Bank Account is being verified for claim process',
  'email-claim-history-pre-approved-title': 'ID and Bank Account is being verified',
  'notif-claim-history-approved-title': 'Claim success!',
  'notif-claim-history-approved-message': 'Yay! please wait the claim money send to your bank account',
  'email-claim-history-approved-title-': 'Claim {0} have been approved',
  'notif-claim-history-rejected-title-': 'Claim {claimNumber} not success',
  'notif-claim-history-rejected-message': 'Your claim is rejected because it is not fulfill the policy requirement',
  'email-claim-history-rejected-title-': 'Claim {0} not success',
  'notif-claim-history-paid-title': 'Your claim have been paid!',
  'notif-claim-history-paid-message-':
    'Congratulation! Claim {0} have been paid to your bank account.' + ' Please check your bank account.',
  'email-claim-history-paid-title': 'Claim have been paid!',
  'email-approve-bank-title': 'Account Bank have been verified',
  'notif-approve-bank-title': 'Account Bank have been verified',
  'notif-approve-bank-message': 'Thank you! Your Account Bank have been verified.',
  'email-reject-bank-info-title': 'Account Bank failed to be verified',
  'notif-reject-bank-info-title': 'Account Bank failed to be verified',
  'notif-reject-bank-info-message':
    'Are you sure bank account name is right?' + ' Please check again and re-enter your bank account.',
  'email-reject-bank-invalid-title': 'Account Bank failed to be verified',
  'notif-reject-bank-invalid-title': 'Account Bank failed to be verified',
  'notif-reject-bank-invalid-message':
    'Are you sure bank account name is right?' + ' Please check again and re-enter your bank account.',
  'email-approve-ktp-title': 'ID have been verified',
  'notif-approve-ktp-title': 'ID have been verified',
  'notif-approve-ktp-message': 'Thank you! your Account Bank have been verified.',
  'email-reject-ktp-not-same-title': 'ID verification is failed',
  'notif-reject-ktp-not-same-title': 'ID verification is failed',
  'notif-reject-ktp-not-same-message': 'Oops! ID photo not match with registration data.',
  'email-reject-ktp-blur-title': 'ID verification is failed',
  'notif-reject-ktp-blur-title': 'ID verification is failed',
  'notif-reject-ktp-blur-message': 'Oops! Cannot read information from your ID photo. Please upload again.',
  'email-claim-flight-delay-title': 'Claim {0} is waiting for you!',
  'notif-claim-flight-delay-title': 'Oo-ow! your flight got delayed. We understand your feeling',
  'notif-claim-flight-delay-message-': 'It means you can get claim {0}. Just need Boarding Pass!',
  'notif-flight-delay-title': 'Did your flight got delayed?',
  'notif-flight-delay-message': 'If yes, submit claim now! Just need Boarding Pass! Upload Boarding Pass photo',
  'email-manual-flight-delay-title': 'Did your flight got delayed?',
  'email-flight-title-': 'How is your flight from {0} to {1}?',
  'notif-flight-title-': 'How is your flight from {0} to {1}?',
  'notif-flight-message': 'We wish you happy flight. If you have trouble, submit your claim or contact us.',
  'notif-auto-claim-message':
    'You can claim with the delay happened,' + ' Please upload your boarding pass to do the claim.',
  'notif-auto-verif-identity-title': 'Congratulations, your ID is successfully verified',
  'notif-auto-verif-identity-message': 'Your ID have been successfully verified by Qoala',
  'phone-claim-approved-notif-title-': 'Claim number {0} has been approved!',
  'phone-claim-approved-notif-message': 'Your handphone will be repaired and completed within 7 working days.',
  'phone-claim-approved-with-additional-notif-title-': 'Claim number {0} has been approved!',
  'phone-claim-approved-with-additional-notif-message': `Please pay the additional repair cost directly to the service
    center when you are picking up the phone. Reparation will be done within 7 working days.`,
  'phone-claim-replaced-notif-title': 'New phone for you!',
  'phone-claim-replaced-notif-message': 'Your new phone will be sent to your registered address within 7 working days.',
  'phone-claim-pick-up-notif-title': 'Your phone has been successfully repaired!',
  'phone-claim-pick-up-notif-message': 'You can now pick up your phone at the service center.',
  'phone-claim-done-notif-title': 'Your phone claim has been completed!',
  'phone-claim-done-notif-message':
    'Thank you for using Qoala. If you have any issues please contact our customer service.',
  'phone-claim-done-replaced-notif-title': 'Your new phone has arrived!',
  'phone-claim-done-replaced-notif-message':
    'Thank you for using Qoala. If you have any issues please contact our customer service.',
  'phone-claim-rejected-notif-title': 'Your claim cannot be processed.',
  'phone-claim-rejected-notif-message': 'For more information, please contact our customer service.',
  'phone-claim-approved-notif-title-repairer-': 'Claim number {0} has been approved!',
  'phone-claim-approved-notif-message-repairer': 'You can now start the repair process.',
  'phone-claim-pick-up-notif-title-repairer-': 'Claim number {0} has been completed!',
  'phone-claim-pick-up-notif-message-repairer': 'Phone owner will pick up the phone at your service center.',
  'phone-claim-replaced-notif-title-repairer-': 'Phone replacement for claim number {0}!',
  'phone-claim-replaced-reapirer-notif-message':
    'Please do not proceed with the repair. Qoala will send a replacement phone to the user.',
  'phone-claim-initiated-notif-title': 'Your claim is received by Qoala',
  'phone-claim-initiated-notif-message': 'Please bring your phone to the nearest service center.',
  go_button: 'Go',
  lul: 'xaxaxa {0}, xixixixi {1}',

  // gadget
  gadget_makeclaim: 'I want to make claim for,',
  gadget_canselect: 'Can select more than one',
  gadget_confirm: 'Please confirm contact detail',
  gadget_upload: 'Upload The Required Documents',
  gadget_upload_desc: 'Take 5-seconds video of your cracked phone screen',
  gadget_reupload_desc: 'Re-upload Document',
  gadget_see_sample: 'See sample video',
  gadget_readagree: 'I have read & agreed on ',
  gadget_deliver: 'Please Deliver Phone to',
  gadget_show_loc: 'Far from your place? See our list of partners ',
  gadget_show_here: 'here',
  gadget_pickup: 'Please pick up phone at',
  confirm_payment: 'Confirm Payment',
  gadget_pay: 'Please pay the own risk fee of',
  gadget_pay2: 'to',
  gadget_pay3: 'before',
  gadget_pay4: 'to continue your claim process.',
  gadget_haveissue: 'Have Issue?',
  gadget_claimsuccess: 'Claim successful',
  gadget_uploading_video: 'Uploading video',
  gadget_success_video: 'Video upload success!',
  gadget_error_imei: 'IMEI does not exist',
  gadget_uploadvideo: 'Upload Video',
  gadget_submitting: 'Submitting Claim',
  gadget_remain: 'Remaining Amount',
  gadget_of: 'of',
  gadget_chronology_limit: 'Minimum 50 characters',

  gadget_info_imei: 'IMEI No.',
  gadget_info_bought: 'Bought From',
  expired_in: 'Expired in',

  product_gadget_screen: 'Screen Cracked',
  product_gadget_camera: 'Camera',
  product_gadget_audio: 'Audio',
  product_gadget_liquid: 'Liquid Damage',
  product_gadget_cantturnon: "Screen can't turn on",
  product_gadget_other: 'Other',

  status_pickup: 'Please Pick Up Your Phone',
  status_recieved_btn: 'Phone Received',
  status_otw: 'Your new phone is on its way!',
  status_replaced: 'Replacement Phone Approved!',
  status_onprogress: 'Your Handphone is Being Repaired',
  status_estimate: 'Insurance Review',
  status_received: 'Service center analyzing device',
  status_done: 'Claim Finished!',
  status_done_rejected: 'Claim Finished!',
  status_done_replaced: 'Claim Finished!',
  status_acknowledge: 'Acknowledge',
  status_acknowledge_bus: 'Document submitted',
  status_initiated_phone: 'Payment Complete',
  status_waiting_payment: 'Waiting for Payment',
  status_courier_start: 'Courier will pick up your phone',
  status_walkin_start: 'Bring phone to the closest service center',
  status_courier_finish: 'Phone will be delivered to your address',
  status_walkin_finish: 'Gadget waiting for pickup',
  status_replace_walkin: 'Device received',
  status_done_repair: 'Done Repair',
  status_payment_not_complete: 'Claim Closed',
  status_courier_picked_up: 'Courier Delivering Phone.',
  status_card_claim_label: 'Claim Process',
  status_insurance_approved: 'Claim Approved!',
  status_insurance_rejected: 'Claim Denied',
  status_insurance_replaced: 'Replacement handphone approved',
  status_insurance_ask_detail: 'Need more document',

  select_repair_opt: 'Select Repair Options',
  pickup_intro: 'Pick-up & Delivery Service',
  walkin_intro: 'Repair center',
  walkin_service: 'Walk in Service',
  pickup_intro2:
    'No hassle! We will pick-up your damaged phone and then deliver it back to your address once it has been repaired.',
  walkin_intro2: 'Visit any of our closest Service Center partners and they will help you with the repair!',
  pickup_day: 'Pick-up Day',
  pickup_time: 'Select Pick-up Time',
  pickup_detail: 'Pick-up Details',
  deliver_detail: 'Delivery Details',
  question_deliver: 'Do you want us to deliver your phone to the same pick-up address?',
  recipient_name: 'Recipient name',
  recipient_pickup: 'Contact Name',
  active_number: 'Active number',
  address: 'Address',
  province: 'Province',
  city: 'City',
  district: 'District',
  zipcode: 'Zip code',
  repair_options: 'Repair options',
  contact_number: 'Contact number',
  noiwant: 'No, I want to be delivered to the other address',
  pickup_time_desc: 'Pick-up Time',
  pickup_address: 'Pick-up Address',
  pay_del_title: 'Pick Up & Delivery',
  pay_del_content: 'Sit tight! We are on our way to pick up your gadget.',
  pay_walk_title: 'Walk In',
  pay_walk_content: "Please make your way to the nearest repair center. We'll take care of the rest.",
  pay_done_title: 'Claim Submitted!',
  pay_done_content: 'Your claim is on analyzing by Qoala, please check your email shortly for more update.',
  pay_button: 'Got it!',

  // redBus
  departure_from: 'Departure From',
  arrival_at: 'Arrivals At',
  bus_number: 'Bus No.',
  view_detail: 'View trip details',
  close_detail: 'Close trip details',
  take_pic_doc: 'Take a picture of the bus ticket',
  input_amount: 'Input the amount',
  upload_desc_circum: 'Upload a video explaining the circumstances of the accident',
  upload_desc_circum_nextkin: 'Next of Kin: Please upload a video explaining the circumstances of the accident',
  upload_desc: 'Please explain the injury from the accident',
  upload_desc_nextkin: 'Next of Kin: Please explain the injury that causes the death',
  upload_receipt: 'Upload medical receipt/bill',
  bus_uploadvideo: 'Add Document + ',
  policy_active_claim: 'This policy already has active claim!',
  takaful_tnc: 'Takaful Ikhlas Terms and Condition',
  tncTakaful1:
    'ANY COMMUNICATION YOU RECEIVE ABOUT THE ALLEGED CLAIM SHOULD NOT BE ANSWERED BUT SENT TO US IMMEDIATELY.',
  tncTakaful2: ' Declaration, ',
  tncTakaful3: 'I declare that these particulars are true to the best of my knowledge and belief.',
  tncTakaful4: 'I hereby authorise the release of any medical information necessary for this claim.',
  upload_picture: 'Upload an image of the required document',
  upload_picture_front: 'Upload the next of kin information, can be NRIC/Birth Certificate/Married Certificate',
  upload_picture_back: 'Upload the next of kin information, can be NRIC/Birth Certificate/Married Certificate',
  required_files: 'Required files',
  police_report: 'Copy of Police Report',
  police_report_detail: 'A copy of police report regarding to the accident',
  death_certif: 'Death Certificate',
  death_certif_detail: 'An official document that gives information about a person’s death',
  burial_permit: 'Burial Permit',
  burial_permit_detail:
    'Burial Permit is an official statement authorising the removal of the dead body (corpse) to the cemetery.',
  copy_nric: 'Copy of NRIC',
  copy_nric_certif: 'Copy NRIC/Certificate of Next Kin',
  copy_nric_certif_detail: 'Please upload the chosen file of next kin',
  letter_conf_bus: 'Letter Confirmation from Bus Operator',
  letter_conf: 'Letter Confirmation',
  letter_conf_detail: 'The letter confirmation from the bus operator',
  optional_files: 'Optional files',
  medical_report: 'Medical Report',
  medical_report_detail: 'The medical report is given to be filled by the doctor',
  purchase_receipts: 'Purchase Receipts',
  purchase_receipts_detail: 'The purchase receipts / bill from the medical expenses',
  doc_name: 'Doctor Name',
  doc_address: 'Doctor Address',
  injured_person_info: 'Injured Person Information',
  fill_injured_details: 'Please fill the details about the injured person from the accident',
  emergency_formAge: 'Age',
  emergency_formJob: 'Job',
  accidental_details: 'Accidental Details',
  fill_accident_details: 'Please fill all the details related to the accident',
  fill_medical_details: 'Please fill all the details related to the taken medical expense',
  fill_loss_details: 'Please fill all the details related to the loss',
  medical_expense: 'Medical Expense',
  partial_disab: 'Partial and Permanent Disability',
  loss_luggage: 'Loss or Damage of Luggage',
  date: 'Date',
  time: 'Time',
  place: 'Place',
  did_you_consult:
    'Did you consult a doctor or have medicine prescribed prior to commencement of your holiday/journey?',
  date_reported: 'Date Reported',
  report_to: 'Report to',
  unable_to_attend: 'Is the injured patient totally unable to attend to any portion of his/her business?',
  please_give_details: 'Please give details',
  totally: 'Temporary Total Disablement',
  partially: 'Temporary Partial Disablement',
  partially_tooltip:
    'Temporary partial disability of the Person Covered which temporarily prevents the ' +
    'Person Covered from attending, engaging in or giving attention to a substantial part of his business or occupation but ' +
    'which shall not render him temporarily totally disabled. ' +
    'The benefit payable is weekly compensation of an amount as specified ' +
    'in the Schedule up to the stated maximum number of weeks from the date the Person Covered ' +
    'was first examined by a duly qualified Medical Practitioner.',
  totally_tooltip:
    'Temporary total disability of the Person Covered which temporarily prevents the ' +
    'Person Covered from attending, engaging in or giving attention to his business or occupation of any kind. ' +
    'The benefit payable is weekly compensation of an amount as specified ' +
    'in the Schedule up to the stated maximum number of weeks from the date the Person Covered ' +
    'was first examined by a duly qualified Medical Practitioner.',
  from: 'From',
  to: 'To',
  usual_doctor: 'Is he/she the injured person’s usual doctor?',
  fill_lost_items: 'Fill the lost items which you claim',
  item_name: 'Item Name',
  purchased_price: 'Purchased Price',
  claimed_amount: 'Claimed Amount',
  add_more: '+ Add More',
  other_insurance:
    'Is the injured person claiming under any other insurance or receiving compensation from other sources?',
  add_medical_expense: 'Add Medical Expense',
  is_there_consultation:
    'Is there a consultation with a doctor or have medicine prescribed prior to commencement of the journey?',
  report_by_doctor: 'Report must be filled by Doctor who attend the injured person. Please download the form',
  proof_nric: 'The documents given are to prove the relation between the passenger and the person whom do the claim',
  here: 'here',
  please_send: 'Please send the hard copy of the required documents to',
  how_upload_title: 'How to record the video!',
  how_upload1: 'Dial *#06# on your insured phone to display the IMEI number',
  how_upload2: 'Use another device to record a video of the phone showing the IMEI number',
  how_upload3: 'While still recording, lock the damaged phone to remove any background colors',
  how_upload4: 'Ensure that the edges of the phones are also captured in the recording',
  close_something: 'Close',
  claimdesc_phone_WAITING_COURIER_START: 'Courier will pick up your phone from the designated address.',
  claimdesc_phone_WAITING_WALKIN_START: 'Please bring your phone to the closest service center.',
  claimdesc_phone_ESTIMATE_COST: 'Please wait while we review the submitted repair cost.',
  claimdesc_phone_WAITING_COURIER_FINISH: 'Your phone will be delivered to your address.',
  claimdesc_phone_WAITING_WALKIN_FINISH: 'Please pick up your phone at the service center.',
  claimdesc_phone_PAYMENT_NOT_COMPLETE: 'Sorry, your claim has been closed due to own risk fee overdue.',
  claimdesc_phone_DONE_REPLACED: 'Your replacement phone has been sent.',
  ACCIDENTAL_DEATH_BENEFIT_DESC_ID:
    'Protecting you from accidental death caused by an accident during the trip with a compensation up to {currency_type} {total_coverage}.',
  PARTIAL_DISABILITY_BENEFIT_DESC_ID:
    'Protecting you from permanent disablement caused by an accident during the trip with a compensation up to {currency_type} {total_coverage}.',
  MEDICAL_EXPENSE_BENEFIT_DESC_ID:
    'Covering your medical expense up to {currency_type} {total_coverage} if anything should happen during the trip and require you to get medical help.',
  LOST_OF_LUGGAGE_BENEFIT_DESC_ID:
    'Protecting you from a baggage loss during the trip with a compensation up to S$ 150. {currency_type} {total_coverage}.',
  emergency_cs: `You can't contact Qoala Call Center due to operational reasons for an undetermined time. But don't be afraid, you can reach us through Email, WhatsApp or Live Chat if you have questions. Thank you!`,
  eidFitrPopUpTitle: 'Announcement',
  eidFitrPopUpDesc: `Related to the ascension day and Eid al-Fitr on May, 21st - 25th 2020, we want to inform you that your claim will be processed on May, 26th 2020.\n\nWe apologize for the inconvenience.\n\nEid Mubarak!\n`,
  callCsPopUpDesc: `You are trying to contact us outside of the Qoala Customer Service operational hours. But rest assured, we'll gladly answer your questions from Monday to Friday, from 9am to 7pm!`,

  // OYO
  oyoClaimLimitDate: 'You can only claim within 7 days before check-in time/30 days after check-out time',

  // QoalaOnlineListPolicies
  inputLabel: 'Input Your Phone Number to Continue',
  inputDesc: 'Type in your Phone Number to easily find your policy',
  nextButtonLabel: 'Next',
  searchPartnerListLabel: 'Search via',
  partnerListLabel: 'Partner List',
  welcomeLabel: 'Welcome back, {user name}',
  welcomeDesc: 'Type in your PIN code to continue',
  findButtonLabel: 'Find My Policy',
  welcomeGuestLabel: 'Hello!',
  welcomeGuestDesc: 'Please type in your OTP code to continue',
  otpResendLabel: 'Resend OTP',
  otpSendLabel: 'OTP Send',
  loginErrorLabel: 'Phone Number Not Found',
  otpErrorLabel: 'OTP Code Incorrect',
  oos_submit_claim: 'Your Out of Stock claim has submitted',
  rejected_sc: 'Your claim rejected by Service Center, please liase with Qoala Customer Service',
  waiting_excess_fee_confirm: 'Please confirm some own risk fee that requested by Service Center',
  claim_added_sc: 'Device received by service center',
  done_oos: 'Your Out of Stock Claim has finished',
  cust_reject_fee: 'You has rejected the own fee',
  done_cancel: 'Your claim has rejected because of policy cancellation',
  done_expired: 'Your claim has expired',
  okButtonLabel: 'OK',
  askUsTitle: 'Need any help?',
  askUsMsg: 'Get advice from our Insurance Expert now!',
  askUsMsgProductList: 'Tap the button and let us know what we can help you with!',
};

export default messages;
