import qoalaStorage from '~/core/Storage';

export default {
  listPromotions(state, value) {
    state.promoData = value;
  },
  saveDetailPromo(state, value) {
    state.detailPromo = value;
  },
  searchPromoCode(state, value) {
    state.searchCodePromo = value;
  },
  promoDetail(state, value) {
    state.detailPromo = value;
    qoalaStorage.setItem('detailPromo', JSON.stringify(state.detailPromo));
  },
  usePromoCode(state, value) {
    state.useCodePromo = value;
  },
  updatePromoProduct(state, value) {
    state.promoProduct = value;
    qoalaStorage.setItem('promoProduct', JSON.stringify(state.promoProduct));
  },
  updatePromoCalculation(state, value) {
    state.promoCalculation = value;
    qoalaStorage.setItem('promoCalculation', JSON.stringify(state.promoCalculation));
  },
  setListPaymentMethod(state, value) {
    state.listPaymentMethod = value;
    qoalaStorage.setItem('listPaymentMethod', JSON.stringify(state.listPaymentMethod));
  },
  deleteListPayment(state) {
    state.listPaymentMethod = [];
    qoalaStorage.removeItem('listPaymentMethod');
  },
};
