import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  //car
  getCarPurchaseHistory: state => {
    if (!state.carPurchaseHistory) {
      return qoalaStorage.getItem('carPurchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('carPurchaseHistory'))
        : state.carPurchaseHistory || {};
    }
    return state.carPurchaseHistory || {};
  },
  getProductDetailCar: state => {
    if (!state.productDetailCar) {
      return qoalaStorage.getItem('productDetailCar')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailCar'))
        : state.productDetailCar;
    }
    return state.productDetailCar;
  },
  //motor
  getMotorPurchaseHistory: state => {
    if (!state.motorPurchaseHistory) {
      return qoalaStorage.getItem('motorPurchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('motorPurchaseHistory'))
        : state.motorPurchaseHistory;
    }
    return state.motorPurchaseHistory;
  },
  getProductDetailMotor: state => {
    if (!state.productDetailMotor) {
      return qoalaStorage.getItem('productDetailMotor')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailMotor'))
        : state.productDetailMotor;
    }
    return state.productDetailMotor;
  },
  //vehicle
  getPolicyList: state => state.policyList || [],
  getPolicyListMeta: state => state.policyListMeta || {},
  getVehiclePlate: state => {
    if (!state.vehiclePlate) {
      return qoalaStorage.getItem('vehiclePlate')
        ? parseIfJsonString(qoalaStorage.getItem('vehiclePlate'))
        : state.vehiclePlate;
    }
    return state.vehiclePlate || [];
  },
  getVehicleBrand: state => {
    if (!state.vehicleBrand) {
      return qoalaStorage.getItem('vehicleBrand')
        ? parseIfJsonString(qoalaStorage.getItem('vehicleBrand'))
        : state.vehicleBrand;
    }
    return state.vehicleBrand || [];
  },
  getVehicleModel: state => {
    if (!state.vehicleModel) {
      return qoalaStorage.getItem('vehicleModel')
        ? parseIfJsonString(qoalaStorage.getItem('vehicleModel'))
        : state.vehicleModel || [];
    }
    return state.vehicleModel || [];
  },
  getVehicleSeries: state => {
    if (!state.vehicleSeries) {
      return qoalaStorage.getItem('vehicleSeries')
        ? parseIfJsonString(qoalaStorage.getItem('vehicleSeries'))
        : state.vehicleSeries;
    }
    return state.vehicleSeries || [];
  },
  getDocumentsType: state => {
    if (!state.documentsType) {
      return qoalaStorage.getItem('documentsType')
        ? parseIfJsonString(qoalaStorage.getItem('documentsType'))
        : state.documentsType || [];
    }
    return state.documentsType;
  }
};
