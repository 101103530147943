import qoalaStorage from '~/core/Storage';
import Cookies from 'js-cookie';

export default () => ({
  appVersion: process?.env?.npm_package_version ?? 'empty',
  loader: false,
  modalMessage: false,
  message: {},
  modalPasswordConfirmation: false,
  searchPolis: null,
  listBank: JSON.parse(qoalaStorage.getItem('banks')),
  pin: JSON.parse(qoalaStorage.getItem('pin')),
  otpStatus: JSON.parse(qoalaStorage.getItem('otpStatus')),
  notifHome: JSON.parse(qoalaStorage.getItem('notifHome')),
  profileView: 'default',
  calledOnce: false,
  firstTime: JSON.parse(qoalaStorage.getItem('activeFirstTime')),
  pdfLink: qoalaStorage.getItem('pdfLink'),
  a2hs: JSON.parse(qoalaStorage.getItem('A2HS')),
  volumeUp: '',
  volumeDown: '',
  localeLanguage: qoalaStorage.getItem('localeLanguage'),
  pickUpDates: '',
  addressData: {
    province: [],
    city: [],
    district: [],
    village: [],
  },
  loginData: JSON.parse(qoalaStorage.getItem('loginData')),
  purchaseHistory: qoalaStorage.getItem('purchaseHistory'),
  detailPromo: {},
  transactionHistory: qoalaStorage.getItem('transactionHistory'),
  transactionHistoryDetail: qoalaStorage.getItem('transactionHistoryDetail'),
  activeTransaction: JSON.parse(qoalaStorage.getItem('activeTransaction')),
  insurancePartner: [],
  comparedProduct: qoalaStorage.getItem('comparedProduct'),
  guest: Cookies.get('guest'),
  snapToken: qoalaStorage.getItem('snapToken'),
  utmParams: qoalaStorage.getItem('utmParams'),
  defaultLanguage: JSON.parse(qoalaStorage.getItem('defaultLanguage')),
  referer: JSON.parse(qoalaStorage.getItem('referer')),
  gtmUserId: JSON.parse(qoalaStorage.getItem('gtmUserId')),
  theme: null
});
