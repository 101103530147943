export function devopsImageCompressorAlias(logo: string) {
  switch (true) {
    case logo.search('https://assets.qoalaplus.com/') !== -1:
      return logo.replace('https://assets.qoalaplus.com/', 'https://img.qoala.app/qplus-assets/');
    case logo.search('https://assets.qoala.app/') !== -1:
      return logo.replace('https://assets.qoala.app/', 'https://img.qoala.app/qapp-assets/');
    case logo.search('https://blog.qoala.app/wp-content/uploads/') !== -1:
      return logo.replace('https://blog.qoala.app/wp-content/uploads/', 'https://img.qoala.app/blog/');
    default:
      return logo;
  }
}

export function safeParseJson<T = any>(dataToParse: string, fallbackReturn?: T): T {
  try {
    const returnValue = JSON.parse(dataToParse) as T;
    return returnValue;
  } catch (_error) {
    return fallbackReturn !== undefined ? fallbackReturn : ({} as T);
  }
}
