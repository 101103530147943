<template>
  <q-tray
    v-model="modal"
    class="modal-guest-tray"
    hide-action-button
    tray-radius="10"
  >
    <q-padder vertical="sm" class="container">
      <form id="searchResultGuest" @submit.prevent="submitLeads">
        <q-text variant="ui-baseline" ink="label" bold>{{ title }}</q-text>
        <q-text variant="ui-small">{{ subtitle }}</q-text>
        <q-spacer bottom="xs" />
        <q-padder vertical="xxs">
          <q-padder bottom="xxs">
            <q-text variant="ui-small" ink="label" bold>{{
              content.QoalaOnlineRefferedCase.fullNameForm
            }}</q-text>
          </q-padder>
          <q-input
            id="guest-name"
            v-model="userGuest.fullName"
            autocomplete="off"
            :placeholder="content.QoalaOnlineRefferedCase.fullNameForm"
            @input="handleFullname"
          >
          </q-input>
        </q-padder>
        <q-padder vertical="xxs">
          <q-padder bottom="xxs">
            <q-text variant="ui-small" ink="label" bold>{{
              content.QoalaOnlineRefferedCase.phoneNumberForm
            }}</q-text>
          </q-padder>
          <q-input-advanced
            ref="phoneInput"
            v-model="userGuest.inputPhoneNumber"
            autocomplete="off"
            format="phonenumber"
            modal-title="Select Country"
            type="number"
            :max-length="13"
            placeholder="800000000000"
            onwheel="return false"
            :disabled="isDisablePhoneNumber"
            @input="handleInputPhone"
            @focus="focused = true"
            @inputState="handlePhoneValidation"
            @open-phone-modal="openModal('country')"
            @select-country-phone-modal="openModal('list')"
          />
          <q-padder top="xxs">
            <q-text
              v-if="!isPhoneValid"
              variant="ui-tiny"
              style="color: #ff0000"
            >
              {{ content.QoalaOnlineError.invalidPhoneLabel }}
            </q-text>
          </q-padder>
        </q-padder>
        <q-padder vertical="xxs">
          <q-row style="justify-content: space-between">
            <q-padder bottom="xxs">
              <q-text variant="ui-small" ink="label" bold>{{
                content.QoalaOnlineRefferedCase.emailForm
              }}</q-text>
            </q-padder>
          </q-row>
          <q-input
            id="guest-email"
            v-model="userGuest.email"
            autocomplete="off"
            format="email"
            :placeholder="content.QoalaOnlineRefferedCase.emailForm"
            @input-state="validateEmail"
          >
          </q-input>
        </q-padder>
        <q-spacer top="sm" />
        <div>
          <q-button
            :id="`guest-next-${productType.toLowerCase().replace(' ', '-')}`"
            class="btn-default"
            block
            size="md"
            type="primary"
            :disabled="!isValidForm"
          >
            {{ content.QoalaOnlineRefferedCase.buttonGet }}
          </q-button>
        </div>
      </form>
      <q-spacer bottom="md" />
      <q-row v-if="!isLogin" style="justify-content: center">
        <q-text center ink="gray-warm" variant="ui-baseline">
          {{ content.QoalaOnlineRefferedCase.optionLogin + '&nbsp;' }}
        </q-text>
        <div
          @click="navigateWithOriginTo('account')"
          @keypress="navigateWithOriginTo('account')"
        >
          <q-text bold center ink="primary" variant="ui-baseline">
            {{ content.QoalaOnlineRefferedCase.optionLoginDesc || 'Login Now' }}
          </q-text>
        </div>
      </q-row>
    </q-padder>
  </q-tray>
</template>

<script>
import {
  Padder as QPadder,
  Spacer as QSpacer,
  Text as QText,
  Row as QRow,
  NewTray as QTray,
} from '@qoala/ui';
import {
  InputAdvanced as QInputAdvanced,
  Input as QInput,
  Button as QButton,
} from '@qoala/ui/v2';

export default {
  components: {
    QPadder,
    QButton,
    QSpacer,
    QText,
    QRow,
    QTray,
    QInput,
    QInputAdvanced,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    productType: {
      type: String,
      required: false,
      default: '',
    },
    userData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    phoneNumber: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
      focused: false,
      isPhoneValid: true,
      userGuest: {
        fullName: '',
        phone: '',
        email: '',
        inputPhoneNumber: {
          phoneCode: '+60',
          phoneNo: '',
          fullPhoneNo: '+60',
        },
      },
      countryChoosen: {
        name: 'Indonesia',
        countryCode: 'ID',
        phoneCode: '+62',
      },
      valid: true,
      tempData: {},
      isDisablePhoneNumber: false,
    };
  },
  computed: {
    isValidForm() {
      if (
        this.userGuest.fullName !== '' &&
        this.userGuest.phone !== '' &&
        this.userGuest.phone.length > 8 &&
        this.$helper.validateEmail(this.userGuest.email)
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.setPhoneNumber();
    if (this.isLogin) {
      this.userGuest.fullName = this.userData.fullName;
      this.userGuest.email = this.userData.email;
    }
  },
  methods: {
    setPhoneNumber() {
      if (
        this.$helper.isEmpty(this.phone) &&
        !this.$helper.isEmpty(this.phoneNumber)
      ) {
        this.countryChoosen = this.phoneNumber;
        this.userGuest.phone = this.phoneNumber.fullPhoneNo;
        this.$refs.phoneInput.phoneNum = this.phoneNumber.phoneNo;
        this.$refs.phoneInput.countryCode = this.phoneNumber.phoneCode;
      }
    },
    openModal(type) {
      if (type === 'country') {
        return (this.modal = false);
      } else if (type === 'list') {
        return (this.modal = true);
      }
    },
    validateEmail(result) {
      if (!result) {
        this.$toasted.error(this.content.QoalaOnlineError.invalidEmailLabel, {
          duration: 3000,
          fullWidth: true,
          closeOnSwipe: true,
        });
      }
    },
    navigateWithOriginTo(to) {
      return this.$router.replace(
        this.localePath({
          name: to,
          query: { origin: this.$route.path },
        })
      );
    },
    handleInputPhone() {
      this.userGuest.phone = this.userGuest.inputPhoneNumber.fullPhoneNo;
    },
    handlePhoneValidation(result) {
      this.isPhoneValid = result;
    },
    handleFullname() {
      this.userGuest.fullName = this.userGuest.fullName.replace(
        /[^a-zA-Z ]/g,
        ''
      );
    },
    showModal() {
      this.modal = !this.modal;
    },
    async submitLeads() {
      if (this.isValidForm) {
        let leadsPayload = {};
        Object.assign(leadsPayload, this.userData);
        leadsPayload.fullName = this.userGuest.fullName;
        leadsPayload.email = this.userGuest.email;
        leadsPayload.phoneNumber = this.userGuest.phone;
        this.modalActive = !this.modalActive;
        this.$emit('handleModalGuest', leadsPayload);
        this.showModal();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-guest-tray {
  ::v-deep {
    .eu-tray-container {
      .eu-tray-body {
        overflow: auto;
        max-height: 100vh;
        .eu-tray-body-padder {
          padding: 12px 16px !important;
        }
        .eu-tray-footer {
          border: 0;
          padding: 0 16px 8px;
        }
      }
    }
  }
}
::v-deep.eu-input {
  font-size: 12px;
}
::v-deep.eu-input-section .border {
  padding: 4px 8px;
  border-radius: 8px;
}
::v-deep.eu-inputadvance-section .eu-input-container {
  border-radius: 8px;
  height: 35px;
}
</style>
