import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getQuotationSummary: (state) => {
    if (!state.quotationSummary) {
      return qoalaStorage.getItem('quotationSummary')
        ? parseIfJsonString(qoalaStorage.getItem('quotationSummary'))
        : state.quotationSummary || [];
    }
    return state.quotationSummary;
  },
  getQuotationScraping: (state) => {
    if (!state.quotationScraping) {
      return qoalaStorage.getItem('quotationScraping')
        ? parseIfJsonString(qoalaStorage.getItem('quotationScraping'))
        : state.quotationScraping || [];
    }
    return state.quotationScraping;
  },
  getQuotationSharePath: (state) => {
    if (!state.quotationSharePath) {
      return qoalaStorage.getItem('quotationSharePath')
        ? parseIfJsonString(qoalaStorage.getItem('quotationSharePath'))
        : state.quotationSharePath || '';
    }
    return state.quotationSharePath;
  },
};
