import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';
import Cookies from 'js-cookie';

export default {
  getUserData: (state) => {
    return state?.userData ?? {};
  },
  getUserToken: (state) => {
    return state.userToken;
  },
  getUserTokenRefresh: (state) => {
    if (!state.userTokenRefresh) {
      return Cookies.get('r') ? Cookies.get('r') : state.userTokenRefresh;
    }
    return state.userTokenRefresh;
  },
  getUserNotif: (state) => {
    if (!state.userNotif) {
      return qoalaStorage.getItem('n')
        ? parseIfJsonString(qoalaStorage.getItem('n'))
        : state.userNotif;
    }
    return state.userNotif;
  },
  getUserNotifList: (state) => {
    if (!state.userNotifs) {
      return qoalaStorage.getItem('n')
        ? parseIfJsonString(qoalaStorage.getItem('n'))
        : state.userNotifs;
    }
    return state.userNotifs;
  },
  getUserPosition: (state) => {
    if (!state.userNotifs) {
      return qoalaStorage.getItem('position')
        ? parseIfJsonString(qoalaStorage.getItem('position'))
        : state.userPosition;
    }
    return state.userPosition;
  },
  getTempRegister: (state) => {
    if (!state.tempRegister) {
      return qoalaStorage.getItem('tempReg')
        ? parseIfJsonString(qoalaStorage.getItem('tempReg'))
        : state.tempRegister;
    }
    return state.tempRegister;
  },
  getUserUnread: (state) => state.userUnread,
  getUuid: (state) => {
    if (!state.uuid) {
      return qoalaStorage.getItem('uuid')
        ? parseIfJsonString(qoalaStorage.getItem('uuid'))
        : state.uuid;
    }
    return state.uuid;
  },
  getTempSign: (state) => {
    return state.tempSign;
  },
  getProfile: (state) => {
    if (!state.profile) {
      const cache = qoalaStorage.getItem('profile');
      return cache ? parseIfJsonString(cache) : state.profile;
    }
    return state.profile;
  },
};
