<template>
  <q-tray
    v-model="modal"
    class="modal-guest-tray"
    hide-action-button
    tray-radius="10"
    @on-button-pressed="handleModalGuest"
  >
    <q-padder vertical="sm" class="container">
      <form id="searchResultGuest" @submit.prevent="handleModalGuest">
        <q-text variant="ui-baseline" ink="label" bold center>{{
          content.QoalaOnlineCarDetail.guestTitle
        }}</q-text>
        <q-spacer bottom="xs" />
        <q-padder vertical="xxs">
          <q-row style="justify-content: space-between">
            <q-padder bottom="xxs">
              <q-text variant="ui-small" ink="label" bold>{{
                content.QoalaOnlineCarDetail.fullNameLabel
              }}</q-text>
            </q-padder>
            <q-padder bottom="xxs" right="xxs">
              <q-text variant="ui-small" ink="muted" style="text-align: right">
                {{ content.QoalaOnlineCarDetail.fullNameDesc }}
              </q-text>
            </q-padder>
          </q-row>
          <q-input
            id="guest-name"
            v-model="userGuest.fullName"
            autocomplete="off"
            :placeholder="content.QoalaOnlineCarDetail.fullNameLabel"
            @input="handleFullname"
          >
          </q-input>
        </q-padder>
        <q-padder vertical="xxs">
          <q-padder bottom="xxs">
            <q-text variant="ui-small" ink="label" bold>{{
              content.QoalaOnlineCarDetail.phoneNumberLabel
            }}</q-text>
          </q-padder>
          <q-input-advanced
            ref="phoneInput"
            v-model="userGuest.inputPhoneNumber"
            autocomplete="off"
            format="phonenumber"
            modal-title="Select Country"
            type="number"
            :max-length="13"
            placeholder="800000000000"
            onwheel="return false"
            @inputState="handlePhoneValidation"
            @input="handleInputPhone"
            @focus="focused = true"
          />
        </q-padder>
        <q-padder vertical="xxs">
          <q-padder bottom="xxs">
            <q-text variant="ui-small" ink="label" bold>{{
              content.QoalaOnlineCarDetail.emailLabel
            }}</q-text>
          </q-padder>
          <q-input
            id="guest-email"
            v-model="userGuest.email"
            autocomplete="off"
            format="email"
            :placeholder="content.QoalaOnlineCarDetail.emailLabel"
            @input-state="validateEmail"
          >
          </q-input>
        </q-padder>
        <q-spacer top="sm" />
        <div>
          <q-button
            :id="`guest-next-${productType.toLowerCase().replace(' ', '-')}`"
            class="btn-default"
            block
            size="md"
            type="primary"
            :disabled="!isValidateForm"
          >
            {{ content.QoalaOnlineCarDetail.doneButtonLabel }}
          </q-button>
        </div>
      </form>
      <q-spacer bottom="md" />
      <q-row justify="center">
        <q-text variant="ui-baseline" ink="gray-raven">
          {{ content.QoalaOnlineGeneral.orLabel }} &nbsp;
        </q-text>
        <div
          @click="navigateWithOriginTo('account')"
          @keypress="navigateWithOriginTo('account')"
        >
          <q-text bold center ink="primary" link-style variant="ui-baseline">
            {{ content.QoalaOnlineGeneral.loginTextLabel }}
          </q-text>
        </div>
      </q-row>
    </q-padder>
  </q-tray>
</template>

<script>
import {
  Padder as QPadder,
  Spacer as QSpacer,
  Text as QText,
  Row as QRow,
  NewTray as QTray,
} from '@qoala/ui';
import {
  InputAdvanced as QInputAdvanced,
  Input as QInput,
  Button as QButton,
} from '@qoala/ui/v2';
import { mapGetters } from 'vuex';

export default {
  components: {
    QPadder,
    QButton,
    QSpacer,
    QText,
    QRow,
    QTray,
    QInput,
    QInputAdvanced,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    productType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      modal: false,
      focused: false,
      isPhoneValid: true,
      userGuest: {
        fullName: '',
        phone: '',
        email: '',
        inputPhoneNumber: {
          phoneCode: '+62',
          phoneNo: '',
          fullPhoneNo: '+62',
        },
      },
      countryChoosen: {
        name: 'Indonesia',
        countryCode: 'ID',
        phoneCode: '+62',
      },
      valid: true,
      tempData: {},
    };
  },
  computed: {
    ...mapGetters({
      carPurchaseHistory: 'vehicle/getCarPurchaseHistory',
      smartphone: 'smartphone/getSmartphonePurchaseHistory',
      car: 'vehicle/getCarPurchaseHistory',
      motor: 'vehicle/getMotorPurchaseHistory',
      health: 'health/getHealthPurchaseHistory',
      hcp: 'hcp/getHcpData',
      life: 'lifeInsurance/getLifePurchaseHistory',
      tpd: 'tropical-disease/getTropicalDiseasePurchaseData',
      healthProtectionType: 'health/getType',
      self: 'health/getSelfData',
      spouse: 'health/getSpouseData',
      son: 'health/getSonData',
      daughter: 'health/getDaughterData',
    }),
    isValidateForm() {
      if (
        this.userGuest.fullName !== '' &&
        this.userGuest.phone !== '' &&
        this.userGuest.email !== '' &&
        this.userGuest.phone.length > 8 &&
        this.$helper.validateEmail(this.userGuest.email)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    validateEmail(result) {
      if (!result) {
        this.$toasted.error(this.content.QoalaOnlineError.invalidEmailLabel);
      }
    },
    navigateWithOriginTo(to) {
      this.saveLeadsData();
      return this.$router.replace(
        this.localePath({
          name: to,
          query: { origin: this.$route.path },
        })
      );
    },
    handleInputPhone() {
      this.userGuest.phone = this.userGuest.inputPhoneNumber.fullPhoneNo;
    },
    handlePhoneValidation(result) {
      this.isPhoneValid = result;
      if (!result)
        this.$toasted.error(this.content.QoalaOnlineError.invalidPhoneLabel);
    },
    handleFullname() {
      this.userGuest.fullName = this.userGuest.fullName.replace(
        /[^a-zA-Z ]/g,
        ''
      );
    },
    showModal() {
      this.modal = !this.modal;
    },
    async handleModalGuest() {
      if (this.isValidateForm) {
        this.tempData.phone = this.countryChoosen;
        this.tempData.guest = this.userGuest;
        this.modal = true;
        this.$analytics.pushEvent({
          event: 'Search-Result-Guest',
          eventCategory: this.productType,
          eventLabel: 'Search-Result-Guest-Click-Submit-Success',
        });
        this.$analytics.pushEvent({
          eventCategory: this.productType,
          eventLabel: `${this.productType}, ${this.tempData.guest.fullName}, ${this.tempData.guest.phone}, ${this.tempData.guest.email}`,
        });

        this.$analytics.pushEventClevertap('Guest Lead', {
          'Product Category': this.productType,
          'Event Type': 'Search Submit',
          Name: this.userGuest.fullName,
          Phone: this.userGuest.phone,
          Email: this.userGuest.email,
        });
      }
      this.saveLeadsData();
      this.$emit('handleModalGuest', { data: this.tempData });
    },
    getUrlAndPayloadForSmartphone() {
      const { smartphoneInfo } = this.smartphone;
      return {
        url: 'smartphone',
        payload: {
          product_category: 'SMARTPHONE',
          smartphone_detail: {
            product: smartphoneInfo.type.value,
            insured_type: 'INDIVIDUAL',
            period: smartphoneInfo.period,
            brand: smartphoneInfo.brand.value,
            model: smartphoneInfo.model.value,
          },
        },
      };
    },
    getUrlAndPayloadForVehicle() {
      let vehicleInfo = {};
      let vehicleAcc = {};
      let accNote = '';
      if (this.productType === 'Car') {
        const { carInfo, accessories } = this.car;
        vehicleInfo = carInfo;
        vehicleAcc = accessories;
      }
      if (this.productType === 'Motorcycle') {
        const { motorInfo, accessories } = this.motor;
        vehicleInfo = motorInfo;
        vehicleAcc = accessories;
      }
      if (vehicleAcc && vehicleAcc.items) {
        accNote = vehicleAcc.items
          .filter((acc) => acc.selected)
          .map((acc) => acc.name)
          .join(', ');
      }
      return {
        url: 'vehicle',
        payload: {
          promo_code: vehicleInfo.promo_code || vehicleInfo.promoCode || '',
          product_category: 'VEHICLE',
          vehicle_detail: {
            product: this.productType === 'Car' ? 'CAR' : 'MOTOR',
            plate_region: vehicleInfo.plate,
            category: 'NON_TRUCK',
            manufacturer: vehicleInfo.brand,
            brand: vehicleInfo.model,
            series: vehicleInfo.series,
            year:
              this.productType === 'Car'
                ? vehicleInfo.carYear
                : vehicleInfo.motorYear,
            price: Number(
              vehicleInfo.price.recommended_price.replace(/\./g, '')
            ),
            accessories_price: vehicleAcc
              ? Number(vehicleAcc.price.recommended_price.replace(/\./g, ''))
              : 0,
            accessories_note: accNote,
            usage_type: 'PERSONAL',
            vehicle_condition: 'USED',
            protection_type: vehicleInfo.protectionType,
          },
        },
      };
    },
    getUrlAndPayloadForHealth() {
      const url = 'health';
      const { healthProtectionType, self, spouse, son, daughter } = this;
      let totalMember = 0;

      if (self.length) totalMember += self.length;
      if (spouse.length) totalMember += spouse.length;
      if (son.length) totalMember += son.length;
      if (daughter.length) totalMember += daughter.length;

      const payload = {
        product_category: 'HEALTH',
        health_detail: {
          product: healthProtectionType,
          insured_type: totalMember > 1 ? 'FAMILY' : 'INDIVIDUAL',
          self_gender:
            (self.length && self[0].gender) ||
            (spouse.length && spouse[0].gender) ||
            (son.length && son[0].gender) ||
            (daughter.length && daughter[0].gender),
          self_birthdate:
            (self.length && self[0].birthdate) ||
            (spouse.length && spouse[0].birthdate) ||
            (son.length && son[0].birthdate) ||
            (daughter.length && daughter[0].birthdate),
        },
      };
      if (self.length && spouse.length) {
        payload.health_detail.spouse_gender = spouse[0].gender;
        payload.health_detail.spouse_birthdate = spouse[0].birthdate;
      }
      if (self.length && (son.length || daughter.length)) {
        payload.health_detail.child_gender =
          (son.length && son[0].gender) ||
          (daughter.length && daughter[0].gender);
        payload.health_detail.child_birthdate =
          (son.length && son[0].birthdate) ||
          (daughter.length && daughter[0].birthdate);
      }
      return { url, payload };
    },
    getUrlAndPayloadForHcp() {
      const { prequote } = this.hcp;
      return {
        url: 'health/hcp',
        payload: {
          product_category: 'MICRO_HEALTH',
          micro_health_detail: {
            product: 'HCP',
            insured_type: prequote.category,
            self_gender: prequote.covereds[0].datas[0].gender,
            self_birthdate: prequote.covereds[0].datas[0].birthdate,
          },
        },
      };
    },
    getUrlAndPayloadForLife() {
      const { prequote, lifeInfo } = this.life;
      return {
        url: 'life',
        payload: {
          product_category: lifeInfo.type,
          life_detail: {
            product: 'TERM_LIFE',
            insured_type: 'INDIVIDUAL',
            self_gender: prequote.covereds[0].datas[0].gender || 'MALE',
            self_birthdate: prequote.covereds[0].datas[0].birthdate,
          },
        },
      };
    },
    getUrlAndPayloadForTpd() {
      return {
        url: 'health/tpd',
        payload: {
          product_category: 'MICRO_HEALTH',
          micro_health_detail: {
            product: 'TROPICAL_DISEASES',
            insured_type: this.tpd.prequote.category,
            self_gender: 'MALE',
            self_birthdate: this.tpd.prequote.covereds[0].datas[0].birthdate,
          },
        },
      };
    },
    async saveLeadsData() {
      try {
        let url = '';
        let payload = {};
        switch (this.productType) {
          case 'Smartphone':
            url = this.getUrlAndPayloadForSmartphone().url;
            payload = this.getUrlAndPayloadForSmartphone().payload;
            break;
          case 'Car':
          case 'Motorcycle':
            url = this.getUrlAndPayloadForVehicle().url;
            payload = this.getUrlAndPayloadForVehicle().payload;
            break;
          case 'Health':
            url = this.getUrlAndPayloadForHealth().url;
            payload = this.getUrlAndPayloadForHealth().payload;
            break;
          case 'HCP':
            url = this.getUrlAndPayloadForHcp().url;
            payload = this.getUrlAndPayloadForHcp().payload;
            break;
          case 'Life':
          case 'ROP':
            url = this.getUrlAndPayloadForLife().url;
            payload = this.getUrlAndPayloadForLife().payload;
            break;
          case 'Tropical Disease':
            url = this.getUrlAndPayloadForTpd().url;
            payload = this.getUrlAndPayloadForTpd().payload;
            break;
        }
        payload.customer_name = this.tempData.guest.fullName;
        payload.customer_email = this.tempData.guest.email;
        payload.customer_phone = this.tempData.guest.phone;
        payload.source = 'Guest Search';
        payload.source_id = '13000440975';
        await this.$store.dispatch('general/sendLeads', { url, payload });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-guest-tray {
  ::v-deep {
    .eu-tray-container {
      .eu-tray-body {
        overflow: auto;
        max-height: 100vh;
        .eu-tray-body-padder {
          padding: 12px 16px !important;
        }
        .eu-tray-footer {
          border: 0;
          padding: 0 16px 8px;
        }
      }
    }
  }
}
::v-deep.eu-input {
  font-size: 12px;
}
::v-deep.eu-input-section .border {
  padding: 4px 8px;
  border-radius: 8px;
}
::v-deep.eu-inputadvance-section .eu-input-container {
  border-radius: 8px;
  height: 35px;
}
</style>
