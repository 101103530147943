export default async function (param) {
  if (!param?.req?.query) {
    return;
  }
  const { query } = param.req;
  const utmQueries = {};
  Object.keys(query).forEach((queryKey) => {
    if (queryKey.includes('utm_')) {
      utmQueries[queryKey] = query[queryKey];
    }
  });
  if (Object.keys(utmQueries).length) {
    param.store.commit('general/setUtmParams', utmQueries);
  }
  if (param?.req?.headers?.referer) {
    const referer = param?.req?.headers?.referer;
    if (!referer.includes('qoala')) {
      param.store.commit('general/setReferer', referer);
    }
  }
}
