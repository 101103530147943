function currenciesToNumber(value) {
  let number = '0';
  if (typeof value === 'number' || typeof value == 'string') {
    if (value !== 0 && value !== '')
      number = value.toString().replace(/\./g, '');
    return number;
  }
  return number;
}
export default currenciesToNumber;
