import phoneUtil from 'awesome-phonenumber';

export const helper = (app) => ({
  countryCode(phoneNumber) {
    if (phoneNumber) {
      return phoneUtil(phoneNumber).getRegionCode();
    }
    return 'ID';
  },
});

export default ({ app }, inject) => {
  inject('clientHelper', helper(app));
};
