import getInitialUserState from './state';
import Cookies from 'js-cookie';
import qoalaStorage from '~/core/Storage';

export default {
  updateData(state, value) {
    state.userToken = value.token;
    state.userTokenRefresh = value.refresh;
    state.userData = value.user;
    Cookies.set('r', state.userTokenRefresh, { expires: 2, secure: true });
  },
  updateRefreshToken(state, value) {
    state.callRefreshToken = value;
  },
  updateDataUser(state, user) {
    state.userData = user;
  },
  updateDataPosition(state, position) {
    state.userPosition = position;
    qoalaStorage.setItem('position', JSON.stringify(state.userPosition));
  },
  updateNotifications(state, notification) {
    state.userNotif = notification;
    qoalaStorage.setItem('n', JSON.stringify(state.userNotif));
  },
  updateNotificationsList(state, notification) {
    state.userNotifs = notification;
    qoalaStorage.setItem('l', JSON.stringify(state.userNotifs));
  },
  /*eslint-disable*/
  clearState(state) {
    state = { ...state, ...getInitialUserState() };
  },
  updateUserUnread(state, status) {
    state.userUnread = status;
  },
  clearRefreshToken(state) {
    clearTimeout(state.callRefreshToken);
    state.callRefreshToken = null;
  },
  removeToken(state) {
    state.userToken = null;
    state.userTokenRefresh = null;
    state.userData = null;
    state.userPosition = null;
    qoalaStorage.clear();
    Cookies.remove('r');
    this.commit('general/updateFirstTime', false);
    this.commit('general/updateCalledOnce', false);
    this.$axios.setToken(false);
  },
  temporaryRegister(state, register) {
    state.tempRegister = register;
    qoalaStorage.setItem('tempReg', JSON.stringify(state.tempRegister));
  },
  setUuid(state, value) {
    state.uuid = value;
    qoalaStorage.setItem('uuid', JSON.stringify(state.uuid));
  },
  removeUuid(state) {
    state.uuid = '';
    qoalaStorage.removeItem('uuid');
  },
  setTempSign(state, value) {
    state.tempSign = value;
  },
  removeTempSign(state) {
    state.tempSign = {};
  },
  setProfile(state, value) {
    state.profile = value;
    qoalaStorage.setItem('profile', JSON.stringify(state.profile));
  },
  removeTokenExternal(state) {
    state.userToken = null;
    state.userTokenRefresh = null;
    state.userData = null;
    state.userPosition = null;
    Cookies.remove('r');
    this.commit('general/updateFirstTime', false);
    this.commit('general/updateCalledOnce', false);
    this.$axios.setToken(false);
  },
};
