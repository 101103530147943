export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function parseIfJsonString(str: string): { [key: string]: any } | string {
  if (isJsonString(str)) {
    return JSON.parse(str);
  }
  return str;
}
