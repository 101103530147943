<template>
  <q-tray
    id="sort"
    v-model="modal"
    :buttonText="content.QoalaOnlineCarDetail.applyButtonLabel"
    primaryButtonType="primary"
    :onClose="closeModal"
    :onButtonPressed="handleSort"
    radius="20"
    style="max-width: 480px;"
    class="sort-tray-container"
  >
    <q-text tag="div" style="width:100%; ">
      <q-padder top="mdl" bottom="sm" horizontal="sm">
        <q-spacer bottom="sm">
          <q-text variant="ui-small" ink="label" bold>{{ content.QoalaOnlineHealthDetail.sortByLabel }}</q-text>
        </q-spacer>
        <radio-group id="health" v-model="selectedSort" :items="items" />
      </q-padder>
    </q-text>
  </q-tray>
</template>

<script>
/**
 * Sort Modal
 * Used on Search List Product Page for Sort List of Product
 *
 * @contributors
 *  - <doni.samalo@qoala.id> updated on: 19 June 2020
 */
import {
  Card as QCard,
  Padder as QPadder,
  Spacer as QSpacer,
  Button as QButton,
  Text as QText,
  Column as QColumn,
  Grid as QGrid,
  Skeleton as QSkeleton,
  RadioGroup,
  Tray as QTray
} from '@qoala/ui';

export default {
  components: {
    QCard,
    QPadder,
    QButton,
    QSpacer,
    QText,
    QGrid,
    QColumn,
    QSkeleton,
    RadioGroup,
    QTray
  },
  data() {
    return {
      modal: false,
      selectedSort: ''
    };
  },
  props: {
    premiRange: {
      type: Array
    },
    content: {
      type: Object
    },
    sortAlphabet: {
      type: Array
    },
    listRecomended: {
      type: Array
    },
    selectedAlphabet: {
      type: String
    },
    selectedPremi: {
      type: String,
      default: 'Lowest'
    },
    items: {
      type: Array
    },
    selectedSortProps: {
      type: String
    }
  },
  methods: {
    closeModal() {
      this.selectedSort = this.selectedSortProps;
      this.$emit('closeModal', '');
    },
    showModal() {
      this.modal = !this.modal;
    },
    handleSort() {
      this.$emit('handleSort', this.selectedSort);
    }
  }
};
</script>

<style scoped>
.leftRadius {
  border-radius: 10px 0 0 10px !important;
  border: 1px solid #dfdfdf;
  border-right: 0.5px;
}
.rightRadius {
  border-radius: 0 10px 10px 0 !important;
}
</style>
<style lang="scss">
.sort-tray-container {
  .tray-controller {
    border-bottom: none;
    padding: 0;
  }
  .tray-inner-content {
    padding-top: 0px;
  }
  .tray-footer {
    border-top: none;
  }
  .radio-group-container {
    .radio-item {
      .radio-container {
        .radio {
          background: #fffef2;
          border: 1px solid #ffa000;
          &.checked {
            border: 5px solid #ff6400;
          }
          .radio-fill {
            background: #ffffff;
          }
        }
      }
      label {
        font-size: 12px;
        color: #1253a4;
        font-weight: bold;
        margin-left: 10px;
        line-height: 20px;
      }
    }
  }
  .tray-footer {
    margin-top: -15px;
    .button {
      background: linear-gradient(to bottom, #ff9738, #ff8743);
    }
  }
}
</style>
