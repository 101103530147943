import Vue from 'vue';
import Service from '~/shared/Service';
import * as ContentResource from './ContentResource.type';
import { API_GET_MULTILANGUAGE_CONTENT } from './ContentResourceConstant';

/**
 * Fetch Content Resource
 */
let dataQuery = {};
let generalErrorData = [];

export function splitEntry(list: any[], number: number) {
  let idx = 0;
  let result = [] as any[];
  while (idx < list.length) {
    if (idx % number === 0) result.push([]);
    result[result.length - 1].push(list[idx++]);
  }
  return result;
}

export async function getContentResource(
  entries: { [key: string]: string[] },
  lang?: string,
  method?: string,
  type?: string
) {
  let locale = '';
  const payload: ContentResource.RequestPayload = {
    locale: (lang || locale).replace(/-/g, '_'),
    queries: Object.keys(entries).map(entry => ({
      entry,
      keys: entries[entry]
    }))
  };
  dataQuery['method'] = method;
  dataQuery['data'] = {};
  const GET_QUERIES: string[] = [];
  let key: string;
  for (key of Object.keys(entries)) {
    GET_QUERIES.push(key);
    dataQuery['data'][key] = {};
  }
  if (method === 'get') {
    if (GET_QUERIES.length > 3) {
      const split = splitEntry(GET_QUERIES, 2);
      const response: any[] = [];
      try {
        await Promise.all(
          split.map(async item => {
            let QUERY_SEND: string =
              '/?source=qoalaapp&entry=' + item.join('&entry=') + `&locale=${(lang || locale).replace(/-/g, '_')}`;
            const { data: request } = await Service.get(API_GET_MULTILANGUAGE_CONTENT + QUERY_SEND);
            request.data.map(dats => response.push(dats));
          })
        );
        return response;
      } catch (e) {
        return response;
      }
    }
    let QUERY_SEND: string =
      '/?source=qoalaapp&entry=' + GET_QUERIES.join('&entry=') + `&locale=${(lang || locale).replace(/-/g, '_')}`;
    try {
      const request = await Service.get(API_GET_MULTILANGUAGE_CONTENT + QUERY_SEND);
      if (type === 'generalError') generalErrorData = request.data.data;
      return request.data.data;
    } catch (e) {
      return [];
    }
  }
  try {
    const request = await Service.post(API_GET_MULTILANGUAGE_CONTENT, payload);
    return request.data.data;
  } catch (e) {
    return [];
  }
}

/**
 * Map from response data to component friendly object
 * Since we wan't a simple usage of it
 */
export function mapDataToContent(
  contentQuery: { [key: string]: string[] },
  responseData: ContentResource.ResponseData[] = []
) {
  if (generalErrorData.length > 0 && !contentQuery.QoalaOnlineError) {
    contentQuery.QoalaOnlineError = [];
    responseData = [...responseData, ...generalErrorData];
  }

  const content = {};
  let contentUse = contentQuery;
  if (dataQuery['method'] === 'get') {
    contentUse = dataQuery['data'];
    Object.keys(contentUse).forEach(contentEntry => {
      // Assign the entry name as it default value if the value is not defined in our content service (Airtable)
      content[contentEntry] = {};
    });
  } else {
    Object.keys(contentUse).forEach(contentEntry => {
      // Assign the entry name as it default value if the value is not defined in our content service (Airtable)
      Object.keys(contentUse[contentEntry]).forEach((entry: string) => {
        content[contentEntry] = {
          ...(content[contentEntry] || {}),
          [entry]: entry
        };
      });
    });
  }
  if (responseData && responseData.length > 0) {
    responseData.forEach(data => {
      content[data.entry] = {
        ...(content[data.entry] || {}),
        [data.key]: data.value
      };
    });
  }
  return content;
}

/**
 * Create Page
 * Basically create a nuxt page with some configuration
 * @example
 * export default createPage({
 *   layout: 'default',
 *   transitions: 'page',
 *   components: {
 *     AppBar,
 *     AppBody,
 *     TabBarNavigation
 *   },
 *  method: 'post',
 *   queries: {
 *     MainPage: ['title'],
 *     SmartPhoneSearchIMEIPage: ['label', 'placeholder', 'submitButtonLabel'],
 *   },
 *   lang: 'en_US',
 * });
 *
 */
export const createPage = ({
  /**
   * The Content Resource Query
   * @example
   * queries: {
   *   MainPage: ['title'],
   *   SmartPhoneSearchIMEIPage: ['label', 'placeholder', 'submitButtonLabel'],
   * }
   */
  queries = {},
  /**
   * Add Content call option
   */
  noContent = false,
  /**
   * Add Transition effect everytime pages created
   */
  staticHead = {},
  /**
   * Add Transition effect everytime pages created
   */
  transition,
  /**
   * Add Route Manipulate befor page rendered
   */
  beforeRouteEnter,
  /**
   * Run function before page get rendered
   */
  asyncFunc = () => {
    return false;
  },
  /**
   * If page is only available in specific language,
   * we can pass the language here
   */
  lang,
  /**
   * api calling method
   */
  method,
  /**
   * All components that are used inside the page,
   * Each components will have content passed as props
   * & Each component should be ordered like we're using template syntax
   * @example
   *  components: {
   *   Header,
   *   Body,
   *   Footer
   *  }
   * @
   * Each component can also have their own queries
   */
  components
}: ContentResource.CreatePage): Vue.Component =>
  Vue.extend({
    components,
    transition,
    beforeRouteEnter,
    data() {
      return {
        content: mapDataToContent(queries)
      };
    },
    head() {
      return staticHead;
    },
    /**
     * This will enable the content before the page render,
     * And will pass the content to all components before it got rendered
     */
    async asyncData(context) {
      let contentQuery = { ...queries };
      /**
       * Check if component has query
       */
      let componentQuery = {};

      /**
       * Check if need content
       */
      if (noContent) return null;
      /**
       * Run async function first before call the content.
       */
      const asyncData = await asyncFunc();
      /**
       * Iterate thru all components and merge it value to createPage queries
       */
      Object.keys(components).forEach((componentKey: string) => {
        if (components[componentKey].queries) {
          Object.keys(components[componentKey].queries).forEach(componentQueryKey => {
            componentQuery[componentQueryKey] = [
              ...(contentQuery[componentQueryKey] || []),
              ...components[componentKey].queries[componentQueryKey]
            ];
          });
        }
      });

      Object.keys(componentQuery).forEach(componentQueryKey => {
        componentQuery[componentQueryKey] = [...new Set(componentQuery[componentQueryKey])];
      });

      contentQuery = { ...contentQuery, ...componentQuery };
      // @ts-ignore
      const locale = context.app.i18n.locales.find(locale => locale.code === context.app.i18n.locale).iso;
      const data = await getContentResource(contentQuery, lang || locale, method);
      const content = mapDataToContent(contentQuery, data);

      return {
        content,
        asyncData
      };
    },
    render(h: Vue.CreateElement): Vue.VNode {
      /**
       * I wish we could return only an array
       * Will wait till Vue 3.0 released to make this happen
       * temporary solution is to wrap it in div =(
       */
      return h(
        'div',
        Object.keys(components).map(component => {
          let content = {};
          Object.assign(content, (components[component] || {}).queries || {});
          Object.assign(content, this.$data.content || {});
          return h(components[component], {
            props: {
              /**
               * Only return the defined *ENTRY* for each component
               * TODO: Only return specific key for each component
               */
              content: content,
              // content: pick(this.$data.content, [
              //   ...Object.keys((components[component] || {}).queries || {}),
              //   ...Object.keys(queries || {})
              // ]),
              asyncData: this.$data.asyncData
            }
          });
        })
      );
    }
  });
