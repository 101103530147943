function generateUuid() {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (char) => {
      const random = (Math.random() * 16) | 0,
        transfomChar = char === 'x' ? random : random & (0x3 | 0x8);
      return transfomChar.toString(16);
    }
  );
  return uuid;
}
export default generateUuid;
