import isEmpty from '@/utils/common/isEmpty';

function mapAccessories(accessories) {
  const acc = [];
  if (!isEmpty(accessories)) {
    accessories.map((item) => {
      if (item.selected) acc.push(item.name);
    });
  }
  return acc;
}
export default mapAccessories;
