import moment from 'moment'

import 'moment/locale/id'
import 'moment/locale/vi'
import 'moment/locale/ms'

import 'moment-timezone'

moment.locale('en')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
