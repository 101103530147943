export const MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY =
  'malayHealthPrequoteInformation';
export const MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY = 'malayHealthProductList';
export const MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY =
  'malayHealthSelectedProduct';
export const MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY =
  'malayHealthPolicyHolderInfo';
export const MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY = 'malayHealthQuotationData';
export const MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY =
  'malayHealthPriceBreakdown';
