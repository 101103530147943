import isEmpty from '@/utils/common/isEmpty';

function mapDocuments(documents) {
  if (isEmpty(documents)) return [];
  return documents.map((doc) => ({
    filename: doc.filename,
    url: doc.url,
    type: doc.key,
  }));
}
export default mapDocuments;
