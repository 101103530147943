import {
  TMalayHealthPolicyHolderInfo,
  TMalayHealthProduct,
  TMalayHealthProductList,
  TMalayHealthPrequoteData,
  TMalayHealthState,
  TQuotationData,
} from './types';
import qoalaStorage from '~/core/Storage';
import {
  MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY,
  MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY,
  MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY,
  MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY,
  MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY,
  MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY,
} from './constants';
import { parseIfJsonString } from '~/core/helper/JSONHelper';
import { TKeyValue } from '~/utils/common/types';

export type TMalayHealthGetters = {
  getInsuredInformation: (
    state: TMalayHealthState
  ) => TMalayHealthPrequoteData | undefined;
  getProductList: (
    state: TMalayHealthState
  ) => TMalayHealthProductList | undefined;
  getSelectedProduct: (
    state: TMalayHealthState
  ) => TMalayHealthProduct | undefined;
  getPolicyHolderInformation: (
    state: TMalayHealthState
  ) => TMalayHealthPolicyHolderInfo | undefined;
  getQuotationData: (state: TMalayHealthState) => TQuotationData | undefined;
  getPriceBreakdown: (
    state: TMalayHealthState
  ) => TKeyValue<number>[] | undefined;
};

const malayHealthGetters: TMalayHealthGetters = {
  getInsuredInformation(state) {
    return getCachedState(
      state.prequoteData,
      MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY
    );
  },
  getProductList(state) {
    return getCachedState(
      state.productList,
      MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY
    );
  },
  getSelectedProduct(state) {
    return getCachedState(
      state.selectedProduct,
      MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY
    );
  },
  getPolicyHolderInformation(state) {
    return getCachedState(
      state.policyHolderInformation,
      MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY
    );
  },
  getQuotationData(state) {
    return getCachedState(
      state.quotationData,
      MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY
    );
  },
  getPriceBreakdown(state) {
    return getCachedState(
      state.priceBreakdown,
      MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY
    );
  },
};

export default malayHealthGetters;

function getCachedState<T>(targetState: T, cacheKey: string) {
  if (!targetState) {
    const cachedValue = qoalaStorage.getItem(cacheKey);
    if (!cachedValue) {
      return targetState;
    }
    return parseIfJsonString(qoalaStorage.getItem(cacheKey) ?? '') as T;
  }
  return targetState;
}
