import axios from 'axios';

export default {
  async getListPromotion(
    context,
    { page = 1, query, category, country, isBanner, queryType }
  ) {
    try {
      let params = {
        limit: 10,
        page,
        query,
        category,
        country,
        is_home_banner: isBanner,
      };
      if (queryType) params.query_type = queryType;
      let response = await axios.get(
        `${process.env.API_BASE_URL}/promotion/campaign/list/B2CONLINE`,
        { params }
      );
      const { data } = response.data;
      return data;
    } catch (error) {
      throw error;
    }
  },
  async detailPromotion(context, { id, code, callback }) {
    try {
      let response = await axios.get(
        `${process.env.API_BASE_URL}/promotion/campaign?id=${id}&code=${code}`
      );
      const { data } = response.data;
      context.commit('promoDetail', data);
      callback(data);
    } catch (error) {
      const errorResponse = error.response;

      return errorResponse;
    }
  },
  async checkPromoCode(context, params) {
    const response = await this.$axios.post(
      '/promotion/campaign/check',
      params
    );
    if (response.data) {
      const {
        data: { data },
      } = response;
      const result = data;
      Object.assign(result, {
        is_used: result.status === 'CHECK',
      });
      if (result && result.payment_methods) {
        if (result.payment_methods.length) {
          context.commit('setListPaymentMethod', result.payment_methods);
        } else {
          context.commit('setListPaymentMethod', []);
        }
      }
      context.commit('updatePromoCalculation', result);
      return result;
    } else {
      throw response;
    }
  },
};
