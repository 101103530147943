function getFilteredDocumentList(benefit, type = 'REQUIRED') {
  const { required_documents } = benefit;
  if (required_documents) {
    if (type === 'REQUIRED') {
      return required_documents.filter(
        (item) => item.isRequired === 1 || item.is_required === 1
      );
    }
    return required_documents.filter(
      (item) => item.isRequired === 0 || item.is_required === 0
    );
  }
  return [];
}

export default getFilteredDocumentList;
