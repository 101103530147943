import { TMalayHealthState } from './types';
import qoalaStorage from '~/core/Storage';
import {
  MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY,
  MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY,
  MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY,
  MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY,
  MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY,
  MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY,
} from './constants';

export default (): TMalayHealthState => ({
  prequoteData: qoalaStorage.getItem(MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY)
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_PREQUOTE_INFO_CACHE_KEY) ?? ''
      )
    : undefined,
  productList: qoalaStorage.getItem(MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY)
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_PRODUCT_LIST_CACHE_KEY) ?? ''
      )
    : undefined,
  selectedProduct: qoalaStorage.getItem(MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY)
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_SELECTED_PRODUCT_CACHE_KEY) ?? ''
      )
    : undefined,
  policyHolderInformation: qoalaStorage.getItem(
    MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY
  )
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_POLICY_HOLDER_INFO_CACHE_KEY) ?? ''
      )
    : undefined,
  quotationData: qoalaStorage.getItem(MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY)
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_QUOTATION_DATA_CACHE_KEY) ?? ''
      )
    : undefined,
  priceBreakdown: qoalaStorage.getItem(MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY)
    ? JSON.parse(
        qoalaStorage.getItem(MALAY_HEALTH_PRICE_BREAKDOWN_CACHE_KEY) ?? ''
      )
    : undefined,
});
