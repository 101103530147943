import axios from 'axios';
import { mapResponse } from './transform';

const INDO_VEHICLE_API_PATH = `/products/vehicle`;

export default {
  async recalculatePremi(context, payload) {
    const { data } = await axios.post(
      `${process.env.API_BASE_URL}/products/vehicle/re-calculate`,
      payload
    );
    const response = data.data;
    return response;
  },
  async getPlate(context) {
    try {
      const { data } = await axios.get(
        `${process.env.API_BASE_URL}/products/vehicle/plate`
      );
      const response = data.data;
      context.commit(
        'updateVehiclePlate',
        mapResponse({ type: 'vehicleInfo', response })
      );
    } catch (error) {
      return error;
    }
  },
  async getVehicle(context, { params, query, type }) {
    try {
      let res;
      // use query parameter to send string as query string (make sure to encode it first)
      if (query) {
        res = await axios.get(
          `${process.env.API_BASE_URL}/products/vehicle/vehicle?${query}`
        );
        // use queryStr parameter to send object as an encoded query string
      } else if (Object.keys(params).length > 0) {
        res = await axios.get(
          `${process.env.API_BASE_URL}/products/vehicle/vehicle`,
          { params }
        );
      } else {
        res = await axios.get(
          `${process.env.API_BASE_URL}/products/vehicle/vehicle`
        );
      }
      const response = res.data.data;
      const resultMap = mapResponse({ type: 'vehicleInfo', response });
      if (type === 'vehicleBrand') {
        context.commit('updateVehicleBrand', resultMap);
      } else if (type === 'vehicleModel') {
        context.commit('updateVehicleModel', resultMap);
      } else if (type === 'vehicleSeries') {
        context.commit('updateVehicleSeries', resultMap);
      }
    } catch (error) {
      return error;
    }
  },
  async getVehiclePriceRange(context, dataSend) {
    try {
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/products/vehicle/price-range`,
        dataSend
      );
      const response = data.data;
      return mapResponse({ type: 'vehiclePriceRange', response });
    } catch (error) {
      // const resultMap = mapResponse({ type: 'vehiclePriceRange' });
      return error;
    }
  },
  async getListSearchResult(context, { payload, country }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    uuid && (payload.uuid = uuid);
    try {
      let link;
      let vehicleType = 'CAR';
      const hasFilters = !!Object.keys(payload?.filters ?? {}).length;
      if (payload && payload.vehicle) {
        if (payload.vehicle.type.includes('MOTOR')) {
          vehicleType = 'MOTOR';
        }
      }
      switch (country) {
        case 'MY':
          link = `/products/my/vehicle?vehicle_type=${vehicleType}`;
          break;
        default:
          link = INDO_VEHICLE_API_PATH;
          break;
      }

      const { data } = await this.$axios.post(link, payload);
      const response = data.data;
      const meta = data.meta;
      context.commit('updatePolicyList', { hasFilters, value: response });
      context.commit('updatePolicyListMeta', meta);
      return mapResponse({ type: 'productList', response });
    } catch (error) {
      const errorResponse = error.response;
      return errorResponse;
    }
  },
  async getIndoProductList(ctx, payload) {
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    try {
      const { data } = await this.$axios.post(INDO_VEHICLE_API_PATH, payload);
      ctx.commit('updateIndoPolicyList', data.data);
      return mapResponse({ type: 'productList', response: data.data });
    } catch (error) {
      return error.response;
    }
  },
  async getDocumentsType(context) {
    try {
      let link = `${process.env.API_BASE_URL}/policies/documents/types`;
      const { data } = await axios.get(link);
      const response = data.data;
      context.commit(
        'updateDocumentsType',
        mapResponse({ type: 'documentsType', response })
      );
    } catch (error) {
      const errorResponse = error.response;
      return errorResponse;
    }
  },
  async getDocumentPresignLink(context, payload) {
    try {
      const { data } = await this.$axios.post(
        `${process.env.API_BASE_URL}/policies/documents/get-upload-url`,
        payload
      );
      const response = data;
      return mapResponse({ type: 'documentPresignLink', response });
    } catch (error) {
      return false;
    }
  },
  // MALAYSIA
  async getVehicleCar(
    context,
    {
      identityNumber,
      regisNumber,
      cancleToken,
      zipCode,
      phoneNumber,
      idType,
      vehicleType,
    }
  ) {
    const axiosSource = axios.CancelToken.source();
    if (cancleToken) cancleToken(axiosSource);
    let query = `?identityNumber=${identityNumber}&registrationNumber=${regisNumber}&zipCode=${zipCode}&phoneNumber=${phoneNumber}&idType=${idType}&vehicleType=${vehicleType}`;
    let url = `${process.env.API_BASE_URL}/products/my/vehicle/vehicle${query}`;
    const { data } = await axios.get(url, { cancelToken: axiosSource.token });
    const response = data.data;
    return response;
  },
  async calculatePremi(context, { payload }) {
    try {
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/products/my/vehicle/calculate`,
        payload
      );
      const response = data.data;
      return response;
    } catch (error) {
      const { response } = error;
      throw response.data;
    }
  },
  async getAddons(context, { payload }) {
    try {
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/products/my/vehicle/addons`,
        payload
      );
      const response = data.data;
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async sendLeads(context, { payload }) {
    try {
      const uuid = await context.rootGetters['user/getUuid'];
      payload.product_category = 'VEHICLE';
      payload.uuid = uuid;
      payload.utm = this.$constructUtmLeadPayload().utmParams;
      payload.referer = this.$constructUtmLeadPayload().referer;
      payload.country = context.rootGetters['core/getCountryCode'];
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/products/my/vehicle/leads`,
        payload
      );
      const response = data.data;
      return response.data;
    } catch (error) {
      this.$sentry.withScope((scope) => {
        scope.setExtra('payload', payload);
        this.$sentry.captureMessage(`FAILED TO SAVE LEADS`);
      });
      throw error;
    }
  },
  async getPostCode(context, { postCode }) {
    const endPoint =
      process.env.NODE_ENV === 'production'
        ? 'https://api.qoalaplus.com'
        : 'https://api.staging.qoalaplus.com';

    try {
      const { data } = await axios.get(
        `${endPoint}/vehicle/locations/my/postcode?code=${postCode}`
      );
      const response = data.data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getState(context, { postCode, state }) {
    const endPoint =
      process.env.NODE_ENV === 'production'
        ? 'https://api.qoalaplus.com'
        : 'https://api.staging.qoalaplus.com';

    try {
      const { data } = await axios.get(
        `${endPoint}/vehicle/locations/my/postcode?code=${postCode}&state=${state}`
      );
      const response = data.data;
      return response;
    } catch (error) {
      throw error;
    }
  },
};
