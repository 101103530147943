import qoalaStorage from '~/core/Storage';

export default {
  setPersonalAccidentData(state, value) {
    state.personalAccidentData = value;
    qoalaStorage.setItem('personalAccidentData', JSON.stringify(value));
  },
  deletePersonalAccidentData(state) {
    state.personalAccidentData = {};
    state.listProduct = [];
    qoalaStorage.removeItem('personalAccidentData');
  },
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('personalAccidentProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('productDetailPersonalAccident', JSON.stringify(state.productDetail));
  }
};
