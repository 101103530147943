import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getListPromotion: state => state.promoData,
  searchDetailPromo: state => {
    if (!state.detailPromo) {
      return qoalaStorage.getItem('detailPromo')
        ? parseIfJsonString(qoalaStorage.getItem('detailPromo'))
        : state.detailPromo;
    }
    return state.detailPromo;
  },
  getSearchDetailListPromo: state => state.searchDetailPromo,
  getSearchCodePromotion: state => state.searchCodePromo,
  promoDetail: state => state.useCodePromo,
  getPromoProduct: state => {
    if (!state.promoProduct) {
      return qoalaStorage.getItem('promoProduct')
        ? parseIfJsonString(qoalaStorage.getItem('promoProduct'))
        : state.promoProduct;
    }
    return state.promoProduct;
  },
  getPromoCalculation: state => {
    if (!state.promoCalculation) {
      return qoalaStorage.getItem('promoCalculation')
        ? parseIfJsonString(qoalaStorage.getItem('promoCalculation'))
        : state.promoCalculation;
    }
    return state.promoCalculation;
  },
  getListPaymentMethod: state => {
    if (!state.listPaymentMethod) {
      return qoalaStorage.getItem('listPaymentMethod')
        ? parseIfJsonString(qoalaStorage.getItem('listPaymentMethod'))
        : state.listPaymentMethod;
    }
    return state.listPaymentMethod;
  }
};
