import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getListProduct: state => {
    if (!state.listProduct) {
      return qoalaStorage.getItem('lifeInsuranceProduct')
        ? parseIfJsonString(qoalaStorage.getItem('lifeInsuranceProduct'))
        : state.listProduct || [];
    }
    return state.listProduct;
  },
  getProductDetail: state => {
    if (!state.productDetail) {
      return qoalaStorage.getItem('productDetailLife')
        ? parseIfJsonString(qoalaStorage.getItem('productDetailLife'))
        : state.productDetail || {};
    }
    return state.productDetail;
  },
  getLifePurchaseHistory: state => {
    if (!state.lifePurchaseHistory) {
      return qoalaStorage.getItem('lifePurchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('lifePurchaseHistory'))
        : state.lifePurchaseHistory || {};
    }
    return state.lifePurchaseHistory;
  }
};
