import qoalaStorage from '~/core/Storage';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  getTransactionHistory: state => {
    return state.transactionHistory || [];
  },
  getPolicyList: state => {
    return state.policyList || [];
  },
  getClaims: state => {
    if (!state.claims) {
      return '';
    }
    if (state.claims === 'undefined') {
      return '';
    }
    if (typeof state.claims === 'string') {
      return JSON.parse(state.claims);
    }
    return state.claims;
  },
  getSmartphonePurchaseHistory: state => {
    if (!state.smartphonePurchaseHistory)
      return qoalaStorage.getItem('smartphonePurchaseHistory')
        ? parseIfJsonString(qoalaStorage.getItem('smartphonePurchaseHistory'))
        : state.smartphonePurchaseHistory;
    return state.smartphonePurchaseHistory;
  },
  getSmartphoneBrand: state => {
    if (!state.smartphoneBrand)
      return qoalaStorage.getItem('smartphoneBrand')
        ? parseIfJsonString(qoalaStorage.getItem('smartphoneBrand'))
        : state.smartphoneBrand;
    return state.smartphoneBrand;
  },
  getSmartphoneModel: state => {
    if (!state.smartphoneModel) {
      return qoalaStorage.getItem('smartphoneModel')
        ? parseIfJsonString(qoalaStorage.getItem('smartphoneModel'))
        : state.smartphoneModel;
    }
    return state.smartphoneModel;
  },
  getListProduct: state => {
    if (!state.listProduct)
      return qoalaStorage.getItem('smartphoneProduct')
        ? parseIfJsonString(qoalaStorage.getItem('smartphoneProduct'))
        : state.listProduct;
    return state.listProduct;
  },
  getProductDetail: state => {
    if (!state.productDetail)
      return qoalaStorage.getItem('smartphoneProductDetail')
        ? parseIfJsonString(qoalaStorage.getItem('smartphoneProductDetail'))
        : state.productDetail;
    return state.productDetail;
  }
};
