import { getContentResource } from '~/core/ContentResource';

export default async ({ $axios, store, app }) => {
  const CONTENT_QUERY = {
    QoalaOnlineError: [],
  };
  const locale = app.i18n.locales.find(
    (locale) => locale.code === app.i18n.locale
  ).iso;
  let contentLocale = locale.includes('en') ? 'en_ID' : locale;
  const method = 'get';
  const type = 'generalError';

  // Content i18n Resources
  const i18nResources = app.i18n
    ? app.i18n.t('QoalaOnlineError')
    : 'QoalaOnlineError';
  let dataError = [];
  if (i18nResources && typeof i18nResources !== 'string') {
    const dataErrorI18n = Object.keys(i18nResources).map((resource) => {
      return {
        entry: 'QoalaOnlineError',
        key: resource,
        value: i18nResources[resource],
        locale,
      };
    });
    dataError = dataErrorI18n;
  } else {
    // Data error fetching from API Airtable
    const data = await getContentResource(
      CONTENT_QUERY,
      contentLocale,
      method,
      type
    );
    dataError = data ? data : [];
  }

  const refresh = store.getters['user/getUserTokenRefresh'];
  const token = store.getters['user/getUserToken'];
  if (token) $axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const allowedPath = (path) => {
    if (!path) return true;
    if (path.indexOf('/documents') >= 0) return false;
    // if (path.indexOf('/notifications/unread') >= 0) return false;
    if (path.indexOf('/banks') >= 0) return false;
    if (path.indexOf('/sessions') >= 0) return false;
    if (path.indexOf('/registrations') >= 0) return false;
    if (path.indexOf('/purchase') >= 0) return false;
    if (path.indexOf('/users/insurance') >= 0) return false;
    if (path.indexOf('/country-detect') >= 0) return false;
    if (path.indexOf('/policies') >= 0) return false;
    if (path.indexOf('/promotion/campaign/check') >= 0) return false;
    if (path.indexOf('/products') >= 0) return false;
    if (path.indexOf('/payment/debit') >= 0) return false;
    if (path.indexOf('/quotes') >= 0) return false;
    return true;
  };

  $axios.defaults.timeout = 1000 * 3600 * 48;

  if (refresh) {
    await store.dispatch('user/refreshToken', {
      credentials: {
        refresh,
      },
    });
  }

  $axios.onRequest(async (config) => {
    // Set the 'Language' header in Axios requests only for '/v2/' API endpoints
    if (config.url.includes('/v2/')) {
      // Use the app's current locale, or default to 'id' if not set
      const language = app.i18n.localeProperties.iso639 || 'id';
      // Set the 'Language' header in Axios requests to communicate the language preference
      config.headers.common.Language = language;
    }
    const currentData = dataError[0];
    if (currentData && currentData.locale && app.i18n) {
      if (currentData.locale.indexOf(app.i18n.locale) < 0) {
        let currentLocale = app.i18n.locales.find(
          (lang) => lang.code === app.i18n.locale
        ).iso;
        if (currentLocale.includes('en')) {
          currentLocale = 'en_ID';
        }
        const currentData = await getContentResource(
          {
            QoalaOnlineError: [],
          },
          currentLocale,
          'get',
          'generalError'
        );
        dataError = currentData ? currentData : [];
      }
    }
    const isLogin = store.getters['user/getUserToken'];
    const dataPayload = config.data || {};
    if (dataPayload.hideModalMessage) {
      config.data = dataPayload.data;
      config.hideModalMessage = dataPayload.hideModalMessage;
      return config;
    }
    if (!isLogin) {
      if (!allowedPath(config.url)) {
        // store.dispatch('general/setLoader', true);
        return config;
      }
      return Promise.reject();
    }
    if (
      dataPayload.hideLoading ||
      config.url.indexOf('/policies?sortDirection') >= 0 ||
      config.url.indexOf('/claims?orderBy') >= 0 ||
      config.url.indexOf('/contents/?source=qoalaapp') >= 0
    ) {
      config.data = dataPayload.data;
      return config;
    }
    return config;
  });

  $axios.onResponse(() => {
    store.dispatch('general/setLoader', false);
  });

  $axios.onRequestError(async (error) => {
    const errorLogout = [
      'QUS-AUTH-008',
      'USER-SESSION-005',
      'USER-SESSION-004',
      'USER-SESSION-003',
      'USER-SESSION-002',
      'USER-SESSION-001',
    ];
    const generalError = dataError.find((error) => {
      return error.key === 'wentWrongLabel';
    });
    if (error.response) {
      const { errorCode, error_code } = error.response.data;
      if (error.response) {
        const { data, config } = error.response;
        const { hideModalMessage } = config;
        const errorTitle = dataError.find((mess) => mess.key === 'sorryLabel');
        const message = {
          errorCode,
          title: errorTitle,
          message: data.message,
        };
        const error_data_code = errorCode || error_code;
        try {
          const messageError = dataError.find(
            (mess) => mess.key === error_data_code
          );
          if (messageError) {
            message.message = messageError.value;
          } else {
            message.title = message.title + ' ' + error_data_code;
            message.message = generalError.value;
          }
          if (errorLogout.indexOf(error_data_code) >= 0) {
            await store.dispatch('user/logout');
          }
          store.dispatch('general/setLoader', false);
          store.dispatch('general/setModalMessage', !hideModalMessage);
          store.dispatch('general/setMessage', message);
          throw error;
        } catch (err) {
          return err;
        }
      }
    } else {
      return error;
    }
  });

  $axios.onResponseError(async (error) => {
    const errorLogout = [
      'QUS-AUTH-008',
      'USER-SESSION-004',
      'USER-SESSION-003',
      'USER-SESSION-002',
      'USER-SESSION-001',
    ];
    const generalError = dataError.find((error) => {
      return error.key === 'wentWrongLabel';
    });
    if (error.response) {
      const { errorCode, error_code } = error.response.data;
      const { data, config } = error.response;
      const { hideModalMessage } = config;
      const message = {
        errorCode,
        title: 'ERROR',
        message: data.message,
      };
      const error_data_code = errorCode || error_code;
      try {
        const messageError = dataError.find(
          (mess) => mess.key === error_data_code
        );
        if (messageError) {
          message.message = messageError.value;
        } else {
          message.title = message.title + ' ' + error_data_code;
          message.message = generalError.value;
        }
        if (errorLogout.indexOf(error_data_code) >= 0) {
          await store.dispatch('user/logout');
        }
        store.dispatch('general/setLoader', false);
        store.dispatch('general/setModalMessage', !hideModalMessage);
        store.dispatch('general/setMessage', message);
        throw error;
      } catch (err) {
        console.log(err);
        return err;
      }
    } else {
      store.dispatch('general/setLoader', false);
      return error;
    }
  });

  $axios.onError(async (error) => {
    const errorLogout = [
      'QUS-AUTH-008',
      'USER-SESSION-004',
      'USER-SESSION-003',
      'USER-SESSION-002',
      'USER-SESSION-001',
    ];
    const generalError = dataError.find((error) => {
      return error.key === 'wentWrongLabel';
    });
    if (error.response) {
      const { errorCode, error_code } = error.response.data;
      let apiPath = '';
      if (error.response) {
        const { data, config } = error.response;
        apiPath = config.url;
        const { hideModalMessage } = config;
        const message = {
          errorCode,
          title: 'ERROR',
          message: data.message,
        };
        const error_data_code = errorCode || error_code;
        try {
          const messageError = dataError.find(
            (mess) => mess.key === error_data_code
          );
          if (messageError) {
            message.message = messageError.value;
          } else {
            message.title = message.title + ' ' + error_data_code;
            message.message = generalError.value;
          }
          if (errorLogout.indexOf(error_data_code) >= 0) {
            app.$gtm.pushEvent({ event: 'setUserId', userId: 'anonymous' });
            await store.dispatch('user/logout');
          }
          store.dispatch('general/setLoader', false);
          store.dispatch('general/setModalMessage', !hideModalMessage);
          store.dispatch('general/setMessage', message);
          throw error;
        } catch (err) {
          console.log(err);
          return err;
        }
      } else {
        apiPath = error.code;
        console.log('error', error);
      }

      if (allowedPath(apiPath)) {
        window.errorHandler.report(
          `${apiPath} -> ${JSON.stringify(error.response)}`
        );
      }
    } else {
      return error;
    }
  });
};
