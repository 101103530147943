class Transform {
  constructor({ response, data }) {
    this.response = response;
    this.data = data;
  }

  get productList() {
    const array = [];
    this.response.map(item => {
      const data = {
        admin_fee: item.admin_fee,
        category: item.category,
        currency_code: item.currency_code,
        detail: item.detail,
        hardcopy_admin_fee: item.hardcopy_admin_fee,
        insurance: item.insurance,
        insurance_code: item.insurance_code,
        insurer_name: item.insurer_name,
        logo: item.logo,
        name: item.name,
        premium: item.premium,
        premium_period: item.premium_period,
        premium_type: item.premium_type,
        product_category: item.product_category,
        product_code: item.product_code,
        type: 'smartphone'
      };
      array.push(data);
    });
    return array;
  }
}

export const mapResponse = ({ type, response }) => {
  const transformResponse = new Transform({ response });
  return transformResponse[type];
};
