import qoalaStorage from '~/core/Storage';

export default {
  setQuotationSummary(state, value) {
    state.quotationSummary = value;
    qoalaStorage.setItem(
      'quotationSummary',
      JSON.stringify(state.quotationSummary)
    );
  },
  removeQuotationSummary(state) {
    state.quotationSummary = {};
    qoalaStorage.removeItem('quotationSummary');
  },
  setQuotationScraping(state, value) {
    state.quotationScraping = value;
    qoalaStorage.setItem(
      'quotationScraping',
      JSON.stringify(state.quotationScraping)
    );
  },
  removeQuotationScraping(state) {
    state.quotationScraping = {};
    qoalaStorage.removeItem('quotationScraping');
  },
  setQuotationSharePath(state, value) {
    state.quotationSharePath = value;
    qoalaStorage.setItem(
      'quotationSharePath',
      JSON.stringify(state.quotationSharePath)
    );
  },
};
