export default {
  async getProductList(context, { payload }) {
    const uuid = await context.rootGetters['user/getUuid'];
    payload.utm = this.$constructUtmLeadPayload().utmParams;
    payload.referer = this.$constructUtmLeadPayload().referer;
    payload.uuid = uuid;
    const { data } = await this.$axios.post(`/products/my/property`, payload);
    const response = data;
    context.commit('setListProduct', response.data);
    return response.data;
  },
  async getRecalculatePremi(context, { payload }) {
    const { data } = await this.$axios.post(
      `/products/my/property/calculate`,
      payload
    );
    return data.data;
  },
};
