import qoalaStorage from '~/core/Storage';

export default {
  updateTransactionHistory(state, value) {
    state.transactionHistory = value;
  },
  updatePolicyList(state, value) {
    state.policyList = value;
  },
  updateClaims(state, value) {
    state.claims = value;
  },
  updateSmartphonePurchaseHistory(state, value) {
    state.smartphonePurchaseHistory = value;
    qoalaStorage.setItem('smartphonePurchaseHistory', JSON.stringify(state.smartphonePurchaseHistory));
  },
  deleteSmartphonePurchaseHistory(state) {
    qoalaStorage.removeItem('smartphonePurchaseHistory');
    state.smartphonePurchaseHistory = {};
  },
  updateSmartphoneBrand(state, value) {
    state.smartphoneBrand = value;
    qoalaStorage.setItem('smartphoneBrand', JSON.stringify(state.smartphoneBrand));
  },
  deleteSmartphoneBrand(state) {
    state.smartphoneBrand = [];
    qoalaStorage.removeItem('smartphoneBrand');
  },
  updateSmartphoneModel(state, value) {
    state.smartphoneModel = value;
    qoalaStorage.setItem('smartphoneModel', JSON.stringify(state.smartphoneModel));
  },
  deleteSmartphoneModel(state) {
    state.smartphoneModel = [];
    qoalaStorage.removeItem('smartphoneModel');
  },
  setListProduct(state, value) {
    state.listProduct = value;
    qoalaStorage.setItem('smartphoneProduct', JSON.stringify(state.listProduct));
  },
  setProductDetail(state, value) {
    state.productDetail = value;
    qoalaStorage.setItem('smartphoneProductDetail', JSON.stringify(state.productDetail));
  },
  deleteData(state) {
    state.smartphonePurchaseHistory = {};
    state.smartphoneBrand = [];
    state.smartphoneModel = [];
    state.listProduct = [];
    state.smartphoneProductDetail = {};
    qoalaStorage.removeItem('smartphoneProduct');
    qoalaStorage.removeItem('smartphonePurchaseHistory');
    qoalaStorage.removeItem('smartphoneBrand');
    qoalaStorage.removeItem('smartphoneModel');
    qoalaStorage.removeItem('smartphoneProductDetail');
    qoalaStorage.removeItem('smartPhonePurchaseConfirmation');
  }
};
